
import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { getToken } from "../../utils/ManageToken";
import { getUserDetailsApi } from "../onBoarding/Api";
import { useHistory } from "react-router-dom";

//? ======================= COMMON GET DATA ===============================
export const getDataApi = async (url) => {
  try {
    // console.log("In profile Api", url)
    const response = await postLoginInstance.get(url);
    const data = response.data;
    //   console.log(data)
    return data;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    return [];
  }
};
export const getDetailsApi = async (url) => {
  try {
    // console.log("In profile Api", url)
    const response = await postLoginInstance.get(url);
    return response;
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return error;
  }
};
export const commonAuthApis = async (url, data = "", method = "post") => {
  try {
    // console.log("In profile Api", url,data,method)
    const response = await postLoginInstance[method](url, data);
    // console.log(response)
    return response;
  } catch (error) {
    toast.error(error.response?.data?.message || "Something went wrong")
    throw error;
  }
};
export const commonPostApi = async (url, data = "", method = "post") => {
  try {
    // console.log("API request initiated", method);

    if (method.toLowerCase() === "delete") {
      const response = await postLoginInstance.delete(url);
      toast.success(response.data.data.message);
      return response.data;
    } else {
      const response = await postLoginInstance[method](url, data);
      return response.data;
    }
  } catch (error) {
    toast.error(error?.response?.data?.message || "Something went wrong")
    return false;
  }
};

export const verifyMobileApi = (ctx, data) => {
  postLoginInstance.post('user/sentMobileNumberOtp', data)
    .then(res => {
      ctx.setState({
        changeMobile: !ctx.state.changeMobile,
        otpSent: true,
        showResend: true,
        showTimer: true
      })
      ctx.startCountdown();
      toast.success(res?.data?.data.message || "Success")
      getUserDetailsApi(ctx);
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const verifyOtpApi = (ctx, data) => {
  postLoginInstance.post('user/verifyMobileNumberOtp', data)
    .then(res => {
      ctx.setState({
        showResend: false,
        changeMobile: false,
        otpSent: false
      })
      toast.success(res?.data?.data.message || "Verified Successfully")
      getUserDetailsApi(ctx);
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong ")
    });
}


export const sendEmailVerificationApi = (data) => {
  postLoginInstance.post('user/sentEmailAddressVerificationUrl', data)
    .then(res => {
      toast.success(res?.data?.data.message || "A verification link has been sent to your email account")
      getUserDetailsApi();
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong ")
    });
}

// CAPTCHA
export const verifyCaptchaApi = (data, ctx) => {
  postLoginInstance.post('user/verifyCaptcha', data)
    .then(res => {
      // toast.success(res?.data?.data.message || "")
      if (res?.data?.data) {
        ctx.setState({
          showOtpBtn: true
        })
      }
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}


export const getPlansApi = async (url) => {
  // console.log("Video details id", id)
  try {
    const response = await (getToken() ? postLoginInstance : preLoginInstance).get(url);
    return response;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    return error;
  }
};

export const purchaseAmoutBreakup = (data, history) => {
  postLoginInstance.post('user/billing-plan/purchase-amount-breakup', data)
    .then(res => {
      // console.log('Success', res);
      history.push({
        pathname: "/checkout",
        state: {
          data: res?.data?.data,
          requestObj: data,
          isNewPurchase: true,
        }
      })
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const upgradeAmoutBreakup = (data, history) => {
  postLoginInstance.post('user/billing-plan/upgrade-amount-breakup', data)
    .then(res => {
      // console.log('Success', res);
      history.push({
        pathname: "/checkout",
        state: {
          data: res?.data?.data,
          requestObj: data,
          isNewPurchase: false,
        }
      })
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const purchasePlanApi = (data) => {
  // console.log('hey', data);
  postLoginInstance.post('user/billing-plan', data)
    .then(res => {
      toast.success("Success")
      // console.log('Success', res);
      processUserBillingApi(res?.data?.data.uuid)
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}


export const upgradePlanApi = (data, userBillingUuid) => {
  // console.log('hey', data);
  postLoginInstance.put(`user/billing-plan/${userBillingUuid}/upgrade`, data)
    .then(res => {
      toast.success("Success")
      // console.log('Success', res);
      processUserBillingApi(res?.data?.data.newBillingDetails.uuid)
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}
export const processUserBillingApi = (uuid) => {
  postLoginInstance.post(`user/billing-plan/${uuid}/process`)
    .then(res => {
      toast.success("Success")
      // console.log('Success', res);
      const history = useHistory();
      // console.log('history', history);
      history.push("/mymembership")
    })
    .catch(error => {
      // console.log('error', error);
      toast.error(error.response.data.message || "Something went wrong")
    });
}
