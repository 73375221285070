import React, { Component } from "react";
import { connect } from "react-redux";
import { Card, Image, Modal } from "react-bootstrap";
import { MdDownloadForOffline } from "react-icons/md";
import BlackHeart from "../../assets/images/black-heart.svg";
import BlackEye from "../../assets/images/black-eye.svg";
import ViewIcon from "../../assets/images/icon/view-icon-24.svg";
import BlackShare from "../../assets/images/icon/Share_1-24.svg";
import BlackBookmark from "../../assets/images/black-save.svg";
import Profile from "../../assets/images/fiba-logo.png";
import defaultIcon from "../../assets/images/default-grey.png";
import download from "../../assets/images/download-icon.svg";
import saveFilled from "../../assets/images/icon/save-field-24.svg";
import save from "../../assets/images/icon/save-24.svg";
import liked from "../../assets/images/Like.svg";
import likdeFill from "../../assets/images/Like-fill.svg";
import video from "../../assets/images/video-play-img.svg";
import BlackOutlineDownload from "../../assets/images/icon/Download_1-24.svg";
import Hashtag from "../../assets/images/hashtag-icon.svg";
import BlackDownload from "../../assets/images/black-download.svg";
import PlayVideo from "../../assets/video/video-detail.mp4";
import Footer from "../common/pages/Footer";
import { Trending, VideoCards2 } from "../common";
import {
  VideoDetailsDownloadModal,
  VideoDetailsContentType,
  Multiviewer,
  ContentTypesCards,
  BreadCrumb
} from "./_utils";
import InfographicsDetails from "./_utils/InfographicsDetails";
import VideoContentDetails from "./_utils/VideoContentDetails";
import close from "../../assets/images/cancel-icon.svg";
import vsite from "../../assets/images/vsite.svg";
// import Presentation from "../../assets/images/presentation-details.png";
import { commonAuthPostApi, getDetailsApi, getSearchAllDataApi } from "./Api";
import { API_LIMIT, DEFAULT_PUBLISH_DATE, MAX_LIMIT, NA, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking, VIDEOS } from "../../utils/Constant";
import { CustomDropDown } from "../../utils/form";
import { formatDate, formatNumber, isToday } from "../../utils/ReusableFunctions";
import { Header, NotFound } from "../common/pages";
import { DetailsSkeleton } from "../common/components/SkeletonCard";
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { BsFillBookmarkFill, BsBookmarkFill, BiSolidBookmark, BsBookmark } from "react-icons/bs";
import { AllShimmer, SignUp } from "../common/components";
import { getToken } from "../../utils/ManageToken";
import { RWebShare } from "react-web-share";
import { toast } from "react-toastify";
import SaveCollectionModal from "../common/components/SaveCollectionModal";
import { saveAs } from 'file-saver';
import moment from "moment";
// const colorObject = [
//   { colorCode: "red", colorName: "Red" },
//   { colorCode: "green", colorName: "green" },
//   { colorCode: "blue", colorName: "blue" },
//   { colorCode: "pink", colorName: "pink" },
// ];
// const languageObj = [
//   { languageName: "Englsh" },
//   { languageName: "Latin" },
//   { languageName: "Spanish" },
// ];


class VideoDetails extends Component {
  constructor(props) {
    super(props);
    const searchValueState = props.location?.state?.searchValue || "";
    const { type } = props.match.params;
    // console.log(searchValueState)
    this.state = {
      searchValue: searchValueState,
      videoId: type?.split("&id=")[1],
      contentType: type,
      suggestionData: [],
      allData: [],
      contentData: [],
      language: "",
      color: "",
      path: "",
      updatedTitle: "",
      updatedDescription: "",
      variationId: "",
      variation: [],
      show: false,
      link: "",
      likeCount: '',
      loading: true,
      isLoading: true,
      isError: false,
      isSaved: null,
      isLiked: null,
      likeDisable: true,
      isView: null,
      viewMore: true,
      saveTo: false,
      confirm: false,
      isDownload: false,
      collectionName: "",
      signup: false

    };
  }
  showDownloadModal = () => {
    // console.log("call Download Model")
    this.setState({
      show: !this.state.show
    })
  }
  breadCrumbHandler = () => {
    const { type } = this.props.match.params;
    const { state } = this.props.location;
    // console.log(state)
    const { updatedTitle } = this.state;
    let baseCrumb = type.split("&id=")[0].replace(/^\w/, (a) => a.toUpperCase()).replace("_", "/");
    if (state?.breadCrumbPath) {
      return state?.breadCrumbPath.length === 1 ? [state.breadCrumbPath[0], baseCrumb, updatedTitle,] : [state.breadCrumbPath[0], state.breadCrumbPath[1], baseCrumb, updatedTitle,]
    } else {
      return [baseCrumb, updatedTitle,]
    }

  }

  async loadData() {
    const id = this.state.contentType?.split("&id=")[1];
    const types = this.state.contentType?.split("&id=")[0];


    if (id) {
      const locationState = this.props.location?.state;
      const data = await getDetailsApi(`website/content/details/${id}`);
      // console.log(!data?.response?.data?.message)
      // console.log("Check this current data ",data.data.data)
      const variation = data.data.data?.contentVariation;
      variation && variation.length > 0 && variation.push(data.data.data?.defaultVariation);
      // * IF COMINF FORM LANGUAGES THEN SET DEFAULT LANGUAGE
      const isItFromLang = locationState?.breadCrumbPath[0] === 'languages';
      let getExactLangObj = false;
      if (isItFromLang) {
        getExactLangObj = variation.length > 0 ? variation?.find((item, i) => (item.language?.languageName === locationState?.breadCrumbPath[1])) : data?.data?.data?.defaultVariation;
        this.setState({
          variationId: getExactLangObj?.uuid,
          link: getExactLangObj?.extUrlLink,
        })
      }
      // console.log(getExactLangObj,locationState?.breadCrumbPath,variation)
      if (!data?.response?.data?.message) {
        this.setState({
          updatedTitle: isItFromLang ? getExactLangObj?.title : data.data.data?.defaultVariation?.title,
          updatedDescription: isItFromLang ? getExactLangObj?.description : data.data.data?.defaultVariation?.description,
          allData: data.data.data,
          contentData: data.data.data,
          variation,
          language: isItFromLang ? getExactLangObj?.language?.languageName : data.data.data?.defaultVariation?.language?.languageName,
          color: isItFromLang ? getExactLangObj?.color : data.data.data?.defaultVariation?.color,
          path: isItFromLang ? getExactLangObj?.contentFilePath : data.data.data?.defaultVariation?.contentFilePath,
          loading: false, isLiked: data.data.data?.userStats?.isLiked,
          isSaved: data.data.data?.userStats?.isSaved,
          isView: data.data.data?.userStats?.isViewed,
          isDownload: data.data.data?.userStats?.isDownloaded,
          likeCount: data?.data?.data?.likeCount
        });
        //todo:- this.setState({ language: data.data.data?.defaultVariation?.language?.languageName, color: data.data.data?.defaultVariation?.color });
        // let keywords = data.data.data?.keywords?.split(", ")?.map((item, i) => `keywords=${item}`).join("&");
        // const suggestionData = await getSearchAllDataApi((types === "All") ? "" : types?.replace("_", "/"), `search=&${keywords !== "keywords=" ? keywords.trim() : ""}&pageNumber=${START_PAGE}&pageSize=8`);
        let keywords = data.data.data?.keywords.replace(/#/g, "");
        // const suggestionData = await getSearchAllDataApi((types === "All") ? "" : types?.replace("_", "/"), `search=&keyword=${locationState?.searchValue ? locationState.searchValue :(keywords ? keywords : '')}&pageNumber=${START_PAGE}&pageSize=8`);
        const suggestionData = await getSearchAllDataApi((types === "All") ? "" : types?.replace("_", "/"), `search=${this.state.searchValue || ""}&pageNumber=${START_PAGE}&pageSize=8`);
        // console.log("Vidoe Details kywords", data.data.data?.keywords);

        // const suggestionDataFilter=data.data.data?.contents[0]?.suggestions.filter((item,i)=>item.uuid !==id);
        // console.log(suggestionData?.keywords !== "" && suggestionData?.keywords?.split(", "));
        // // console.log("Suggation Data:-", suggestionData)
        if ((suggestionData?.contents?.length ?? false)) {
          this.setState({ suggestionData, isLoading: false });
        } else {
          this.setState({ isLoading: false });
        }
      } else {
        this.setState({ isError: true })
      }


    }
  }
  userTrackingHandler = async (type) => {
    // const id = this.state.contentType?.split("&id=")[1];
    // console.log("Check Id", this.state.videoId)
    const info = {
      "data": {
        "contentUuid": this.state.videoId,
        "type": type,
        "contentVariationUuid": this.state.variationId ? this.state.variationId : this.state.contentData?.defaultVariation.uuid
      }
    }
    let token = getToken();
    if (!token) {
      // toast.error("You need to login first");
      if (type !== UserTracking.SHARED && type !== UserTracking.DOWNLOADED) {
        this.setState({ signup: true })
      } else if (type === UserTracking.DOWNLOADED) {
        // console.log("yes downl call");
        // console.log("yes downl call");
        // const response = await commonAuthPostApi(USER_TRACKING, info);
      }
    } else {
      this.setState({ likeDisable: false })
      if (type === UserTracking.LIKED || type === UserTracking.UNLIKE) {
        const response = await commonAuthPostApi(USER_TRACKING, info);
        if (!response.message) {
          const newCount = type === UserTracking.LIKED ? this.state.likeCount + 1 : this.state.likeCount - 1;
          this.setState({ isLiked: !this.state.isLiked, likeCount: newCount, likeDisable: true })
          // toast.success(`Succesfully Downloaded`);
        }
        // console.log(!response.message)
      }
      else if (type === UserTracking.DOWNLOADED && this.state.path) {
        const response = await commonAuthPostApi(USER_TRACKING, info);
        if (!response.message) {
          toast.success(`Successfully Downloaded`);
        }
      }

    }
  }
  async componentDidMount() {
    this.loadData();
  }

  async componentDidUpdate(prevProps) {
    const { type } = this.props.match.params;
    const prevType = prevProps.match.params.type;

    // Check if the type in the URL has changed
    if (type !== prevType) {
      this.setState({ contentType: type }, () => {
        this.loadData();
        window.scrollTo(0, 0);
      });
    }
  }
  setDefaultVariationData = (params) => {
    // console.log(params);
    let downloadPath = params?.contentFilePath;
    let youtubeLink = params?.extUrlLink;
    let updatedTitle = params?.title;
    let updatedDescription = params?.description;
    let variationId = params?.uuid;
    this.setState({
      updatedDescription,
      variationId,
      updatedTitle,
      language: params.languageName,
      color: params?.color || this.state.color,
      path: downloadPath,
      link: youtubeLink
    })

  }

  languageSelectHandler = (data) => {
    console.log("U selct this Language :-", data);
    console.log("Cehck all varatin data = ", this.state.variation)
    console.log("What is current Color in state = ", this.state.color)
    console.log("What is current Language in state = ", this.state.language)
    // let getFixedObj = this.state.variation?.find((item, i) => ( item.color?.colorName === this.state?.color?.colorName || item.language?.languageName === data?.languageName ));
    let getFixedObj = this.state.variation?.find(item =>
      item.color?.colorName === this.state.color.colorName && item.language?.languageName === data.languageName
    ) || this.state.variation?.find(item =>
      item.language?.languageName === data.languageName
    );

    getFixedObj.languageName = data.languageName;
    console.log("As select language Name :-", getFixedObj)
    this.setDefaultVariationData(getFixedObj);
  }
  colorSelectHandler = (data) => {
    let getFixedObj = this.state.variation?.find((item, i) => (item.color?.colorName === data?.colorName && item.language?.languageName === this.state.language));
    getFixedObj.languageName = this.state.language;
    this.setDefaultVariationData(getFixedObj);
  }
  saveToCollectionHandler = () => {
    let token = getToken();
    if (!token) {
      this.setState({ signup: true })
    } else {
      this.setState({ saveTo: true })
    }
  }
  downloadVideohandler = async () => {
    const token = this.state.allData?.isPremium;
    // console.log(token, this.state.allData?.isPremium)
    // console.log(token, this.state.allData?.isPremium)
    const info = {
      "data": {
        "contentUuid": this.state.videoId,
        "type": UserTracking.DOWNLOADED,
        "contentVariationUuid": this.state.variationId ? this.state.variationId : this.state.contentData?.defaultVariation.uuid
      }
    }
    if (!token) {
      const anchor = document.createElement('a');
      const auth = getToken()
      if (this.state.path) {
        // anchor.click();
        // const fileURL = this.state.path === undefined ? this.state.allData?.defaultVariation?.contentFilePath : this.state.path; // Replace with the actual file URL.
        // const filename = 'downloadFile';
        // // console.log(filename);
        // saveAs(fileURL, filename);
        // this.userTrackingHandler(UserTracking.DOWNLOADED);
        // this.setState({ isDownload: true })
        this.showDownloadModal()

        // const response = await commonAuthPostApi(USER_TRACKING, info);
      } else {
        // toast.error("No download file available");
      }


    } else {
      this.setState({ signup: true })
    }
  }
  conformCollectionPopUp = (data) => {
    // console.log("Conform to saved says,",data)
    this.setState({
      saveTo: data.label,
      confirm: !this.state.confirm,
      isSaved: true,
      collectionName: data.selectedItem
    })
  }
  navigateAmcDetails = () => {
    this.props.history.push({ pathname: `/content-type/All`, search: `?key=amc&value=${this.state.allData?.amc?.amcName}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, })
  }


  render() {
    const { type } = this.props.match.params;
    const { state } = this.props.location;
    const { location, allData, likeCount, signup, saveTo, isDownload, confirm, collectionName, isView, viewMore, isLoading, updatedDescription, variationId, updatedTitle, isLiked, isSaved, data, isError, suggestionData, show, loading, link, contentType, contentData, language, color, variation, path } = this.state;
    // console.log('contentData', contentData);
    // console.log('allData', allData);
    // console.log("Check state is no", state)

    // let languageOption = [...new Set(variation?.map((item) => item.language))];
    let languageOption1 = variation?.map((item) => item.language);
    let languageOption = Object.values(languageOption1.reduce((uniqueMap, obj) => {
      uniqueMap[obj.languageName] = obj;
      return uniqueMap;
    }, {}));

    let colorOption = variation?.filter((item) => item.language.languageName === language).map((item) => item.color);

    // console.log("colorOption",colorOption);
    // console.log("languageOption",languageOption);
    let publishedAtSplit=[];
    let publishedAt='';
    if(contentData?.publishedAt){
      publishedAtSplit = contentData?.publishedAt.split('-');
      publishedAt=publishedAtSplit[2]+'-'+publishedAtSplit[1]+'-'+publishedAtSplit[0]+'T00:00:00.000Z'
    }
  
    return (
      <div className="video-details-page">
        <Header />
        {signup && <SignUp show={signup} handleClose={() => this.setState({ signup: !signup })} />}
        {
          confirm &&
          <Modal className="save-confirm-collection-custom-model" show={confirm} onHide={() => this.setState({ confirm: !confirm })}>

            <Modal.Body className="save-confirm-collection-model-body">
              <Image src={close} onClick={() => this.setState({ confirm: !confirm })} alt="canle icon" className="close-btn" />
              <h4 className="lato-regular fs-28-18 black-242 w-100 text-center">{collectionName}</h4>
              <div className="d-flex justify-content-center ">
                <div className={`primary-btn py-3 pointer`} onClick={() => { this.props.history.push('/mycollections') }}>View Collection</div>
              </div>
            </Modal.Body>
          </Modal>

        }
        {saveTo &&
          <SaveCollectionModal id={this.state.contentType?.split("&id=")[1]} variationId={this.state.variationId ? this.state.variationId : this.state.contentData?.defaultVariation.uuid} show={saveTo} handleClose={() => this.setState({ saveTo: !saveTo })} collectionPopUp={this.conformCollectionPopUp} />
        }
        {/* ?============================ SAVE TO MODAL END  ========================== */}

        {
          show &&
          <VideoDetailsDownloadModal
            show={show}
            handleClose={this.showDownloadModal}
            contentType={contentType}
            detailsData={allData}
            downloadPath={path}
            variation={variation}
            languageColor={{ "language": language, "color": color }}
            updatedThings={{ "tittle": updatedTitle, "description": updatedDescription }}
            variationId={variationId}
          // selectedVariation={}
          />
        }
        {
          isError ? (
            <NotFound massage="Something went wrong" />
          ) : loading ? (
            <DetailsSkeleton />
          ) : (
            <div className="video-detail-wrapper">
              <div className="container">

                <BreadCrumb
                  path={this.breadCrumbHandler()}
                  clasese="mb-0 detail-page-breadcrumb"
                  isKeyValue={(state?.breadCrumbPath ?? '')}
                />

                <div className="video-profile-details">
                  <div className="profile-detail">
                    <Image
                      src={contentData?.amc?.circleImagePath || contentData?.contentCreator?.thumbnailPath || defaultIcon}
                      className="desk-show profile-img pointer"
                      alt="progile Image"
                      title={contentData?.amc?.amcName}
                      onClick={contentData?.amc?.amcName ? this.navigateAmcDetails : null}
                    />
                    <div className="name-count-details">
                      <div className="count-detail-heading">
                        <h6 className="lato-regular f-s-24 lh-32 black-242">
                          {updatedTitle || 'Na'}
                          {/* {contentData && contentData.title

                            ? contentData.title
                            : contentData && contentData.defaultVariation && contentData.defaultVariation.title || NA} */}
                        </h6>
                      </div>
                      <div className="d-flex align-items-center ">
                        {
                          contentData?.amc?.amcName &&
                          <p onClick={this.navigateAmcDetails} className="fs-18-14 op-5 lato-semibold grey-151 pointer mb-0">{contentData?.amc?.amcName || ""} &nbsp;&nbsp;&nbsp;</p>
                        }
                        <div className="details-duration desk-show ">
                          <div className="video-tag">
                            <h6 onClick={() => {
                              const newSearchValue = `pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                              this.props.history.push({
                                pathname: `/content-type/${(contentType?.split("&id=")[0]).toLowerCase().replace("/", "_")}`,
                                search: `${newSearchValue}`,
                              });
                            }} className="fs-14-12 lato-medium pointer op-6">{(contentType?.split("&id=")[0]).toUpperCase()}</h6>
                          </div>
                          {
                            contentData?.category &&
                            <div className="video-tag pointer"
                              onClick={() => {
                                const newSearchValue = `?key=category&value=${contentData?.category?.categoryName.toLowerCase()}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
                                this.props.history.push({
                                  // pathname: `/content-type/${(contentType?.split("&id=")[0]).toLowerCase().replace("/", "_")}`,
                                  pathname: `/content-type/All`,
                                  search: `${newSearchValue}`,
                                });
                              }}
                            >
                              <h6 className="fs-14-12 lato-medium op-6">{(contentData?.category?.categoryName.toUpperCase() || "")}</h6>
                            </div>
                          }

                          {
                            contentData?.publishedAt && contentData?.publishedAt !== DEFAULT_PUBLISH_DATE &&
                            // <span><h6 className="fs-18-12 lato-semibold grey-151 op-5">{formatDate(contentData?.publishedAt)}</h6></span>
                            <span><h6 className="fs-18-12 lato-semibold grey-151 op-5">{isToday(publishedAt)?'today': moment(publishedAt).fromNow()}</h6></span>
                          }
                        </div>
                      </div>
                      {/* ==================== Duration mb=============== */}
                      <div className="details-duration mb-flex justify-content-start">
                        <div className="video-tag">
                          <h6 className="fs-14-12 lato-medium op-6">{(contentType?.split("&id=")[0]).toUpperCase()}</h6>
                        </div>

                        <span><h6 className="fs-18-12 lato-semibold">{isToday(publishedAt) ? 'today': moment(publishedAt).fromNow()}</h6></span>
                      </div>

                    </div>
                  </div>

                </div>
                <div className="video-detail-para">
                  {/* <h3 className="fs-18-14 mb-show grey-151 op-5">1 year ago</h3> */}
                  <p className=" like-share-icons-wrapper">

                    {/* <span className="desk-show"><h6 className="fs-18-14 lato-semibold desk-show ">{formatDate(contentData?.createdAt)}</h6></span> */}
                    <span>  {isLiked ? <Image src={likdeFill} className="fs-18-16 lato-semibold" onClick={this.state.likeDisable ? () => { this.userTrackingHandler(UserTracking.UNLIKE) } : null} /> : <Image src={liked} className="fs-18-16 lato-semibold" onClick={this.state.likeDisable ? () => { this.userTrackingHandler(UserTracking.LIKED) } : null} />}<h6 className="fs-18-12 lato-semibold">{formatNumber(likeCount)}</h6></span>
                    <span>  {isView ? <Image src={BlackEye} alt="download" /> : <Image src={ViewIcon} alt="videw icon" onClick={() => { }} />}<h6 className="fs-18-12 lato-semibold">{formatNumber(contentData?.viewCount)}</h6></span>
                    <span>
                      <RWebShare
                        data={{
                          text: "",
                          // url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase() + "&id=" + contentData.uuid}`,
                          url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase()}`,
                          title: contentData.title,
                        }}
                        onClick={() => this.userTrackingHandler(UserTracking.SHARED)}
                      >

                        {/* <div className="lato-semibold fx-14 black-242 d-flex share-wrapper-btn "> */}
                        <div className="video-details-share-icon">
                          <Image src={BlackShare} alt="download" />
                          <h6 className="fs-18-12  lato-semibold">{formatNumber(contentData?.shareCount)}
                          </h6>
                        </div>
                        {/* </div> */}
                      </RWebShare>
                    </span>
                    <span className={this.state.path ? '' : 'pointer-none'} onClick={() => { if (this.state.path) { this.downloadVideohandler() } else { toast.error("No download file available"); } }}> <Image src={isDownload ? BlackDownload : BlackOutlineDownload} alt="download" className="" /><h6 className="fs-18-12 lato-semibold">{formatNumber(contentData?.downloadCount)}</h6></span>
                    <span onClick={this.saveToCollectionHandler}> {isSaved ? <Image src={saveFilled} className=" fs-18-16 lato-semibold " /> : <Image src={save} className="fs-18-16 lato-semibold " />}<h6 className="fs-18-12 lato-semibold">{formatNumber(contentData?.savedCount)}</h6></span>
                  </p>
                  <div className="video-details-dropwon-component-wrapper">

                    <CustomDropDown
                      label={language || "Select Language"}
                      options={languageOption?.length === 1 && language === languageOption[0]?.languageName ? [] : languageOption || []}
                      getData={(data) => {
                        this.languageSelectHandler(data)
                        // console.log(data)
                      }}
                      id="Language"
                      disabled={languageOption?.length <= 1}
                    // disabled={languageOption?.length>=1 && language===languageOption[0]?.languageName}
                    />
                    {/* {console.log(languageOption?.length<=1 , language===languageOption[0]?.languageName)} */}
                    <CustomDropDown
                      label={color || "Select Color"}
                      options={colorOption || []}
                      getData={(data) => this.colorSelectHandler(data)}
                      id="color"
                      disabled={colorOption?.length <= 1}
                    />
                    <button onClick={this.showDownloadModal} className="primary-btn download-btn mb-none pointer">
                      <Image src={download} alt="download" />
                      Download
                    </button>
                  </div>
                </div>

                <div className="video-play-area">
                  {/* {this.renderContentByType(type)} */}
                  <VideoDetailsContentType type={type.split("&id=")[0]} extUrlLink={link ? link || '' : contentData?.defaultVariation?.extUrlLink || ""} contentFilePath={this.state.path || contentData?.defaultVariation?.contentFilePath || ''} />
                  {/* <Multiviewer data={contentData || ""}/> */}
                </div>
                {
                  updatedDescription &&
                  <div className={`description-box-wrapper d-flex align-items-end w-100 ${!viewMore && "flex-wrap justify-content-end"}  `}>
                    <p className={`lato-regular  fs-18-14 op-5 grey-151 w-100 word-wrap ${viewMore ? "ellipsis-1" : ""} `}>{updatedDescription || NA}</p>
                    {updatedDescription.length > 150 &&
                      <span className="lato-regular blue-0C5 pointer  view-more-des fs-16-14 " onClick={() => this.setState({ viewMore: !viewMore })}>view {viewMore ? "more" : "less"}</span>
                    }
                  </div>
                }
                {
                  type.split("&id=")[0] !== VIDEOS.toLocaleLowerCase() && contentData?.defaultVariation?.extUrlLink &&
                  <div className=" d-flex align-items-center justify-content-end">
                    <a
                      target="_blank"
                      className="vist-btn f-s-11 lh-16 lato-medium pointer"
                      href={contentData?.defaultVariation?.extUrlLink}
                    >
                      <Image src={vsite} alt="image" />  Visit source website
                    </a>
                  </div>}
                {
                  contentData?.keywords &&
                  <div className="filter-wrapper trend-suggestions video-trend-suggestion">
                    {contentData?.keywords !== ""
                      && contentData?.keywords?.split(", ")?.map((item) => {
                        return (
                          <Trending title={item} type={type.split("&id=")[0]} classes="video-trend lh-20 fs-14-10 lato-medium black-242 hashtag" image={Hashtag} />
                        )
                      })
                    }
                  </div>}
                <div className="suggestion-section">
                  {isLoading ? (
                    <AllShimmer type="suggestion" tabName={type.split("&id=")[0]} />
                  ) : (
                    suggestionData?.contents && suggestionData?.contents.length > 0 &&
                    // suggestionData?.contents[0]?.suggestions &&
                    suggestionData?.contents?.map((item, j) => {
                      // // console.log("Check how mahy times Loaded =>", suggestionData?.contents, "id=", type.split("&id=")[1])
                      const suggestionDataFilter = item?.list?.filter((item, i) => item.uuid !== type.split("&id=")[1]);
                      // const suggestionDataFilter = item?.suggestions?.filter((item, i) => item.uuid !== type.split("&id=")[1]);
                      // console.log("Got Uinque Data",suggestionDataFilter)
                      if (item.list?.length > 0) {
                        return (
                          <ContentTypesCards
                            key={j}
                            countTitle={item?.title}
                            hide={true}
                            data={suggestionDataFilter}
                            // data={item?.suggestions}
                            count={item?.count}
                            typeId={item?.uuid}
                            suggestionData="Suggested"
                            code={item?.code}
                            removeNotFound={suggestionDataFilter.length == 0 ? false : true}
                          />
                        );
                      }
                    })
                  )}
                </div>
              </div>
            </div>)
        }
        <Footer />

        <div className="download-btn-container ">
          <button onClick={this.showDownloadModal} className="primary-btn download-btn ">
            <Image src={download} alt="download" />
            Download
          </button>
        </div>

      </div>
    );
  }
}

const mapStateToProps = (state) => ({ onBoardingState: state.HomePageState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
VideoDetails.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(VideoDetails);
