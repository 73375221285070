import React from "react";
import FolderPic from "../../assets/images/folder.png";
import { Dropdown, Image, Modal } from "react-bootstrap";
import Option from "../../assets/images/option.png";
import remove from "../../assets/images/recycle-bin-2--remove-delete-empty-bin-trash-garbage.svg";
import share from "../../assets/images/black-share.svg";
import edit from "../../assets/images/edit-pencil.svg";
import close from "../../assets/images/cancel-icon.svg";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { formatDate } from "../../utils/ReusableFunctions";
import { useState } from "react";
import AddCollectionModal from "./_utils/AddCollectionModal";
import moment from "moment";
import { SHARE_BASE_URL } from "../../utils/Constant";
import { RWebShare } from "react-web-share";
const collectionOptions = [
  { name: "Rename", img: edit, method: "put" },
  { name: "Share", img: share, method: "share" },
  { name: "Delete", img: remove, method: "delete" },
]
const Folder = ({ data, click }) => {
  const history = useHistory();
  const [updateCollection, setUpdateCollection] = useState('')
  const [collection, setCollection] = useState({ collectionName: "", collectionId: "", collectionMethod: "" });
  const { collectionName, collectionId, collectionMethod } = collection;
  // const [collectionMethod, setCollectionMethod] = useState('')
  const [show, setShow] = useState(false)
  const [del, setDel] = useState(false)
  function clickHandler(id, method) {
    // console.log("share data", method)
    let info = ""
    if (method === "put") {
      setShow(!show);
      setCollection({ ...collection, collectionName: data.name, collectionId: id, collectionMethod: method })
    } else if (method === "delete") {
      setDel(!del);
      setCollection({ ...collection, collectionName: data.name, collectionId: id, collectionMethod: method })
    }
    else {
      click(id, info, method);

    }

  }
  return (
    <div
      className="folder-card pointer"
    >
      <div className="" onClick={() => history.push({ pathname: `/collection-details/id=${data.uuid}`, state: { "collectionName": data.name } })}>
        <Image src={FolderPic} alt="folder" />
        <div className="date-created"    >
          <h2 className=" fs-22-18 lato-regular text-start">{data.name}</h2>
          <p className=" fx-12 lato-regular">{data.items?.length} items | {moment(data.createdAt).fromNow()}</p>
        </div>
      </div>
      <Dropdown className="collection-main-dropdown">
        <Dropdown.Toggle className="bg-transparent header-profile-icon three-dot" id="dropdown-basic">
          {" "}
          <Image src={Option} alt="folder" />
        </Dropdown.Toggle>

        <Dropdown.Menu className="show-profile-dropdown collection-dropdown-show">
          {collectionOptions && collectionOptions
            ?.map((item, i) => {
              if (item.method === 'share') {
                return (
                  <Dropdown.Item className="lato-regular fs-14-12 op-7 black-242" >
                    <RWebShare
                      data={{
                        text: "",
                        url: `${SHARE_BASE_URL + "collection-details" + "/id=" + data?.uuid}`,
                        title: data?.title,
                      }}
                      onClick={() => console.log(data?.uuid, 'share')}
                    >
                      <div className="lato-regular fs-14-12  black-242 p-0 collection-share-wrapper-btn ">
                        {/* <spam className="icon-fixe-width"> */}
                        <Image src={share} className="h-auto" alt="icon" />
                        {/* </spam> */}
                        Share </div>
                    </RWebShare>
                  </Dropdown.Item>
                )
              }
              return (
                <Dropdown.Item
                  key={i}
                  onClick={() => clickHandler(data.uuid, item.method)}
                  className="lato-regular fs-14-12 op-7 black-242 "
                  href="">
                  <Image src={item.img} alt="icon" className=" h-auto" />
                  {item.name}
                </Dropdown.Item>
              );
            })}
        </Dropdown.Menu>
      </Dropdown>
      {show &&
        // This modal for Put
        <AddCollectionModal show={show} click={() => setShow(!show)} updated={click} id={collectionId} method={collectionMethod} collectionName={collectionName} />
      }
      {/* ==================== DELETE MODAL START =========================== */}
      <Modal
        className="save-confirm-collection-custom-model"
        show={del}
        onHide={() => setDel(!del)}
      >
        <Modal.Body className="save-confirm-collection-model-body">
          <Image
            src={close}
            onClick={() => setDel(!del)}
            alt="canle icon"
            className="close-btn"
          />
          <h4 className="lato-semibold fs-28-18 black-242  text-center w-100">Are you sure you want to delete '<span className="lato-bold primary">{data && data?.name}</span>'
          </h4>
          <div className="d-flex justify-content-center ">
            <div
              className={`primary-btn py-3 pointer`}
              onClick={() => { click(collectionId, "", collectionMethod); setDel(false) }}
            >
              Yes
            </div>
            &nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;
            <div
              className={`primary-btn py-3 pointer`}
              onClick={() => setDel(false)}
            >
              No
            </div>
          </div>
        </Modal.Body>
      </Modal>
      {/* ==================== DELETE MODAL END =========================== */}
    </div>
  );
};

export default Folder;
