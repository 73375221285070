import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { BsFillCircleFill } from "react-icons/bs";
import subTik from '../../assets/images/subscription-tick.svg'
import subCross from '../../assets/images/subscription-cross.svg'
import { includes } from "lodash";
import { formatCurrency, getUser } from "../../utils/ReusableFunctions";
import { Link } from 'react-router-dom';

export default function SubscriptionPlan({ index, data, handleSubscription = () => { console.log("hi") }, isActivePlan = [], isActive = false }) {
  const [contentType, seContentType] = useState(JSON.parse(sessionStorage.getItem("contenType")) || []);
  // console.log(data, isActivePlan, (data?.level), isActivePlan[0]?.premiumPlan?.level)
  // console.log((isActivePlan && isActivePlan?.length > 0 && data?.uuid === isActivePlan[index]?.premiumPlan?.uuid))
  // const activePlanLavels=isActivePlan?.map(item=>item?.premiumPlan?.level);
  // console.log(activePlanLavels)
  // const disableCard=(isActivePlan?.some((item,i)=>item.premiumPlan?.uuid===data?.uuid));
  function planCardCondition() {
    // console.log("Checkt is active plan:=",index+1,activePlanLavels?.includes(index + 1))
    switch (true) {
      // case activePlanLavels.includes(index + 1):
      case isActivePlan[0]?.premiumPlan?.uuid === data.uuid:
        return 'activeted-plan'
      // case (index + 1) < Math.max(...activePlanLavels):
      case (data?.level) < isActivePlan[0]?.premiumPlan?.level:
        return 'disable-card'
      default:
        return ''


    }
  }
  return (
    <div className={`plan-card ${index === 1 && "hover"} ${(isActivePlan && isActivePlan?.length > 0 && data?.uuid === isActivePlan[0]?.premiumPlan?.uuid) && "active"}`}>
      {
        (isActivePlan && isActivePlan?.length > 0 && data?.uuid === isActivePlan[0]?.premiumPlan?.uuid) && <span className="lato-regular fs-14-12 primary currently-active">Active</span>
      }
      <h4 className=" fs-22-18 lato-bold black-242">{data?.name || ""}
        <p className="fs-16-14 lato-medium black-242 text-center">{data?.description || ""}</p>
      </h4>
      {index === 0 && <ul className="" >
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Search & View <b className="primary">Content</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Like, Share & Add to <b className="primary">Collection</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Download Reports Using <b className="primary">Tools</b> <b>2/day</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Access To Free <b className="primary">Events</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Download <b className="primary">Content</b> <b>1/day</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Cobrand/Whitelabel <b className="primary">Content</b> <b>1/day</b> </li>
        <li className="lato-medium"><img src={subCross} alt="" className="desk obj-cover" />&nbsp;
          Premium TFP <b className="primary">Content</b> <b>0</b> </li>
        {/* <li className="lato-medium">Search & View Content, Social Media, AMC, Campaign, Trending, Tools</li> */}
        {/* {data?.premiumPlanSpecification &&
          data?.premiumPlanSpecification.map((item) => {
            return (
              <li className="lato-medium">
                <img src={subTik} alt="" className="desk obj-cover" />&nbsp;
                {
                  item.type == "Content"
                  &&
                  <>
                    {item.actionType} {item.isPremium ? "(Premium)" : "(Non-Premium)"} - {item.limit} {item.type} in {item.resetInDays > 1 ? `${item.resetInDays} days` : "a day"}
                  </>
                }
                {
                  item.type == "Event"
                  &&
                  <>
                    Access for {item.limit} {item.type} in {item.resetInDays} {item.resetInDays > 1 ? "days" : "day"} {item.isPremium ? "" : "(Non-Premium)"}
                  </>
                }

              </li>
            );
          })} */}
        {
          (data?.discountedPrice && data?.discountedPrice !== data?.price)
            ?
            <li> <s>₹{data?.price}</s> ₹{data?.discountedPrice}</li>
            :
            data?.price ?
              <li>₹{formatCurrency(data?.price)} / year</li>
              :
              <li>Free</li>
        }


      </ul>}
      {index === 1 && <ul className="" >
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Search & View <b className="primary">Content</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Like, Share & Add to <b className="primary">Collection</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Download Reports Using <b className="primary">Tools</b> <b>5/day</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Access To Free <b className="primary">Events</b> <b>Unlimited</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Access To <b className="primary">Premium Events</b> <b>12/year</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Download <b className="primary">Content</b> <b>2/day</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Cobrand/Whitelabel <b className="primary">Content</b> <b>2/day</b> </li>
        <li className="lato-medium"><img src={subTik} alt="" className="desk obj-cover" />&nbsp;
          Premium TFP <b className="primary">Content</b> <b>8/month</b> </li>
        {/* <li className="lato-medium">Search & View Content, Social Media, AMC, Campaign, Trending, Tools</li> */}
        {/* {data?.premiumPlanSpecification &&
          data?.premiumPlanSpecification.map((item) => {
            return (
              <li className="lato-medium">
                <img src={subTik} alt="" className="desk obj-cover" />&nbsp;
                {
                  item.type == "Content"
                  &&
                  <>
                    {item.actionType} {item.isPremium ? "(Premium)" : "(Non-Premium)"} - {item.limit} {item.type} in {item.resetInDays > 1 ? `${item.resetInDays} days` : "a day"}
                  </>
                }
                {
                  item.type == "Event"
                  &&
                  <>
                    Access for {item.limit} {item.type} in {item.resetInDays} {item.resetInDays > 1 ? "days" : "day"} {item.isPremium ? "" : "(Non-Premium)"}
                  </>
                }

              </li>
            );
          })} */}
        {
          (data?.discountedPrice && data?.discountedPrice !== data?.price)
            ?
            <li> <s>₹{data?.price}</s> ₹{data?.discountedPrice}</li>
            :
            data?.price ?
              <li className="primary">₹{formatCurrency(data?.price)} / year</li>
              :
              <li>Free</li>
        }
      </ul>}
      {

        !getUser() && data.level === 0 ?
          <Link to={{
            pathname: "/onboarding",
            state: { register: true }
          }}>
            <Button
              className="secondary-btn px-5">Register</Button>
          </Link>
          :
          data.level !== 0 &&
          <Button
            onClick={
              (isActivePlan && isActivePlan?.length > 0 && data?.uuid === isActivePlan[0]?.premiumPlan?.uuid) ? null :
                () => handleSubscription(data, isActivePlan)}
            className={"secondary-btn px-5"}>
            {
              isActivePlan && isActivePlan.length > 0 && data.uuid === isActivePlan[0]?.premiumPlan?.uuid
                ?
                "Subscribed"
                :
                data.level < isActivePlan[0]?.premiumPlan?.level && !data.isTopup && !data.isCustomPlan
                  ?
                  // "Label"
                  'Subscribe'
                  :
                  data.level > isActivePlan[0]?.premiumPlan?.level && !data.isTopup && !data.isCustomPlan
                    ?
                    "Upgrade"
                    :
                    "Subscribe"
            }

          </Button>
      }

    </div>
  );
}
