import React, { useState, useEffect } from "react";

export default function RadioFields({ options, onSelectionChange, lable, classes, initialValue = null, disabled = false }) {
  const [selectedValue, setSelectedValue] = useState(initialValue);
  // console.log("desal", disabled)
  useEffect(() => {
    setSelectedValue(initialValue)
  }, [initialValue])

  // const handleRadioChange = (event) => {
  //   onSelectionChange(option.value ? option.value : option);
  //   setSelectedValue(option.value || option.position);
  // };
  return (
    <div className="radio-fields-frame">
      <h3 htmlFor="lato-regular sip-amount f-s-14 lh-20 black-242 ">
        {lable}
      </h3>
      <div className="radio-option-frame d-flex align-items-center gap-12 py-4">
        {options?.map((option, i) => (
          <div className="d-flex align-items-center gap-12 mr-2">
            <input
              type="radio"
              className="input-radio"
              name={option.label}
              // disabled={option.disabled}
              value={option.value || option.position}
              checked={selectedValue === (option.value || option.position)}
              onChange={() => {
                onSelectionChange(option.value ? option.value : option);
                setSelectedValue(option.value || option.position);
              }}
            />
            <label
              htmlFor={option.label}
              className="f-s-14 lh-20 black-242 lato-regular mb-0"
            >
              {option.label || option.position}
            </label>
          </div>))}
        {/* <div className="d-flex align-items-center gap-12 mr-2">
          <input
            type="radio"
            className="input-radio"
            name="sip"
            id="abcd"
            value="abcd"
            checked={selectedOption === "abcd"}
            onChange={handleRadioChange}
          />
          <label htmlFor="abcd" className="f-s-14 lh-20 black-242 lato-regular mb-0">
            abcd
          </label>
        </div> */}
      </div>
    </div>
  );
}
