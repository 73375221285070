import React, { useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import Logo from "../../../assets/images/brand-logo.svg";
import MbLogo from "../../../assets/images/logo-mobile.svg";
import Menu from "../../../assets/images/menu-icon.svg";
import Close from "../../../assets/images/close.svg";
import ProfileIcon from "../../../assets/images/profile-icon.svg";
import NotificationIcon from "../../../assets/images/notification-icon.svg";
import { deleteToken, getToken } from "../../../utils/ManageToken";
import { useState } from "react";
import { EVENTS, API_LIMIT, UserTracking } from "../../../utils/Constant";
const profileList = [{ name: "Profile Details", path: "/profile-details" }, { name: 'My Collections', path: '/mycollections' }, { name: 'My Membership', path: "/mymembership" }, { name: 'My Events', path: '/my-events' }, { name: "My Activity", path: `/myactivity/user-tracking?type=${UserTracking?.DOWNLOADED.toLowerCase()}` }, { name: "Log Out", path: "/logout" }]

export default function Header() {
  const history = useHistory();
  const [token, setToken] = useState(false);
  useEffect(() => {
    if (getToken()) {
      setToken(true)
    }
    window.scrollTo(0, 0);
  }, []);


  function clickHandler(data) {
    // console.log('data', data, history);
    if (data === '/logout') {
      // localStorage.removeItem('finpediaCustomerToken');
      deleteToken();
      setToken(false)
      history.push('/');
    } else {
      history.push({
        pathname: data
      });
    }

  }
  return (
    <header className="main-header">
      <div className="container">
        <div className="header-wrapper">
          <input type="checkbox" name="so" id="so" className="d-none" />
          <div className="d-flex align-items-center justify-content-between gap-12-mb">
            <label htmlFor="so" className="nemue-lable mb-0">
              <Image src={Menu} className="menu-bar" alt="Humbar" />
              <Image src={Close} className="cancel-menu" alt="Humbar" />
            </label>
            <Image
              src={Logo}
              onClick={() => history.push("/")}
              className="desk-show pointer"
              alt="logo"
            />
            <Image
              src={MbLogo}
              onClick={() => history.push("/")}
              className="tab-show pointer"
              alt="logo"
            />
          </div>
          <ul className="header-ul">
            <label htmlFor="so">
              <li
                onClick={() => history.push("/trending")}
                className="lato-semibold white f-s-18 lh-24">
                Trending
              </li>
              <li
                onClick={() => history.push({ pathname: `/${EVENTS}`, search: `pageNumber=1&pageSize=10` })}
                className="lato-semibold white f-s-18 lh-24"
              >
                Events
              </li>
              <li
                onClick={() => history.push("/tools")}
                className="lato-semibold white f-s-18 lh-24">Tools</li>
              {token ? (
                <>
                  <li className="d-flex align-items-center mb-fixed-user">
                    <Dropdown className="header-profile-dropdown">
                      <Dropdown.Toggle
                        className="bg-transparent header-profile-icon"
                        id="dropdown-basic"
                      >

                        {" "}
                        <Image
                          src={ProfileIcon}
                          className="profile-header-icon"
                          alt="Profile Icon"
                        />

                      </Dropdown.Toggle>

                      <Dropdown.Menu className="show-profile-dropdown">
                        {profileList &&
                          profileList?.map((item, i) => {
                            return (
                              <Dropdown.Item
                                key={i}
                                onClick={() => clickHandler(item.path)}
                                className="lato-regular fs-22-13 op-7 black-242 "
                                href=""
                              >
                                {item.name}
                              </Dropdown.Item>
                            );
                          })}
                      </Dropdown.Menu>
                    </Dropdown>

                    <Image
                      src={NotificationIcon}
                      onClick={() => history.push({ pathname: '/notification', search: 'pageNumber=1&pageSize=18' })}
                      className="notification-header-icon"
                      alt="Notification Icon"
                    />
                  </li>
                </>
              ) : (
                <li>
                  <label htmlFor="so" >
                    <Link to="/onboarding" className="secondary-btn  fx-14 mb-fixed-user pointer">
                      <span className=""> Log in</span>
                      {/* <Image
                      {/* <Image
                        src={ProfileIcon}
                        className="profile-header-icon mb-show"
                        alt="Profile Icon"
                      /> */}
                    </Link>

                  </label>
                </li>
              )}
            </label>
          </ul>
        </div>
      </div>
    </header>
  );
}
