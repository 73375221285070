import React from "react";
import Trending from "../Trending";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { imageClass } from "../../../utils/Constant";
export default function SkeletonCard({ imageClass, socialMedia, type }) {
  // console.log(type +'=>'+ imageClass)
  return (
    <div className={!socialMedia ? `common-card-box bh-white  ${imageClass}` : `${imageClass}`}>
      <div className={`main-img mb-0 shimmer-container  lato-medium fs-14-10 ${imageClass}`}></div>
      {!socialMedia && type &&
        <div className="description-box">
          <div className="des-profile desk-show shimmer-container"></div>
          <div className="des-content-wrappper">
            <p className=" fs-16-14 w-100 lato-regular h-auto black-242 shimmer-container"> &nbsp;</p>
            <p className=" lh-8 mt-2 w-100 lato-medium black-242 mt-1 shimmer-container"> &nbsp; </p>
          </div>
        </div>
      }
    </div>
  );
}


export function DetailsSkeleton() {
  const param = useParams();
  // console.log(param?.type?.split("&id")[0])
  // console.log(imageClass[param?.type?.split("&id")[0]])
  return (
    <div className="">
      <div className="video-detail-wrapper">
        <div className="container">
          <div className="video-profile-details">
            <div className="profile-detail w-100">
              <span className="desk-show profile-img shimmer-container"
              />
              <div className="name-count-details ">
                <div className="count-detail-heading w-75">
                  <h6 className="lato-regular fs-32-24 w-100 black-242 shimmer-container">&nbsp;</h6>
                </div>
                <p className="lh-19 mt-1 op-5 w-50 lato-semibold grey-151 mb-0 shimmer-container">&nbsp;</p>
                <p className="lh-19 mt-1 op-5 w-100 lato-semibold grey-151 mb-0 shimmer-container">&nbsp;</p>
                {/* ==================== Duration mb=============== */}
                <div className="details-duration mb-flex justify-content-start">
                  <div className="video-tag px-5 w-25 shimmer-container">
                    <h6 className="fs-14-12 lato-medium op-6 w-100 ">&nbsp;</h6>
                  </div>

                  <span className="w-25"><h6 className="fs-18-12 w-100 lato-semibold shimmer-container">&nbsp;</h6></span>
                </div>

              </div>
            </div>

          </div>
          <div className="video-detail-para w-100">
            {/* <h3 className="fs-18-14 mb-show grey-151 op-5">1 year ago</h3> */}
            <div className="video-details-dropwon-component-wrapper w-100 d-flex">

              <p className="mt-12 w-100 d-flex gap-12">
                <div className="details-duration desk-show ">
                  <div className="video-tag  shimmer-container">
                    <h6 className=" px-5 lh-24 op-6 ">&nbsp;</h6>
                  </div>

                  <span><h6 className="fs-18-12 lato-semibold px-5 shimmer-container">&nbsp;</h6></span>
                </div>
                <span className="desk-show"><h6 className="fs-18-14 w-auto lato-semibold desk-show px-5 shimmer-container">&nbsp;</h6></span>
                <span>  <h6 className="fs-18-12 w-auto lato-semibold px-4 shimmer-container">&nbsp;</h6></span>
                <span>  <h6 className="fs-18-12 w-auto lato-semibold px-4 shimmer-container">&nbsp;</h6></span>
                <span> <h6 className="fs-18-12 w-auto lato-semibold px-4 shimmer-container">&nbsp;</h6></span>
                <span> <h6 className="fs-18-12  w-auto lato-semibold px-4 shimmer-container">&nbsp;</h6></span>
                <span> <h6 className="fs-18-12 w-auto lato-semibold px-4 shimmer-container">&nbsp;</h6></span>
              </p>
              <div className="w-100 d-flex justify-content-end ">
                <div className="shimmer-container  me-5 py-3 mw-151">&nbsp;</div>&nbsp;&nbsp;&nbsp;
                <div className="shimmer-container  py-3 mw-151">&nbsp;</div>
                <div className="lh-32 pointer shimmer-container desk-show mw-151">
                  &nbsp;
                </div>
              </div>
            </div>

            <div className="video-play-area mh-400 w-100 shimmer-container">
              &nbsp;
            </div>
            <div className="filter-wrapper trend-suggestions video-trend-suggestion">
              {
                [...Array(3)]?.map((item) => {
                  return (
                    <Trending title={" "} classes="video-trend border-0 mw-151 lh-20 fs-14-10 lato-medium black-242 shimmer-container" />
                  )
                })
              }

            </div>
            <div className="suggation-section common-all-card-wrapper ">
              {
                [...Array(8)]?.map((item, j) => {
                  return (
                    <SkeletonCard imageClass={imageClass[param?.type?.split("&id")[0]]} />
                  )
                })
              }
            </div>

          </div>
        </div>
      </div>
    </div>
  )
}