import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import arrowNext from "../../../assets/images/Arrow_next.svg";
import arrowPre from "../../../assets/images/Arrow_pre.svg";
import { navigateSetUrl } from "../../../utils/ReusableFunctions";
import { START_PAGE } from "../../../utils/Constant";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function Pagination(props) {
  // const [pageNo, setPageNo] = useState(parseInt(props.page, 10))
  const [pageNo, setPageNo] = useState(parseInt(props.page, 10))
  const history=useHistory()
  const location=useLocation()
  // console.log("Page no:=",props.page);

  useEffect(()=>{
    setPageNo(props.page)
  },[props.page])
  const onLeftClick = (props) => {
    if (props.page > 1) {
      const params = new URLSearchParams(location.search);
      params.set("pageNumber", parseInt(props.page, 10) - 1);
      setPageNo(pageNo - 1)
      navigateSetUrl(history, history.pathname, params.toString())
    // ?*

    }
  };
  const onValueInput = (e) => {
    let no = e.target.value;
    setPageNo(no > 0 ? no : START_PAGE);
    // console.log(pageNo, no);
    if (no > 0) {
      const params = new URLSearchParams(props.location.search);
      params.set("pageNumber", parseInt(no, 10));
      navigateSetUrl(props.history, props.history.pathname, params.toString())
    }
  }

  const onNextClick = (props) => {
    // console.log(pageNo)
    if (props.page < props.pageCount) {
      const params = new URLSearchParams(props.location.search);
      params.set("pageNumber", parseInt(props.page, 10) + 1);
      setPageNo(parseInt(props.page, 10) + 1)
      navigateSetUrl(props.history, props.history.pathname, params.toString())
    }
  };
  const onInputWheel = (e) => {
    e.preventDefault(); 
  };
  return (
    <div className="pagination-section">
      <Image
        onClick={() => onLeftClick(props)}
        src={arrowPre}
        className="pagination-pre-icon pointer"
        alt="back_icon"
      />
      <div className="pagination-count f-s-14 lato-medium black-242">
        Page
        {/* <span className="pagination-item pointer"> */}

        <input className="pagination-item pointer"   onWheel={(e) => e.target.blur()} type="number" value={pageNo || props.page} onChange={onValueInput} />
        {/* </span> */}
        of {props.pageCount}
      </div>
      <Image
        onClick={() => onNextClick(props)}
        src={arrowNext}
        className="pagination-next-icon pointer"
        alt="back_icon"
      />
    </div>
  );
}
