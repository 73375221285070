import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import { Col, Row } from 'react-bootstrap';
import abcLogo from "../../assets/images/abc.png";
import { Image } from 'react-bootstrap'
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { useMemo } from 'react';
import { SignUp } from '../common/components';
export default function RetirementPlanner({ calculator, textLogo = '' }) {
  let [monthlyExpense, setMonthlyExpense] = useState(50000);
  let [currentAge, setCurrentAge] = useState(25);
  let [retirementAge, setRetirementAge] = useState(60);
  let [lifeExpectancy, setLifeExpectancy] = useState(85);
  let [expectedInvestmentReturn, setExpectedInvestmentReturn] = useState(12);
  let [inflation, setInflation] = useState(5);
  let [stepUp, setStepUp] = useState(10);


  let [fixedSIP, setFixedSIP] = useState(196757);
  let [stepUpSIP, setStepUpSIP] = useState(30197);
  let [fixedSIPInvesment, setFixedSIPInvesment] = useState(82637800);
  let [cumulativeSIP, setCumulativeSIP] = useState(108392817);
  let [lumpsum, setLumpsum] = useState(59872462);
  let [corpus, setCorpus] = useState(119738311);

  // let [investmentPV, setInvestmentPV] = useState(5000000);
  // let [investmentFV, setInvestmentFV] = useState(12379816);

  useEffect(() => {

    setMonthlyExpense(monthlyExpense);
    setCurrentAge(currentAge);
    setRetirementAge(retirementAge);
    setLifeExpectancy(lifeExpectancy);
    setExpectedInvestmentReturn(expectedInvestmentReturn);
    setInflation(inflation);
    setStepUp(stepUp);


    setFixedSIP(fixedSIP);
    setStepUpSIP(stepUpSIP);

    setFixedSIPInvesment(fixedSIPInvesment);
    setCumulativeSIP(cumulativeSIP);
    setLumpsum(lumpsum);
    setCorpus(corpus);


  }, [monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, stepUp, fixedSIP, stepUpSIP, fixedSIPInvesment, cumulativeSIP, lumpsum, corpus])

  const handleChange = (value, name) => {
    if (name === "monthlyExpense") {
      monthlyExpense = value
    }
    if (name === "currentAge") {
      currentAge = value
    }
    if (name === "retirementAge") {
      retirementAge = value
    }
    if (name === "lifeExpectancy") {
      lifeExpectancy = value
    }
    if (name === "expectedInvestmentReturn") {
      expectedInvestmentReturn = value
    }
    if (name === "inflation") {
      inflation = value
    }
    if (name === "stepUp") {
      stepUp = value
    }
    onSubmitAction(monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, stepUp);
  }

  const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx, }) => {
    if (typeof customToleranceFn !== 'function') {
      if (!percentTolerance) {
        throw Error('invalid inputs');
      }
    }
    let g;
    let y;
    let y1;
    let oldGuess;
    let newGuess;
    let res;
    const absoluteTolerance = ((percentTolerance || 0) / 100) * goal;
    // iterate through the guesses
    for (let i = 0; i < maxIterations; i++) {
      // define the root of the function as the error
      res = fn.apply(null, fnParams);
      res = parseFloat(res);

      y = res - goal;
      // console.log(`result: ${res}; y: ${y}`);
      if (isNaN(y))
        throw TypeError('resulted in NaN 1');

      // was our initial guess a good one?
      if (typeof customToleranceFn !== 'function') {
        if (Math.abs(y) <= Math.abs(absoluteTolerance))
          return fnParams[independentVariableIdx];
      }
      else {
        if (customToleranceFn(res))
          return fnParams[independentVariableIdx];
      }
      // set the new guess, correcting for maxStep
      oldGuess = fnParams[independentVariableIdx];
      newGuess = oldGuess + y;
      // if (Math.abs(newGuess - oldGuess) > maxStep) {
      //     if (newGuess > oldGuess) {
      //         newGuess = oldGuess + maxStep;
      //     }
      //     else {
      //         newGuess = oldGuess - maxStep;
      //     }
      // }
      fnParams[independentVariableIdx] = newGuess;
      // re-run the fn with the new guess
      y1 = parseInt(fn.apply(null, fnParams)) - goal;

      // console.log('A', parseInt(fn.apply(null, fnParams)));
      // console.log('B', goal);
      // console.log('D', y1);

      if (isNaN(y1))
        throw TypeError('resulted in NaN 2');
      // calculate the error
      g = (y1 - y) / y;
      if (g === 0)
        g = 0.0001;
      // set the new guess based on the error, correcting for maxStep
      newGuess = oldGuess - y / g;
      // if (maxStep && Math.abs(newGuess - oldGuess) > maxStep) {
      //     if (newGuess > oldGuess) {
      //         newGuess = oldGuess + maxStep;
      //     }
      //     else {
      //         newGuess = oldGuess - maxStep;
      //     }
      // }
      fnParams[independentVariableIdx] = newGuess;
    }
    // done with iterations, and we failed to converge
    throw Error('failed to converge');
  };


  const fn = function calculate(pmt, rate, tper, growth) {
    let years;
    let cum_returns = 0;
    let growth_calc;
    let i;
    let this_pmt;
    let this_nper;
    let fv;

    // console.log(`try with pmt: ${pmt}`);
    growth_calc = 1 + growth;
    years = tper / 12;
    for (i = 1; i <= years; i++) {
      if (i == 1) {
        this_pmt = pmt;
      } else {
        this_pmt = pmt * ((growth_calc ** (i - 1)) - (growth_calc ** (i - 2)));
      }
      this_nper = tper - ((i - 1) * 12);
      // console.log(`rate: ${rate}; nper=${this_nper}; pmt: ${this_pmt}`);
      fv = parseFloat(FV(rate, this_nper, this_pmt, 0, 1));
      // console.log(`year: ${i}; fv=${fv}`);
      cum_returns = cum_returns + fv;
    }
    return cum_returns
  }

  function FV(rate, nper, pmt, pv, type) {
    var pow = Math.pow(1 + (rate / 12), nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + (rate / 12) * type) * (1 - pow) / (rate / 12)) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  function totalSIP(firstValue, tenure, rate) {
    var totalValue = firstValue * 12;
    for (var i = 0; i < tenure; i++) {
      var temp = ((1 + (rate / 100)) * firstValue).toFixed(2);
      totalValue += temp * 12;
      firstValue = temp;
    }
    return Math.round(totalValue);
  }

  function onSubmitAction(monthlyExpense, currentAge, retirementAge, lifeExpectancy, expectedInvestmentReturn, inflation, stepUp) {
    // currentAge = document.getElementById('inputCurrentAge').value;
    // retirementAge = document.getElementById('inputRetirementAge').value;
    // lifeExpectancy = document.getElementById("inputLifeExpectancy").value;
    // inflation = document.getElementById('input4').value;
    // expectedInvestmentReturn = document.getElementById('input5').value;
    let roi = expectedInvestmentReturn / 100;
    // monthlyExpense = document.getElementById('input1').value.replace(/\D+/g, '');
    let sipGrowthRate = stepUp;
    let yearsForRetirement = retirementAge - currentAge;
    let yearsInRetirement = lifeExpectancy - retirementAge;
    let tenure = yearsForRetirement * 12;
    let netReturns = ((roi + 1) / (1 + (inflation / 100))) - 1;
    // netReturns=0.07;
    let annualExpense = monthlyExpense * 12;
    let firstYearExpense = annualExpense / (1 / (1 + (inflation / 100)) ** yearsForRetirement);
    let monthlyExpenseRetirement = firstYearExpense / 12;
    let roiConst = (expectedInvestmentReturn / 100) / 12;

    let corpus = ((firstYearExpense * ((((1 + netReturns) ** yearsInRetirement) - 1) / netReturns) * (1 + netReturns)) / ((1 + netReturns) ** yearsInRetirement))
    let fixedSIP = (corpus / (((((roiConst) + 1) ** tenure) - 1) / roiConst)) / (roiConst + 1);
    let lumpsum = (1 / ((roi + 1) ** yearsForRetirement)) * corpus;
    let fixedSIPInvesment = tenure * fixedSIP;


    // console.log('fixedSIP,stepUpSIP,fixedSIPInvesment,cumulativeSIP,lumpsum,corpus', fixedSIP, stepUpSIP, fixedSIPInvesment, cumulativeSIP, lumpsum, corpus);

    setFixedSIP(Math.round(fixedSIP || 0));
    setStepUpSIP(Math.round(stepUpSIP || 0));

    setFixedSIPInvesment(Math.round(fixedSIPInvesment || 0));
    setCumulativeSIP(Math.round(cumulativeSIP || 0));
    setLumpsum(Math.round(lumpsum || 0));
    setCorpus(Math.round(corpus || 0));


    // console.log(currentAge, retirementAge, lifeExpectancy, inflation, expectedInvestmentReturn, monthlyExpense);
    // document.getElementById('fixedSIP').innerHTML = Math.round(fixedSIP).toLocaleString('en', { useGrouping: true });
    // document.getElementById('stepUpSIP').innerHTML = Math.round(stepUpSIP).toLocaleString('en', { useGrouping: true });;
    // document.getElementById('fixedSIPInvesment').innerHTML = Math.round(fixedSIPInvesment).toLocaleString('en', { useGrouping: true });
    // document.getElementById('cumulativeSIP').innerHTML = Math.round(cumulativeSIP).toLocaleString('en', { useGrouping: true });;
    // document.getElementById('lumpsum').innerHTML = Math.round(lumpsum).toLocaleString('en', { useGrouping: true });
    // document.getElementById('corpus').innerHTML = Math.round(corpus).toLocaleString('en', { useGrouping: true });;

    fixedSIP = Math.round(fixedSIP);
    stepUpSIP = Math.round(stepUpSIP);
    fixedSIPInvesment = Math.round(fixedSIPInvesment);
    cumulativeSIP = Math.round(cumulativeSIP);
    lumpsum = Math.round(lumpsum);
    corpus = Math.round(corpus);




    let corpus1 = Math.round(corpus);
    // console.log(`corpus1 1: ${corpus1}`);
    let init_pmt = -10000;
    let rate_of_return = expectedInvestmentReturn; // in percentage
    let total_period = yearsForRetirement; //years
    let growth_rate = sipGrowthRate; // in percentage
    let goal = corpus1;

    const fnParams = [init_pmt, rate_of_return / 100, total_period * 12, growth_rate / 100];
    // console.log(`fnParams : ${fnParams}`);
    try {
      const result = goalSeek({
        fn,
        fnParams,
        percentTolerance: 0.1,
        maxIterations: 100,
        maxStep: 0.1,
        goal: goal,
        independentVariableIdx: 0
      });

      let result1 = Math.ceil(Math.abs(result));
      // document.getElementById('stepUpSIP').innerHTML = Math.round(result1).toLocaleString('en', { useGrouping: true });;
      stepUpSIP = Math.round(result1);
      setStepUpSIP(stepUpSIP || 0)
      // myChart.config.data.datasets[0].data[1] = Math.round(result1 || 0);
      // myChart.update();

      let totalCorpus = totalSIP(result1, total_period, growth_rate);

      // document.getElementById('cumulativeSIP').innerHTML = Math.round(totalCorpus).toLocaleString('en', { useGrouping: true });;
      // cumulativeSIP =Math.round(totalCorpus);
      // myChart2.config.data.datasets[0].data[1] = Math.round(totalCorpus || 0);
      // myChart2.update();
      setCumulativeSIP(Math.round(totalCorpus || 0))
      // document.getElementById('stepUpSIP').innerHTML = result1;
      // document.getElementById('cumulativeSIP').innerHTML = result1;

    } catch (e) {
      // console.log('error', e);
    }
  };

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP"],
          data: [fixedSIP, stepUpSIP],
        }}
      />
    );
  }, [fixedSIP, stepUpSIP]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Retirement Corpus"],
          data: [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus],
        }}
      />
    );
  }, [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          {/* <RangeSlider min={10} max={5000} /> */}

          <RangeFields
            label="Monthly Expense"
            name={"monthlyExpense"}
            value={monthlyExpense}
            minVal={5000}
            maxVal={1000000}
            step={1000}
            getValue={handleChange} />
          <RangeFields
            label="Current Age"
            name={'currentAge'}
            value={currentAge}
            minVal={18}
            maxVal={60}
            unit="Year"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="Retirement Age"
            name={"retirementAge"}
            value={retirementAge}
            minVal={30}
            maxVal={70}
            unit="Year"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="Retirement Corpus to last till the Age of"
            name={"lifeExpectancy"}
            value={lifeExpectancy}
            minVal={31}
            maxVal={100}
            unit="Year"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="Expected Investment Returns"
            name={"expectedInvestmentReturn"}
            value={expectedInvestmentReturn}
            minVal={1}
            maxVal={50}
            unit="%"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="Rate of Inflation"
            name={"inflation"}
            value={inflation}
            minVal={1}
            maxVal={25}
            unit="%"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="SIP Step Up"
            name={"stepUp"}
            value={stepUp}
            minVal={1}
            maxVal={25}
            unit="%"
            step={1}
            getValue={handleChange}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}

            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-50"}`}>
            <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Monthly SIP Amount</p>   
              {/* <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP"],
                  data: [fixedSIP, stepUpSIP],
                }}
              /> */}
              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
            <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Total Investment to create a corpus</p>   
                {/* <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Retirement Corpus"],
                  data: [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus],
                }}
              /> */}
              {barChart2}
            </div>
          </div>
          <div className='d-flex w-100'>
            <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
              If you start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span> at the age of <span className=" lato-semibold primary">{currentAge}</span> and plan to retire by the age of <span className=" lato-semibold primary">{retirementAge}</span> you will create a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(corpus)}</span>. And if you step up the monthly SIP amount annually at a rate of <span className=" lato-semibold primary">{stepUp}%</span> then you will have a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{expectedInvestmentReturn}%</span> for the investment tenure.
            </p>
          </div>

        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot 
        textLogo={textLogo} 
        fixedSIP={fixedSIP} 
        stepUpSIP={stepUpSIP} 
        fixedSIPInvesment={fixedSIPInvesment} 
        cumulativeSIP={cumulativeSIP} 
        lumpsum={lumpsum} 
        corpus={corpus} 
        currentAge={currentAge} 
        retirementAge={retirementAge} 
        stepUp={stepUp} 
        expectedInvestmentReturn={expectedInvestmentReturn} 
        onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}



function CalculatorOutputScreenShot({ textLogo, fixedSIP, stepUpSIP, fixedSIPInvesment, lumpsum, cumulativeSIP,corpus,expectedInvestmentReturn,stepUp,retirementAge,currentAge, onDownloadScreenshot }) {
  const {cobrandingLabelling,investor}=textLogo;
  const userProfileDetails = getUser();
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const [signup, setSignup] = useState(false);
 
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot ) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current && getToken()) {
          html2canvas(screenshotRef.current).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (
    <>

    {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>
          </div>}

      </div>

   {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
      <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-50"}`}>
              <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP"],
                  data: [fixedSIP, stepUpSIP],
                }}
              />
             
            </div>
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
              <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Retirement Corpus"],
                  data: [fixedSIPInvesment, cumulativeSIP, lumpsum, corpus],
                }}
              />
             
            </div>
          </div>
          <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      
          <div className='d-flex w-100'>
            <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
              If you start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span> at the age of <span className=" lato-semibold primary">{currentAge}</span> and plan to retire by the age of <span className=" lato-semibold primary">{retirementAge}</span> you will create a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(corpus)}</span>. And if you step up the monthly SIP amount annually at a rate of <span className=" lato-semibold primary">{stepUp}%</span> then you will have a retirement corpus of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{expectedInvestmentReturn}%</span> for the investment tenure.
            </p>
          </div>


      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

    </>
  )
}