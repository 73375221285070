import React, { Component } from "react";
import { Image, Button } from "react-bootstrap";
import Whatsapp from "../../assets/images/whatsapp-phone-img.png";
import WhatsappMb from "../../assets/images/whatsapp-phone-img.png";
import { Header, Footer } from "../common/pages";
import {
  HomeSearch,
  ContentTypeSection,
  SocialMediaSection,
  CuratedContent,
  SearchEngin,
  WhyPremium,
  RegisterTo,
} from "./staticComponents";
class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount() { }
  render() {
    return (
      <section className="home-page">
        <Header />
        <HomeSearch />
        <ContentTypeSection {...this.props} />
        <SocialMediaSection />
        <CuratedContent />
        <SearchEngin />
        <RegisterTo {...this.props} />
        <WhyPremium />
        <div className="join-community-section">
          <div className="container">
            <div className="community-wrapper">
              <div className="whatsapp-community ">
                <h3 className="ft-57-32 lato-bold primary ">
                  Join Thefinpedia’s WhatsApp community
                </h3>
                <div className="mb-whatsapp-img-wrapper">
                  <p className="fs-24-18 lato-regular ">
                    Get your daily dose of the latest, relevant, & popular content & events in personal finance and marketing.
                  </p>
                  <Image src={WhatsappMb} className="mb-show mb-wp-img" alt="image" />
                </div>
                <br /><br /><br />
                <a href="https://chat.whatsapp.com/F1RUur7wUva02FYW6oGFPH" ><Button className="primary-btn mt-5 mx-auto cp">Join now</Button></a>
              </div>
              <div className="whats-app-image">
                <Image src={Whatsapp} alt="image" />
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </section>
    );
  }
}

export default Home;
