import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import { Image } from 'react-bootstrap'
import html2canvas from 'html2canvas';
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import { useRef } from 'react';
import { useMemo } from 'react';
import { getToken } from '../../utils/ManageToken';
import { SignUp } from '../common/components';

export default function DelaySip({ calculator, textLogo = '' }) {

  let [frequency, setFrequency] = useState("monthly");
  let [sipAmount, setSipAmount] = useState(25000);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [tenure, setTenure] = useState(7);
  let [delayInYears, setDelay] = useState(2);
  const selectOption = [
    { value: "monthly", label: "Monthly" },
    // { value: "quarterly", label: "Quarterly" },
  ];

  let [investedAmountNonDelay, setInvestedAmountNonDelay] = useState(2100000);
  let [investmentValNonDelay, setInvestmentValNonDelay] = useState(3299475);

  let [investedAmountDelay, setInvestedAmountDelay] = useState(1500000);
  let [investmentValDelay, setInvestmentValDelay] = useState(2062159);


  useEffect(() => {
    setFrequency(frequency)
    setSipAmount(sipAmount)
    setRateOfInterest(rateOfInterest)
    setTenure(tenure)
    setDelay(delayInYears)
    //  setFrequency(frequency)

    setInvestedAmountNonDelay(investedAmountNonDelay);
    setInvestmentValNonDelay(investmentValNonDelay);
    setInvestedAmountDelay(investedAmountDelay);
    setInvestmentValDelay(investmentValDelay);
  }, [sipAmount, rateOfInterest, tenure, delayInYears, frequency, investedAmountNonDelay, investmentValNonDelay, investedAmountDelay, investmentValDelay])

  const handleChange = (value, name) => {
    // console.log('name', name);
    if (name === "sipAmount") {
      sipAmount = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }
    if (name === "delayInYears") {
      delayInYears = value
    }
    if (name === "frequency") {
      frequency = value
    }

    onSubmitAction(sipAmount, rateOfInterest, tenure, delayInYears, frequency);
  }
  const onSubmitAction = (sipAmount = 0, rateOfInterest, tenure = 0, delayInYears = 0, frequency) => {
    // frequency=document.querySelector('input[name="radio"]:checked').value;
    let multiplicationFactor = mfObj[frequency];
    let roi = (rateOfInterest / 12) * 100 || 0;


    let roiOriginal = rateOfInterest / 100;
    // tenure =document.getElementById("tenure").value || 0 ;
    // sipAmount =document.getElementById("input1").value.replace(/\D+/g, '') || 0 ;
    // delayInYears =document.getElementById("delayInYears").value ;

    const toDivideROI = roi / 10000 || 0;
    const toMultiplyROI = ((1 + (roiOriginal / 12)) * 100 / 100) || 0


    let investedAmountNonDelay = sipAmount * tenure * 12;
    let investedAmountDelay = (tenure - delayInYears) * 12 * sipAmount;

    let investmentValNonDelay = Math.round(((((((1 + (roiOriginal / 12)) * 100) / 100) ** (tenure * 12)) - 1) / toDivideROI) * sipAmount * toMultiplyROI);
    let investmentValDelay = Math.round((((toMultiplyROI ** ((tenure - delayInYears) * 12)) - 1) / toDivideROI) * sipAmount * toMultiplyROI);

    setFrequency(frequency)
    setSipAmount(sipAmount)
    setRateOfInterest(rateOfInterest)
    setTenure(tenure)
    setDelay(delayInYears)

    setInvestedAmountNonDelay(investedAmountNonDelay);
    setInvestmentValNonDelay(investmentValNonDelay);

    setInvestedAmountDelay(investedAmountDelay);
    setInvestmentValDelay(investmentValDelay);


  };

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };


  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [investedAmountNonDelay, investmentValNonDelay],
        }}
      />
    );
  }, [investedAmountNonDelay, investmentValNonDelay]);

  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [investedAmountDelay, investmentValDelay],
        }}
      />
    );
  }, [investedAmountDelay, investmentValDelay]);
  return (
    <div>
        
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator

          </h4>
          <RangeFields
            label="SIP Amount"
            name={"sipAmount"}
            value={sipAmount}
            minVal={500}
            maxVal={1000000}
            step={500}
            getValue={handleChange} />

          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfInterest'}
            value={rateOfInterest}
            minVal={4}
            maxVal={50}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />
          <RadioFields
            options={selectOption}
            initialValue={"monthly"}
            onSelectionChange={(v) => { setFrequency(v) }}
            // initialValue={""}
            lable="SIP Frequency"
          />
          <RangeFields
            label="Investment Tenure"
            name={"tenure"}
            value={tenure}
            minVal={2}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={handleChange}
          />
          {/* <RadioFields
            label={"SIP Frequency"}
            value={"monthly"}
            name={"frequency"}
            value={frequency}
            selected={true}
          /> */}

          <RangeFields
            label="Delay"
            name={'delayInYears'}
            value={delayInYears}
            minVal={1}
            maxVal={20}
            step={1}
            unit="Year"
            getValue={handleChange}
          />



          {/* <RangeFields label="Delay" minVal={6} maxVal={50} unit="Year" /> */}
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
            <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Without Delay</p>
              {/* <BarChart
                info={{
                  labels: ["Invested Amount", "Investment Value"],
                  data: [investedAmountNonDelay, investmentValNonDelay],
                }}
              /> */}
              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
            <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>With Delay of 2 years</p>
              {/* <BarChart
                info={{
                  labels: ["Invested Amount", "Investment Value"],
                  data: [investedAmountDelay, investmentValDelay],
                }}
              /> */}
              {barChart2}
            </div>
          </div>
          <div className='d-flex justify-content-around p-2'>
            <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
              With a monthly SIP of <span className=" lato-semibold primary">₹{sipAmount}</span>, after <span className=" lato-semibold primary">{tenure}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountNonDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValNonDelay)}</span>. However, if you delay your SIP by <span className=" lato-semibold primary">{delayInYears}</span> years, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValDelay)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
            </p>
          </div>
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          data={[investedAmountNonDelay, investmentValNonDelay]}
          data2={[investedAmountDelay, investmentValDelay]}
          sipAmount={sipAmount}
          investedAmountNonDelay={investedAmountNonDelay}
          investmentValNonDelay={investmentValNonDelay}
          investedAmountDelay={investedAmountDelay}
          investmentValDelay={investmentValDelay}
          tenure={tenure}
          delayInYears={delayInYears}
          rateOfInterest={rateOfInterest}
          onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, data, data2, sipAmount, tenure, delayInYears, investedAmountNonDelay, onDownloadScreenshot, investmentValDelay, rateOfInterest, investmentValNonDelay, investedAmountDelay, }) {
  const userProfileDetails = getUser();
  const { cobrandingLabelling, investor } = textLogo;
  const [signup, setSignup] = useState(false);
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(async() => {
  //   try {
  //     const response = await fetch(userProfileDetails?.organizationLogoPath ?? "",{ mode: 'cors' });
  //     if (!response.ok) throw new Error('Network response was not ok.');
  //     const blob = await response.blob();
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //         alert(reader.result);
  //     };
  //     reader.readAsDataURL(blob);
  // } catch (error) {
  //     console.error('Error fetching or converting image:', error);
  // }
  
    if (onDownloadScreenshot ) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current && getToken()) {
          html2canvas(screenshotRef.current,{useCORS:true,allowTaint:true}).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (<>
  {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>


          </div>}

      </div>


      {/* <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
        <BarChart
          info={{
            labels: ["Total Invested Amount", "Total Investment Value"],
            data: [data[0], data[1]],
          }}
        />
      </div> */}
      <div className="result-graph-wrapper">
        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: data,
            }}
          />
        </div>
        <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: data2,
            }}
          />
        </div>
      </div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      <br />
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        With a monthly SIP of <span className=" lato-semibold primary">₹{sipAmount}</span>, after <span className=" lato-semibold primary">{tenure}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountNonDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValNonDelay)}</span>. However, if you delay your SIP by <span className=" lato-semibold primary">{delayInYears}</span> years, your total investment amount will be <span className=" lato-semibold primary">₹{formatCurrency(investedAmountDelay)}</span> and its value will be <span className=" lato-semibold primary">₹{formatCurrency(investmentValDelay)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
      </p>

 
      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

    </>
  )
}