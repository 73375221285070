import React from 'react'
import { Image, Modal } from 'react-bootstrap'
import close from "../../../assets/images/cancel-icon.svg";
import logo from "../../../assets/images/brand-logo.svg";
import google from "../../../assets/images/google-icon.png";
// import facebook from "../../../assets/images/facebook-icon.svg";
// import linedin from "../../../assets/images/linedin-icon.svg";
import email from "../../../assets/images/mail-send-icon.svg";
import { Link } from 'react-router-dom';
import { API_BASE_URL } from '../../../utils/Constant';

const loginWith = [
  // { text: "Continue with Google", icon: google, link: `https://${API_BASE_URL}finpedia/user/auth/google`, target: "_blank" },
  // { text: "Continue with Facebook", icon: facebook, link: "" },
  // { text: "Continue with Linedin", icon: linedin, link: "" },
  { text: "Sign up", icon: email, link: "/onboarding", target: "_self" }
]

export default function SignUp({ show, handleClose }) {
  return (
    <div className=''>
      <Modal className="signup-custom-model" show={show} onHide={handleClose}>
        <Modal.Body className="signup-model-body">
          <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn" />
          <Image src={logo} onClick={() => { }} alt="logo" className="brand-logo" />
          <h5 className='lato-semibold fs-32-24 black-242'>Sign up to continue</h5>
          <h6 className='lato-regular fs-16-14 black-242'>Already a member? <Link to="/onboarding" ><span className='primary pointer'> Log in</span></Link></h6>
          <div className="login-accound-wrapper">
            {loginWith?.map((item, i) => {
              return (
                <a href={item.link} target={item.target} key={i} className="continue-with-account lato-regular fs-16-14 black-242">
                  <Image src={item.icon} alt='google' />
                  {item.text}
                </a>
              )
            })}
          </div>
        </Modal.Body>
      </Modal>
    </div>
  )
}
