import React from 'react'
import { Footer, Header } from '../common/pages'
import { Button, Image } from 'react-bootstrap'
import aboutHeroImg from '../../assets/images/about-heror-img.png';
import aboutHeroImgMb from '../../assets/images/abot-heroe-mb.png';
import founder from '../../assets/images/GauravMody.png';
import linkdin from '../../assets/images/linkdin-2.png';
import aboutGallary from '../../assets/images/about-gallary1.png';
import aboutGallary1 from '../../assets/images//about-gallary2.png';
import gallary2 from '../../assets/images/gallary2.jpg';
import gallary1 from '../../assets/images/gallary1.jpg';
import gallary3 from '../../assets/images/gallary3.jpg';
import gallary4 from '../../assets/images/gallary4.jpg';
import gallary5 from '../../assets/images/gallary5.jpg';
import { useState } from 'react';

export default function Aboutus() {
  return (
    <div className='pt-5'>
      <Header />
      <section className='about-hero-section py-5'>
        <div className="container-mb h-100">
          <div className="about-hero-frame">
            <div className="about-contetn">
              <h5 className=' lato-semibold f-s-20 lh-28 primary'>ABOUT US </h5>
              <div className='about-below-line'></div>
              <h6 className='mw-570 lato-regular fs-32-28 black-242'>We empower MFDs & advisors to up their digital game by <span className='primary'>crafting contextual communication and creating impactful brand stories!</span> </h6>
              <p className=' mw-570 lato-regular fs-18-16 black-242 mb-4'>At Thefinpedia, we are here to create a MFD (Mutual Fund Distributor) & RIA (Registered Investment Advisor) community of smart marketers.Reach out now to Unlock your Marketing Potential! </p>
              <Button className='primary-btn mt-3'>Let's Talk</Button>
            </div>
            <div className="about-image">
              <Image src={aboutHeroImg} className='desk-image' alt={"image"} />
              <Image src={aboutHeroImgMb} className=' mb-show' alt={"image"} />

            </div>
          </div>
        </div>
      </section>
      <section className='about-story-section'>
        <div className="container">
          <div className="about-over-story ">
            <p className=' lato-semibold fs-22-18 primary'>OUR STORY</p>
            <p className=' lato-regular fs-16-14 black-242 text-justify'>Thefinpedia- founded by Gaurav Mody aims to empower MFDs & advisors who thereby help their clients make right financial decisions. This is his second entrepreneurial venture after starting FinAce Infosolutions LLP in 2016. Over the years, our collaboration with major brands achieved great success. And we realized that its now the time for us to help the distributors and advisors community seeking for effective communication & marketing solutions.
              <br />
              <br />
              Recognizing a significant gap in the market, we realized the need to establish Thefinpedia. At Thefinpedia we focus on assisting advisors and distributors in connecting more meaningfully with their clients by providing them with a one-stop-solution for their branding and content needs. This strategic move aligns with our objective to empower investors in making informed and sound financial decisions.
              At Thefinpedia, we are driven by the belief that better communication leads to better decisions. Our journey from Finace Infosolutions to Thefinpedia is a testament to our commitment to bridging gaps and facilitating informed financial choices for all.
            </p>
          </div>
          <div className="about-over-story about-over-purpost">
            <p className=' lato-semibold fs-22-18 primary'>OUR PURPOSE</p>
            <p className=' lato-regular fs-16-14 black-242 text-justify'>
              In the intricate landscape of the financial industry, MFDs & RAIs stand as pivotal players, with 77% of individuals choosing them as their investment partners, driven by their remarkable retention rates. At Thefinpedia, we are not merely observers of this reality; we are architects of transformation, fostering an ecosystem where MFDs/ Advisors thrive not just as individuals or small teams but as dynamic institutes.
            </p>
          </div>

        </div>
      </section>

      <section className='about-achieve-section '>
        <div className="container">
          <div className="about-over-story  ">
            <p className=' lato-semibold fs-22-18 primary'>HOW WE ACHIEVE THIS?</p>
            <p className=' lato-regular fs-16-14 black-242 text-justify'>
              At Thefinpedia, we don't just support Advisors; we empower them to redefine industry standards. Our commitment is to fuel their evolution from individuals to institutes, ensuring they stand at the forefront of excellence in the financial advisory realm.
            </p>
          </div>
        </div>
        <div className="container-mb ">
         <ImageGallery/>
        </div>

        <div className="container position-relative">
          <p className=' lato-semibold fs-22-18 primary'>MEET THE FOUNDER</p>
          <div className="founder-frame">
            <div className="founder-image">
              <Image src={founder} alt='' />
            </div>
            <div className="founder-text">
              <div className="founder-name-head">
                <h5 className=' lato-regular fs-45-28 primary'>Gaurav Mody </h5>
                <p className=' lato-semibold fs-18-14 black-242'>Founder, Thefinpedia</p>
              </div>
              <p className='description lato-regular fs-20-16 black-242'>Sets the bar high for out-of-the-box thinking and cutting-edge ideas.
                Loves turning intricate problems into simple, elegant solutions, transforming challenges into opportunities with a creative touch.
                Passionate about making communication not just effective but downright fun.
              </p>
              <div className="lets-connect">
                <a href="https://www.linkedin.com/company/thefinpedia/" className='pointer' target='_blank'>
                  <Image src={linkdin} alt='icon' />
                </a>
                <span className='get-in-touch'>Get in Touch</span>
              </div>
            </div>

          </div>
        </div>

      </section>


      <Footer />
    </div>
  )
}



// import React, { useState } from 'react';
// import './ImageGallery.scss';

const images = [
  { id: 1, url: gallary1 },
  { id: 2, url: gallary2 },
  { id: 3, url: gallary3 },
  { id: 4, url: gallary4 },
  { id: 5, url: gallary5 },
];

const ImageGallery = () => {
  const [activeImage, setActiveImage] = useState(1);

  const handleMouseOver = (id) => {
    setActiveImage(id);
  };

  const handleMouseOut = () => {
    setActiveImage(null);
  };

  return (
    <div className="image-gallery">
      {images.map((image) => (
        <div
          key={image.id}
          className={`image-container ${activeImage === image.id ? 'active' : ''}`}
          onMouseOver={() => handleMouseOver(image.id)}
          // onMouseOut={handleMouseOut}
          style={{ backgroundImage: `url(${image.url})` }}
        >
          {/* <p className='bottom-text lato-regular fs-24-18 text-white'>Regularly engage on different platforms.</p> */}
        </div>
      ))}
    </div>
  );
};


