import React from 'react'
import { Image } from 'react-bootstrap'
import { Trending } from '..'
import noResult from '../../../assets/images/No-results-found.gif'
// import noResult from '../../../assets/images/Error-404-not-found.png'
import trending from '../../../assets/images/trend-black.svg'

export default function NotFound({ massage = "" }) {
  return (
    <div className='search-not-found'>
      <div className="not-found-img">
        <Image src={noResult} alt='Result Not Foune' className=' mx-auto' />
      </div>

      <h5 className='lato-semibold fs-24-12 primary text-center mb-2'>
        The paper chase ends here: {massage || "No results found"} .<br />
      </h5>
      <h5 className='lato-semibold fs-24-12 primary text-center'>

        Try refining your search keywords or explore our popular categories below
      </h5>
      {/* <div className="search-trand-wraper">
        <Trending title={"SIP"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
        <Trending title={"ELSS"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
        <Trending title={"Financial Planning"} classes="fs-18-14 lato-semibold not-found-trend" image={trending} />
      </div> */}
    </div>
  )
}
