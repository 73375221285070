import React from 'react'
import { Image } from 'react-bootstrap';
import Video from "../../../assets/images/Videos.png";
import Infographic from "../../../assets/images/Infographics.png";
import Minimalists from "../../../assets/images/minimalists.png";
import Presentations from "../../../assets/images/presentations.png";
import Arrow from "../../../assets/images/arrow-2.svg";
import { INFOGRAPHICS, MAX_LIMIT, MINIMALISTS, PRESENTATIONS, START_PAGE, VIDEOS } from '../../../utils/Constant'
import { navigateSetUrl } from '../../../utils/ReusableFunctions';
import { useHistory } from 'react-router-dom';

export default function ContentTypeSection() {
  const history = useHistory();
  const navigateData = (type) => {
    let pathname = `/content-type/${type}`;
    let search = `?pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
    navigateSetUrl(history, pathname, search);
  }
  return (
    <div className="feature-section">
      <div className="container-mb">
        <div className="feature-box-wrapper">
          <FeatureBox onClick={() => navigateData(VIDEOS)} img={Video} name="Videos" />
          <FeatureBox onClick={() => navigateData(INFOGRAPHICS)} img={Infographic} name="Infographics" />
          <FeatureBox onClick={() => navigateData(MINIMALISTS)} img={Minimalists} name="Minimalists" />
          <FeatureBox onClick={() => navigateData(PRESENTATIONS)} img={Presentations} name="Presentations" />
        </div>
        {/* <Image src={Arrow} className="right-arrow" alt="arrow" /> */}
      </div>
    </div>

  )
}

function FeatureBox({ onClick, img, name }) {
  return (
    <div className='feature-box' onClick={onClick}>
      <Image src={img} alt='img' className='feature-image' />
      <div className="black-wrapper">
        <h4 className='lato-regular fs-18-16 white'>{name}</h4>
      </div>

    </div>
  )
}
