import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import { BreadCrumb } from "../home/_utils";
import { getAuthDataApi } from "../home/Api";
import close from "../../assets/images/cancel-icon.svg";
import unboxImage from "../../assets/images/Subscription-sucess.png";
import SubscriptionPlan from "./SubscriptionPlan";
import moment from "moment/moment";
import { purchaseAmoutBreakup, purchasePlanApi, upgradeAmoutBreakup, upgradePlanApi } from "./Api";
import { Button, Image, Modal } from "react-bootstrap";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

function MySubscription(props) {
  const history = useHistory();
  const [planList, setPlanList] = useState([]);
  const [signup, setSignup] = useState();
  const [active, setActive] = useState("");
  const [planData, setPlanData] = useState([]);
  useEffect(() => {
    const showSucces = JSON.parse(sessionStorage.getItem('finSubscriptionDone'));
    // console.log(typeof(showSucces),showSucces)
    setSignup(showSucces);
    async function fetchData() {
      const data = await getAuthDataApi(`user/billing-plan/available`);
      const planData = await getAuthDataApi(`user/billing-plan/subscriptions`);
      setPlanData(planData);
      setPlanList(data);
    }
    fetchData();
  }, []);

  const handleSubscription = (selectedPlan, isActivePlan) => {
    // console.log('data', selectedPlan);
    // console.log('isActivePlan', isActivePlan);
    // console.log('this.props', props);

    const data = {
      "data": {
        "planUuid": selectedPlan.uuid,
      }
    }
    purchaseAmoutBreakup(data, props.history)
    // if (isActivePlan.length > 0) {
    //   const data = {
    //     "data": {
    //       "userBillingPlanUuid": isActivePlan[0]?.premiumPlan?.uuid,
    //       "newPlanUuid": selectedPlan?.uuid,
    //     }
    //   }
    //   upgradeAmoutBreakup(data, props.history)
    // } else {
    //   const data = {
    //     "data": {
    //       "planUuid": selectedPlan.uuid,
    //     }
    //   }
    //   purchaseAmoutBreakup(data, props.history)
    // }


  }
  const handleClose = () => { setSignup(!signup); sessionStorage.removeItem('finSubscriptionDone') }
  return (
    <div className="mySubscription-page">
      {signup &&
        <Modal className="signup-custom-model  mysubscription-custom-succes" show={signup} onHide={handleClose}>
          <Modal.Body className=" mysubscription-body-succes ">
            <Image src={close} onClick={handleClose} alt="canle icon" className="close-btn" />
            <h5 className='lato-semibold fs-32-24 primary'>Subscription successful</h5>
            <h6 className='lato-regular fs-16-14 black-242 mt-3'>Unbox exclusive premium membership benefits now! </h6>
            <Image src={unboxImage} alt="logo" className="box-img" />
            <Button onClick={() => { history.push({ pathname: '/' }); console.log("Nevigae to Home") }} className=" primary-btn explore-fin-btn">Explore Finpedia</Button>
          </Modal.Body>
        </Modal>
      }
      <BreadCrumb path={["My Membership Plans"]} clasese="mb-3-desk" />
      <div className="mySubscription-wrapper">
        <h5 className="lato-rgular fs-36-24 black-242 ">My Membership Plans</h5>
        <div className="subscription-details bg-primary-05">
          <div className="container">
            {planData.length > 0 ?
              <div className="plan-update-wrapper bg-ECE-mb">
                <div className="plan-details-box">
                  <h6 className="lato-rgular  fx-24 primary">Active Plan</h6>
                  {
                    planData?.map((item, i) => {
                      return (
                        <p className="fs-16-14 lato-regular black-252">{item.premiumPlan?.name}</p>
                      )
                    })
                  }

                </div>
                <div className="plan-details-box">
                  <h6 className="lato-rgular  fx-24 primary">Start</h6>
                  {
                    planData?.map((item, i) => {
                      return (
                        <p className="fs-16-14 lato-regular black-252">{moment(item.startDate).format('ll')}</p>
                      )
                    })
                  }
                </div>
                <div className="plan-details-box">
                  <h6 className="lato-rgular  fx-24 primary">Expiry</h6>
                  {
                    planData?.map((item, i) => {
                      return (
                        <p className="fs-16-14 lato-regular black-252">{moment(item.endDate).format('ll')}</p>
                      )
                    })
                  }
                </div>
                {/* <div className="plan-details-box">
                  <h6 title="Downloads Remaining" className="lato-rgular  fx-24 primary"> Downloads Remaining </h6>
                  {
                    planData?.map((item, i) => {
                      return (
                        <p className="fs-16-14 lato-regular black-252">{item.contentLimits}/{item.premiumPlan?.contentLimits} for current month</p>
                      )
                    })
                  }

                </div> */}
                <div className="plan-details-box">
                  {/* <h6 className="lato-rgular  fx-24 primary">Billing Information</h6> */}
                  {/* <p className="fs-16-14 lato-regular">
                  On
                  <input
                    type="checkbox"
                    name="so"
                    id="renewal"
                    className="d-none"
                    checked={planData[0].needsRenew}
                  />
                  <label htmlFor="renewal" className="toggle-btn">
                    <div className="toggle-circle"></div>
                  </label>
                  off
                </p> */}
                  {/* <a className="fs-16-14 lato-regular black-252 pb-10 d-block" href="">Auto Renewal</a>
                  <a className="fs-16-14 lato-regular black-252 pb-10 d-block" href="">Modify billing info</a>
                  <a className="fs-16-14 lato-regular black-252 pb-10 d-block" href="">Billing history</a> */}
                </div>
              </div>
              :
              <div className="plan-update-wrapper w-100">
                {/* <h6 className="lato-rgular py-4 text-center w-100 fx-24 primary">There is no active plan...</h6> */}
              </div>
            }
          </div>
        </div>
      </div>
      <div className="up-your-game">
        <h5 className="fs-28-24 lato-regular black-242 pb-10">
          Want to up your game?
        </h5>
        <p className="fs-18-14 lato-regular">
          Select and upgrade to a plan of your choice
        </p>
        <div className="your-game-card-wrapper">
          {
            planList && planList.map((item, i) => {
              // if (planData.length === 0 || item.level > planData[0]?.premiumPlan.level || item.isTopup || item.isCustomPlan) {
              return (
                <SubscriptionPlan
                  key={i}
                  index={i}
                  data={item}
                  isActivePlan={planData}
                  // status={status}
                  handleSubscription={(data, isActivePlan) => handleSubscription(data, isActivePlan)}
                />
              )
              // }
            })
          }
        </div>
      </div>
    </div>
  );
}

export default MySubscription;
