
import { toast } from "react-toastify";
import { postLoginInstance, preLoginInstance } from "../../utils";
export const getCommonApi = async (url) => {
    try {
      const response = await postLoginInstance.get(url);
      // const data = response.data;
      // console.log(data)
      return response;
    } catch (error) {
      toast.error(error.response.data.message || "Something went wrong")
      return error;
    }
  };