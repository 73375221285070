import { combineReducers } from 'redux';
/* PLOP_INJECT_REDUCER_IMPORT */
import { SocialMediaReducer } from '../app/socialMedia';
import { EventsReducer } from '../app/events';
import { HomeReducer } from '../app/home';
import { ProfileReducer } from '../app/profile';
import { OnBoardingReducer } from '../app/onBoarding';

export default combineReducers({
  /* PLOP_INJECT_REDUCER */
	SocialMediaState: SocialMediaReducer,
	EventsState: EventsReducer,
	HomeState: HomeReducer,
	ProfileState: ProfileReducer,
	OnBoardingState: OnBoardingReducer,
});