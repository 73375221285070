import React from 'react';
import { Button } from 'react-bootstrap';
import RegisterToCard from './RegisterToCard';
import socialMedia from '../../../assets/images/social-media.png';
import discover from '../../../assets/images/Discover-icon.png';
import personalise from '../../../assets/images/personalise.png';
import mostViewed from '../../../assets/images/most-viewed.png';
import illustrations from '../../../assets/images/homepage-illustrations.png';
import collection from '../../../assets/images/add-to-collection.png';
import { useHistory } from 'react-router-dom';
import { getUser } from '../../../utils/ReusableFunctions';

export default function RegisterTo() {
  const history = useHistory();
  return (
    <div className='register-to-section'>
      <div className="container text-center">
        <h5 className=" fs-32-28 lato-semibold primary text-center">
          Register & get started
        </h5>
        <p className="lato-regular fs-22-16 black-242 text-center">
          Get access to exciting features and benefits, absolutely Free!
        </p>
        <div className="registet-wrapper">
          <RegisterToCard image={socialMedia} text={`Up your social media game with <span class='lato-semibold primary'>free posts</span> `} clases="" />
          <RegisterToCard image={discover} text={`<span class='lato-semibold primary'>Discover</span> compliant personal finance content easily `} clases="" />
          <RegisterToCard image={personalise} text={`<span class='lato-semibold primary'>Personalise</span>  content with your brand logo & colors`} clases="" />
          <RegisterToCard image={mostViewed} text={`Check the most <span class='lato-semibold primary'>viewed, liked, & shared</span> content`} clases="" />
          <RegisterToCard image={collection} text={`Save content to <span class='lato-semibold primary'>collection</span> for training & upskilling`} clases="" />
          <RegisterToCard image={illustrations} text={`Stay updated with top <span class='lato-semibold primary'>industry trends and events</span>`} clases="" />
        </div>
        {
          !getUser() &&
          <Button className='primary-btn mx-auto cp' onClick={() => history.push({ pathname: "/onboarding", state: { register: true } })}>Register for Free</Button>}
      </div>


    </div>
  )
}
