import React from "react";
import Carousel from "react-bootstrap/Carousel";
import { connect } from "react-redux";
import { Image } from "react-bootstrap";
import Thefinpedia from "../../assets/images/brand-manual.svg";
import PasswordHide from "../../assets/images/password-hide.svg";
import PasswordShow from "../../assets/images/password-show.svg";
import bulb2x from "../../assets/images/bulb2x.png";
import sideAnimation from "../../assets/images/Carousel-login.gif";
// import sideAnimation from "../../assets/images/Start-carousel-screen_V.gif";
import { registerUserApi, verifyUserApi } from "./Api";
import {
  BaseReactComponent,
  CustomButton,
  CustomTextControl,
  Form,
  FormElement,
  FormValidator,
} from "../../utils/form";
import { deleteToken } from "../../utils/ManageToken";
import ForgotPassword from "./ForgotPassword";
import { toast } from "react-toastify";
import ResetPassword from "./ResetPassword";
import LoginForm from "./_utils/LoginForm";
import { SITE_KEY } from "../../utils/Constant";
import ReCAPTCHA from "react-google-recaptcha";
import { useState } from "react";
import { verifyCaptchaApi } from "../profile/Api";


class OnBoarding extends BaseReactComponent {
  constructor(props) {
    super(props);
    // console.log(props)
    const show = props.location?.state?.register || "";
    this.myRef = React.createRef();
    const token = props.match.params.token || "";
    this.state = {
      index: 0,
      show: show ? false : true,
      showOtp: false,
      login: "",
      password: "",
      loginPassword: "",
      // name: "",
      companyName: "",
      arnRai: "",
      email: "",
      phone: "",
      // reEnterPassword: "",
      showForgotPasswordModal: false,
      token: token ? token : "",
      showPassowrd: false,
      showConfirmPassword: false,
      recaptchaToken: null,
      captureText: false,
      showOtpBtn: false,
      showTimer: false,
      secondsLeft: 30,
    };
  }
  handleSelect = (selectedIndex) => {
    this.setState({
      index: selectedIndex,
    });
  };

  componentDidMount() {
    deleteToken();
  }

  onValidSubmitRegister = async () => {
    const { password, companyName, arnRai, email, phone } = this.state;
    if (this.state.recaptchaToken === null || !this.state.showOtpBtn) {
      this.setState({ captureText: true, error: "Invalid captcha" })
    } else if (email && arnRai && companyName && phone && password) {
      const data = {
        "data": {
          "mobileNumber": phone
        }
      }
      const response = await verifyUserApi(data);
      if (!response.error && !response.data.httpCode) {
        this.setState({ showOtp: true, login: "", loginPassword: "", showTimer: true })
        toast.success(response.data.message || "OTP sent to you mobile number");
        this.startCountdown();
      } else {
        toast.error(response.data.message || "Something went wrong");
      }

    } else {
      toast.error("Please enter all the values");
    }
  }
  startCountdown() {
    let countdown = setInterval(() => {
      if (this.state.secondsLeft > 0) {
        this.setState((prevState) => ({
          secondsLeft: prevState.secondsLeft - 1,
        }));
      } else {
        clearInterval(countdown);
        this.setState({ showTimer: false, secondsLeft: 30 });
      }
    }, 1000); // Update every second
  }
  handleChange = (e, validation) => {
    let isValid = [];
    validation.map((item) => {
      if (FormValidator[item](e.target.value)) {
        isValid.push(true)
      } else {
        isValid.push(false)
      }
    })
    const check = isValid.every((item) => item === true);
    if (check) {
      this.setState({ [e.target.name]: e.target.value })
    } else if (!check && !(validation.includes("isRequired"))) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  render() {
    const { index, show } = this.state;
    return (
      <div className="login-page">
        {
          this.state.showForgotPasswordModal &&
          <ForgotPassword
            show={this.state.showForgotPasswordModal}
            handleClose={() => { this.setState({ showForgotPasswordModal: false }) }}
          />
        }
        {
          this.state.token &&
          <ResetPassword
            show={this.state.token}
            handleClose={() => {
              this.setState({ token: false })
              this.props.history.push("/onboarding");
            }}
            {...this.prosp}
          />
        }
        <div className="left-carousel-frame">
          <Image src={sideAnimation} alt="clouser" className="w-100 h-100 " />
        </div>
        <div className="right-login-frame">
          <div className="logo-wrapper">
            <Image src={Thefinpedia} alt="logo" className="pointer" onClick={() => this.props.history.push("/")} />
          </div>
          {show ? (
            <LoginForm
              toggleForm={() => this.setState({ show: !show })}
              {...this.props}
            />
          ) : (
            <div className="register-box">
              {!this.state.showOtp ? <>
                <h5 className="lato-regular white f-s-24 lh-32">Register</h5>
                <Form onValidSubmit={this.onValidSubmitRegister}>
                  {/* <div className="form-group">
                  <input
                    value={this.state.name}
                    type="text"
                    name="name"
                    className="form-control"
                    onChange={(e) => this.handleChange(e, ["isLetter"])}
                    placeholder="Name"
                  />
                </div>*/}
                  <div className="form-group">
                    <input
                      value={this.state.companyName}
                      type="text"
                      name="companyName"
                      className="form-control"
                      onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                      placeholder="Company Name"
                    />
                  </div>
                  <div className="form-group">
                    <input
                      value={this.state.arnRai}
                      type="text"
                      name="arnRai"
                      className="form-control"
                      onChange={(e) => this.handleChange(e, ["isNum"])}
                      placeholder="ARN/RAI"
                      maxLength={10}
                    />
                  </div>
                  <FormElement
                    valueLink={this.linkState(this, "email")}
                    required
                    validations={[
                      // {
                      //   validate: FormValidator.isRequired,
                      //   message: "Email cannot be empty",
                      // },
                      {
                        validate: FormValidator.isEmail,
                        message: "Please enter valid email",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        placeholder: "Email",
                      },
                    }}
                  />
                  <div className="form-group">
                    <input
                      value={this.state.phone}
                      type="text"
                      name="phone"
                      className="form-control"
                      onChange={(e) => this.handleChange(e, ["isNum"])}
                      placeholder="Mobile no. (Whatsapp preferably)"
                      maxLength={10}
                    />
                  </div>
                  <div className='pos-rel'>
                    <FormElement
                      valueLink={this.linkState(this, "password")}
                      required
                      validations={[
                        // {
                        //   validate: FormValidator.isRequired,
                        //   message: "Password cannot be empty",
                        // },
                        {
                          validate: FormValidator.hasMinLength(8),
                          message: "Minimum length should be 8 characters",
                        },
                        {
                          validate: FormValidator.isPassword,
                          message: "Your password should include atleast 1 capital, 1 small, 1 number & 1 special character",
                        },
                      ]}
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: this.state.showPassowrd ? "text" : "password",
                          placeholder: "Password",
                        },
                      }}
                    />
                    <Image
                      src={this.state.showPassowrd ? PasswordShow : PasswordHide}
                      className='password-hide pos-abs pointer'
                      onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
                    />
                  </div>
                  {/* <div className='pos-rel'>
                  <FormElement
                    valueLink={this.linkState(this, "reEnterPassword")}
                    required
                    validations={[
                      {
                        validate: FormValidator.isRequired,
                        message: "Password cannot be empty",
                      },
                    ]}
                    control={{
                      type: CustomTextControl,
                      settings: {
                        type: this.state.showConfirmPassword ? "text" : "password",
                        placeholder: "Re-enter Password",
                      },
                    }}
                  />
                  <Image
                    src={this.state.showConfirmPassword ? PasswordShow : PasswordHide}
                    className='password-hide pos-abs pointer'
                    onClick={() => this.setState({ showConfirmPassword: !this.state.showConfirmPassword })}
                  />
                </div> */}
                  <div className="pb-20">
                    <ReCAPTCHA
                      sitekey={SITE_KEY}
                      onChange={(data) => {
                        // console.log('data', data)
                        this.setState({ recaptchaToken: data, captureText: false, error: '' })
                        const postData = {
                          data: {
                            recaptchaToken: data
                          }
                        }
                        verifyCaptchaApi(postData, this);
                      }
                      }
                    />
                    {
                      this.state.error && this.state.captureText &&
                      <p className="lato-regular red-FF3 f-s-14 lh-20 error-msg pointer">{this.state.error}</p>
                    }
                    <p className="lato-regular f-s-10 lh-16 white">On clicking the button below, you agree with Thefinpedia <a href="/terms-conditions" target="_blank"> Terms of Service </a> and <a href="privacy-policy" target="_blank"> Privacy Policy</a> </p>
                  </div>

                  <CustomButton
                    type="button "
                    // onClick={}
                    isDisabled={!this.state.showOtpBtn}
                    className={`ghost-btn mw-352 mw-b-15`}
                    buttonText="Send verification OTP"
                  />


                </Form>
                <h6 className='lato-regular fs-16-14 black-242 mt-5 white'>Existing user?<span onClick={() => this.setState({ show: true, login: "", loginPassword: "" })} className='lato-semibold pointer wh-boder-b'> Log in</span></h6>
              </>
                :
                <div className="otp-main-wrapper">
                  <h3 className="back-to-register lato-regular white f-s-24 lh-32 mb-24">
                    <div onClick={() => this.setState({ showOtp: false, showOtpBtn: false })} className="back-btn fx-16"> <span className="back-arrow"></span>back</div>
                    Enter OTP </h3>
                  <h5 className="lato-regular white f-s-24 lh-32 pb-0">Please enter verification code we’ve sent to
                    mobile number ending with **{this.state.phone.slice(-4) || '2345'}</h5>
                  <OtpInput ctx={this} resendOtp={this.onValidSubmitRegister} />
                </div>


              }
            </div>

          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  onBoardingState: state.OnBoardingState,
});
const mapDispatchToProps = {
  // getPosts: fetchPosts
};
OnBoarding.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(OnBoarding);




const OtpInput = ({ ctx, resendOtp }) => {
  const { email, password, arnRai, companyName, phone, showTimer, secondsLeft } = ctx.state;
  const otpLength = 6;
  const [otp, setOtp] = useState(new Array(otpLength).fill(''));


  const handleChange = (element, index) => {
    if (isNaN(element.value)) return false;

    const newOtp = [...otp];
    newOtp[index] = element.value.slice(0, 1);
    setOtp(newOtp);

    if (index < otpLength - 1 && element.value !== '') {
      const nextSibling = document.getElementById(`otp${index + 2}`);
      if (nextSibling) {
        nextSibling.focus();
      }
    }
  };


  const otpVerificationHandler = async (e) => {
    e.preventDefault()
    // console.log(otp.join(''))
    const data =
    {
      "data": {
        "unverifiedEmailAddress": email,
        "password": password,
        "arnNumber": arnRai,
        "companyName": companyName,
        "unverifiedMobileNumber": phone,
        "mobileNumberOtp": otp.join("")
      }
    }
    const response = await registerUserApi(data);
    if (!response.error && !response.data.httpCode) {
      ctx.setState({ showOtp: true, show: true, login: "", loginPassword: "" })
      toast.success("Registration Successful. Please verify your email ID to login to your account. Click the link in the email we've sent you.", { autoClose: 20000 });
    } else {
      toast.error(response.data.message || "Something went wrong");
    }
  }
  const handleBackspace = (element, index) => {
    if (index > 0 && element.value === '') {
      const prevSibling = document.getElementById(`otp${index}`);
      if (prevSibling) {
        prevSibling.focus();
      }
    }
  };
  return (
    <div className="otp-frame">
      <form onSubmit={otpVerificationHandler}>
        <div className="otp-container">
          {otp.map((data, index) => {
            return (
              <input
                key={index}
                type="text"
                id={`otp${index + 1}`}
                maxLength="1"
                value={data}
                onChange={e => handleChange(e.target, index)}
                onKeyDown={e => e.key === 'Backspace' && handleBackspace(e.target, index)}
                onFocus={e => e.target.select()}
                className="otp-input lato-regular fx-14 black-06 "
                autoComplete="off"
                required
              />
            );
          })}

        </div>
        <button className="ghost-btn mw-352 mw-b-15 pointer">Verify and Register</button>
        <div className="resend-otp-frame d-flex align-items-center justify-content-center mw-352 mx-auto">

          {showTimer ? (
            <div>
              <h6 className='lato-bold fs-16-14 black-242 mb-0 white '> Resend in {secondsLeft} seconds</h6>
            </div>
          ) : (
            <p onClick={resendOtp} className='lato-bold fs-16-14 black-242 mb-0 white '> Resend OTP</p>
            // <button onClick={this.handleClick}>Resend</button>
          )}



          {/* <p className='lato-bold fs-16-14 black-242 mb-0 white '> Request by Call</p> */}
        </div>
      </form>
      <div className="why-otp-need-box">
        <Image src={bulb2x} alt="icon" />
        <div className="why-otp-contetn">
          <h4 className=" lato-regular fx-14 lh-24 black-242 text-start">Why do we need this?</h4>
          <p className=" lato-regular f-s-12 lh-16 black-242 op-5 text-start">We need the phone number to verify your account, to send you account updates and to contact you.</p>
        </div>
      </div>
    </div>
  );
};




function CarouselCode() {
  return (
    <Carousel activeIndex={1} onSelect={this.handleSelect}>
      {/* <Carousel activeIndex={index} onSelect={this.handleSelect}> //khs is correct */}
      <Carousel.Item>
        {/* ==================== mak scroll ====================== */}
        <div className="first-carousel">
          <div className="left-heading">
            <h2 className="lato-regular blue-270 f-s-57 lh-68">
              Discover
            </h2>
            <p className="lato-regular blue-270 f-s-29 lh-35">
              Perfect marketing material to help your clients invest
              wisely
            </p>
          </div>
        </div>
        {/* ==================== mak scroll ====================== */}
      </Carousel.Item>
      <Carousel.Item>
        {/* ==================== mak scroll ====================== */}
        <div className="first-carousel login-second-image">
          <div className="left-heading">
            <h2 className="lato-regular blue-270 f-s-57 lh-68">
              Personalize
            </h2>
            <p className="lato-regular blue-270 f-s-29 lh-35">
              Personalize ready to share posts, videos, presentations and
              more.
            </p>
          </div>
        </div>
        {/* ==================== mak scroll ====================== */}
      </Carousel.Item>
      <Carousel.Item>
        {/* ==================== mak scroll ====================== */}
        <div className="first-carousel login-third-image">
          <div className="left-heading">
            <h2 className="lato-regular blue-270 f-s-57 lh-68">
              Share
            </h2>
            <p className="lato-regular blue-270 f-s-29 lh-35">
              Perfect marketing material to help your clients invest
              wisely
            </p>
          </div>
        </div>
        {/* ==================== mak scroll ====================== */}
      </Carousel.Item>
    </Carousel>
  )
}