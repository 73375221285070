import React, { useState } from "react";
import { Header, Footer } from "../common/pages";
import { Image, Modal } from "react-bootstrap";
import search from "../../assets/images/search.svg";
import FolderPic from "../../assets/images/folder.png";
import Option from "../../assets/images/option.png";
import Plus from "../../assets/images/+.png";
import PageSubHeader from "../common/components/PageSubHeader";
import Folder from "./Folder";
import { useEffect } from "react";
import { commonPostApi, getDataApi } from "./Api";
import { filter, method } from "lodash";
import AddCollectionModal from "./_utils/AddCollectionModal";
import { toast } from "react-toastify";
import { MYCOLLECTION, USER_COLLECTION } from "../../utils/Constant";
import { AllShimmer } from "../common/components";
import { BreadCrumb } from "../home/_utils";

const MyCollections = () => {
  const [collection, setCollection] = useState([]);
  const [collectionData, setCollectionData] = useState([]);
  const [show, setShow] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  async function getCollection() {
    try {
      const data = await getDataApi(USER_COLLECTION);
      setCollection(data.data);
      setCollectionData(data.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setIsLoading(false);
    }
  }

  useEffect(() => {
    getCollection();
  }, []);
  function searchHandler(e) {
    //  console.log(e.target.value)
    const filterData = collectionData.filter((item, i) => item.name?.toLowerCase().includes(e.target.value.toLowerCase()));
    //   //  console.log(filterData)
    if (e.target.value) {
      setCollection(filterData);
    } else {
      setCollection(collectionData)
    }
  }

  const addCollection = async () => {
    setShow(!show);
  };

  const deleteUpdateHandler = async (id = false, info = "", method = "") => {
    // console.log(id, "method", method);
    const isMatch = collection?.some((item) => item.name === info?.data?.name) || false;
    // console.log("Checked", isMatch);
    if (!isMatch) {
      const data = await commonPostApi(`user-collection${id && `/${id}`}`, info, method);
      if (data) {
        getCollection();
        // console.log("Id", info);
        if (info) {
          toast.success("Successful Updated");
        }
      }
    } else {
      toast.error("This Collection Name Exists");
    }
  };

  return (
    <div>
      {/* <Header /> */}
      <BreadCrumb path={[MYCOLLECTION]} clasese="mb-3-desk" />
      <PageSubHeader title="My Collection" clases="myactivity-pageheader" count={collection?.length} isSearch={true} changeHandler={searchHandler} />

      <div className="mycollection-wrapper">
        {/* <div className="container"> */}
        <div className="mycollection-body">
          <div className="folder-card pointer" onClick={addCollection}>
            <Image src={FolderPic} alt="folder" />
            <div className="add-more">
              <Image src={Plus} alt="plus" />
              <h2 className="fs-22-18 lato-regular">Add New Collection</h2>
            </div>
          </div>
          {isLoading ? (
            <AllShimmer type={USER_COLLECTION} />
          ) : (
            collection?.map((item, index) => (
              <Folder key={index} data={item} click={deleteUpdateHandler} />
            ))
          )}
        </div>
        {/* </div> */}
      </div>
      {show && <AddCollectionModal show={show} click={() => setShow(!show)} updated={deleteUpdateHandler} data={collection} />}
    </div>
  );
};

export default MyCollections;
