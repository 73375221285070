import React from 'react'
import { Image } from 'react-bootstrap'
import amcImae from "../../../assets/images/Video-Thumbnail.jpg"
import { useHistory, useLocation, useParams } from 'react-router-dom';
import { API_LIMIT, START_PAGE } from '../../../utils/Constant';
import { HrSlider } from '../../common/components';
import { formatNumber } from '../../../utils/ReusableFunctions';

export default function AmcCard({
  classes,
  collectionType,
  data = "",
  classdescription, }) {
  // const location=useLocation();
  // const params=new URLSearchParams(location.search);
  const param = useParams();
  const history = useHistory();
  function navigate() {
    // history.push({ pathname: `/collection-type-details/${collectionType}?id=${data?.uuid || START_PAGE}&pageNumber=${START_PAGE}&pageSize=8`, state:data  })
    // history.push({ pathname: `/content-type/All`, search: `collection-type=${collectionType}&collection-type-id=${data?.uuid || START_PAGE}`, state: data })//temp pagesze remve ,becuse its not in backend
    history.push({ pathname: `/content-type/All`, search: `?key=${collectionType === 'language' ? 'languages' : (collectionType === 'campaign') ? 'campaigns' : collectionType}&value=${encodeURIComponent(data?.[collectionType + 'Name'])}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })//temp pagesze remve ,becuse its not in backend
  }
  return (
    <div className={`amc-card-frame ${classes} ${data.thumbnailPath ? '' : 'amc-rambo-effect'}`}>
      <div onClick={navigate} className="amc-imag-frame w-100 pointer ">
        {data.thumbnailPath ?
          <Image src={data.thumbnailPath || amcImae} alt='amc image' className={`w-100 ${param.type === 'language' ? 'object-fit-none' : ''}`} />
          :
          <p className=' lato-regular fx-14 l-s-129 m-auto  pointer'>{data[`${collectionType.toLowerCase()}Name`] || "Na"}</p>

        }
      </div>
      <div className="amc-description-box">
        <p className={`lato-regular fx-14 l-s-129 black-242 pointer ${param.type === 'language' ? ' text-center' : ''}`} onClick={navigate}>{data[`${collectionType.toLowerCase()}Name`] || "Na"}</p>
        {/* {data?.contentTypeStats &&  Object.keys(data?.contentTypeStats)?.length <= 4 ? */}
        <div className={`amc-count-content-type ${(data?.contentTypeStats ?? false) ? Object.keys(data?.contentTypeStats || {})?.length > 4 && "justify-content-start" : ""} `}>
          {
            Object.keys(data?.contentTypeStats || {})?.map((item, i) => {
              if(item==='totalContentCount'){
                return ""
              }
              return (
                <>
                  <div className='amc-type lato-semibold fx-14 black-242'>
                    {formatNumber(data?.contentTypeStats[item])}
                    <p className='lato-regular f-s-10 lh-14 black-242'>{item}</p>
                  </div>
                  <div className={`vertical-strip ${Object.keys(data?.contentTypeStats || {})?.length === i + 2 ? "d-none" : ""}`}>&nbsp;</div>
                </>
              )
            })
          }
        </div>
        {/* //   :
        //   data?.contentTypeStats &&  <HrSlider type="amcCard" data={data} />
        // } */}
      </div>
    </div>
  )
}
