import React, { useState } from "react";
import { Button, Image } from "react-bootstrap";
import { BiSolidShareAlt } from "react-icons/bi";
import { BsFillBookmarkFill } from "react-icons/bs";
import eventImg from '../../assets/images/event-img.png';
import paidIcon from '../../assets/images/paid-icon.svg';
import onlineEventTag from '../../assets/images/Online-Event-tag.svg';
import inPerson from '../../assets/images/in-person.svg';
import onlineEventMbTag from '../../assets/images/Online-Event-mb-tag.svg';
import prenium from '../../assets/images/tags.svg';
import ads from '../../assets/images/ads.svg';
import userGroup from '../../assets/images/userGroup.svg';
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment/moment";
import { SignUp } from "../common/components";
import { getToken } from "../../utils/ManageToken";
import { RWebShare } from "react-web-share";
import { SHARE_BASE_URL } from "../../utils/Constant";
import { isEventExpired } from "../../utils/ReusableFunctions";
import { commonAuthApis} from "../profile/Api";
export default function EventCard({ data, classes, registered = false }) {
  const history = useHistory()
  const [signup, setSignup] = useState(false);
 
  const registerHandler = async(id) =>{
    if(data?.contentCreator?.isFinpediaUser){
      history.push({ pathname: '/event-chekout', state: { detail: data } })
    }else{
      // CALL API FOR REGISTER IN FIND DATA
      const info={
        "data" : {
            "eventUuid" :id
        }
    }
      const res= await commonAuthApis('/eventRegistration',info)
      // console.log(res)
      if(res.status>199 && res.status<240){
        // console.log("look htis is regineter linke")
        window.open(data?.registrationLink || "", '_blank');
      }
    }
  }
  return (
    <div className={`event-card pointer ${classes}`} >
      <div className="event-image-box">
        <div className="event-mode" onClick={() => history.push("./events-details/" + data.uuid)} >
          <div>
            {data?.eventFormat == "Online" ? <>
              <Image src={onlineEventTag} alt="mode" className="online-event-tag pointer dt-show" />
              <Image src={onlineEventMbTag} alt="mode" className="online-event-tag pointer mb-show" />
            </> :
              <Image src={inPerson} alt="mode" className="online-event-tag pointer" />
            }

            {/* {true && */}
            {data?.isAd &&
              <Image src={ads} alt="mode" className="ads-icon mx-2" />
            }
          </div>

          {data?.isPremium &&
            <Image src={prenium} alt="mode" className="prenium" />
          }
        </div>
        <span className="thumbnail-imag-framez" onClick={() => history.push("./events-details/" + data.uuid)}>
          <Image src={data?.thumbnailPath || eventImg} className="bg-event-image" alt="" />
        </span>
        <div className="event-actions-frame">
          <div className="event-attend">
            <Image src={userGroup} alt="usergroup" className="desk-show" />
            {data?.totalSeats && <span className="lato-semibold fs-14-11 desk-show">{data?.totalSeats} | </span>}
            {/* {data?.eventType > 0 && <> */}
            <Image src={paidIcon} alt="" />{" "}
            <span className="lato-semibold fs-14-11 ">{data?.eventType?.toUpperCase()}</span>
            {/* </>} */}
          </div>
          <div className="event-rggister desk-show">
            {/* <span className="save-frame">
              <BsFillBookmarkFill className="icon save-icon" />
            </span> */}
            <RWebShare
              data={{
                text: "",
                url: `${SHARE_BASE_URL + "events-details/" + data?.uuid}`,
                title: data?.name,
              }}
            // onClick={() =>{}}
            >
              <span className="save-frame">
                <BiSolidShareAlt className="icon share-icon" />
              </span>
            </RWebShare>
            {registered ?
              <Button onClick={() => { }} className={"lato-medium fs-18-16 disable-btn"}>Registered</Button>
              :
              <Button onClick={data.isRegistered ? null : () => { if (!getToken()) { setSignup(true) } else { registerHandler(data?.uuid) } }} className={(data.isRegistered || isEventExpired(data?.endDate)) ? "lato-medium fs-18-16 disable-btn" : "primary-btn"}>{data.isRegistered ? "Registered" : 'Register'}</Button>
            }
          </div>
        </div>
      </div>
      <div className="event-content-frame" onClick={() => history.push("./events-details/" + data.uuid)}>
        <p className="lato-semibole fs-14-11 black-242">
          {/* {moment.utc(data?.date).format("ddd, DD MMM | h:mm a") || 'NA'} */}
          {moment(data?.startDate).format("ddd, DD MMM | h:mm a")} - {moment(data?.endDate).format("ddd, DD MMM | h:mm a") || 'NA'}
        </p>
        <h5 className="lato-bold fs-18-14 black-242">{data?.name || "NA"}</h5>
        <p className="lato-semibole fs-14-11 black-242">Organised by &nbsp;{data?.amc?.amcName || data?.contentCreator?.contentCreatorName || "NA"}</p>
        <p className="lato-semibole fs-14-11 black-242">Speakers- &nbsp;{(data?.eventSpeakers?.map((item, i) => item?.speakerName))?.join(', ')}</p>
        {data?.eventFormat !== "ONLINE" ? <p className="lato-semibole fs-14-11 black-242">Venue :- {data?.venueName} {data?.city && `(${data.city})` || 'Na'}</p> : <br />}
      </div >
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />
      }
    </div >
  );
}
