import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { Image } from "react-bootstrap";
import { MAX_LIMIT, START_PAGE, TARGETEDCONTENT } from "../../../utils/Constant";
import { getCommonApi } from "../Api";
export default function CuratedContent() {
  const navigate = useHistory();
  const [data, setData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  async function featchData() {
    const data = await getCommonApi(`website/targetedContent/dashboard/list?pageNumber=${START_PAGE}&pageSize=8&withPosition=true&sortBy=most recent`);
    if (data.status === 200) {
      setData(data?.data?.data.list || []);
      // console.log(data?.data?.data.list || []);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    featchData();
  }, []);
  return (
    <div className="online-presence-section  position-relative">
      <div className="container-mb position-relative">
        <p onClick={() => navigate.push({ pathname: `/${TARGETEDCONTENT}`, search: `?pageSize=18&pageNumber=${START_PAGE}` })} className="lato-medium fx-14 black-242 pointer home-explort-all">Explore All</p>

        <h4 className="fs-32-28 lato-semibold  primary text-center">
          Targeted Content
        </h4>
        <p className="lato-regular fs-22-16 black-242 text-center">
          Get relevant content as per client segments and needs
        </p>
        <div className="">
          <div className="social-media-card-wrapper ">
            {isLoading ?
              [...Array(4)].map((item) => {
                return (
                  <div className=" target-imag-frame h-350">&nbsp;</div>
                )
              })
              :
              data?.map((item, i) => {
                // console.log(i)
                return (
                  <div
                    className={`${item.thumbnailPath ? 'target-img-wrapper' : 'target-imag-frame'}  p-0 h-350 `}
                    onClick={() =>
                      navigate.push({
                        pathname: `/content-type/All`,
                        search: `?key=targetedContents&value=${item.targetedContentName}&pageSize=${MAX_LIMIT}&pageNumber=${START_PAGE}`,
                        state: item
                      })
                    }
                  >
                    {item.thumbnailPath ?
                      <Image
                        src={item.thumbnailPath}
                        alt={item.targetedContentName}
                      // className="responsive-card-width "
                      /> :
                      <p className=" lato-medium f-s-14 ">{item.targetedContentName}</p>
                    }
                  </div>
                );
              })}
            {/* <Image src={Card1} alt="icon" />
          <Image src={Card2} alt="icon" />
          <Image src={Card3} alt="icon" />
          <Image src={Card4} alt="icon" /> */}
          </div>
        </div>
      </div>
    </div>
  );
}
