import React from "react";
import { Image } from "react-bootstrap";

import trending from "../../assets/images/trending.svg";
import { MAX_LIMIT, START_PAGE } from "../../utils/Constant";
import { useHistory } from "react-router-dom/cjs/react-router-dom";

export default function Trending({title="Trending_1",link='', classes, image='' ,type="",isImage=true}) {
  const history=useHistory();

  function serchKeywordsHandler(){
    if(link){
      window.open(link, '_blank');
    }else{
      history.push({
        pathname: `/content-type/${type ? type : "All"}`,
        search: `searchValue=${title}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`,
      });
    }
 
  }
  return (
    <div className={`trending-one pointer ${classes?classes:''} `} onClick={serchKeywordsHandler}>
      {isImage &&
      <Image src={image ? image : trending} alt="image" />
    }
      {title}
    </div>
  );
}
