import React from "react";
import { Image } from "react-bootstrap";
import Twitter from "../../../assets/images/Twitter.svg";
import Linkedin from "../../../assets/images/linkedin-1.svg";
import fackbook from "../../../assets/images/fb.svg";
import Instagram from "../../../assets/images/instagram.svg";
import { useHistory } from 'react-router-dom'

export default function Footer() {
  const history = useHistory()
  return (
    <footer className="footer-section">
      <div className="container">
        <div className="footer-wrapper">
          <div className="footer-box">
            <h6 className=" fs-28-18 mb-2 lato-regular white">Information</h6>
            <p className=" fs-16-12 lato-regular pointer white">
            <span onClick={() => history.push("/about-us")}> About us </span> | <span onClick={() => history.push("/membership-plans")}>Plan Pricing</span>  |  <span onClick={() => history.push("/contact")}>Contact Us</span>
            </p>
          </div>
          <div className="footer-box">
            <h6 className=" fs-28-18 mb-2 lato-regular white">Legal</h6>
            <p className=" fs-16-12 lato-regular white pointer">
              <a href="/terms-conditions">Terms & Conditions</a> | <a href="/privacy-policy">Privacy Policy</a>
            </p>
          </div>
          <div className="footer-box">
            <h6 className=" fs-28-18 mb-2 lato-regular white">Social Media</h6>
            <div className="icons-wrapper">
              <a href="https://www.facebook.com/share/8W8KCKqeTkshKawG/?mibextid=qi2Omg" target="_blank">
                <Image src={fackbook} alt="icon" />
              </a>
              <a href="https://www.instagram.com/thefinpedia/" target="_blank">
                <Image src={Instagram} alt="icon" />
              </a>
              <a href="https://twitter.com/TheFinPedia/" target="_blank">
                <Image src={Twitter} alt="icon" />
              </a>
              <a href="https://www.linkedin.com/company/thefinpedia/" target="_blank">
                <Image src={Linkedin} alt="icon" />
              </a>
            </div>
          </div>
        </div>
        <p className="copy-right  fx-14 lato-regular white text-center">
          Thefinpedia Copyright 2023
        </p>
      </div>
    </footer>
  );
}
