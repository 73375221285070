const deleteToken = () => {
  localStorage.removeItem('finpediaCustomerToken');
  localStorage.removeItem('finUserDetails');
};

const getToken = () => {
  return JSON.parse(localStorage.getItem('finpediaCustomerToken'));
};


export { getToken, deleteToken };