import React from "react";
import { useHistory } from "react-router-dom";
import { Button, Image, Modal } from "react-bootstrap";
import { useState } from "react";
import defaultDownload from '../../../assets/images/Videos.png'
import cancelIcon from '../../../assets/images/cancel-icon.svg'
import { commonAuthPostApi } from "../Api";
import { USER_TRACKING, UserTracking } from "../../../utils/Constant";
import { getToken } from "../../../utils/ManageToken";
import { toast } from "react-toastify";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
// import { createBrowserHistory } from 'history';

export default function VideoDetailsDownloadModal({
  selectedVariation = "",
  show = true,
  handleClose,
  contentType,
  detailsData = "",
  downloadPath = "",
  updatedThings,
  variation = [],
  languageColor = "",
  variationId = ""
}) {
  const navigate = useHistory();
  const param=useParams()
  const information = { "variation": variation, "data": detailsData, "languageColor": languageColor, "updatedThings": updatedThings }
  const [download, setDownload] = useState(false);
  console.log("download why not called",downloadPath)
  async function downloadHandler() {
    const info = {
      "data": {
        "contentUuid": detailsData.uuid,
        "type": UserTracking.DOWNLOADED,
        // "contentVariationUuid": detailsData?.defaultVariation ? detailsData?.defaultVariation.uuid : detailsData?.contentVariation.find(x => x.isDefault === true)?.uuid,
        "contentVariationUuid": variationId ? variationId : detailsData?.defaultVariation.uuid,
      }
    }
    if(getToken()){
      const response = await commonAuthPostApi(USER_TRACKING, info);
      if(!response.message){
        console.log(response)
        const anchor = document.createElement('a');
        anchor.href = downloadPath;
        // console.log('downloadPath', downloadPath);
        // anchor.target = "_blank";
        const parts = downloadPath.split('/');
        anchor.download = parts[parts.length - 1];
        anchor.style.display = 'none';
        document.body.appendChild(anchor);
        if (downloadPath) {
          anchor.click();
          document.body.removeChild(anchor);
          setTimeout(() => {
            setDownload(true);
          }, 1300);
        } else {
          toast.error("No download file available")
        }
      }
    }
    // console.log("In card Like, Saved, share", response);
  


  }
  function navigateHandler() {
    if (getToken()) {
      // if(true){
      navigate.push({ pathname: `/cobranding`, state: information });
    } else {
      toast.error("You Need to Login");
    }
  }
  const isCoBrandDisabled = detailsData ? detailsData.branding?.brandingCode !== "NO-BRANDING" && (detailsData?.defaultVariation?.contentFilePath !== "") : false;
  // console.log(isCoBrandDisabled)


  return (
    <>
      <Modal
        show={show}
        onHide={handleClose}
        className="details-downolad-modal  mb-py-20"
      >
        <Image onClick={handleClose} src={cancelIcon} alt="cancleIon" className="co-branding-popup-cancle pointer" />
        <Modal.Body className="model-download-body">
          {download ? (
            <p className="lato-bold fs-22-18 pointer text-center px-3">
              Your {contentType.split('&id')[0]?.replace(/s\b/g, '')} has been downloaded
            </p>
          ) : (
            <div>
              <div
                onClick={!isCoBrandDisabled ? null : navigateHandler}
                className={`lato-bold f-s-18 with-cobrand-download ${isCoBrandDisabled ? '' : 'disabled-Co-Brand-download'}`}
              >
                Co-Brand and download
              </div>
              <p
                onClick={()=> downloadPath ? downloadHandler() : toast.error("No download file available")}
                // href={downloadPath || defaultDownload}
                // download
                // target="_blank"
                title={downloadPath ? " ":"No download file available"}
                className={`lato-regular f-s-16 pb-4 pointer text-decoration-none black-242 ${downloadPath?'':'pointer-none'}`}
              >
                No, I just want to download my files
              </p>
            </div>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
}
