import React from "react";
import Filter from "../../assets/images/leadingicon.svg";
import DownIcon from "../../assets/images/dropdown-icon.svg";
import Trend from "../../assets/images/black-traind-Icon.svg";
import { Image } from "react-bootstrap";
import { Trending } from ".";

export default function FilterTrend({ classes = "", showFilter }) {
  return (
    <div className="container">
      <div className={`filter-sort-frame ${classes}`}>
        <div className="filter-button" onClick={showFilter}>
          <Image src={Filter} alt="icon" />
          <p className=" fx-14 lato-medium black-495 desk-show">Filters</p>
          <Image src={DownIcon} alt="icon" className="desk-show" />
        </div>
        {/* <div className="filter-wrapper">
          <Trending classes="filter-trend lato-medium " image={Trend} />
          <Trending classes="filter-trend lato-medium " image={Trend} />
          <Trending classes="filter-trend lato-medium " image={Trend} />
          <Trending classes="filter-trend lato-medium " image={Trend} />
        </div> */}
        <div className="sorted-by fx-14  lato-regular">
          Sort by <Image src={DownIcon} alt="icon" className="" />
        </div>
      </div>

    </div>
  );
}
