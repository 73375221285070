import React, { Component } from "react";
import { Image } from "react-bootstrap";

export class TargetedContentCard extends Component {
  render() {
    const { targetedList, totalCount, title } = this.props;
    // console.log("targetedList", targetedList);
    return (
      <div className="curated-content-wrapper">
        <div className="container">
          <div className="curated-sub-header">
            <h5 className="lato-regular fs-28-18 black-242">{title}</h5>
            <p className="fs-14-12 lato-medium black-242">
              View all{" "}
              <span className="desk-show">
                {" "}
                {totalCount} {title}
              </span>
            </p>
          </div>
          <div className="courated-slider-srapper">
            {targetedList?.map((item) => {
              return (
                <div key={item.id} className="curated-card">
                  <Image src={item.image} alt="image" />
                  <div className="caourated-hover-text">
                    <p className=" fx-14 lato-regular white">
                      {item.hoverText}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
  }
}

export default TargetedContentCard;
