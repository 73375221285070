import React, { useEffect, useState } from "react";
import { Footer, Header, NotFound } from "../common/pages";
import { SearchDropDown, SearchFilter } from "../common";
import { AmcCard, BreadCrumb, ContentTypesCards } from "./_utils";
import { AllShimmer, CardHeader } from "../common/components";
import { SlideFilter } from "./staticComponents";
import { getAuthDataApi } from "./Api";
import { toast } from "react-toastify";
import { AMC, START_PAGE } from "../../utils/Constant";
import { Dropdown, Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import DownIcon from '../../assets/images/dropdown-icon.svg';
import searchIcon from "../../assets/images/Search_1.svg";
import { useHistory, useLocation, useParams } from "react-router-dom/cjs/react-router-dom.min";
export default function AmcListeningPage() {
  const param = useParams();
  const history = useHistory();
  const location = useLocation();
  const [info, setInfo] = useState([]);
  const [filterData, setFilterData] = useState([]);
  const [filterMaxData, setFilterMaxData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [keyword, setKeyword] = useState("");
  const [sortBy, setSortBy] = useState("Max Content");
  async function gaetData() {
    // console.log("chck amd is exist",param.type);
    let url = param.type === 'language' ? 'website/languages' : "website/" + param.type;
    let data = await getAuthDataApi(`/${url}?pageSize=10000&pageNumber=${START_PAGE}`);
    if (data) {
      const maxContentData = [...data?.list].sort((a, b) => {
        const sumA = Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0);
        const sumB = Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0);
        return sumB - sumA;
      });
      setInfo(data);
      setFilterData(maxContentData);
      setFilterMaxData(maxContentData);
      setIsLoading(false);
    } else {
      toast.error(data?.response.data.message || "Something went wrong");

    }
  }
  useEffect(() => {
    gaetData();

  }, []);
  function searchAmchandler(key = "") {
    let filteredData = info?.list?.filter((item) => item?.amcName?.toLowerCase().includes(key ? key.toLowerCase() : keyword?.toLowerCase())) || [];
    if (filterData.length > 0) {
      setFilterData(filteredData);
    } else {
      setFilterData(info?.list);
    }

  }



  function sortHandler(data) {
    setSortBy(data);

    let sortedData;
    switch (data) {
      case 'Ascending (A-Z)':
        sortedData = [...filterData].sort((a, b) => a?.[param.type.toLowerCase() + 'Name'].localeCompare(b?.[param.type.toLowerCase() + 'Name']));
        break;
      case 'Descending (Z-A)':
        sortedData = [...filterData].sort((a, b) => b?.[param.type.toLowerCase() + 'Name'].localeCompare(a?.[param.type.toLowerCase() + 'Name']));
        break;
      case "Min Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0);
          return sumA - sumB;
        });
        break;
      case "Max Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0);
          return sumB - sumA;
        });
        break;
      default:
        sortedData = [...filterMaxData];
    }

    setFilterData(sortedData);
  }

  return (
    <div className="page-padding-filter">
      <div className="container">
        <BreadCrumb path={[param.type.toLowerCase() === "amc" ? param.type.toUpperCase() : param.type.replace(/\w/, (a) => a.toUpperCase())]} />

      </div>
      <Header />
      {isLoading ? (
        <AllShimmer type="amc" />
      ) : (
        <div className="amc-card-wrapper">
          <div className="container">

            <SearchFilter data={filterMaxData} filterData={filterData} setValueHandler={(e) => setFilterData(e)} name={param.type.toLowerCase()} placeholder={param.type.toLowerCase() === "amc" ? "AMC" : (param.type.charAt(0).toUpperCase() + param.type.slice(1))} />
            <div className="d-flex align-items-center justify-content-between">
              <CardHeader
                title="AMCs"
                count={filterData?.length}
                hide={false}
                countTitle={param.type.toLowerCase() === "amc" ? param.type.toUpperCase() : param.type.replace(/\w/, (a) => a.toUpperCase())}
              // viewAll={`/content-type/${countTitle?.replace('/', "_")}`}//change
              />

              <div className={`dropdown-main-wrapper w-auto`}>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <h6 className="lato-semibold fx-14 black-242">{sortBy || 'Sort By'}</h6>
                    <Image src={DownIcon} alt="image" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="show-filter-dropdown">
                    {['Ascending (A-Z)', 'Descending (Z-A)', "Max Content", "Min Content"]?.map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => sortHandler(item)} className="lato-regular black-242 fx-14" href=''>{item}</Dropdown.Item>
                      )
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>
            <div className="amc-all-card-frame">
              {filterData?.length > 0 ? (
                <div className={`common-all-card-wrapper w-100 result-wrap  ${filterData?.length >= 3 && ""}`}>
                  {filterData?.map((item, index) => {
                    return (
                      <AmcCard
                        key={index}
                        collectionType={param.type}
                        data={item}
                        classes={true ? `responsive-card-width ` : ""}
                      />
                    );
                  })}
                </div>
              ) : (
                <NotFound />
              )}
            </div>
          </div>
        </div>
      )}

      <Footer />
    </div>

  );
}

// function ListeningAmc({ info }) {
//   return (
//     <div className="amc-card-wrapper py-5">
//       <CardHeader
//         title="AMCs"
//         count={20}
//         hide={false}
//         countTitle={"AMCs"}
//         // viewAll={`/content-type/${countTitle?.replace('/', "_")}`}//change
//       />
//       <div className="amc-all-card-frame">
//         {info?.length > 0 ? (
//           <div className={`common-all-card-wrapper result-wrap`}>
//             {info.list?.map((item, index) => {
//               return (
//                 <AmcCard
//                   key={index}
//                   contentType={AMC}
//                   data={item}
//                   classes={true ? "responsive-card-width" : ""}
//                 />
//               );
//             })}
//           </div>
//         ) : (
//           <NotFound />
//         )}
//       </div>
//     </div>
//   );
// }
