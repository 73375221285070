import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import { useMemo } from 'react';
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { Image } from 'react-bootstrap'
import abcLogo from "../../assets/images/abc.png";
import { useRef } from 'react';
import { SignUp } from '../common/components';

export default function StepUp({ calculator, textLogo = '' }) {

  let [sipAmount, setSipAmount] = useState(8000);
  let [sipTenure, setSipTenure] = useState(10);
  let [growthRate, setGrowthRate] = useState(10);
  let [rateOfReturn, setRateOfReturn] = useState(12);
  let [frequency, setFrequency] = useState("monthly");
  const selectOption = [
    { value: "monthly", label: "Monthly" },
    // { value: "quarterly", label: "Quarterly" },
  ];

  let [totalInvestmentGrowing, setTotalInvestmentGrowing] = useState(1529993);
  let [corpusValueGrowing, setCorpusValueGrowing] = useState(2699461);

  let [totalInvestmentFixed, setTotalInvestmentFixed] = useState(960000);
  let [corpusValueFixed, setCorpusValueFixed] = useState(1858713);


  useEffect(() => {
    setFrequency(frequency)
    setSipAmount(sipAmount)
    setSipTenure(sipTenure)
    setGrowthRate(growthRate)
    setRateOfReturn(rateOfReturn)
    setTotalInvestmentGrowing(totalInvestmentGrowing)
    setCorpusValueGrowing(corpusValueGrowing)
    setTotalInvestmentFixed(totalInvestmentFixed)
    setCorpusValueFixed(corpusValueFixed)
  }, [sipAmount, sipTenure, growthRate, rateOfReturn, totalInvestmentGrowing, corpusValueGrowing, totalInvestmentFixed, corpusValueFixed])

  const handleChange = (value, name) => {
    // console.log('name', name);
    if (name === "frequency") {
      frequency = value
    }
    if (name === "sipAmount") {
      sipAmount = value
    }
    if (name === "sipTenure") {
      sipTenure = value
    }
    if (name === "growthRate") {
      growthRate = value
    }
    if (name === "rateOfReturn") {
      rateOfReturn = value
    }

    onSubmitAction(frequency, sipAmount, sipTenure, growthRate, rateOfReturn);
  }
  const onSubmitAction = (frequency, sipAmount, sipTenure, growthRate, rateOfReturn) => {
    // frequency=document.querySelector('input[name="radio"]:checked').value;
    // sipTenure=Number(document.getElementById('sipTenure').value);
    // sipAmount=document.getElementById('input1').value.replace(/\D+/g, '');
    rateOfReturn = rateOfReturn / 100;
    growthRate = growthRate / 100;
    let array1 = Array.from(Array(Number(sipTenure)).keys(), x => x + 1);
    let roi = rateOfReturn;
    let mf = 12;
    mf = mfObj[frequency];
    let sipOldArray = [sipAmount];
    let sipNewArray = [];

    let fv = sipAmount * ((((1 + (rateOfReturn / mf)) ** (1 * mf)) - 1) / (rateOfReturn / mf) * (1 + (rateOfReturn / mf)));
    let fvFixed = sipAmount * ((((1 + (rateOfReturn / mf)) ** (1 * mf)) - 1) / (rateOfReturn / mf) * (1 + (rateOfReturn / mf)));
    let fvNewArray = [];
    let fvOldArray = [fv];
    let fvNewFixedArray = [];
    let fvOldFixedArray = [fv];
    let cumulativeSIP = sipAmount * 12;
    let cumulativeSIPOldArray = [cumulativeSIP];
    let cumulativeSIPNewArray = [];
    let cumulativeSIPFixedOldArray = [cumulativeSIP];
    let cumulativeSIPFixedNewArray = [];

    for (let i = 0; i < (sipTenure); i++) {

      if (i >= 1) {

        sipNewArray.push((sipOldArray[i]) * (1 + growthRate));
        fvNewArray.push(fvOldArray[i] * ((1 + (rateOfReturn / mf)) ** mf) + (sipNewArray[i] * ((((1 + (rateOfReturn / mf)) ** (1 * mf)) - 1) / (rateOfReturn / mf) * (1 + (rateOfReturn / mf)))));
        cumulativeSIPNewArray.push((sipNewArray[i] * 12) + cumulativeSIPOldArray[i]);
        cumulativeSIPFixedNewArray.push((sipAmount * 12) + cumulativeSIPFixedOldArray[i]);
        fvNewFixedArray.push(((fvOldFixedArray[i] * ((1 + (rateOfReturn / mf)) ** mf)) + fvFixed));
      }



      // and old sip array will also be pushed with temporary sip amount
      else {
        sipNewArray.push(sipAmount);
        fvNewArray.push(fv);
        cumulativeSIPNewArray.push(cumulativeSIP);
        cumulativeSIPFixedNewArray.push(cumulativeSIP);
        fvNewFixedArray.push(fvFixed);
      }

      // new array has one member now, which will be pushed in sip old array

      sipOldArray.push(sipNewArray[i]);
      fvOldArray.push(fvNewArray[i]);
      cumulativeSIPOldArray.push(cumulativeSIPNewArray[i]);
      cumulativeSIPFixedOldArray.push(cumulativeSIPFixedNewArray[i]);
      fvOldFixedArray.push(fvNewFixedArray[i]);
    }

    totalInvestmentGrowing = Math.round(cumulativeSIPNewArray[sipTenure - 1]);
    totalInvestmentFixed = Math.round(cumulativeSIPFixedNewArray[sipTenure - 1]);
    corpusValueFixed = Math.round(fvNewFixedArray[sipTenure - 1]);
    corpusValueGrowing = Math.round(fvNewArray[sipTenure - 1]);

    setFrequency(frequency)
    setSipAmount(sipAmount)
    setSipTenure(sipTenure)
    setGrowthRate(growthRate * 100)
    setRateOfReturn(rateOfReturn * 100)

    setTotalInvestmentGrowing(totalInvestmentGrowing)
    setCorpusValueGrowing(corpusValueGrowing)

    setTotalInvestmentFixed(totalInvestmentFixed)
    setCorpusValueFixed(corpusValueFixed)


    sipOldArray = [sipAmount];
    fvOldArray = [fv];
    cumulativeSIPOldArray = [cumulativeSIP];
    cumulativeSIPFixedOldArray = [cumulativeSIP]
    fvNewArray = [];
    cumulativeSIPNewArray = [];
    sipNewArray = [];
    cumulativeSIPFixedNewArray = [];
  };
  let downloadScreenshotFunc = null;
  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
      title={"Step Up SIP at  10 %"}
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [totalInvestmentGrowing, corpusValueGrowing],
        }}
      />
    );
  }, [totalInvestmentGrowing, corpusValueGrowing]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        title={"Fixed SIP"}
        info={{
          labels: ["Invested Amount", "Investment Value"],
          data: [totalInvestmentGrowing, corpusValueFixed],
        }}
      />
    );
  }, [totalInvestmentGrowing, corpusValueFixed]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          <RangeFields
            label="SIP Amount"
            name={"sipAmount"}
            value={sipAmount}
            minVal={500}
            maxVal={1000000}
            step={500}
            getValue={handleChange} />

          <RangeFields
            label="Investment Tenure"
            name={"sipTenure"}
            value={sipTenure}
            minVal={1}
            maxVal={50}
            unit="Year"
            // step={1}
            getValue={handleChange}
          />
          <RadioFields
            options={selectOption}
            initialValue={"monthly"}
            onSelectionChange={(v) => { setFrequency(v) }}
            // initialValue={""}
            lable="SIP Frequency"
          />

          <RangeFields
            label="Step Up Rate (Annual)"
            name={'growthRate'}
            value={growthRate}
            minVal={1}
            maxVal={25}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />
          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfReturn'}
            value={rateOfReturn}
            minVal={4}
            maxVal={50}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />


          {/* <RangeFields label="Delay" minVal={6} maxVal={50} unit="Year" /> */}
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Step Up SIP at  10 %</p>
              {/* <BarChart
                info={{
                  labels: ["Invested Amount", "Investment Value"],
                  data: [totalInvestmentGrowing, corpusValueGrowing],
                }}
              /> */}
              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
          
            <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Fixed SIP</p>   
              {/* <BarChart
                info={{
                  labels: ["Invested Amount", "Investment Value"],
                  data: [totalInvestmentGrowing, corpusValueFixed],
                }}
              /> */}
              {barChart2}
            </div>
          </div>
          <div className='d-flex justify-content-around p-2'>
            <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
              With a monthly SIP of <span className=" lato-semibold primary">₹{sipAmount}</span>, after <span className=" lato-semibold primary">{sipTenure}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">{totalInvestmentFixed}</span> and its value will be <span className=" lato-semibold primary">{corpusValueFixed}</span>. While in the same period, with an annual SIP Step of <span className=" lato-semibold primary">{sipAmount}</span>, your total investment amount will be <span className=" lato-semibold primary">₹{totalInvestmentGrowing}</span> and its value will be <span className=" lato-semibold primary">₹{corpusValueGrowing}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfReturn}%</span> for the investment tenure.
            </p>
          </div>
        </div>
      </div>
      <div className="make-none">
      <CalculatorOutputScreenShot
        textLogo={textLogo}
        data={[totalInvestmentGrowing, corpusValueGrowing]}
        data2={[totalInvestmentGrowing, corpusValueFixed]}
        sipAmount={sipAmount}
        sipTenure={sipTenure}
        totalInvestmentFixed={totalInvestmentFixed}
        totalInvestmentGrowing={totalInvestmentGrowing}
        corpusValueGrowing={corpusValueGrowing}
        corpusValueFixed={corpusValueFixed}
        rateOfReturn={rateOfReturn}
        onDownloadScreenshot={setDownloadScreenshotFunc}
      />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}

    </div>
  )
}


function CalculatorOutputScreenShot({ textLogo, data, data2, sipAmount, sipTenure, corpusValueFixed, corpusValueGrowing, totalInvestmentGrowing, totalInvestmentFixed, rateOfReturn, onDownloadScreenshot }) {
  const { cobrandingLabelling, investor } = textLogo;
  const userProfileDetails = getUser();
  const [signup, setSignup] = useState(false);
 
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {
 
      onDownloadScreenshot(() => {
        if (screenshotRef.current  && getToken()) {
          html2canvas(screenshotRef.current).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (
    <>
     {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>
          </div>}

      </div>

   {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
      <div className="result-graph-wrapper">
        <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: [totalInvestmentGrowing, corpusValueGrowing],
            }}
          />
        </div>
        <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Invested Amount", "Investment Value"],
              data: [totalInvestmentGrowing, corpusValueFixed],
            }}
          />
        </div>
      </div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      <div className='d-flex justify-content-around p-2'>

        <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
          With a monthly SIP of <span className=" lato-semibold primary">₹{sipAmount}</span>, after <span className=" lato-semibold primary">{sipTenure}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">{totalInvestmentFixed}</span> and its value will be <span className=" lato-semibold primary">{corpusValueFixed}</span>. While in the same period, with an annual SIP Step of <span className=" lato-semibold primary">{sipAmount}</span>, your total investment amount will be <span className=" lato-semibold primary">₹{totalInvestmentGrowing}</span> and its value will be <span className=" lato-semibold primary">₹{corpusValueGrowing}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfReturn}%</span> for the investment tenure.
        </p>
      </div>
      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>
        
    </>
  )
}