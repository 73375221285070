import React from "react";
import { Header } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { SearchFilter } from "../common";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { useState } from "react";
import { CardHeader } from "../common/components";
import { Image } from "react-bootstrap";
import { toolCalculator } from "../../utils/Constant";


export default function ToolsPage() {
  const [data, setData] = useState(toolCalculator);
  const [filterData, setFilterData] = useState(toolCalculator);
  function setValueHandler(info) {
    console.log(info)
    setFilterData(info)
  }
  return (
    <div className="page-padding ">
      <div className="container">
        <Header />
        <BreadCrumb path={["Tools"]} />
        <SearchFilter
          data={data}
          filterData={filterData}
          setValueHandler={setValueHandler}
          name="tools"
          placeholder="tool"
          clases=""
        />

        <CardHeader
          title={"Tools"}
          count={toolCalculator.length}
          hide={false}
          countTitle={"Tools"}
        // viewAll={`/content-type/${countTitle?.toLowerCase()}`}//change
        />
        <div className="tolls-card-wrapper">
          {filterData?.map(item => {
            return (
              <ToolsCard data={item} />
            )
          })}
        </div>
      </div>
    </div>
  );
}

function ToolsCard({ data }) {
  const history = useHistory();
  const nameUrl = data.toolsName.split(" ").join("-").toLowerCase();
  return (
    <div
      className="toolsCardWrapper responsive-card-width"
      onClick={() => {
        history.push({
          pathname: `/tools/calculator${data.url}`,
        })
      }}>
      <div className="image-frame">
        <Image src={data.img} alt={data.toolsName} />
      </div>
      <div className="content-frame lato-regular fs-24-16 black-242 ">
        {data.toolsName || 'NA'}
      </div>
    </div>
  );
}
