import React, { useEffect, useRef } from "react";
import thumbnail from "../../assets/images/user-information.png";
import downloadIcon from "../../assets/images/download-white-icon.svg";
import downloadGif from "../../assets/images/loading-spinner.gif";
import downloadWhiteIcon from "../../assets/images/download-white.svg";
import BlackShare from "../../assets/images/black-share.svg";
import save1 from "../../assets/images/save-filed.svg";
import InfographicThumbnail from "../../assets/images/InfographicThumbnail.jpg";
import close from "../../assets/images/close.svg";
import { CustomDropDown, FormValidator, SelectOneRadio } from "../../utils/form";
import { Image, Modal } from "react-bootstrap";
import { BsFillBookmarkFill } from "react-icons/bs";
import { MdDownloadForOffline } from "react-icons/md";
import { BiSolidShareAlt } from "react-icons/bi";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { downloadCobrandingApi } from "./Api";
import { CobrandTypeEnum, DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH, MINIMALISTS, NA, ORGANIZATION_LOGO, UPDATE_PROFILE, organization_Logo } from "../../utils/Constant";
import { Header } from "../common/pages";
import { getUser } from "../../utils/ReusableFunctions";
import UploadNewLogoModal from "./_utils/UploadNewLogoModal";
import { Spinner } from "../common/components";
import { BreadCrumb } from "./_utils";
import { toast } from "react-toastify";
import { commonPostApi, getDetailsApi } from "../profile/Api";
import { getUserDetailsApi } from "../onBoarding/Api";
var FileSaver = require('file-saver');
const selectOption = [
  { value: "Select Logo", label: "Select Logo" },
  { value: "Select Text", label: "Select Text" },
];

const selectPosition = {
  "Top Left": { top: "1rem", left: "1rem" },
  "Top Right": { top: "1rem", right: "1rem" },
  "Bottom Left": { bottom: "1rem", left: "1rem", top: "unset", right: "unset" },
  "Bottom Right": { bottom: "1rem", right: "1rem", top: "unset", left: "unset" },
  "Video Start": { display: "none" },
  "Video End": { display: "none" },
}

function getColorOptions(variation, language) {
  return (
    variation
      ?.filter((item) => item.language.languageName === language)
      .map((item) => item.color) || []
  );
}
export default function CoBranding() {
  const location = useLocation();
  let userProfileDetails = getUser()
  // const [userProfileinfo,setUserProfileinfo]=useState(false);
  
  const imageRef = useRef(null);
  const [newLogo, setNewLogo] = useState(false)
  const { data, variation, languageColor, updatedThings } = location.state;
  // const [logo, setLogo] = useState(ORGANIZATION_LOGO + userDetails?.organizationLogoPath || null);
  // const [logoFile, setLogoFile] = useState(ORGANIZATION_LOGO + userDetails?.organizationLogoPath || null);
  const [logoFile, setLogoFile] = useState(null);
  const [allVariation, setAllVariation] = useState([]);
  const [displayOption, setDisplayOption] = useState("");
  const [logo, setLogo] = useState(userProfileDetails?.organizationLogoPath ?? null);
  const [brandinText, setBrandinText] = useState({ company: userProfileDetails?.companyName ?? "", arnNo: userProfileDetails?.arnNumber ?? "", companyEmail: userProfileDetails?.emailAddress ?? "", companyNo: userProfileDetails?.mobileNumber ?? "" });
  const { company, arnNo, companyEmail, companyNo } = brandinText;
  const [isLoading, setIsLoading] = useState(false);
  const [cobrandingDetails, setCobrandingDetails] = useState({ display: "", position: "", color: languageColor?.color, language: languageColor?.language, positionId: "" });
  const { display, position, color, language, positionId } = cobrandingDetails;
  // const [colorLanguage,setColorLanguage]=useState({color:"",language:''});
  // const {color,language}=colorLanguage;


  function logoUploadHandler(e) {
    const file = e.target.files[0];
    const image = new window.Image();
    image.src = URL.createObjectURL(file);
    setNewLogo(false)
    image.onload = function () {
      const width = image.width;
      const height = image.height;

      if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
        toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller.Uploaded image is(${width}x${height}) `);
      } else {
        setLogo(URL.createObjectURL(file));
        setLogoFile(file);
      }
    };
  }

  function coBrandingTextHandler(e, validation) {
    let isValid = [];
    validation.map((item) => {
      if (FormValidator[item](e.target.value)) {
        isValid.push(true)
      } else {
        isValid.push(false)
      }
    })
    const check = isValid.every((item) => item === true);
    if (check) {
      // this.setState({ [e.target.name]: e.target.value })
      setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
    }
    // setBrandinText({ ...brandinText, [e.target.name]: e.target.value });
  }
  function selectHandler(data) {
    setDisplayOption(data);
    // setBrandinText({ company: userProfileDetails?.organizationName || "", arnNo:userProfileDetails?.arnNumber || "", companyEmail: "", companyNo: "" });
    // setLogo(ORGANIZATION_LOGO + userDetails?.organizationLogoPath || null);
    // setLogo(null);
    setCobrandingDetails({ display: "", position: "", color: color, language: language, positionId: "" })
  }
  function selectPositionHandler(value) {
    // console.log("Checking", cobrandingDetails);
    // console.log('value', value);
    setCobrandingDetails({ ...cobrandingDetails, display: value.position, position: value.position, positionId: value.uuid })
  }
  useEffect(() => {
    let check = data.displayPosition.filter((item, i) =>
      item?.code.includes("CORNER")
    )
    // console.log("check", check);
    if (check && check.length >= 1 && display === "Full Video") {
      setCobrandingDetails({ ...cobrandingDetails, position: "" })
    }
  }, [display])
  function checkIsChangeInfo() {
    if (company === userProfileDetails?.companyName && arnNo === userProfileDetails.arnNumber && companyEmail === userProfileDetails.emailAddress && companyNo === userProfileDetails.mobileNumber) {
      return false
    } else {
      return true
    }
  }
  const onDownloadFile = async () => {
    // console.log("pos cobrand functin");
    setIsLoading(true);
    let coBrandData = {
      "data": {
        "content": {
          "uuid": data.uuid
        },
        "language": {
          "uuid": (variation.find((item) => item.language?.languageName === language) || {}).language?.uuid || data.defaultLanguage.uuid
        },
        "color": {
          "uuid": color.uuid
        },
        "displayPosition": {
          "uuid": positionId
        },
        "arn": arnNo,
        "emailId": companyEmail,
        "name": company,
        "contactNo": companyNo,
        "cobrandType": (displayOption === "Select Text" ? CobrandTypeEnum.TEXT : CobrandTypeEnum.LOGO),
        "dataChanged": checkIsChangeInfo()
        // ...(displayOption === "Select Text" && {
        //   "details": {
        //     "name": company,
        //     "arn": arnNo,
        //     "emailId": companyEmail,
        //     "contactNo": companyNo
        //   }
        // })
      }
    };
    // console.log("Post Download data,", coBrandData);
    const dataApi = new FormData();
    dataApi.append("key", JSON.stringify(coBrandData));
    if (displayOption !== "Select Text" && logoFile !== getUser()?.organizationLogoPath && logoFile !== null) {
      dataApi.append("file", logoFile);
    }
    const downloadResponse = await downloadCobrandingApi(dataApi);
    // console.log(downloadResponse);
    if (downloadResponse.status === 200) {
      setIsLoading(false);
      if (checkIsChangeInfo() || (logoFile !== getUser()?.organizationLogoPath || logoFile !== null)) {
        getUserDetailsApi(null, setLogoFile, setBrandinText);
      }
      // const localData=getUser()
      // const response = await getDetailsApi(`user/${localData?.uuid}`);
      // if(response.status==200){
      //   toast.success("Updated Successfully");
      //   localStorage.setItem("finUserDetails", JSON.stringify(response.data.data));
      // }else{
      //   toast.success(  response.response.data.message || "Some thing wrong");

      // }
      // console.log(downloadResponse)
      let fileName = downloadResponse?.data.data?.split('\\.');
      // FileSaver.saveAs(downloadResponse.data, data.defaultVariation.title + "." + fileName[fileName?.length - 1]);
      const anchor = document.createElement('a');
      anchor.href = downloadResponse.data.data;
      // anchor.download = data.defaultVariation.title + "." + fileName[fileName?.length - 1];
      anchor.download = "download";
      anchor.target = "_blank";
      anchor.style.display = "none";
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);


    } else {
      setIsLoading(false);
    }
  }
  // console.warn(cobrandingDetails)

  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed

  const isImage = (filePath) => {
    const extension = filePath ? filePath.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
    return imageExtensions.includes(extension);
  };

  return (
    <div className="cobranding-main-section">
      <Header />
      {/* ----- HERO SECTION START ------- */}
      <div className="cobranding-hero-section">
        <div className="container">
          <BreadCrumb path={[data?.contentType?.contentTypeName, updatedThings?.tittle, "Co-branding"]} clasese="mb-3-desk" />
          <div className="cobranding-wrapper">
            <div className="co-branding-box">
              <h4 className="lato-regular mb-32 fs-32-28 black-242">
                Co-branding
              </h4>
              <div className="co-branding-options">
                <div className="">
                  <SelectOneRadio
                    options={selectOption}
                    onSelectionChange={(v) => { selectHandler(v) }}
                    initialValue={displayOption}
                    lable="Display Option"
                  />
                  {displayOption == "Select Logo" && (
                    <div className="brand-uploade-logo-frame">

                      {/* MAKE INPUT COMMIT FOR MODAL */}
                      {/* <input
                        type="file"
                        onChange={logoUploadHandler}
                        className="d-none"
                        name="uploade-log"
                        id="cobranding-logo"
                        // accept='image/*'
                        accept="image/jpeg,image/jpg, image/png"
                      /> */}
                      <label
                        // htmlFor="cobranding-logo"
                        className="lato-regular fs-14-12 black-242 under-line pointer"
                        onClick={() => setNewLogo(!newLogo)} // UN COMMENT FOR MODERL
                      >
                        <div className="uploade-img-wrapper">
                          {logo && <Image src={logo} alt="logo" />}
                        </div>
                        <p className="f-s-10">(Please upload image of {DEFAULT_IMAGE_WIDTH} X {DEFAULT_IMAGE_HEIGHT} dimensions for best output)</p>
                        Upload New Company logo
                      </label>
                      {
                        <UploadNewLogoModal newLogo={newLogo} handleClose={() => setNewLogo(!newLogo)} logoUploadHandler={logoUploadHandler} />
                      }
                    </div>
                  )}

                  {displayOption == "Select Text" && (
                    <div className="brand-select-text-frame">
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isAlphaNumeric"])}
                        className={`input-box lato-regular fs-16-14 ${company ? "higheLight" : ""
                          }`}
                        value={company}
                        name="company"
                        placeholder="Company Name"
                      />
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-16-14 ${arnNo ? "higheLight" : ""
                          }`}
                        value={arnNo}
                        name="arnNo"
                        placeholder="ARN Number"
                        maxLength={10}
                      />
                      <input
                        type="text"
                        onChange={coBrandingTextHandler}
                        className={`input-box lato-regular fs-16-14 ${companyEmail ? "higheLight" : ""
                          }`}
                        value={companyEmail}
                        name="companyEmail"
                        placeholder="Email Id"
                        disabled
                      />
                      <input
                        type="text"
                        onChange={(e) => coBrandingTextHandler(e, ["isNum"])}
                        className={`input-box lato-regular fs-16-14 ${companyNo ? "higheLight" : ""
                          }`}
                        value={companyNo}
                        name="companyNo"
                        placeholder="Contact Number"
                        maxLength={10}
                      />
                    </div>
                  )}
                </div>
                <div className="">
                  <SelectOneRadio
                    options={data.displayPosition?.filter((item, i) => item?.code.includes("VIDEO"))}
                    onSelectionChange={(value) => { selectPositionHandler(value) }}
                    lable="Display Position"
                    initialValue={display}
                    disabled={logo ? !logo : (Object.values(brandinText)[0] === "")}
                  />
                  {(display === "Full Video" && data.displayPosition)
                    &&
                    <div className="select-position-wrapper">
                      <SelectOneRadio
                        options={data.displayPosition.filter((item, i) =>
                          item?.code.includes("CORNER")
                        )}
                        // options={ShowPosition}
                        // options={[{position:"Top Left"},{position:"Top Right"},{position:"Bottom Right"}]}
                        onSelectionChange={(value) => { /*console.log("Label Position", value); */ setCobrandingDetails({ ...cobrandingDetails, position: value.position, positionId: value.uuid }) }}
                        classes='lable-position'
                        lable="Label Position"
                        initialValue={position}
                      />
                    </div>
                  }
                </div>
                <div className="branding-color-language-wrapper">
                  <h6 className="lato-regular fs-24-18 black-242">
                    Color and Language{" "}
                  </h6>
                  <div className="dropwon-component-wrapper">
                    <CustomDropDown
                      label={language || "Language"}
                      options={[...new Set(variation?.map((item) => item.language))] || []}
                      getData={(data) =>
                        setCobrandingDetails({ ...cobrandingDetails, language: data.languageName, color: getColorOptions(variation, data.languageName)[0] || "" })
                      }
                      id="Language"
                      disabled={([...new Set(variation?.map((item) => item.language))].length < 1)}
                    />
                    <CustomDropDown
                      label={color || "Color"}
                      // options={variation?.filter((item) => item.language.languageName === language).map((item) => item.color) || []}
                      options={getColorOptions(variation, language)}
                      getData={(data) => setCobrandingDetails({ ...cobrandingDetails, color: data })}
                      id="color"
                      disabled={getColorOptions(variation, language).length <= 1}
                    />

                  </div>
                </div>
              </div>
              <div className="branding-file-information-desk mb-none">
                <h4 className="lato-regular mb-32 fs-32-28 black-242">
                  File Information
                </h4>
                <div className="brand-file-info-wrapper">
                  <div className="thabmail-box">

                    <Image
                      src={data?.contentType?.contentCode?.toLocaleLowerCase() === "minimalist" ? (isImage(data?.defaultVariation?.contentFilePath) ? data?.defaultVariation?.contentFilePath : thumbnail) : data.thumbnail || thumbnail}
                      className="file-thumbnail"
                      alt="thumbnail"
                    />
                    <div className="file-user-frame">
                      {data?.amc?.thumbnailPath && <Image
                        src={data?.amc && data?.amc.thumbnailPath || thumbnail}
                        alt="profile-icon"
                        className="file-user-profile"
                      />}
                      <h6 className="fs-18-12 fw-65  black-242">
                        {data?.amc ? data?.amc?.amcName : ""}
                      </h6>
                    </div>
                  </div>
                  <div className="file-info-right-box">
                    <h6 className="fs-18-12 fw-65 black-242">
                      {data.title ? data.title : data.defaultVariation.title}
                    </h6>
                    <p className="lato-medium fs-14-12 black-242 ellipsis-4">
                      {data.description ? data.description : data.defaultVariation.description}

                    </p>
                    <p className="lato-regular fs-14-12 black-242 ">
                      Content Type : <span className="lato-medium">{data.contentType.contentTypeName || 'Report'}</span>{" "}
                      <br />
                      Category :<span className="lato-medium">
                        {data.category.categoryName || 'Product'}
                      </span>{" "}
                      <br />
                      Language :<span className="lato-medium">{data.defaultLanguage.languageName || language || 'English'}</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* ------------------------------ */}
            <div className="preview-box">
              <h4 className="lato-regular mb-32 fs-32-28 black-242 mb-none">
                Preview
              </h4>
              <div className="preview-box-frame">
                <h4 className="lato-regular mb-32 f-s-24 black-242 mb-show">
                  Preview
                </h4>

                <div className="preview-action-frame bg-transparent mb-none">
                  <div className="save-frame">
                    {/* <BsFillBookmarkFill className="icon save-icon" /> */}
                    <Image src={save1} className="icon save-icon" />
                  </div>
                  <div className="save-frame">
                    {/* <BiSolidShareAlt className="icon share-icon" /> */}
                    <Image src={BlackShare} className="icon share-icon" />
                  </div>
                  {isLoading ?
                    <div className="lato-medium fx-18 button-download primary loading-button">
                      {/* <Image src={downloadGif} alt="icon" className={"download-icon"} /> */}
                      <Spinner />
                      Downloading...
                    </div>
                    :
                    <div
                      onClick={Object.values(cobrandingDetails).every(item => !!item) ? onDownloadFile : () => { alert('Select all options') }}
                      // onClick={Object.values(cobrandingDetails).every(item => !!item) ? convertToImage : () => { alert('Select all options') }}
                      className={Object.values(cobrandingDetails).every(item => !!item) ? "lato-medium fx-18 button-download bg-primary text-white pointer" : " lato-medium fx-18 button-download pointer"}>
                      <Image
                        src={Object.values(cobrandingDetails).every(item => !!item) ? downloadWhiteIcon : downloadIcon}
                        alt="icon"
                        className={"download-icon"}
                      />
                      Download
                    </div>
                  }
                </div>
                <div className="preview-image-wrapper">
                  <Image src={(data?.contentType?.contentCode?.toLocaleLowerCase() === "minimalist" || data?.contentType?.contentCode?.toLocaleLowerCase() === "infographics") ? (isImage(data?.defaultVariation?.contentFilePath) ? data?.defaultVariation?.contentFilePath : InfographicThumbnail) : data.thumbnail || thumbnail} className="preview-main-img" alt="imag" />
                  {logo && displayOption == "Select Logo" && position && (
                    <Image src={logo} alt="logo" className="co-branding-logo" style={selectPosition[position]} />
                  )}
                  {displayOption == "Select Text" && position && (
                    <div ref={imageRef} className="text-logo lato-regular fs-12-10 " style={selectPosition[position]}>
                      {company} <br /> {arnNo} <br /> {companyEmail} <br /> {companyNo}{" "}
                    </div>
                  )}
                </div>
              </div>
              <div className="branding-file-information-desk mb-show">
                <h4 className="lato-regular mb-32 f-s-24 black-242">
                  File Information
                </h4>
                <div className="brand-file-info-wrapper  flex-wrap">
                  <div className="thabmail-box">
                    <div className="file-user-frame">
                      <Image
                        src={data?.amc && data?.amc.thumbnailPath || thumbnail} alt="profile-icon"
                        className="file-user-profile"
                      />
                      <h6 className="fs-18-12 fw-65  black-242">
                        {data?.amc ? data?.amc?.amcName : ""}
                      </h6>
                    </div>
                  </div>
                  <div className="file-info-right-box">
                    <h6 className="fs-18-12 fw-65 black-242">
                      {data.title || data.defaultVariation.title || NA}
                    </h6>
                    <p className="lato-medium fs-14-12 black-242 ">
                      {data.description || data.defaultVariation.description || NA
                      }

                    </p>
                    <p className="lato-regular fs-14-12 black-242 ">
                      Content Type : <span className="lato-medium">{data.contentType.contentTypeName || 'Report'}</span>{" "}
                      <br />
                      Category :<span className="lato-medium">
                        {data.category.categoryName || 'Product'}
                      </span>{" "}
                      <br />
                      Language :<span className="lato-medium">{language || 'English'}</span>
                    </p>
                  </div>
                </div>
              </div>
              <div className="preview-action-frame mb-flex py-3">
                <div className="save-frame">
                  {/* <BsFillBookmarkFill className="icon save-icon" /> */}
                  <Image src={save1} className="icon save-icon" />
                </div>
                <div className="save-frame">
                  {/* <BiSolidShareAlt className="icon share-icon" /> */}
                  <Image src={BlackShare} className="icon share-icon" />
                </div>
                {isLoading ?
                  <div className="lato-medium fx-14 button-download primary loading-button">
                    {/* <Image src={downloadGif} alt="icon" className={"download-icon"} /> */}
                    <Spinner />
                    Downloading...
                  </div>
                  :
                  <div
                    onClick={Object.values(cobrandingDetails).every(item => !!item) ? onDownloadFile : () => { alert('Select all options') }}
                    className={Object.values(cobrandingDetails).every(item => !!item) ? "lato-medium fx-14 button-download bg-primary text-white pointer" : " lato-medium fx-14 button-download pointer"}>
                    <Image
                      src={Object.values(cobrandingDetails).every(item => !!item) ? downloadWhiteIcon : downloadIcon}
                      alt="icon"
                      className={"download-icon"}
                    />
                    Download
                  </div>
                }
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* ----- HERO SECTION END ------- */}
    </div>
  );
}




