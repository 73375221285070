import React, { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
import { getDataApi } from './Api';
import { PageSubHeader } from '../common';
import { BreadCrumb, ContentTypesCards } from '../home/_utils';
import { AllShimmer } from '../common/components';
import { NotFound } from '../common/pages';
import { MYCOLLECTION } from '../../utils/Constant';

export default function CollectionDetails() {
  const [collectionDetails, setCollectionDetails] = useState([]);
  const [loading, setLoading] = useState(true); 
  const [totalItems,setTotalItems]= useState(0)
  const param = useParams();
  const location = useLocation()

  useEffect(async () => {
    try {
      const data = await getDataApi(`user-collection/${param.id.split("=")[1]}/items`);
      setCollectionDetails(data.data);
      setTotalItems(data.data?.reduce((acc, obj) => acc + (obj.count || 0), 0))
      setLoading(false); 
      // console.log();
    } catch (error) {
      // console.error('Error fetching data:', error);
      setLoading(false); 
    }
  }, []);

  return (
    <div>
      <BreadCrumb path={[MYCOLLECTION, location?.state?.collectionName]} clasese="mb-3-desk" />
        
      {loading ? (
        <AllShimmer type="collectionDetails" noContainer={true} />
      ) : (
        <>
          <PageSubHeader title={location?.state?.collectionName} clases="myactivity-pageheader p" count={totalItems} isSearch={false} />
          {collectionDetails?.length === 0 ? (
            <NotFound />
          ) : (
            collectionDetails?.map((item, j) => {
              if (item.list?.length > 0) {
                return (
                  <ContentTypesCards
                    key={j}
                    countTitle={item?.title}
                    hide={collectionDetails?.length === 1 ? false : true}
                    wrapperadd={collectionDetails?.length === 1 ? "result-wrap" : ""}
                    data={item?.list}
                    count={item?.count}
                    typeId={item?.uuid}
                    code={item?.code}
                  />
                );
              }
            })
          )}
        </>
      )}
    </div>
  );
}
