import { Toast } from "react-bootstrap";
import { postLoginInstance, preLoginInstance } from "../../utils";
import { toast } from "react-toastify";
import { API_LIMIT, START_PAGE } from "../../utils/Constant";

// LOGIN
export const loginApi = (ctx, data) => {
  preLoginInstance.post('user/login', data)
    .then(res => {
      // console.log('res', res);
      localStorage.setItem('finpediaCustomerToken', JSON.stringify(res?.data?.data.token.token));
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data.token.user));
      sessionStorage.setItem('fromLogin', true);
      ctx.props.history.push({
        pathname: '/content-type/All',
        search: `pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`,
        // state: { fromLogin: true }
      });
    })
    .catch((error) => {
      // console.log('hello', error.response.data.message);
      ctx.setState({
        error: error.response.data.message || "Something went wrong"
      })
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const verifyUserApi = async (data) => {
  try {
    const response = await preLoginInstance.post('user/sentMobileNumberOtp', data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    throw error;
  }
};
export const registerUserApi = async (data) => {
  try {
    const response = await preLoginInstance.post('user/register', data);
    return response.data;
  } catch (error) {
    toast.error(error.response.data.message || "Something went wrong")
    throw error;
  }
};

export const forgotPasswordApi = (ctx, data) => {
  preLoginInstance.post('user/forgot-password', data)
    .then(res => {
      ctx.setState({
        showSuccess: true
      })
      toast.success(res?.data?.data.message || "Success")
    })
    .catch(error => {
      toast.error(error.response.data.message || "Something went wrong")
    });
}

export const getUserDetailsApi = (ctx = null, setLogoFile = null, setBrandinText = null) => {
  postLoginInstance.get('user/details')
    .then(res => {
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
      if (ctx?.state?.userDetails) {
        ctx.setState({
          userDetails: res?.data?.data
        })
      }
      if (setLogoFile !== null && setBrandinText !== null) {
        console.warn("call fo cobranding");
        setLogoFile(res?.data?.data?.organizationLogoPath ?? null);
        setBrandinText({ company: res?.data?.data?.companyName ?? "", arnNo: res?.data?.data?.arnNumber ?? "", companyEmail: res?.data?.data?.emailAddress ?? "", companyNo: res?.data?.data?.mobileNumber ?? "" })

      }

      if (ctx?.state?.token) {
        ctx.props.history.push(`/content-type/All?pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`);
      }
    })
    .catch(error => {
      console.log('heyyyyy', error);
      toast.error(error.response?.data.message || "Something went wrong")
    });
}

export const resetPasswordApi = (ctx, data) => {
  preLoginInstance.put(`/user/reset-password/${ctx.state.token}`, data)
    .then((res) => {
      toast.success(res?.data?.data.message || "Password set successfully");
      ctx.props.handleClose()
      // ctx.props.history.push("/onboarding");
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};
// VERIFY EMAIL API
export const verifyEmailApi = (ctx) => {
  preLoginInstance.post(`/user/verifyEmailAddressVerificationToken/${ctx.state.token}`)
    .then((res) => {
      // console.log('res', res);
      toast.success(res?.data?.data.message || "Email verified successfully");
      ctx.props.history.push("/onboarding");
    })
    .catch((error) => {
      toast.error(error.response.data.message || "Something went wrong")
    });
};