import React from 'react'
import { useState, useEffect } from 'react';
import { BsFillBookmarkFill } from "react-icons/bs";
import { BiSolidShareAlt } from "react-icons/bi";
import profile from '../../assets/images/markit-plan.png'
import eventImg from '../../assets/images/event-img.png';
import calendar from '../../assets/images/calendar.svg';
import location from '../../assets/images/location.svg';
import speaker from '../../assets/images/speeker.svg';
import attendees from '../../assets/images/attendees.svg';
import onlineIcon from '../../assets/images/Online-Event-tag.svg';
import inPerson from '../../assets/images/in-person.svg';
import { Image } from 'react-bootstrap';
import { Footer, Header } from '../common/pages';
import { Trending } from '../common';
import EventCard from './EventCard';
import { getCommonApi } from './Api';
import { useParams } from 'react-router-dom';
import moment from 'moment/moment';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { RWebShare } from 'react-web-share';
import { SHARE_BASE_URL } from '../../utils/Constant';
import { getToken } from '../../utils/ManageToken';
import { SignUp } from '../common/components';
import { BreadCrumb } from '../home/_utils';
import { isEventExpired } from '../../utils/ReusableFunctions';
import { commonAuthApis } from '../profile/Api';

export default function EventDetails() {
  const param = useParams();
  const history = useHistory()
  const [signup, setSignup] = useState(false);
  const [detail, setDetail] = useState();

  const registerHandler = async(data) =>{
    if(data?.contentCreator?.isFinpediaUser){
      history.push({ pathname: '/event-chekout', state: { detail: data } })
      // history.push({ pathname: '/event-chekout', state: { detail } })
    }else{
      // CALL API FOR REGISTER IN FIND DATA
      const info={
        "data" : {
            "eventUuid" :data?.uuid
        }
    }
      const res= await commonAuthApis('/eventRegistration',info)
      // console.log(res)
      if(res?.status>199 && res?.status<240){
        // console.log("look htis is regineter linke")
        window.open(data?.registrationLink || "", '_blank');
      }
    }
  }

  const getDetailsData = async () => {
    const res = await getCommonApi('website/events/' + param.id)
    // console.log(res.data)
    setDetail(res?.data?.data)
  }
  useEffect(() => {
    getDetailsData()
  }, [])
  return (
    <div className=' page-padding'>
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}
      <Header />
      <BreadCrumb path={["Event", `${detail?.name}`]} clasese="mb-3-desk container" />
      <div className="container">
        <div className="event-details-header">
          <h3 className='lato-bold fs-32-28 black-242'>{detail?.name || 'Na'}</h3>
          <div className="event-register-wrapper">
            <div className="w-100 mb-show">
              <p className='lato-medium fx-14 black-242'>₹ {detail?.amount || "Na"}</p>
            </div>
            {/* <div className="save-frame">
              <BsFillBookmarkFill className="icon save-icon" />
            </div> */}
            <RWebShare
              data={{
                text: "",
                url: `${SHARE_BASE_URL + "events-details/" + detail?.uuid}`,
                title: detail?.name,
              }}
            // onClick={() =>{}}
            >
              <div className="save-frame pointer">
                <BiSolidShareAlt className="icon share-icon" />
              </div>
            </RWebShare>

            <div className={(detail?.isRegistered || isEventExpired(detail?.endDate)) ? "lato-medium fs-18-16 px-3  disable-btn" : "primary-btn pointer"} onClick={detail?.isRegistered ? null :
              () => {
                if (!getToken()) {
                  setSignup(true)
                }
                else { registerHandler(detail)}
              }}>
              {detail?.isRegistered ? 'Registered' : 'Register'} </div>
          </div>
        </div>
      </div>
      <div className="event-info-wrapper">
        <div className="container">
          <div className="event-detail-profile-wrapper">
            <Image src={detail?.amc?.circleImagePath || detail?.contentCreator?.thumbnailPath || profile} alt='profile event' />
            <h6 className='lato-regular fs-24-18 black-242'>{detail?.amc?.amcName || detail?.contentCreator?.contentCreatorName}</h6>
          </div>
          <div className="event-details-poster">
            {detail?.eventFormat === "ONLINE" ?
              <Image src={onlineIcon} alt='img' className='offline-icon' />
              :
              <Image src={inPerson} alt='img' className='offline-icon' />
            }
            <Image src={detail?.bannerPath || eventImg} alt='img' className='event-details-image' />
            <div className="attendees-frame lato-regular fs-14-13 black-242 ">
              <Image src={attendees} alt='attendees icon' className='attendess-image' />
              {detail?.totalSeats} Attendees
            </div>
          </div>
          <p className='lato-regular fs-16-13 black-242'><Image src={calendar} alt='image' />&nbsp;    {moment(detail?.startDate).format("ddd, DD MMM | h:mm a") || 'NA'} - {moment(detail?.endDate).format("ddd, DD MMM | h:mm a") || 'NA'}</p>
          {detail?.eventFormat !== "ONLINE" &&
            <p className='lato-regular fs-16-13 black-242 mt-2 '>&nbsp;&nbsp;<Image src={location} alt='image' className='' />&nbsp; Venue : {detail?.venueName} {detail?.city && `(${detail.city})` || 'Na'}</p>}
        </div>
      </div>
      <div className="speker-section">
        <div className="container">
          <h6 className='lato-regular fs-24-18 black-242 m-0'>Speakers</h6>
          <div className="speaker-card-frame">
            {detail?.eventSpeakers?.map((item, i) => {
              return (
                <div key={i} className="speaker-box">
                  <Image src={item?.profilePicture || speaker} alt='speakerImg' className='mx-auto desk-show obj-cover' />
                  <div className="speaker-card-profile ">
                    <Image src={item?.profilePicture || speaker} alt='speakerImg' className='mx-auto mb-show obj-cover ' />
                    <div className="">
                      <h5 className='lato-regular fs-16-12 w-100 text-center'>{item?.speakerName || "Na"}</h5>
                    </div>
                  </div>
                  <p className='lato-regular fx-14 light-black ellipsis-5 text-justify'>{item?.description || 'Na'}
                  </p>
                  {item?.description.length > 500 &&
                    <p className='lato-regular fx-14 primary underline text-start pointer'>read more</p>
                  }
                  <a href={item?.link} target='_blank' className='lato-regular underline fs-16-12 w-100 text-center'>Let's Connect</a>
                </div>
              )
            })
            }

          </div>
          <h6 className='lato-regular fs-24-18 black-242 m-0'>About the event</h6>
          <p className='lato-regular fs-16-14 light-black my-3'>{detail?.description}
          </p>
          <div className='event-keys-wrapper d-flex gap-12 align-items-center py-3'>
            {
              detail?.keywords?.split(',').map((item, i) => {
                return (
                  <div className='key-words-frame lato-regular fs-14-13 black-242'>{item}</div>
                )
              })
            }
          </div>
          {/* <h3 className='lato-bold fs-32-28 black-242 pb-3'>Suggested events</h3>
          <div className="events-card-wrapper overflow-xauto">
            <EventCard classes="res-event-card" />
            <EventCard classes="res-event-card" />
            <EventCard classes="res-event-card" />
            <EventCard classes="res-event-card" />
          </div> */}
        </div>
      </div>
      <Footer />
      {!isEventExpired(detail?.endDate) && 
      <div className="sticky-event-detailse-footer desk-show">
        <div className="container">
          <div className="sticky-frame-footer py-2">
            <div className="w-100 ">
              <h3 className='lato-bold fs-24-14 black-242'>{detail?.name || 'Na'}</h3>
              <p className='lato-regular fs-14-12 black-242'> {moment(detail?.startDate).format("lll") || 'NA'}</p>
              <p className='lato-regular fs-14-12 black-242 '><a target='_blank' className='lato-regular fs-16-13 black-242 text-decoration-none' href={detail?.venueMapLink}> Venue : {detail?.venueName} {detail?.city && `(${detail.city})` || 'Na'}</a></p>
            </div>
            <div className="stiky-footer-rifht">
              <p className='lato-bold fs-24-14 black-242'> {detail?.amount || "Free"}</p>
              <div className=" d-flex align-items-center gap-12 pt-2">


                {/* <div className="save-frame">
              <BsFillBookmarkFill className="icon save-icon" />
            </div> */}
                <RWebShare
                  data={{
                    text: "",
                    url: `${SHARE_BASE_URL + "events-details/" + detail?.uuid}`,
                    title: detail?.name,
                  }}
                // onClick={() =>{}}
                >
                  <div className="save-frame pointer">
                    <BiSolidShareAlt className="icon share-icon" />
                  </div>
                </RWebShare>

                <div className={detail?.isRegistered ? "lato-medium fs-18-16 px-3 h-40 disable-btn" : "primary-btn pointer py-3"} onClick={detail?.isRegistered ? null :
                  () => {
                    if (!getToken()) {
                      setSignup(true)
                    }
                    else {registerHandler(detail)  }
                  }}>
                  {detail?.isRegistered ? 'Registered' : 'Register'} </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      }
    </div>
  )
}
