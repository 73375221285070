import React from "react";
import { BsFillBookmarkFill } from "react-icons/bs";
import { MdDownloadForOffline } from "react-icons/md";
import { BiSolidShareAlt } from "react-icons/bi";
import Trend from "../../assets/images/black-traind-Icon.svg";
import Filter from "../../assets/images/leadingicon.svg";
import DownIcon from "../../assets/images/dropdown-icon.svg";
// import notFound from "../../assets/images/result-not-found.png";
import notFound from "../../assets/images/No-results-found.gif";
import closeFilter from "../../assets/images/cancel-icon.svg";
import { useHistory } from "react-router-dom";
import { Button, Col, Image, Row, Accordion, Card } from "react-bootstrap";
import { DropdownCommon, DropdownFilter, SlideFilter } from "../home/staticComponents";
import { useState } from "react";
import { Footer, Header, NotFound, Pagination } from "../common/pages";
import { EventCard } from "./";
import { useEffect } from "react";
import { getCommonApi } from "./Api";
import { calculateTotalPageCount, formatNumber } from "../../utils/ReusableFunctions";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { AllShimmer } from "../common/components";
import { API_LIMIT, EVENTS, START_PAGE } from "../../utils/Constant";



const filterObj = ['Day', 'Organiser', 'Event Type', 'Event Format', 'Category', 'Speaker', 'Location']
export default function EventPage() {
  const history = useHistory();
  return (
    <div className="social-media-main-wrapper page-padding">
      <Header />
      <SlideFilter placeholder="Search in Events" dropdown={false} component={<EventAllCard />} />
      <Footer />
    </div>
  );
}
function EventAllCard() {
  const location = useLocation();
  const history = useHistory();
  const getParam = new URLSearchParams(location.search);
  const [eventData, setEventData] = useState();
  const [isLoading, setIsLoading] = useState(true)
  const getEventApiData = async () => {
    const res = await getCommonApi(`website/events${location.search}`);
    // console.log(res?.data?.data)
    if (res.status === 200) {
      setEventData(res?.data?.data);
      setIsLoading(false)
    } else (
      setIsLoading(false)
    )
  }

  useEffect(() => {
    // console.log(location.search,getParam.get('from'))
    window.scrollTo(0, 0);
    setIsLoading(true)
    // const res = await getCommonApi('website/events?pageSize=10000&pageNumber=1');
    getEventApiData()
    // }, [])
  }, [location.search])

  return (
    <>
      <div className="filter-slide-event-wrapper">
        <h3 className="lato-regular fs-36-24 my-50 ">
          Events
          <span className="lato-semibold fs-14-12 black-242">
            &nbsp;&nbsp;
            {formatNumber(eventData?.length)}
          </span>
        </h3>

        <div className="events-card-wrapper">
          {isLoading ? <AllShimmer type={EVENTS} /> :
            (eventData?.length > 0 ?
              eventData?.list?.map((item, i) => {
                return (<>
                  {
                    (i === 4 || i === 12) && (eventData?.bannerAds?.[0] || eventData?.bannerAds?.[1]) &&
                    <div className="banner-main-wrapper">
                      <Image src={eventData?.bannerAds?.[i === 4 ? 0 : 1]?.bannerImagePath} alt="banner Image" />
                    </div>
                  }
                  <EventCard key={i} data={item} />
                </>
                )
              })
              :
              <div className="result-not-found-section text-center mx-auto">
                <img src={notFound} className="w-100" alt="image" />
                <p className="text-center lato-regular fs-24-18 primary fw-400 mb-0 mt-4">No results found</p>
                <p className="text-center lato-regular fs-24-18 primary fw-400 mb-4">Even our detective's stumped! Ready for the next clue?</p>
                <Button className=" primary-btn mx-auto">Back to Homepage</Button>
              </div>)
          }
        </div>

        {eventData?.length > 10 &&
          <Pagination
            history={history}
            location={location}
            page={getParam.get('pageNumber')}
            pageCount={Math.ceil(eventData?.length / 10)}
          />
        }

      </div>
    </>
  );
}