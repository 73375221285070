import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { useState } from "react";
import {
  Form,
  FormElement,
  FormValidator,
  CustomTextControl,
  SelectControl,
  BaseReactComponent,
  FormSubmitButton,
  DatePickerControl,
} from "../../utils/form";
import { Button, Image } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
// import Footer from ".../commom/Footer";
import Footer from "../common/pages/Footer";
import Edit from "../../assets/images/edit-icon.png";
import Logo from "../../assets/images/profile-logo.png";
import Profile from "../../assets/images/profile.png";
import { getAuthDataApi, updateProfileApi } from "../home/Api";
import { DEFAULT_IMAGE_HEIGHT, DEFAULT_IMAGE_WIDTH, ORGANIZATION_LOGO, PROFILE_PIC, UPDATE_PROFILE, organization_Logo } from "../../utils/Constant";
import { Header } from "../common/pages";
import { getUser } from "../../utils/ReusableFunctions";
import { commonPostApi, sendEmailVerificationApi, verifyMobileApi, verifyOtpApi } from "./Api";
import { BreadCrumb } from "../home/_utils";
import { isArray } from "lodash";
import DatePicker from "react-datepicker";
import { toast } from "react-toastify";
import closeIcon from '../../assets/images/close-black.svg'
import warningIcon from "../../assets/images/warning-icon.svg";
import lockIcon from "../../assets/images/lock-icon.svg";
import greenTickIcon from "../../assets/images/green-tick-icon.svg";
import { getUserDetailsApi } from "../onBoarding/Api";
import { Spinner } from "../common/components";
const companyTypes = [{ "value": "MFD (Mutual Fund Distributor)", "label": "MFD (Mutual Fund Distributor)" }, { "value": "RIA (Registered Investment Advisor)", "label": "RIA (Registered Investment Advisor)" }, { "value": "ND (National Distributor)", "label": "ND (National Distributor)" }, { value: "Other", label: "Other" }]
export class ProfileDetails extends BaseReactComponent {
  constructor(props) {
    super(props);
    const userDetails = getUser();
    // console.log('userDetails', userDetails);
    this.state = {
      userDetails,
      firstName: userDetails?.firstName || "",
      lastName: userDetails?.lastName || "",
      pleaseselect: userDetails?.pleaseselect || "",
      dateOfBirth: userDetails?.dateOfBirth ? new Date(userDetails.dateOfBirth) : "",
      gender: userDetails?.gender || "",
      genderOption: [
        { value: "Male", label: "Male" },
        { value: "Female", label: "Female" },
      ],
      mobileNumber: userDetails?.unverifiedMobileNumber || userDetails?.mobileNumber || "",
      emailAddress: userDetails?.unverifiedEmailAddress || userDetails?.emailAddress || "",
      // whatsappMobileNumber: userDetails?.whatsappMobileNumber || "",
      organizationName: userDetails?.companyName || "",
      organizationType: userDetails?.organizationType || "",
      arnNumber: userDetails?.arnNumber || "",
      euinNumber: userDetails?.euinNumber || "",
      companyAddress: userDetails?.companyAddress || "",
      city: userDetails?.city || "",
      state: userDetails?.state || "",
      pincode: userDetails?.pincode || "",
      gstNumber: userDetails?.gstNumber || "",
      companyInception: userDetails?.companyInception ? new Date(userDetails.companyInception) : "",
      organizationLogo: userDetails?.organizationLogoPath ? userDetails?.organizationLogoPath : null,
      profilePicture: userDetails?.profilePicturePath ? userDetails?.profilePicturePath : null,
      allStateData: [],
      allCityData: [],
      mobileStatus: "",
      otpMobile: null,
      otpSent: false,
      showResend: userDetails?.unverifiedMobileNumber ? true : false,
      changeMobile: false,
      emailStatus: "",
      changeEmail: false,
      showTimer: false,
      secondsLeft: 30,
      isSubmited: false,
    };
  }
  onValidSubmit = async () => {
    this.setState({ isSubmited: true })
    const {
      firstName,
      lastName,
      dateOfBirth,
      gender,
      // mobileNumber,
      // emailAddress,
      // whatsappMobileNumber,
      organizationName,
      organizationType,
      arnNumber,
      euinNumber,
      companyAddress,
      city,
      state,
      pincode,
      gstNumber,
      companyInception,
      allStateData,
      allCityData,
    } = this.state;
    let data = {
      data: {
        firstName: firstName,
        lastName: lastName,
        dateOfBirth: dateOfBirth,
        gender: gender,
        // mobileNumber: mobileNumber,
        // emailAddress: emailAddress,
        // whatsappMobileNumber: whatsappMobileNumber,
        companyAddress: companyAddress,
        companyName: organizationName,
        organizationType: organizationType,
        arnNumber: arnNumber,
        euinNumber: euinNumber,
        city: city,
        state: state,
        pincode: pincode,
        gstNumber: gstNumber,
        companyInception: companyInception,
      },
    };
    const response = await updateProfileApi(UPDATE_PROFILE, data, "put");
    // console.log("Profile Updated Responce =>", response);
    if (response.data) {
      toast.success("Updated Successfully");
      localStorage.setItem("finUserDetails", JSON.stringify(response.data));
      this.setState({ isSubmited: false })
    } else {
      this.setState({ isSubmited: false })
    }
  };
  getDataHandler = async (url) => {
    const data = await getAuthDataApi(url);
    return data;
  }
  componentDidMount() {
    getUserDetailsApi(this);
    this.getDataHandler("/website/location")
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allStateData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
    if (this.state?.state) {
      this.getCity();
    }
  }
  getCity = () => {
    this.getDataHandler(`/website/location/${this.state?.state}`)
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allCityData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }
  logoUploadHandler = async (e, type) => {
    this.setState({ isSubmited: true })
    console.log("Is lofog and proile pdaed")
    if (type === 'profile') {
      let data = new FormData();
      data.append('profile-picture', e.target.files[0])
      const imageUrl = URL.createObjectURL(e.target.files[0]);
      this.setState({ profilePicture: imageUrl })
      let response = await commonPostApi("user/upload-profile-picture", data, "put");
      // console.log("Profile Petcure", response);
      if (response.data) {
        toast.success("Updated Successfully");
        localStorage.setItem("finUserDetails", JSON.stringify(response.data));
        this.setState({ isSubmited: false });
      }
      else {
        this.setState({ isSubmited: false });
      }
    } else {
      let data = new FormData();
      data.append('organization-logo', e.target.files[0]);
      const imageUrl = URL.createObjectURL(e.target.files[0]);

      // todo======================== SIZING IMEGE CODE START ==================
      const image = new window.Image();
      image.src = URL.createObjectURL(e.target.files[0]);
      image.onload = async () => {
        const width = image.width;
        const height = image.height;

        if (width > DEFAULT_IMAGE_WIDTH || height > DEFAULT_IMAGE_HEIGHT) {
          toast.error(`Image dimensions must be ${DEFAULT_IMAGE_WIDTH}x${DEFAULT_IMAGE_HEIGHT} or smaller. Uploaded image is (${width}x${height})`);
        } else {
          this.setState({ organizationLogo: imageUrl }, async () => {
            let response = await commonPostApi("user/upload-organization-logo", data, "put");
            // console.log(response)
            if (response.data) {
              localStorage.setItem("finUserDetails", JSON.stringify(response.data));
              this.setState({ isSubmited: false })
            }
            else {
              this.setState({ isSubmited: false })
            }
          });
        }
      };
      // todo======================== END


    }
    //
  }
  handleChange = (e, validation) => {
    let isValid = [];
    validation.map((item) => {
      if (FormValidator[item](e.target.value)) {
        isValid.push(true)
      } else {
        isValid.push(false)
      }
    })
    const check = isValid.every((item) => item === true);
    if (check) {
      this.setState({ [e.target.name]: e.target.value })
    } else if (!check && !(validation.includes("isRequired"))) {
      this.setState({ [e.target.name]: e.target.value })
    }
  }
  startCountdown() {
    let countdown = setInterval(() => {
      if (this.state.secondsLeft > 0) {
        this.setState((prevState) => ({
          secondsLeft: prevState.secondsLeft - 1,
        }));
      } else {
        clearInterval(countdown);
        this.setState({ showTimer: false, secondsLeft: 30 });
      }
    }, 1000); // Update every second
  }
  render() {
    const { profilePicture, isSubmited, organizationLogo, allStateData, state, userDetails } = this.state;
    return (
      <div>
        {/* <Header /> */}
        <BreadCrumb path={["Profile Details"]} clasese="mb-3-desk" />
        <div className="information-wrapper">
          <h2>Basic Information</h2>
          <div className="form-area">
            <div className="form-details">
              <Form onValidSubmit={() => { }}>
                <Row>
                  <Col md={6}>
                    {/* <FormElement
                      valueLink={this.linkState(this, "firstName")}
                      label="First Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          placeholder: "Enter First Name",
                        },
                      }}
                    /> */}
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">First Name</p>
                      <input
                        value={this.state.firstName}
                        type="text"
                        name="firstName"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isLetter"])}
                        placeholder="Enter First Name"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    {/* <FormElement
                      valueLink={this.linkState(this, "lastName")}
                      label="Last Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          placeholder: "Enter Last Name",
                        },
                      }}
                    /> */}
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Last Name</p>
                      <input
                        value={this.state.lastName}
                        type="text"
                        name="lastName"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isLetter"])}
                        placeholder="Enter Last Name"
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group custom-date-range-picker">
                      <label htmlFor="" className="form-label">Date of birth</label>
                      <DatePicker
                        selected={this.state.dateOfBirth}
                        onChange={(date) => this.setState({ dateOfBirth: date })}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Date of birth"
                        className="form-control "
                        // showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={50}
                        scrollableYearDropdown
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "gender")}
                      label="Gender"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.genderOption,
                          multiple: false,
                          searchable: true,
                          placeholder: "Select gender",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.gender);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Mobile Number</p>
                      {
                        userDetails.unverifiedMobileNumber
                          ?
                          <span className="verification-status"><Image src={warningIcon} className="icons" />  Verification Pending</span>
                          :
                          <span className="verification-status verified"><Image src={greenTickIcon} className="icons" />  Verified</span>
                      }
                      <input
                        value={this.state.mobileNumber}
                        type="text"
                        name="mobileNumber"
                        className="form-control verify"
                        onChange={(e) => this.handleChange(e, ["isNum"])}
                        placeholder="Mobile no."
                        maxLength={10}
                        disabled={!this.state.changeMobile}
                      />
                      {
                        this.state.changeMobile ?
                          <span
                            className="change-button"

                          >
                            <span onClick={() => {
                              const profileData = {
                                data: { unverifiedMobileNumber: this.state.mobileNumber }
                              };
                              const response = updateProfileApi(UPDATE_PROFILE, profileData, "put");
                              // console.log('response', response);
                              if (response) {
                                const data = {
                                  data: { mobileNumber: this.state.mobileNumber }
                                }
                                verifyMobileApi(this, data);
                              }

                            }}> Update</span> <Image src={closeIcon} className="ml-2" onClick={() => this.setState({ changeMobile: !this.state.changeMobile })} />
                          </span>
                          :
                          <span
                            className="change-button"
                            onClick={() => {
                              this.setState({
                                changeMobile: !this.state.changeMobile,
                              })
                            }}
                          >
                            {(!this.state.changeMobile && !this.state.otpSent) ? "Change" : "Edit"}
                          </span>
                      }
                      {
                        // !this.state.changeMobile &&
                        <div>
                          {

                            (this.state.otpSent || userDetails.unverifiedMobileNumber)
                              ?
                              <div className="verification-wrapper">
                                <Image src={lockIcon} className="icons" />
                                <div className="right">
                                  <h6 className="lato-regular f-s-14 lh-20 black-242">Enter OTP</h6>
                                  <p className="lato-regular f-s-12 lh-16 black-4 pt-1">Please enter an OTP sent to your new mobile number.</p>
                                  <div className="otp-wrapper">
                                    <input
                                      value={this.state.otpMobile}
                                      type="number"
                                      name="otpMobile"
                                      className="form-control"
                                      onChange={(e) => this.handleChange(e, ["isNum"])}
                                      placeholder="Enter OTP"
                                    // maxLength={10}
                                    />
                                    {
                                      this.state.otpMobile
                                        ?
                                        <Button
                                          className={`primary-btn`}
                                          onClick={() => {
                                            const data = {
                                              data: {
                                                mobileNumber: userDetails.unverifiedMobileNumber,
                                                mobileNumberOtp: this.state.otpMobile
                                              }
                                            }
                                            verifyOtpApi(this, data);
                                          }
                                          }
                                        >
                                          Verify
                                        </Button>
                                        :
                                        <Button className={`primary-btn disable`}>Verify</Button>
                                    }
                                  </div>
                                  {
                                    this.state.showResend &&
                                    (this.state.showTimer ? (
                                      <div>
                                        <span className='lato-regular primary f-s-12'>Resend in {this.state.secondsLeft} seconds</span>
                                      </div>
                                    ) : (
                                      <span
                                        onClick={() => {
                                          const data = {
                                            data: {
                                              mobileNumber: this.state.mobileNumber
                                            }
                                          }
                                          verifyMobileApi(this, data);
                                        }}
                                        className="lato-regular primary f-s-12 pointer">
                                        Resend OTP
                                      </span>
                                    ))

                                  }
                                </div>
                              </div>
                              :
                              (this.state.changeMobile && !this.state.otpSent)
                              &&
                              <div className="verification-wrapper">
                                <Image src={warningIcon} className="icons" />
                                <div className="right">
                                  <h6 className="lato-regular f-s-14 lh-20 black-242">Change Mobile Number</h6>
                                  <p className="lato-regular f-s-12 lh-16 black-4 pt-1">An OTP will be sent to your updated mobile number for verification.</p>
                                </div>
                              </div>
                          }
                        </div>
                      }
                    </div>
                  </Col>

                  <Col md={6}>
                    {
                      userDetails.unverifiedEmailAddress
                        ?
                        <span className="verification-status email"><Image src={warningIcon} className="icons" />  Verification Pending</span>
                        :
                        <span className="verification-status email verified"><Image src={greenTickIcon} className="icons" />  Verified</span>
                    }
                    <FormElement
                      valueLink={this.linkState(this, "emailAddress")}
                      label="Email Address"
                      validations={[
                        {
                          validate: FormValidator.isEmail,
                          message: "Please enter a valid email",
                        },
                      ]}
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: !this.state.changeEmail,
                          placeholder: "Enter email address",

                        },
                      }}
                      classes={{
                        formGroup: "custom-group",
                        inputField: "verify"
                      }}
                    />

                    <span
                      className="change-button email-change"
                    >
                      <span onClick={async () => {
                        if (this.state.changeEmail) {
                          const profileData = {
                            data: { unverifiedEmailAddress: this.state.emailAddress }
                          };
                          const response = await updateProfileApi(UPDATE_PROFILE, profileData, "put");
                          // console.log('response', response);
                          if (response.data) {
                            this.setState({ userDetails: response.data })
                            const data = {
                              "data": { "emailAddress": response.data.unverifiedEmailAddress }
                            };
                            sendEmailVerificationApi(data);
                          }

                        }
                        this.setState({
                          changeEmail: !this.state.changeEmail,
                        })
                      }}>
                        {!this.state.changeEmail ? "Change" : "Update"}
                      </span>
                      {this.state.changeEmail && <Image src={closeIcon} className="ml-2" onClick={() => this.setState({ changeEmail: !this.state.changeEmail })} />}

                    </span>
                    {
                      userDetails.unverifiedEmailAddress &&
                      <div className="verification-wrapper email">
                        <Image src={warningIcon} className="icons" />
                        <div className="right">
                          <h6 className="lato-regular f-s-14 lh-20 black-242">Verification email sent</h6>
                          <p className="lato-regular f-s-12 lh-16 black-4 pt-1">Please verify your email address by following the link sent to {userDetails.unverifiedEmailAddress}.</p>
                          <Button className="secondary-btn" onClick={() => {
                            const data = {
                              "data": { "emailAddress": userDetails.unverifiedEmailAddress }
                            };
                            sendEmailVerificationApi(data);
                          }}>Resend verification email</Button>
                        </div>
                      </div>
                    }
                  </Col>
                  {/* <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Whatsapp Number</p>
                      <input
                        value={this.state.whatsappMobileNumber}
                        type="text"
                        name="whatsappMobileNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isNum"])}
                        placeholder="Whatsapp Number"
                        maxLength={10}
                      />
                    </div>
                  </Col> */}

                </Row>
              </Form>
            </div>
            <div className="profile-image">
              <div className="profile-section">
                <div className="edit">
                  <input type="file" name="Profile Picture" onChange={(e) => this.logoUploadHandler(e, "profile")} className="d-none" id="Profile-Picture" />
                  <label htmlFor="Profile-Picture">
                    <Image src={Edit} alt="edit" className="pointer" />
                  </label>
                </div>
                <Image src={profilePicture ? profilePicture : Profile} alt="edit" />
                <h3 className="fs-14-13">Profile Picture</h3>
              </div>
            </div>
          </div>
          <h2>Company/Billing Information</h2>
          <span className="lato-regular f-s-12 lh-18 op-5">*This information will be used for Billing & GST purposes</span>
          <div className="form-area">
            <div className="form-details">
              <Form onValidSubmit={() => { }}>
                <Row>
                  <Col md={6}>
                    {/* <FormElement
                      valueLink={this.linkState(this, "organizationName")}
                      label="Company Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          placeholder: "Enter organization name"
                        },
                      }}
                    /> */}
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Company Name</p>
                      <input
                        value={this.state.organizationName}
                        type="text"
                        name="organizationName"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter company name"
                      />
                    </div>

                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "organizationType")}
                      label="Company Type"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: companyTypes,
                          multiple: false,
                          searchable: true,
                          placeholder: "Select Type",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.organizationType);
                          }
                        }
                      }}
                    />

                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">ARN/RAI Number</p>
                      <input
                        value={this.state.arnNumber}
                        type="text"
                        name="arnNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isNum"])}
                        placeholder="Enter arn/rai number"
                        maxLength={10}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">EUIN Number</p>
                      <input
                        value={this.state.euinNumber}
                        type="text"
                        name="euinNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter EUIN number"
                        maxLength={15}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    {/* <FormElement
                      valueLink={this.linkState(this, "companyInception")}
                      label="Company Inception"

                      control={{
                        type: CustomTextControl,
                        settings: {
                          placeholder: "Enter company inception",

                        },
                      }}
                    /> */}
                    <div className="form-group custom-date-range-picker">
                      <label htmlFor="" className="form-label">Company Inception</label>
                      <DatePicker
                        selected={this.state.companyInception}
                        onChange={(date) => this.setState({ companyInception: date })}
                        maxDate={new Date()}
                        dateFormat="dd/MM/yyyy"
                        placeholderText="Enter company inception"
                        className="form-control "
                        // showMonthDropdown
                        showYearDropdown
                        dateFormatCalendar="MMMM"
                        yearDropdownItemNumber={100}
                        scrollableYearDropdown
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Company Address</p>
                      <input
                        value={this.state.companyAddress}
                        type="text"
                        name="companyAddress"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter company address"
                      />
                    </div>

                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "state")}
                      label="State"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allStateData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select State",
                          onChangeCallback: (onBlur) => {
                            this.setState({ city: "" }, () => {
                              this.getCity()
                            })

                            onBlur(state);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "city")}
                      label="City"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allCityData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select City",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.city);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">Pincode</p>
                      <input
                        value={this.state.pincode}
                        type="text"
                        name="pincode"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isNum"])}
                        placeholder="Enter pincode"
                        maxLength={6}
                      />
                    </div>
                  </Col>
                  <Col md={6}>
                    <div className="form-group">
                      <p className="form-label-custom fs-18-16">GST Number</p>
                      <input
                        value={this.state.gstNumber}
                        type="text"
                        name="gstNumber"
                        className="form-control"
                        onChange={(e) => this.handleChange(e, ["isAlphaNumeric"])}
                        placeholder="Enter GST number"
                        maxLength={15}
                      />
                    </div>
                  </Col>
                </Row>
              </Form>
            </div>
            <div className="profile-image">
              <div className="profile-section mb-90">
                <div className="edit">
                  <input type="file" name="organizationLogo" className="d-none" onChange={(e) => this.logoUploadHandler(e, "organization")} id="organization-Logo" />
                  <label htmlFor="organization-Logo">
                    <Image src={Edit} alt="edit" className="pointer" />
                  </label>
                </div>
                { }
                <Image src={organizationLogo ? organizationLogo : Logo} alt="edit" />

                <p className="f-s-10 image-requirment">(Please upload image of {DEFAULT_IMAGE_WIDTH} X {DEFAULT_IMAGE_HEIGHT} dimensions for best output)</p>
                <h3 className="fs-14-13">Company Logo</h3>
              </div>
            </div>
          </div>

          {isSubmited ?
            <div className="lato-medium fx-18 detail-button-download primary ">
              <Spinner />
              Submitting...
            </div>
            :
            <Button onClick={this.onValidSubmit} className="primary-btn">Submit</Button>

          }
          {/* <FormSubmitButton customClass={"primary-btn"}>
            Submit
          </FormSubmitButton> */}
        </div>
        {/* <Footer /> */}
      </div>
    );
  }
}

export default ProfileDetails;
