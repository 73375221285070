import React from "react";
import { FilterTrend, SearchDropDown } from "..";
import errorImage from "../../../assets/images/404-Error.gif";
// import errorImage from "../../../assets/images/error-404.png";
import { Image } from "react-bootstrap";
import { Footer, Header } from ".";

export default function Error404() {
  return (
    <div className="page-padding">
      <Header />
      <div className="user-search-wrapper container">
        <SearchDropDown
          classes="search-border"
          // dropdown={false}
          placeholder="Search"
        />
      </div>
      <FilterTrend classes="social-media-filter" />
      <div className="container">
        <div className="erro-image-wrapper text-center pb-60-40">
          <Image
            src={errorImage}
            alt="Error 404"
            className="error-image  mx-auto mw-698"
          />
          <h4 className="lato-bold fs-32-24 primary text-center">Error 404</h4>
          <h4 className="lato-bold fs-32-24 primary text-center"> Looks like you wandered into a digital maze.</h4>
        </div>
      </div>
      <Footer />
    </div>
  );
}
