import React, { useEffect } from 'react'
import { useState } from 'react';
import { Header, Footer } from '../common/pages';
import searcIcon from "../../assets/images/search.svg";
import settingBlack from "../../assets/images/settings-black.svg";
import replayBlack from "../../assets/images/replay-black.svg";
import instagram from "../../assets/images/instagrap-Bold.svg";
import facebook from "../../assets/images/facebook-bold.svg";
import linkding from "../../assets/images/linkding-bold.svg";
import twiter from "../../assets/images/twiter-bold.svg";
import instagramIcon from "../../assets/images/Instagram-color.svg";
import facebookIcon from "../../assets/images/facebook-color.svg";
import linkdingIcon from "../../assets/images/linedin-icon.svg";
import twiterIcon from "../../assets/images/twitter-color.svg";
import DownIcon from "../../assets/images/dropdown-icon.svg";
import Hashtag from "../../assets/images/hashtag-icon.svg";
import { Dropdown, Image, Tab, Tabs } from 'react-bootstrap';
import { LinkedInEmbed, TwitterEmbed, FacebookEmbed, InstagramEmbed } from 'react-social-media-embed';
import { SearchDropDown, Trending } from '../common';
import { ContentTypesCards } from './_utils';
import { getCommonApi, getSearchAllDataApi } from './Api';
import { API_LIMIT, START_PAGE } from '../../utils/Constant';
import { AllShimmer } from '../common/components';

// const links = [
//   "https://twitter.com/abslmf/status/1731666800826360024?ref_src=twsrc%5Etfw",
//   "https://twitter.com/EdelweissMF/status/1731543202665312553?ref_src=twsrc%5Etfw",
//   "https://www.instagram.com/p/C0a2IABSDBD/?utm_source=ig_embed&amp;utm_campaign=loading",
//   "https://www.instagram.com/p/C0bQKPUSUGR/?utm_source=ig_embed&amp;utm_campaign=loading",
//   "https://www.linkedin.com/embed/feed/update/urn:li:share:7137078102710534144",
//   "https://twitter.com/TataMutualFund/status/1740382440651375090?ref_src=twsrc%5Etfw",
//   "https://twitter.com/amfiindia/status/1733110480519188838?ref_src=twsrc%5Etfw",
//   "https://twitter.com/amfiindia/status/1733110480519188838?ref_src=twsrc%5Etfw",
//   "https://www.instagram.com/p/C03GZrcs2DO/?utm_source=ig_embed&amp;utm_campaign=loading",
//   "https://www.linkedin.com/embed/feed/update/urn:li:share:7145676083957346304",
//   "https://www.linkedin.com/embed/feed/update/urn:li:share:7146110147831005184"
// ]
const feeds = [
  { icon: twiter, text: "x", icon2: twiterIcon },
  { icon: instagram, text: "INSTAGRAM", icon2: instagramIcon },
  { icon: facebook, text: "Facebook", icon2: facebookIcon },
  // { icon: linkding, text: "LinkedIn", icon2: linkdingIcon },

]
export default function TrendingPage() {
  const [selectedKeys, setSelectedKeys] = useState("");
  return (
    <section className='page-padding'>
      <Header />
      <div className="container">
        {/* <div className="treandign-search">
                    <input
                        type="text"
                        className='lato-semibold fs-18-16 input-search-box black-242'
                        placeholder='Search' />
                    <Image src={searcIcon} className='' alt="icon" />
                </div> */}

        <SearchDropDown getKeys={(keys) => setSelectedKeys(keys)} classes="search-border trend-serch" />

        <h3 className='lato-light fs-48-32 black-242 mb-8'>What's
          <span className='lato-bold'>Trending</span>
          !</h3>
        <p className='lato-regular fs-18-14 black-242 op-5'>The insights you need to keep ahead in the game.</p>
        <div className="treanding-tab-wrapper">
          {/* <div className="settinng-reset-btn">
                        <Image src={replayBlack} alt='icon' />
                        <Image src={settingBlack} alt='icon' />
                    </div> */}
          <Tabs
            defaultActiveKey="TheFinpedia"
            class="trending-tabs"
            id="uncontrolled-tab-example">
            <Tab
              eventKey="TheFinpedia"
              class='lato-regular fs-18-14 black-242'
              title="TheFinpedia">
              <TheFinPedia />
            </Tab>
            <Tab eventKey="Around the Globe" title="Around the Globe">
              <AroundGlobe />
            </Tab>
          </Tabs>
        </div>
      </div>
      <Footer />
    </section>

  )
}

function TheFinPedia() {
  const keysValue = ["SIP", "MAF", "BAF", "Volatility", "STP", "SWP", "ELSS", "Financial Planning"];
  const [listData, setListData] = useState()
  const [isLoading, setIsLoading] = useState(true);
  const [keys, setKeys] = useState([])
  useEffect(async () => {
    const info = await getCommonApi(`content/search?contentType=&keyword=${keysValue.join(',')}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}&sortBy=most%20recent`);
    if (info.status === 200) {
      setListData(info?.data?.data?.contents);
      setIsLoading(false)
    }
    const keysValues = JSON.parse(sessionStorage.getItem("trendingKeywords"));
    setKeys(keysValues);

  }, [])
  const [data, setData] = useState([])
  return (
    <div className="">
      <div className="treanding-one-wrapper gap-12 ">
        {
          keys?.map((item, i) => {
            // console.log(item)
            return (
              <Trending key={i} title={item?.trendingKeyword} classes="video-trend-border lh-20 fs-14-10 lato-medium black-242 " image={Hashtag} />
            )
          })
        }

      </div>

      {
        isLoading ?
          <AllShimmer type='All' noContainer={true} serchHide={false} />
          : (
            listData?.map((item, j) => {
              return (
                <ContentTypesCards
                  key={j}
                  countTitle={item?.title}
                  hide={true}
                  data={item?.list}
                  count={item?.count}
                  typeId={item?.uuid}
                  // suggestionData="Suggested"
                  code={item?.code}
                />
              );
            })
          )
      }
    </div>
  )
}



function AroundGlobe() {
  const dateList = ["Today", 'Yesterday', 'Last 7 days', 'Last 30 days', 'This Month']
  const [selected, setSelected] = useState([]);
  const [links, setLinks] = useState([]);
  const [socialLink, setSocialLink] = useState([])
  useEffect(async () => {
    const res = await getCommonApi('website/trending');
    setLinks(res?.data?.data?.list)
    setSocialLink(res?.data?.data?.list)
    // console.log(res)
  }, []);

  useEffect(() => {
    // console.log(selected)
    let filteredLinks = socialLink.filter(obj => selected?.includes(obj.type.toLowerCase()));
    if (selected.length === 0) {
      setLinks(socialLink)
    } else {
      setLinks(filteredLinks);
    }
    if (filteredLinks.length > 0) {
      // setLinks(filteredLinks);
    } else {
      // setLinks(socialLink)
    }

  }, [selected])
  function activeHandler(item) {
    // console.log(item)
    if (selected.includes(item)) {
      let filter = selected.filter((ele => ele !== item))
      setSelected(filter)
    } else {
      setSelected([...selected, item])
    }
  }


  return (
    <div className="">
      <div className="around-the-globe-wrapper">
        <div className="feeds-wrapper">
          <p className='lato-regular black-242 op-6  fs-18-16 desk-show'>Feeds :</p>
          <h4 onClick={() => {
            selected.length === 4 ? setSelected([]) :
              setSelected([
                "instagram",
                "facebook",
                // "linkedin",
                "x"
              ])
          }} className={`Social-media-frame fs-14-12 lato-bold black-242  ${selected.length === 4 ? "active-feed" : 'bg-white'}`}>
            All
          </h4>
          <div className="vertial-line"></div>
          {
            feeds?.map((item, i) => {
              //active-feed
              return (
                <h4 key={i} onClick={() => activeHandler(item.text.toLowerCase())} className={`Social-media-frame fs-14-12 lato-bold black-242 ${selected?.includes(item.text.toLowerCase()) ? "active-feed" : ''} `}>
                  <Image src={item.icon} alt='icon' className='' />
                  {/* <Image src={item.icon2} alt='icon' className='mb-show' /> */}
                  <span className='desk-show'>  {item.text}</span>

                </h4>
              )
            })
          }

        </div>
        {/* <div className={`dropdown-main-wrapper `}>
          <Dropdown>
            <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
              <h6 className="lato-semibold fx-14 black-242">Filter by date</h6>
              <Image src={DownIcon} alt="image" />
            </Dropdown.Toggle>

            <Dropdown.Menu className="show-filter-dropdown">
              {dateList?.map((item, i) => {
                return (
                  <Dropdown.Item key={i} className="lato-regular black-242 fx-14" href=''>{item}</Dropdown.Item>
                )
              })}
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </div>
      <div class="grid-container">
        {links.length > 0 ? links?.map((item, i) => {
          if (item.type.toLowerCase().includes('linkedin')) {
            return (
              <div className="embeded-wrapper ">
                <LinkedInEmbed url={item.link} width={425}
                />
              </div>
            )
          } else if (item.type.toLowerCase().includes('x')) {
            return (
              <div className="embeded-wrapper twiter-iframe">
                <TwitterEmbed url={item.link} width={425} />
              </div>
            )
          } else if (item.type.toLowerCase().includes('instagram')) {
            return (
              <div className="embeded-wrapper ">
                <InstagramEmbed url={item.link} width={425} />
              </div>
            )
          } else if (item.type.toLowerCase().includes('facebook')) {
            return (
              <div className="embeded-wrapper ">
                <FacebookEmbed url={item.link} width={425} />
              </div>
            )
          }

        }) :
          <h3 className=' text-center py-5 lato-bold w-100   '>Result is Not found</h3>


        }
      </div>
    </div>
  )
}