// import Home from "./app/home/Home";
import { Route } from 'react-router-dom';
import Sandbox from './utils/form/Sandbox';
import PrivateRoute from './utils/PrivateRoute';
import { Authentication, OnBoarding, Verify } from './app/onBoarding';
import { ProfileDetails, MyCollections, MySubscription, MyActivity, CollectionDetails, PricingPlan } from './app/profile';
import { CoBranding, Error404, EventDetails, EventPage, Home, SearchBasedLayout, SocialMedia, SocialMediaContent, VideoDetails, ContentResult, AmcListeningPage, AmcDetails, ContactUs, TrendingPage, CoBrandingV2 } from './app/home';
import ResetPassword from './app/onBoarding/ResetPassword';
import { EVENTS, TARGETEDCONTENT } from './utils/Constant';
import { TargetedContent } from './app/home/staticComponents';
import { NotificationPage, PrivacyPolicy, TermsConditions } from './app/common/pages';
import { CheckOutPage } from './app/common';
import { CalculatorPage, ToolsPage } from './app/tools';
import { EventCheckOut, MyEvents } from './app/events';
import { Aboutus } from './app/aboutus';
import RedirectionPage from './app/common/pages/RedirectionPage';

const routes = [
  {
    path: ["/onboarding", "/onboarding/:token"],
    name: "OnBoarding",
    type: Route,
    component: OnBoarding
  },

  {
    path: "/auth",
    name: "Authentication",
    type: Route,
    component: Authentication
  },
  {
    path: "/verify/:token",
    name: "Verify",
    type: Route,
    component: Verify
  },
  {
    path: "/",
    name: "Home",
    type: Route,
    component: Home
  },
  {
    path: "/tools",
    name: "ToolsPage",
    type: Route,
    component: ToolsPage
  },
  {
    path: "/tools/calculator/:name",
    name: "ToolsPage",
    type: Route,
    component: CalculatorPage
  },
  {
    path: ["/content-type/:type", "/search/:type", "/social-media/:type"],
    name: "ContentResult",
    type: Route,
    component: ContentResult
  },
  {
    path: "/social-media",
    name: "SocialMedia",
    type: Route,
    component: SocialMedia
  },
  {
    path: [`/${TARGETEDCONTENT}`, `/${TARGETEDCONTENT}/filter`],
    name: "TargetedContent",
    type: Route,
    component: TargetedContent
  },
  {
    path: "/membership-plans",
    name: "PricingPlan",
    type: Route,
    component: PricingPlan
  },
  {
    path: `/${EVENTS}`,
    name: "EventPage",
    type: Route,
    component: EventPage
  },
  {
    path: `/my-events`,
    name: "MyEvents",
    type: Route,
    component: MyEvents
  },
  {
    path: `/notification`,
    name: "NotificationPage",
    type: Route,
    component: NotificationPage
  },
  {
    path: "/privacy-policy",
    name: "PrivacyPolicy",
    type: Route,
    component: PrivacyPolicy
  },
  {
    path: "/terms-conditions",
    name: "TermsConditions",
    type: Route,
    component: TermsConditions
  },
  {
    path: "/events-details/:id",
    name: "EventDetails",
    type: Route,
    component: EventDetails
  },
  {
    path: "/about-us",
    name: "Aboutus",
    type: Route,
    component: Aboutus
  },
  {
    path: "/event-chekout",
    name: "EventCheckOut",
    type: Route,
    component: EventCheckOut
  },
  {
    path: "/mycollections",
    name: "MyCollections",
    type: PrivateRoute,
    component: MyCollections
  },

  {
    path: "/details/:type",
    name: "videodetails",
    type: Route,
    component: VideoDetails
  },
  {
    path: "/cobranding",
    name: "CoBranding",
    type: Route,
    // component: CoBranding
    component: CoBrandingV2
  },
  {
    path: "/cobranding-v2",
    name: "CoBrandingV2",
    type: Route,
    component: CoBrandingV2
  },
  {
    path: "/mymembership",
    name: "MySubscription",
    type: PrivateRoute,
    component: MySubscription
  },
  {
    path: "/collection-details/:id",
    name: "CollectionDetails",
    type: PrivateRoute,
    component: CollectionDetails
  },
  {
    path: "/myactivity/:type",
    name: "MyActivity",
    type: PrivateRoute,
    component: MyActivity
  },
  {
    path: "/profile-details",
    name: "ProfileDetails",
    type: PrivateRoute,
    component: ProfileDetails
  },
  {
    path: "/search-based/:type",
    name: "SearchBasedLayout",
    type: Route,
    component: SearchBasedLayout
  },
  {
    path: "/collection-type/:type",
    name: "AmcListeningPage",
    type: Route,
    component: AmcListeningPage
  },
  {
    path: "/contact",
    name: "ContactUs",
    type: Route,
    component: ContactUs
  },
  {
    path: "/checkout",
    name: "CheckOutPage",
    type: PrivateRoute,
    component: CheckOutPage
  },
  {
    path: "/trending",
    name: "TrendingPage",
    type: Route,
    component: TrendingPage
  },
  {
    path: ["/facebook/redirect", "/instagram/redirect"],
    name: "TrendingPage",
    type: Route,
    component: RedirectionPage
  },
  {
    path: "/*",
    name: "Error404",
    type: Route,
    component: Error404
  },
  {
    path: "/sandbox",
    name: "Sandox",
    type: Route,
    component: Sandbox
  },


];
export default routes