import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { Footer, Header } from './pages';
import { BreadCrumb } from '../home/_utils';
import CancelImage from '../../assets/images/cancle-input.svg'
import logo from '../../assets/images/brand-logo.png'
import coupon from '../../assets/images/coupon-ticket.svg'
import { BaseReactComponent, CustomTextControl, Form, FormElement, FormSubmitButton, FormValidator, SelectControl } from '../../utils/form';
import { Button, Col, Image, Row } from 'react-bootstrap';
import { getUser, reUpdatedUserDetails } from '../../utils/ReusableFunctions';
import { purchasePlanApi, commonAuthApis, upgradePlanApi } from '../profile/Api';
import { SHARE_RAZ_KEY, loadScript } from '../../utils/Constant';
import { toast } from 'react-toastify';
import axios from 'axios';
import { getToken } from '../../utils/ManageToken';
import { getAuthDataApi } from '../home/Api';
import { Spinner } from './components';

class CheckOutPage extends BaseReactComponent {
  constructor(props) {
    super(props);
    const userDetails = getUser() || {};
    const breakup = props.location.state.data || null;
    const requestObj = props.location.state.requestObj || null;
    // console.log("Exits plans -",requestObj,requestObj?.data.newPlanUuid?"Upgrade":"Process");
    const isNewPurchase = props.location.state.isNewPurchase || null;
    this.state = {
      userDetails,
      firstName: userDetails?.firstName || null,
      lastName: userDetails?.lastName || null,
      // name: userDetails?.firstName + " " + userDetails?.lastName || "",
      companyName: userDetails?.companyName || null,
      emailAddress: userDetails?.emailAddress || null,
      arnNumber: userDetails?.arnNumber || null,
      city: userDetails?.city || null,
      state: userDetails?.state || null,
      pincode: userDetails?.pincode || null,
      mobileNumber: userDetails?.mobileNumber || null,
      breakup,
      requestObj,
      isNewPurchase,
      gstCoupon: true,
      couponCode: true,
      couponApplied: false,
      coupon: "",
      allStateData: [],
      allCityData: [],
      isUpdataing: false,
    }
  }
  getDataHandler = async (url) => {
    const data = await getAuthDataApi(url);
    return data;
  }
  componentDidMount() {
    loadScript("https://checkout.razorpay.com/v1/checkout.js");
    // console.log(window)

    this.getDataHandler("/website/location")
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allStateData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
    if (this.state.state) {
      this.getCity();
    }
  }
  getCity = () => {
    this.getDataHandler(`/website/location/${this.state.state}`)
      .then((data) => {
        if (Array.isArray(data)) {
          this.setState({ allCityData: data });
        }
      })
      .catch((error) => {
        // console.error("Error fetching data:", error);
      });
  }


  isUpdated = (name) => {
    const { userDetails } = this.state;
    return userDetails[name] !== this.state[name];
  }
  onValidSubmit = async (e) => {
    // e.preventDefault();
    this.setState({ isUpdataing: true });
    const { firstName, lastName, companyName, arnNumber, emailAddress, city, state, pincode, mobileNumber } = this.state;
    const userInfo = {
      data: {
        ...(this.isUpdated("firstName") ? { firstName } : {}),
        ...(this.isUpdated("lastName") ? { lastName } : {}),
        ...(this.isUpdated("companyName") ? { companyName } : {}),
        ...(this.isUpdated("emailAddress") ? { emailAddress } : {}),
        ...(this.isUpdated("arnNumber") ? { arnNumber } : {}),
        ...(this.isUpdated("city") ? { city } : {}),
        ...(this.isUpdated("state") ? { state } : {}),
        ...(this.isUpdated("pincode") ? { pincode } : {}),
        ...(this.isUpdated("mobileNumber") ? { mobileNumber } : {}),
      }
    }
    console.table(Object.keys(userInfo.data).length)
    if ((Object.keys(userInfo.data).length) > 0) {
      // console.log(this.state.breakup.breakup.finalAmount);
      const res = await commonAuthApis("user/update-profile", userInfo, "put");
      localStorage.setItem('finUserDetails', JSON.stringify(res?.data?.data));
      if (res.status >= 200 && res.status <= 210) {
        this.reaxorpayHandler()
      }
    } else {
      this.reaxorpayHandler();

    }
  }
  reaxorpayHandler = async () => {
    // console.log(window)
    let orderData = {
      "data": {
        "amount": this.state.breakup.breakup.finalAmount * 100,
        "currency": "INR",
        "receipt": "Finpedia"
      }
    }
    const res = await commonAuthApis('payment/order', orderData);
    this.setState({ isUpdataing: false });
    // console.log(res)
    if (res.status >= 200 && res.status <= 210) {
      // toast.success('Order is Recived...')
      const self = this;

      var options = {
        "key": SHARE_RAZ_KEY, // Enter the Key ID generated from the Dashboard
        "amount": res?.data?.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
        "currency": res?.data?.data.currency,
        "name": "Thefinpedia",
        "description": "Test Transaction",
        "image": logo,
        "order_id": res?.data?.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
        // "callback_url": "http://13.200.83.191:3001/finpedia/v1/payment/checkout",
        "handler": async function (response) {
          const res = await commonAuthApis('payment/checkout', { "data": response });
          // console.log(res)
          if (res.status >= 200 && res.status <= 210) {
            toast.success('payment successful')
            const planAcitve = {
              "data": {
                "planUuid": self.state.requestObj?.data.newPlanUuid || self.state.requestObj?.data.planUuid,
                ...(self.state.coupon ? { "couponCode": self.state.coupon } : {}),
              }
            }
            const UpgradePlan = {
              "data": {
                "newPlanUuid": self.state.requestObj?.data.newPlanUuid || self.state.requestObj?.data.planUuid,
              }
            }
            const userBellingHandler = async (id) => {
              const isUpgrade = self.state.requestObj?.data?.userBillingPlanUuid ? true : false;
              const newId = self.state.requestObj?.data?.userBillingPlanUuid;
              const res2 = await commonAuthApis(`user/billing-plan/${isUpgrade ? newId : id}/${isUpgrade ? 'upgrade' : 'process'}`, isUpgrade ? UpgradePlan : planAcitve, isUpgrade ? "put" : "post");
              // console.log("Sucessfull apply coupon",res2)
              if (res2.status >= 200 && res2.status <= 210) {
                if (res2.data.data?.newBillingDetails?.uuid ?? false) {
                  const res3 = await commonAuthApis(`user/billing-plan/${res2.data.data?.newBillingDetails?.uuid}/process`);
                  if (res2.status >= 200 && res2.status <= 210) {
                    sessionStorage.setItem('finSubscriptionDone', true);
                    // console.log("Last Responce")
                    reUpdatedUserDetails()
                  }
                  self.props.history.push('/mymembership')
                } else {
                  sessionStorage.setItem('finSubscriptionDone', true);
                  reUpdatedUserDetails()
                  self.props.history.push('/mymembership')
                }




              }
            }

            if (self.state.requestObj.data.userBillingPlanUuid) {
              userBellingHandler();
            } else {
              const res = await commonAuthApis('user/billing-plan', planAcitve);
              if (res.status >= 200 && res.status <= 210) {
                userBellingHandler(res?.data?.data.uuid);
              }
            }


          }
        },
        "prefill": {
          "name": this.state.name,
          "email": this.state.emailAddress,
          "contact": this.state?.mobileNumber
        },
        "notes": {
          "address": "Razorpay Corporate Office"
        },
        "theme": {
          "color": "#3399cc"
        }
      };
      let rzp1 = window.Razorpay(options);
      rzp1.open();
    }
  }

  aplyCouponHandler = async (remove) => {
    // console.log("cHECK-PARIMTER",remove)
    const coponData = {
      "data": {
        "planUuid": this.state.requestObj.data.newPlanUuid || this.state.requestObj.data.planUuid,
        ...(remove ? {} : { "couponCode": this.state.coupon })

      }
    }
    // console.log("This api===>", this.state.requestObj.data.newPlanUuid)
    const res = await commonAuthApis('user/billing-plan/purchase-amount-breakup', coponData);
    // console.log(res)
    if (res.status === 200) {
      this.setState({ couponApplied: true, breakup: res?.data?.data });
    }
  }
  render() {
    const { couponCode, gstCoupon, breakup } = this.state;
    // console.log(breakup)
    return (
      <section className='checkout-section'>
        {/* <Header /> */}
        <div className="checkout-main-wrapper">
          {/* <div className="container"> */}
          <BreadCrumb path={['My Membership', 'Checkout']} />
          <Form onValidSubmit={this.onValidSubmit}>
            <div className="checkout-frame">
              <div className="billing-information w-51">
                <h4 className='lato-regular fs-32-24 black-242'>Billing Information</h4>

                <Row>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "firstName")}
                      label="Full Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter First Name"
                        },
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "lastName")}
                      label="Last Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter Last Name"
                        },
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "emailAddress")}
                      label="Email Address"
                      validations={[
                        {
                          validate: FormValidator.isEmail,
                          message: "Please enter a valid email",
                        },
                      ]}
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter email address",
                        },
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "companyName")}
                      label="Company Name"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          // options: this.state.CompanyName,
                          // multiple: false,
                          searchable: true,
                          placeholder: "Enter company name",
                          // onChangeCallback: (onBlur) => {
                          //     onBlur(this.state.companyName);
                          // }
                        }
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "arnNumber")}
                      label="ARN/RAI Number"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter arn/rai number",
                        }
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "gstNo")}
                      label="GST Number"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter GST number",
                        }
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "state")}
                      label="State"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allStateData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select State",
                          onChangeCallback: (onBlur) => {
                            this.setState({ city: "" }, () => {
                              this.getCity()
                            })

                            onBlur(this.state.state);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "city")}
                      label="City"
                      control={{
                        type: SelectControl,
                        settings: {
                          options: this.state.allCityData?.map((item, i) => { return ({ "value": item, "label": item }) }),
                          multiple: false,
                          searchable: true,
                          placeholder: "Select City",
                          onChangeCallback: (onBlur) => {
                            onBlur(this.state.city);
                          }
                        }
                      }}
                    />
                  </Col>
                  <Col md={6}>
                    <FormElement
                      valueLink={this.linkState(this, "pincode")}
                      label="Pincode"
                      control={{
                        type: CustomTextControl,
                        settings: {
                          type: "text",
                          disabled: false,
                          placeholder: "Enter pincode",
                        }
                      }}
                      classes={{
                        inputField: "form-control",
                        label: "form-label-custom"
                      }}
                    />
                  </Col>
                </Row>

              </div>
              <div className="order-details w-51">
                <div className="billing-information">
                  <h4 className='lato-regular fs-32-24 black-242'>Order Details</h4>
                </div>
                <ul className='order-detail-ul'>
                  <li >
                    <p className='lato-semibold fs-18-14 black-242'>Amount</p>
                    <p className='lato-bold fs-18-14 black-242'>{breakup.breakup.planDiscountedAmount ? <><s>₹ {breakup.breakup.planPrice}</s>&nbsp;&nbsp;   ₹ {breakup.breakup.planDiscountedAmount}</> : `₹${breakup.breakup.planPrice}`}</p>
                  </li>
                  {/* {breakup.breakup?.planDiscountedAmount > 0 &&
                    <li >
                      <p className='lato-semibold fs-18-14 black-242'>Discount</p>
                      <p className='lato-bold fs-18-14 black-242'>- ₹ {breakup.breakup.planPrice - breakup.breakup.planDiscountedAmount}</p>
                    </li>
                  } */}
                  {/* {console.log("breakup?.breakup?.balanceAmount",breakup?.breakup?.balanceAmount)} */}
                  {breakup?.breakup?.balanceAmount && <li className=''>
                    <p className='lato-semibold fs-18-14 black-242'>Balance Amount</p>
                    <p className='lato-bold fs-18-14 black-242'>- ₹ {breakup?.breakup?.balanceAmount}</p>
                  </li>}
                  {breakup?.breakup?.couponDiscountedAmount && <li className=''>
                    <p className='lato-semibold fs-18-14 black-242'>Coupon Discount</p>
                    <p className='lato-bold fs-18-14 black-242'>- ₹ {breakup?.breakup?.couponDiscountedAmount}</p>
                  </li>}
                  {/* <li className='lato-normal f-s-12 lh-16 primary pointer'> {gstCoupon ? <p onClick={() => this.setState({ gstCoupon: false })} >Do you have a GST number?</p> :
                  <div className="coupon-box">
                    <input type="text" className='lato-regular fs-14-11 coupon-input' placeholder='Add GST Number' />
                    <Button className='primary-btn py-2'>Apply</Button>
                  </div>
                }
                </li> */}

                  <li className='mb-0'>
                    <p className='lato-semibold fs-18-14 black-242'>Total</p>
                    <p className='lato-bold fs-18-14 black-242'>₹ {breakup.breakup.finalAmount}</p>
                  </li>
                  {
                    !this.state.requestObj.data.userBillingPlanUuid &&
                    <li className='lato-normal f-s-12 lh-16 primary pointer'>
                      {couponCode ?
                        <p onClick={() => this.setState({ couponCode: false, couponApplied: false })} >Do you have a coupon code?</p>
                        : (this.state.couponApplied) ?
                          <div className="coupon-aplay">
                            <p className='lato-regular fs-14-13 primary d-flex align-items-center gap-12'>
                              <Image src={coupon} alt='' /> Code: {this.state.coupon}
                            </p>
                            <Image src={CancelImage} onClick={() => { this.setState({ couponApplied: false, couponCode: true, coupon: "" }); this.aplyCouponHandler("remove") }} alt='' />
                          </div>
                          :
                          <div className="coupon-box">
                            <input type="text" className='lato-regular fs-14-11 coupon-input w-100' onChange={(e) => this.setState({ coupon: e.target.value })} name='coupon' value={this.state.coupon} placeholder='Add Coupon Code' />
                            <Image src={CancelImage} onClick={() => this.state.coupon ? this.setState({ coupon: "" }) : this.setState({ couponCode: true })} alt="cancle" />
                            <Button onClick={() => this.aplyCouponHandler("")} className='primary-btn '>Apply</Button>
                          </div>

                      } </li>
                  }

                  <li className='confirm-pay-btn'>
                    <FormSubmitButton customClass={"mt-3 primary-btn fx-14 w-100"}>
                      {this.state.isUpdataing ? <span className=' d-flex align-items-center gap-12 justify-content-center'><Spinner /> Loading...</span> : 'Confirm and Pay'}
                    </FormSubmitButton>
                    {/* <Button onClick={this.handlePay} className=" mt-3 primary-btn fx-14 w-100"> </Button> */}
                  </li>
                </ul>
                <p className='lato-regular f-s-10 lh-12 black-05 '>Cancel your subscription anytime from your subscription settings.
                  By clicking "Confirm and pay" you agree to the <span className='primary '>Terms and Conditions.</span> </p>
              </div>

            </div>
          </Form>
          {/* </div> */}
        </div>
        {/* <Footer /> */}
      </section>
    )
  }
}

const mapStateToProps = state => ({ profileState: state.ProfileState });
const mapDispatchToProps = {
  // getPosts: fetchPosts
}
CheckOutPage.propTypes = {
  // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckOutPage);