// import React, { Component } from "react";
// import { SlideFilter } from "./staticComponents";
// import { AMC, API_LIMIT, MAX_LIMIT, SOCIALMEDIA, SOCIALMEDIA_URL, START_PAGE, imageClass } from "../../utils/Constant";
// import { getAuthDataApi, getCommonApi, getDataApi, getDetailsApi, getSearchAllDataApi } from "./Api";
// import {
//   calculateTotalPageCount,
//   getSearchParams,
// } from "../../utils/ReusableFunctions";
// import { SocialMedia } from "../socialMedia";
// import { Header, Footer, Pagination, NotFound } from "../common/pages";
// import { AmcCard, ContentTypesCards } from "./_utils";
// import { AllShimmer, CardHeader, SkeletonCard } from "../common/components";
// import AmcListeningPage from "./AmcListeningPage";
// import { toast } from "react-toastify";
// import { withRouter } from "react-router-dom/cjs/react-router-dom";

// export class ContentResult extends Component {
//   constructor(props) {
//     super(props);
//     const { type } = props.match.params;
//     console.log("Check type for amc 0", type);
//     let searchParams = getSearchParams(props.location);
//     this.state = {
//       data: [],
//       isLoading: true,
//       // pageType: social.includes('/social-media') ? "social-media" : "content",
//       type: type === "All" ? "" : type?.replace("_", "/"),
//       currentPage: searchParams.page
//         ? parseInt(searchParams.page, 10)
//         : START_PAGE,
//     };
//   }
//   async componentDidMount() {
//     // let social = this.props.location.pathname;
//     // const { type, pageType } = this.state;
//     const type = this.props.match.params.type;
//     console.log("Check type for amc 1", type);
//     // let searchParams = getSearchParams(this.props.location);
//     const params = new URLSearchParams(this.props.location.search);
//     console.log("collection-type-id", params.get("collection-type-id"));
//     console.log("collection-type", params.get("collection-type"));
//     let searchParams = this.props.location.search;
//     let isMedia = this.props.match.url.includes('/social-media/');
//     // console.log('type', type);
//     if (type === SOCIALMEDIA) {
//       let data = await getDataApi(SOCIALMEDIA_URL);
//       this.setState({ data, isLoading: false });
//     }
//     else if (type === "All" && params.get("collection-type") === AMC) {
//       console.log("Amc Details api call");
//       const res = await getDetailsApi(`/content/amcDetails/${params.get("collection-type-id")}`);
//       this.setState({ data:res?.data?.data, isLoading: false });
//     }

//     else {
//       console.log("1st All data  api call");
//       const data = await getSearchAllDataApi((isMedia) ? "" : (type === "All") ? "" : type?.replace("_", "/"), searchParams, (isMedia) ? type : "");
//       this.setState({ data, isLoading: false });
//       // console.log("Test it");
//       // console.log(data);
//     }
//   }
//   async componentDidUpdate(prevProps, prevState) {
//     const type = this.props.match.params.type;
//     console.log("Check type for amc 3", type);
//     const currentParams = this.props.location.search;
//     const prevParams = prevProps.location.search;
//     const params = new URLSearchParams(this.props.location.search);
//     let page = params.get("pageNumber") || START_PAGE;

//     // console.log("collection-type-id", params.get("collection-type-id"));
//     // console.log("collection-type", params.get("collection-type"));
//     // console.log("currentParams", currentParams);
//     // console.log("prevParams", prevParams);
//     // console.log(type !== prevProps.match.params.type)
//     // if (type === SOCIALMEDIA) {

//     // } else {
//     if (currentParams !== prevParams || type !== prevProps.match.params.type) {
//       // console.log('Heyaaaa');
//       // console.log("2st api call");
//       if (type === SOCIALMEDIA) {
//         let data = await getDataApi(SOCIALMEDIA_URL);
//         this.setState({ data, isLoading: false });
//       }
//       else if (type === "All" && params.get("collection-type") === AMC) {
//         console.log("Amc Details Data")
//         const data = await getDetailsApi(`/content/amcDetails/${params.get("collection-type-id")}`);
//         console.log(data.status)
//         this.setState({ data:data.data.data, isLoading: false,type });

//       }

//       else {
//         // console.log(this.props.match.url);
//         console.log("All serch data")
//         let isMedia = this.props.match.url.includes('/social-media');
//         const data = await getSearchAllDataApi((isMedia) ? "" : (type === "All") ? "" : type?.replace("_", "/"), currentParams, (isMedia) ? type : "");

//         // const data = await getSearchAllDataApi(
//         //   type === "All" ? "" : type?.replace("_", "/"),
//         //   currentParams
//         // );
//         // console.log("Test it2");
//         // console.log(data);
//         this.setState({
//           data,
//           type: type,
//           currentPage: page,
//           isLoading: false
//         });
//       }
//     }
//   }

//   renderComponent = () => {
//     const type = this.props.match.params.type;
//     const { data } = this.state;
//     console.log("Not getting data why ------------",(data?.length > 0 && type !== "amc"),"type=",type,"chck data",data.length>0,)
//     console.log("Check amc details data ===",data);
//     if (type === SOCIALMEDIA) {
//       return <SocialMedia data={data && data} />;
//     } else if (data?.length > 0 && type !== "amc") {
//       return data?.map((item, j) => {
//         if (item.list?.length > 0) {
//           return (
//             <ContentTypesCards
//               key={j}
//               countTitle={item?.title}
//               hide={data.length === 1 ? false : true}
//               wrapperadd={data.length === 1 ? "result-wrap" : ""}
//               data={item.list}
//               count={item.count}
//               typeId={item.uuid}
//             />
//           );
//         }
//         return null;
//       });
//     } else {
//       return <div><NotFound /></div>;
//     }
//   };
//   render() {
//     const { history, location,match } = this.props;
//     const { type, data, currentPage, isLoading } = this.state;
//     let searchParams = getSearchParams(this.props.location);
//     const totalPage = calculateTotalPageCount(data && data[0]?.count);
//     return (
//       <div className="content-result-page page-padding-filter">
//         <Header />
//         <>
//           {isLoading ? (
//             <AllShimmer type={type === AMC ? null : type} />
//           ) :
//             <SlideFilter
//               dataLength={data && data.length}
//               component={this.renderComponent()}
//             />
//           }
//           {(data[0]?.count > API_LIMIT) && data.length < 2 && (
//             <Pagination
//               history={history}
//               location={location}
//               page={currentPage}
//               pageCount={totalPage}
//             />
//           )}
//         </>
//         <Footer />
//       </div>
//     );
//   }
// }

// export default withRouter(ContentResult);
import React, { useState } from "react";
import { useEffect } from 'react';

import { useHistory, useLocation, useParams } from "react-router-dom";
import {
  AMC,
  API_LIMIT,
  MUTUAL_FUND_COMPANY,
  SOCIALMEDIA,
  SOCIALMEDIA_URL,
  START_PAGE,
  TARGETEDCONTENT,
  socialMedias,
} from "../../utils/Constant";
import {
  getAuthDataApi,
  getCommonApi,
  getDataApi,
  getDetailsApi,
  getSearchAllDataApi,
} from "./Api";
import {
  calculateTotalPageCount,
  formatNumber,
  getSearchParams,
} from "../../utils/ReusableFunctions";
import { SocialMedia } from "../socialMedia";
import { Header, Footer, Pagination, NotFound } from "../common/pages";
import { ContentTypesCards } from "./_utils";
import defaultImage from "../../assets/images/Infographic-thumbnail.jpg";
import BlackDownload from "../../assets/images/download-white.svg";
import BlackShare from "../../assets/images/black-share.svg";
import save2 from "../../assets/images/save-filed.svg";
import { toast } from "react-toastify";
import { SlideFilter, TargetedContent } from "./staticComponents";
import { AllShimmer, SkeletonCard, CardHeader } from "../common/components";
import { Image } from "react-bootstrap";
import defaultIcon from "../../assets/images/default-grey.png";
const validValues = [
  "AMC",
  'amc',
  "category",
  "campaigns",
  "languages",
  AMC,
  "targetedContents",
 
];
function ContentResult() {
  const history = useHistory();
  const { type } = useParams();
  const location = useLocation();
  const getParams = getSearchParams(location);
  const params = new URLSearchParams(location.search);
  // const type = props.match.params.type;
  // console.log(location);
  // console.log(type);
  // console.log(getParams);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [isSubLoading, setIsSubLoading] = useState(true);
  const [searchData, setSearchData] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    parseInt(getParams.page, 10) || START_PAGE
  );
  async function fetchData() {
    setIsSubLoading(true);
    const currentParams = location.search;
    const isSocialMedia = location?.pathname.includes(SOCIALMEDIA);
    const isTargetedContent = location?.pathname.includes(TARGETEDCONTENT);
    // console.log((type === "All" && params.get("collection-type") === AMC))
    // console.log("typw=", type, "collection-type:=", params.get("collection-type"))

    //  console.log(type !== TARGETEDCONTENT ,isSocialMedia)
    
    if (type !== TARGETEDCONTENT && params.get("key") === socialMedias) {
      console.log("Social-media api called",currentParams);
      const data = await getCommonApi(
        `/content/searchDetails${currentParams}${type === "All" ? "" : `&contentType=${type?.replace("_", "/")}`}${params.get("sortBy") ? `&sortBy=${params.get("sortBy")}` : ""}`
      );
      // const data = await getCommonApi(
      //   `/content/searchDetails?key=socialMedias&value=${params.get("value")}${type === "All" ? "" : `&contentType=${type?.replace("_", "/")}`}${params.get("sortBy") ? `&sortBy=${params.get("sortBy")}` : ""
      //   }&pageNumber=${params.get("pageNumber")}&pageSize=${params.get(
      //     "pageSize" 
      //   )}`
      // );
      // console.log("THis is social media api called ");
      if (data.status === 200) {
        setData(data?.data?.data || []);
        setIsLoading(false);
        setIsSubLoading(false);
        setSearchData(false);
      }
    }
    // else if (type === TARGETEDCONTENT) {
    //   const data = await getCommonApi(`/targetedContent?pageNumber=${params.get("pageNumber")}&pageSize=${params.get("pageSize")}`);
    //   if(data.status===200){
    //     setData(data?.data?.data.list || []);
    //     setIsLoading(false);
    //   }

    // }
    else if (validValues.includes(params.get("key"))) {
      // console.log('check is ContentTypeisAlrady exist',params.has('contentType'))
      const isContentType=params.has('contentType');
      if (isContentType) {
        let existingValue = params.get('contentType');
        // params.set('contentType', `${existingValue},${type}`);
        params.set('contentType', `${existingValue}`);
      }
      // console.log("AMC,Camping,language,catogary .... api called", params.toString());
     const typeAllUrl=type === "All" ? "" :(isContentType)?'': `&contentType=${type?.replace("_", "/")}`;
      // const data = await getDetailsApi( `/content/amcDetails/${params.get("key")}` );
      // const data = await getDetailsApi( `/content/searchDetails?key=${params.get("key")}&value=${params.get("name")}${type === "All" ? "" : `&contentType=${type?.replace("_", "/")}`}${ params.get("sortBy") ? `&sortBy=${params.get("sortBy")}` : ""}&pageNumber=${params.get("pageNumber")}&pageSize=${params.get( "pageSize")}`);
      const data = await getDetailsApi(
        `/content/searchDetails?${params.toString()}${typeAllUrl}` + `${(params.get('sortBy') ?? false) ? '' : `&sortBy=${(params.get('searchValue') ?? false) ? "most relevant" : 'most recent'}`}`
      );
      setData(data?.data?.data || []);
      setIsLoading(false);
      setIsSubLoading(false);
      setSearchData(false);
      // console.log("In Key ha or Not condition ", data.status, data?.data?.data?.length)
    } else {
      let isMedia = location?.pathname.includes("/social-media");
      // console.log(params.get('creator')===MUTUAL_FUND_COMPANY,'amc==',params.has('amc'))
      // console.log("chek Serch is or Not =", params.get('searchValue'), "get sorby is=", (params.get('sortBy') ?? false))
   
 
      const data = await getSearchAllDataApi(
        isMedia ? "" : type === "All" ? "" : type === "E-Book" ? 'EBook' : type?.replace("_", "/"),
        currentParams + `${(params.get('sortBy') ?? false) ? '' : `&sortBy=${(params.get('searchValue') ?? false) ? "most relevant" : 'most recent'}`}`,
        isMedia ? type : ""
      );
      // console.log("Commom search api called ", type, validValues.includes(params.get("key")), data);

      setData(data);
      setSearchData(true)

     
     setIsLoading(false);
     setIsSubLoading(false);
    }
  }
  useEffect(()=>{
    window.scroll(0, 0);
  },[getParams.page, currentPage ])
  useEffect(() => {
    window.scrollTo(0, 0);
    // console.log("UseEffect called",typeof(type),typeof(location.search),typeof(location.pathname),typeof(getParams.page))
    fetchData();
    setCurrentPage(getParams.page);
  }, [type, location.search, location.pathname, getParams.page]);
  const renderComponent = () => {

    // console.log((data?.items?.length ?? 0) > 0, (data?.length ?? 0) > 0, type !== AMC, type !== TARGETEDCONTENT)

    if (((data?.contents?.length ?? 0) > 0) && type !== AMC && type !== TARGETEDCONTENT) {
      // console.log("this want in socialMedia rells,shrablelsetc", type);
      

      return (
        <>
          {params.get("key") !== null && params.get("key") !== socialMedias && type === "All" && <AmcDetailshead type={params.get("key")?.toLowerCase()} data={data} />}
          {params.get("key") === socialMedias && (
            <div className="socialMedias-main-header">
              <h5 className="lato-regular  fs-32-24 black-242">
                {decodeURIComponent(params.get("value").replace(/\w/, (a) => a.toUpperCase()) ?? "NaN") || "Na"} &nbsp;&nbsp;&nbsp;&nbsp;
                <span className="lato-regular fs-14-12">
                  {formatNumber(data.length)}
                </span>
              </h5>
            </div>
          )}
          {/* {(Array.isArray(data) ? data : data.contents)?.map((item, j) => { */}
          {(data.contents)?.map((item, j) => {
            // console.log(j, (j+1 ) % 2)
            if (item.list?.length > 0) {
              return (
                <div>
                  <ContentTypesCards
                    key={j}
                    countTitle={item?.title}
                    hide={data?.contents?.length === 1 ? false : true}
                    // wrapperadd={data.length === 1 ? `result-wrap ${item.list?.length>=3 && "justify-content-between"}` : ""}
                    wrapperadd={data?.contents?.length === 1
                      ? `result-wrap ${item.list?.length >= 3 && ""}`
                      : ""
                    }
                    data={item.list}
                    count={item.count}
                    typeId={item?.uuid}
                    bannerAds={data?.bannerAds}
                    code={item.code}
                    isLoading={isSubLoading}
                    type={type}
                  />
                  {
                    j < data?.contents.length && data?.contents.length !== 1 &&
                    (j + 1) % 2 == 0 && Math.floor(j / 2) < 2 && data?.bannerAds && data?.bannerAds.length > 0 && data?.bannerAds?.[Math.floor((j / 2))]?.bannerImagePath &&
                    <a className="banner-main-wrapper" href={data?.bannerAds[Math.floor(j / 2)]?.redirectionLink} target="_blank">
                      {/* {console.log("hey", j,j/2,Math.floor(j / 2), data?.bannerAds)} */}
                      <Image src={data?.bannerAds[Math.floor((j / 2))]?.bannerImagePath} alt="banner Image" />
                    </a>
                  }
                </div>
              );
            }
            return null;
          })}
          {/* </div> */}
        </>
      );
    } else {
      // console.log("else last component")
      return (
        <div>
          {params.get("key") !== null && params.get("key") !== socialMedias && type === "All" ? (<>
            <AmcDetailshead type={params.get("key")} data={data} />
            {
              ((data?.contents?.length ?? 0) === 0) && <NotFound />
            }

          </>
          ) : (
            <NotFound />
          )}
          {/* <NotFound /> */}
        </div>
      );
    }
  };
  // console.log('data', data , ((data?.contents?.length)??5) );
  const searchParams = getSearchParams(location);
  const totalPage = calculateTotalPageCount(data && (((data?.contents?.length) ?? 0) !== 0) ? data?.contents[0].count : 10);
  // console.log('totalPage', totalPage);
  return (
    <div className="content-result-page page-padding-filter">
      <Header />
      <>
        {isLoading ? (
          <AllShimmer type={type === "amc" ? null : type} />
        ) : (
          <SlideFilter
            dataLength={data && ((data?.contents?.length) ?? 0)}
            component={renderComponent()}
            isFilter={type === TARGETEDCONTENT ? false : true}
          />
        )}
        {/* {data[0]?.count > API_LIMIT && data.length < 2 && ( */}
        {data && ((data?.contents?.length) ?? false) && data?.contents[0].count > API_LIMIT && data.contents.length < 2 && (
          <Pagination
            history={history}
            location={location}
            page={currentPage}
            pageCount={totalPage}
          />
        )}
      </>
      <Footer />
    </div>
  );
}

export default ContentResult;

function AmcDetailshead({ type, data }) {
  // console.log("Text keys", type.toLowerCase());
  const location = useLocation();
  const params = new URLSearchParams(location.search)
  const [amcCardData, setAmcCardData] = useState([]);
  return (
    <div className="">
      <div className="amc-details-heading-wrapper">
        <div className="amc-details-profile-image">
          {type.toLowerCase() !== 'languages' && data?.circleImagePath &&
            <span className="amc-detail-profile desk-show">
              <Image
                src={data?.circleImagePath || defaultIcon}
                alt="profile-Image"
                className="profile-Image"
              />
            </span>
          }

          <div>
            <h4 className=" lato-semibold fs-32-24 black-242">
              {data ? (data[type?.toLowerCase() + "Name"] || decodeURIComponent(params.get("value")).replace(/\w/, (a) => a.toUpperCase()) ) : decodeURIComponent(params.get("value") || "Na").replace(/\w/, (a) => a.toUpperCase())}
            </h4>
            <div className="content-type-data">
              {Object.keys(data?.contentTypeStats || {})?.map(
                (item, i) => {
                  return (
                    <>
                      <p className=" lato-regular f-s-12 lh-16 black-242">
                        {item}{" "}
                        {formatNumber(data?.contentTypeStats[item])}
                      </p>
                      <span
                        className={`virtical-strip ${Object.keys(data?.contentTypeStats)
                          .length ===
                          i + 1 && "d-none"
                          }`}
                      ></span>
                    </>
                  );
                }
              )}
            </div>
          </div>
        </div>
        {type.toLowerCase() === "campaign" && (
          <div className="download-share-icon ">
            <span className="icon-fram">
              <Image src={save2} alt="download icon" className="save-icon" />
            </span>
            <span className="icon-fram">
              <Image
                src={BlackShare}
                className="share-icon"
                alt="download icon"
              />
            </span>
            <div className="primary-btn d-flex align-items-center">
              <Image src={BlackDownload} alt="download icon" />
              &nbsp; Download{" "}
            </div>
          </div>
        )}
      </div>
      {/* <div className="content-type-data">
        {
          Object.keys(location.state?.contentTypeStats || {})?.map((item, i) => {
            return (
              <>
                <p className=' lato-regular f-s-12 lh-16 black-242'>{item} {formatNumber(location.state?.contentTypeStats[item])}</p>
                <span className={`virtical-strip ${Object.keys(location.state?.contentTypeStats).length === i + 1 && "d-none"}`}></span>
              </>
            )
          })
        }
      </div> */}
      <p className=" lato-regular fs-16-14 black-242 op-6">
        {data?.description || ""}
      </p>
      {data?.bannerPath &&
        <Image
          src={data?.bannerPath}
          alt="image"
          className="amc-details-main-image"
        />
      }
    </div>
  );
}
