import React from "react";
import { Footer, Header, NotFound } from "../common/pages";
import { PageSubHeader } from "../common";
import { Image, Modal, Tab, Tabs } from "react-bootstrap";
import activityImage from "../../assets/images/activity-box-img.png";
import share from "../../assets/images/black-share.svg";
import save from "../../assets/images/black-save.svg";
import download from "../../assets/images/black-download.svg";
import close from "../../assets/images/cancel-icon.svg";
import search from "../../assets/images/search.svg";
import loadingImage from "../../assets/images/loading-spinner.gif";
import { MAX_LIMIT, NA, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking } from "../../utils/Constant";
import { useState } from "react";
import { getDataApi } from "./Api";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom.min";
import { useEffect } from "react";
import { format, isToday, isYesterday, isSameMonth, isSameYear, differenceInCalendarDays } from 'date-fns';
import { AllShimmer } from "../common/components";
import { RWebShare } from "react-web-share";
import SaveCollectionModal from "../common/components/SaveCollectionModal";
import { toast } from "react-toastify";
import { commonAuthPostApi } from "../home/Api";
import { BreadCrumb } from "../home/_utils";
import { formatDate } from "../../utils/ReusableFunctions";
import { getToken } from "../../utils/ManageToken";

const activityTab = [
  { tab_Name: "Downloads", type: UserTracking.DOWNLOADED },
  { tab_Name: "Co-Brands", type: UserTracking.Cobrand },
  { tab_Name: "Likes", type: UserTracking.LIKED },
  { tab_Name: "Shares", type: UserTracking.SHARED },
  { tab_Name: "Recent Search", type: UserTracking.SEARCH },
];

export default function MyActivity() {
  const history = useHistory();
  const location = useLocation();
  const urlTypeValue = location.search.split("type=")[1]?.replace(/^\w/, (a) => a.toUpperCase());
  const [activityData, setActivityData] = useState([]);
  const [saveTo, setSaveTo] = useState(false)
  const [variationId, setVariationId] = useState('')
  const [id, setId] = useState(null)
  const [confirm, setConfirm] = useState(false)
  const [collectionName, setCollectionName] = useState('Collection Name')
  const [loading, setLoading] = useState(true);
  const [type, setType] = useState(location.search?.split("type=")[1]);
  const serchParems = new URLSearchParams(location.search);

  useEffect(async () => {
    setLoading(true)
    const url = (urlTypeValue === UserTracking.SEARCH) ? "user-search" : `user-tracking?type=${urlTypeValue ?? UserTracking.DOWNLOADED}`;
    const response = await getDataApi(url);
    // console.log(urlTypeValue ?? UserTracking.DOWNLOADED)
    setLoading(false);
    if (urlTypeValue === UserTracking.SEARCH) {
      setActivityData([... new Set(response?.data.map((item) => item.searchText))]);
    } else {
      setActivityData(response?.data);
    }
    // setType(location.search?.split("type=")[1]);
  }, [location.search])
  // useEffect(()=>{
  //   history.push(`/myactivity/user-tracking?type=${UserTracking.DOWNLOADED.toLowerCase()}`)

  // },[])
  const handleTabSelect = (Type) => {
    if (type !== Type) {
      setLoading(true);
    }
    history.push(`/myactivity/user-tracking?type=${Type.toLowerCase()}`)
    setType(Type);
  };

  function searchHandler(text) {
    let path = `/content-type/All`;
    let search = `${text ? `searchValue=${text}` : ""}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
    history.push({
      pathname: path,
      search: search,
    });
  }
  // conformCollectionPopUp
  function conformCollectionPopUp(data) {
    // console.log("Conform to saved says,",data)
    setSaveTo(data.label);
    setCollectionName(data.selectedItem);
    setConfirm(!confirm)
  }
  //

  function navigate(contentType, uuid) {
    history.push({ pathname: `/details/${contentType?.replace("/", "_").toLowerCase()}&id=${uuid || START_PAGE}`,state:{'breadCrumbPath':['My Activity']} })
  }
  async function userTrackingHandler(content,id,variationId) {
    // console.log(content)
    const info = {
      "data": {
        "contentUuid": id,
        "type": content,
        "contentVariationUuid":variationId,
      }
    }
      const response = await commonAuthPostApi(USER_TRACKING, info);
      if (response && !response?.message) {
        toast.success(`Succesfully ${content}`);
      }
  }
  return (
    <div>
      {/* <Header /> */}
      <BreadCrumb path={["My Activity"]} clasese="mb-3-desk" />
      <PageSubHeader
        title="My Activity"
        count=""
        isSearch={false}
        clases="myactivity-pageheader"
      />
      {/* <div className="container"> */}
      <div className="tabs-main-wrapper">
        <Tabs
          defaultActiveKey={UserTracking.DOWNLOADED}
          activeKey={location.search?.split("type=")[1]?.replace(/^\w/, (a) => a.toUpperCase()) || UserTracking.DOWNLOADED}
          id="uncontrolled-tab-example"
          onSelect={handleTabSelect}
        >
          {activityTab?.map((item, i) => {
            return (
              <Tab
                eventKey={item.type}
                key={i}
                title={item.tab_Name}
                className="activity-tab"
              >
                {loading ?
                  <AllShimmer type="MyActivity" tabName={item.tab_Name} />
                  : (
                    <>
                      {item.tab_Name === "Recent Search" ? (
                        <div className="tab-recent-wrapper">
                          {activityData?.length > 0 ? (
                            activityData?.map((item, i) => {
                              if (!item) {
                                return null;
                              }

                              return (
                                <div onClick={() => searchHandler(item)} className="resent-serch-box fs-14-12 black-242" key={i}>
                                  <Image src={search} alt="icon" />
                                  {item}
                                </div>
                              );
                            })

                          ) : (
                            <div className="d-flex justify-content-center w-100">
                              <NotFound />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div className="tab-content-wrapper">
                          {
                            activityData?.list?.length > 0 ? (
                              activityData?.list?.map((item, i) => {
                                return (
                                  <div key={i} className="activity-box">
                                    <div className="activity-image-frame p-0">
                                      {item?.thumbnail ?
                                        <Image
                                          onClick={() => navigate(item?.contentType, item.uuid)}
                                          src={item?.thumbnail}
                                          className="activity-image pointer"
                                          alt={item?.title || NA}
                                        />
                                        : <p className=" lato-regular fs-14-12 p-1"> {item?.title || NA}</p>}
                                    </div>
                                    <div className="activity-content-frame">
                                      <h6 title={item?.title || NA} onClick={() => navigate(item?.contentType, item.uuid)} className="lato-semibold pointer fs-18-14 black-242">
                                        {item?.title || NA}
                                      </h6>
                                      <h6 className="lato-regular fs-16-12 black-242 op-6 ">
                                        Content type: {item?.contentType || NA}
                                      </h6>
                                      <h6 className="lato-regular fs-16-12 black-242 op-6">
                                        {formatDate(item?.contentTrackingCreatedAt)}
                                      </h6>
                                      <div className="activity-options">
                                        <RWebShare
                                          data={{
                                            text: "",
                                            url: `${SHARE_BASE_URL + "details/" + item?.contentType.toLowerCase().replace("/", "_") + "&id=" + item?.uuid}`,
                                            title: item?.title,
                                          }}
                                          onClick={() => userTrackingHandler(UserTracking.SHARED,item.uuid,item.defaultVariation?.uuid)}
                                        >
                                          <div className="act-btn lato-regular fs-14-12 black-242">
                                            <Image src={share} alt="image" />{" "}
                                            <span className="desk-show"> Share</span>

                                          </div>
                                        </RWebShare>

                                        <div onClick={() => { setSaveTo(!saveTo); setId(item.uuid);setVariationId(item.defaultVariation?.uuid) }} className="act-btn lato-regular fs-14-12 black-242">
                                          <Image src={save} alt="image" />
                                          <span className="desk-show"> Save</span>

                                        </div>
                                        <a download  onClick={() => userTrackingHandler(UserTracking.DOWNLOADED,item.uuid,item.defaultVariation?.uuid)} href={item?.defaultVariation?.contentFilePath} target="_blank" className="act-btn lato-regular fs-14-12 black-242 ">
                                          <Image src={download} alt="image" />{" "}
                                          <span className="desk-show"> Download</span>

                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                );
                              })


                            ) : (
                              <div className="d-flex justify-content-center w-100">
                                <NotFound />
                              </div>

                            )
                            // <Image src={loadingImage} alt='loading'/>
                          }
                        </div>
                      )}
                    </>
                  )}
              </Tab>
            );
          })}
        </Tabs>
      </div>
      {/* </div> */}
      {/* <Footer /> */}
      {/* ?============================ SAVE TO MODAL START ========================= */}
      {saveTo &&
        <SaveCollectionModal id={id} variationId={variationId} show={saveTo} handleClose={() => setSaveTo(!saveTo)} collectionPopUp={conformCollectionPopUp} />
      }
      {/* ?============================ SAVE TO MODAL END  ========================== */}

      {
        confirm &&
        <Modal className="save-confirm-collection-custom-model" show={confirm} onHide={() => setConfirm(!confirm)}>

          <Modal.Body className="save-confirm-collection-model-body">
            <Image src={close} onClick={() => setConfirm(!confirm)} alt="canle icon" className="close-btn" />
            <h4 className="lato-regular fs-28-18 black-242 w-100 text-center">{collectionName}</h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mycollections') }}>View Collection</div>
            </div>
          </Modal.Body>
        </Modal>

      }
    </div>
  );
}
