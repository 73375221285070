import React from 'react'
import SkeletonCard from './SkeletonCard'
import { AMC, ContentTypeOptions, EVENTS, SOCIALMEDIA, USER_COLLECTION, imageClass } from '../../../utils/Constant'
import FolderPic from "../../../assets/images/folder.png";
import { Image } from 'react-bootstrap';
export default function AllShimmer({ type = "any", tabName = "", noContainer = false, serchHide = true,urlType='',isAll=false }) {
  // console.log("Alllllllll", type)
  switch (type) {
    case EVENTS:
      // console.log("Shrimer Executer =",EVENTS)
      return (

        [...Array(8)]?.map((item, i) => (
          <div className="event-card" key={i}>
            <div className="event-image-box shimmer-container "></div>
            <div className="event-content-frame">
              <p className="lato-semibole fs-14-11 w-25 shimmer-container">&nbsp;</p>
              <h5 className="lato-bold fs-18-14 w-75 shimmer-container">&nbsp;</h5>
              <p className="lato-semibole fs-14-11 w-50 shimmer-container">&nbsp;</p>
              <p className="lato-semibole fs-14-11 mw-151-1 shimmer-container">&nbsp;</p>
              <p className="lato-semibole fs-14-11 mw-101 shimmer-container">&nbsp;</p>
            </div>
          </div>
        ))


      )
    case "suggestion":
      // console.log("Shrimer Executer =","Suggestion")
      return (
        <>
          {/* <div className="shimmer-container lh-30 mw-151 mt-5">&nbsp;</div> */}
          <div className="d-flex justify-content-between align-items-center mb-t ">
            <div className="shimmer-container lh-30 mw-151 ">&nbsp;</div>
            <div className="shimmer-container lh-20 mw-101">&nbsp;</div>
          </div>
          <div className={`common-all-card-wrapper  justify-content-start' `}>
            {[...Array(9)].map((item2, i) => (
              <SkeletonCard
                key={i}
                imageClass={imageClass[tabName?.replace('/', "_")?.toUpperCase() || "videos"] + " responsive-card-width-skleton"}
                socialMedia={type === SOCIALMEDIA ? true : false}
              />
            ))}
          </div>
        </>
      );
    case USER_COLLECTION:
      return (
        [...Array(3)].map((item, i) => (
          <div key={i} className="folder-card pointer shimmer-container folder-clip br-8" >
            {/* Your placeholder content */}
            <div className="date-created">
              <h2 className="fs-22-18 lato-regular text-start">&nbsp;</h2>
              <p className="fx-12 lato-regular">&nbsp;</p>
            </div>
          </div>
        ))
      );
    case "All" || "collectionDetails":
      // console.log("Shrimer Executer =","collectionDetails,All")
      return (
        <div className={noContainer ? "" : "container"}>
          {type === "collectionDetails" && serchHide ?
            <div className="shimmer-container lh-30  mb-3 w-50 mb-show">&nbsp;</div>
            : null}
          {serchHide &&
            <div className=" shimmer-container lh-57 mx-auto w-100 mb-5">&nbsp;</div>
          }
          {type === "collectionDetails" ? null : <div className="d-flex justify-content-between  align-items-center">
            <div className="shimmer-container lh-30 mw-101 br-10 mb-5">&nbsp;</div>
            <div className="shimmer-container lh-20 mw-151">&nbsp;</div>
          </div>}

          {
            ContentTypeOptions?.map((item, j) => {
              // console.log(item.option?.replace('/', "_")?.toUpperCase())
              if (item.option === "All") {
                return null;
              }
              return (
                <>
                  {/* <div className="shimmer-container lh-30 mw-151 mt-5">&nbsp;</div> */}
                  <div className="d-flex justify-content-between align-items-center mb-t ">
                    <div className="shimmer-container lh-30 mw-151 ">&nbsp;</div>
                    <div className="shimmer-container lh-20 mw-101">&nbsp;</div>
                  </div>
                  <div className={`common-all-card-wrapper  justify-content-start' `}>
                    {[...Array(9)].map((item2, i) => (
                      <SkeletonCard
                        key={i}
                        imageClass={imageClass[item.option?.replace('/', "_")?.toUpperCase() || "videos"] + " responsive-card-width-skleton"}
                        socialMedia={type === SOCIALMEDIA ? true : false}
                        type={type}
                      />
                    ))}
                  </div>
                </>
              );
            })
          }
        </div>
      )
    case 'MyActivity':
      // console.log("Shrimer Executer =","MyActivity")
      return (
        <>
          {tabName === "Recent Search" ? (
            <div className="tab-recent-wrapper">
              {
                [...Array(8)]?.map((item, i) => {
                  return (
                    <div className="resent-serch-box fs-14-12 black-242  " key={i}>
                      <span alt="icon" className="shimmer-container lh-30 rounded-circle mw-30" >&nbsp;</span>
                      <p className="shimmer-container w-75 lh-20">&nbsp;</p>
                    </div>
                  );
                }

                )}
            </div>
          ) : (
            <div className="tab-content-wrapper ">
              {[...Array(8)]?.map((item, i) => {
                return (
                  <div key={i} className="activity-box d-flex align-items-stretch">
                    <div className="activity-image-frame bg-white shimmer-container">
                      <div
                        // src={item?.thumbnail || activityImage}
                        className="activity-image  vh-100 w-100 shimmer-container"
                      // alt="image"
                      >&nbsp;</div>
                    </div>
                    <div className="activity-content-frame">
                      <h6 className="lato-semibold fs-18-14 black-242 w-75 shimmer-container">
                        &nbsp;
                      </h6>
                      <h6 className="lato-regular fs-16-12 black-242 w-50 op-6 shimmer-container">
                        &nbsp;   &nbsp;   &nbsp;
                      </h6>
                      <h6 className="lato-regular fs-16-12 black-242 op-6 w-25 shimmer-container">
                        &nbsp;   &nbsp;:   &nbsp;   &nbsp;,    &nbsp;   &nbsp;
                      </h6>
                      <div className="activity-options w-100 ">
                        <div className=" lato-regular lh-29 black-242 w-25 shimmer-container">
                          &nbsp;

                        </div>
                        <div className=" lato-regular lh-29 black-242 w-25 shimmer-container">
                          &nbsp;
                        </div>
                        <div className=" lato-regular lh-29 black-242 w-25 shimmer-container">

                          &nbsp;
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

            </div>)}
        </>
      )
    case SOCIALMEDIA:
      // console.log("Shrimer Executer =",SOCIALMEDIA)
      return (
        <div className="container">
          <div className=" shimmer-container lh-57 mx-auto w-100 mb-5">&nbsp;</div>
          <div className="shimmer-container lh-30 mw-101 rounded-lg mb-5">&nbsp;</div>
          {/* <div className="shimmer-container lh-23 mb-5 w-100">&nbsp;</div> */}
          <div className='shimmer-container lh-40 mw-200 mb-5'>&nbsp;</div>
          <div className="d-flex justify-content-between align-items-center">
            <div className='shimmer-container lh-30 mw-200 '>&nbsp;</div>
            <div className="shimmer-container lh-20 mw-101 ">&nbsp;</div>
          </div>
          <div className="w-100 mh-400 shimmer-container my-4">&nbsp;</div>
          <div className={`common-all-card-wrapper flex-wrap ${type === SOCIALMEDIA ? 'justify-content-start' : ""}`}>
            {[...Array(8)].map((item, i) => (
              <SkeletonCard
                key={i}
                imageClass={imageClass[type?.replace('/', "_")?.toLocaleLowerCase() || "videos"]}
                socialMedia={type === SOCIALMEDIA ? true : false}

              />
            ))}
          </div>
        </div>
      )
    case "common":
      // console.log("Shrimer Executer =","common",type,'=,',urlType)
      return(
        <div className={`common-all-card-wrapper ${false?"":'flex-wrap'}`}>
        {[...Array(9)].map((item, i) => (
          <SkeletonCard
            key={i}
            imageClass={imageClass[urlType?.replace('/', "_")?.toUpperCase() || "videos"] + " responsive-card-width"}
            socialMedia={urlType === SOCIALMEDIA ? true : false}
            type={urlType !== AMC ? true : false}
          />
        ))}
      </div>
      )
    case "commonAllCard":
      // console.log("Shrimer Executer =","commonAllCard")
      return(
        <div className={`common-all-card-wrapper`}>
        {[...Array(3)].map((item, i) => (
          <SkeletonCard
            key={i}
            imageClass={imageClass[urlType?.replace('/', "_")?.toUpperCase() || "videos"] + " responsive-card-width"}
            socialMedia={urlType === SOCIALMEDIA ? true : false}
            type={urlType !== AMC ? true : false}
          />
        ))}
      </div>
      )
    default:
      // console.log("In shrimmer type=>",type,);
      return (
        <div className={noContainer ? "" : "container"}>
          <div className="shimmer-container lh-20 mb-4 mw-151">&nbsp;</div>
          <div className=" shimmer-container lh-57 mx-auto w-100 mb-5">&nbsp;</div>
          {type === "POTD" ?
            <>
              <div className="shimmer-container lh-30 mw-151 mb-4">&nbsp;</div>
              <div className="shimmer-container w-100 sponsored-card-shrimer-box lh-100 py-5 mb-4"></div>
            </>
            :
            <div className="d-flex justify-content-between align-items-center">
              <div className="shimmer-container lh-30 mw-151 mb-5">&nbsp;</div>
              <div className="shimmer-container lh-20 mw-151">&nbsp;</div>
            </div>
          }
          <div className="shimmer-container lh-30 mw-151 ">&nbsp;</div>
          <div className={`common-all-card-wrapper flex-wrap ${type === SOCIALMEDIA ? 'justify-content-start' : ""}`}>
            {[...Array(9)].map((item, i) => (
              <SkeletonCard
                key={i}
                imageClass={imageClass[type?.replace('/', "_")?.toUpperCase() || "videos"] + " responsive-card-width"}
                socialMedia={type === SOCIALMEDIA ? true : false}
                type={type !== AMC ? true : false}
              />
            ))}
          </div>
        </div>

      )
  }

}
