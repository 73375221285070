
import React, { useEffect } from "react";
import { Image, Tab, Tabs } from "react-bootstrap";
import { Footer, Header } from "../common/pages";
import { useState } from "react";
import { BreadCrumb } from "../home/_utils";
import EventCard from "./EventCard";
import { commonAuthApis, getDataApi } from "../profile/Api";
import { EVENTS } from "../../utils/Constant";
import { AllShimmer } from "../common/components";

export default function MyEvents() {
    const [selectedKeys, setSelectedKeys] = useState("");
    return (
        <section className='page-padding'>
            <Header />
            <BreadCrumb path={["My Events" ]} clasese="mb-3-desk container" />
            <div className="container">
                {/* <div className="treandign-search">
                          <input
                              type="text"
                              className='lato-semibold fs-18-16 input-search-box black-242'
                              placeholder='Search' />
                          <Image src={searcIcon} className='' alt="icon" />
                      </div> */}

                {/* <SearchDropDown getKeys={(keys) => setSelectedKeys(keys)} classes="search-border trend-serch" /> */}

                <h3 className='lato-light fs-32-28 black-242 mb-0'>My Events <span className='lato-bold'></span></h3>
                <div className="treanding-tab-wrapper pt-4">
                    <Tabs
                        defaultActiveKey="bookings"
                        class="trending-tabs"
                        id="uncontrolled-tab-example">
                        <Tab
                            eventKey="bookings"
                            class='lato-regular fs-18-14 black-242'
                            title="Bookings">
                                 <BookingEvents />
                        </Tab>
                        {/* <Tab eventKey="saved" title="Saved">
                           hi
                        </Tab>
                        <Tab eventKey="shared" title="Shared">
                           hi
                        </Tab> */}
                    </Tabs>
                </div>
            </div>
            <Footer />
        </section>

    )
}


function BookingEvents(){
    // let eventData={
    //     "id": 49,
    //     "uuid": "ec7252ac-ac4d-442a-9119-9b945089260c",
    //     "createdAt": 1706874232000,
    //     "createdBy": null,
    //     "updatedAt": 1706874548000,
    //     "updatedBy": null,
    //     "deletedAt": null,
    //     "name": "Mera India",
    //     "description": "India is my country ",
    //     "date": "2024-02-14T18:30:00.000Z",
    //     "isPremium": true,
    //     "venueName": "",
    //     "venueMapLink": "",
    //     "onlineLink": "https://meet.google.com/weq-ssht-spr?authuser=1",
    //     "city": "Nicobar",
    //     "eventFormat": "ONLINE",
    //     "eventType": "FREE",
    //     "amount": null,
    //     "totalSeats": 11,
    //     "keywords": "",
    //     "thumbnailPath": "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/events/thumbnail/8654c276-ca60-418d-a123-e1053a5abbdf_.jpeg",
    //     "bannerPath": "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/events/banner/6faac03e-c6c0-41bf-91fd-79009397a951_.jpeg",
    //     "eventOrganiser": {
    //         "id": 16,
    //         "uuid": "37faa57c-8ed2-4d73-802b-daf2ab8bef2d",
    //         "createdAt": 1706631967000,
    //         "createdBy": null,
    //         "updatedAt": null,
    //         "updatedBy": null,
    //         "deletedAt": null,
    //         "eventOrganiserName": "Aditya Birla",
    //         "description": "Hello World",
    //         "thumbnailPath": "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/eventOrganiser/thumbnail/09e1bdff-b84c-4eb7-b033-d8a29b7e52e3_.png",
    //         "bannerPath": "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/eventOrganiser/banner/96dc97eb-70b7-45d8-ab6a-0a2a945aa6a0_.png"
    //     },
    //     "eventCategory": {
    //         "id": 28,
    //         "uuid": "e2c52afc-10bd-4709-b07c-97c71d439430",
    //         "createdAt": 1706632388000,
    //         "createdBy": null,
    //         "updatedAt": 1706632405000,
    //         "updatedBy": null,
    //         "deletedAt": null,
    //         "eventCategoryName": "Technology"
    //     },
    //     "isRegistered": true,
    //     "eventSpeakers": [
    //         {
    //             "id": 18,
    //             "uuid": "cccfaf6c-7077-4729-8d80-53b0f5e8ea1d",
    //             "createdAt": 1706632734000,
    //             "createdBy": null,
    //             "updatedAt": null,
    //             "updatedBy": null,
    //             "deletedAt": null,
    //             "speakerName": "Yash Kaskar",
    //             "profilePicture": "https://s3.ap-south-1.amazonaws.com/beta.thefinpedia.com/eventSpeaker/profilePic/675b08fe-36d7-4735-8776-c3922ffb1850_.jpg",
    //             "link": "www.google.com",
    //             "description": "Hello, Yash Kaskar this side"
    //         }
    //     ]
    // }
    const [eventData,setEventData]=useState([])
    const [isLoading,serIsLoading]=useState(false);
    async function featchedEvents(){
        const res=await commonAuthApis('eventRegistration/registrationByUser',"",'get');
        // console.log(res)
        if (res.status >= 200 && res.status <= 210) {
            setEventData(res.data.data);
            serIsLoading(false);
        }
    }
    useEffect(()=>{
    serIsLoading(true)
    featchedEvents()
    },[])
    return(
        <div className="events-card-wrapper pt-5">
              {isLoading ? <AllShimmer type={EVENTS}/>:
               eventData?.map((item,i)=>{
                return(
                    <EventCard  data={item.event} registered={true}/>
                )
            })}
        </div>
    )
}
