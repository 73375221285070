import React, { useState } from "react";
import { Footer, Header } from "../common/pages";
import { BreadCrumb } from "../home/_utils";
import { Image } from "react-bootstrap";
import abcLogo from "../../assets/images/abc.png";
import { useHistory, useLocation } from "react-router-dom";
import Personalised from "./Personalised";
import { toolCalculator } from "../../utils/Constant";

import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import {
  ChildEducationPlanner,
  DelaySip,
  FixedMonthlyWithdrawal,
  ImpactOfInflation,
  Lumpsum,
  SIPCalculator,
  TargetAmount,
  StepUp,
  SWP,
  RetirementPlanner
} from ".";
import { useRef } from "react";

export default function CalculatorPage() {
  const graphRef = useRef(null);

  const location = useLocation();
  const history = useHistory()
  const [isChecked, setIsChecked] = useState({});
  // console.log('location', location);
  // console.log('his', history);
  const calculator = toolCalculator.find(x => location.pathname.includes(x.url))
  // console.log('calculator', calculator);
  //* -----------------------------------------------------------------------------------------


  const downloadScreenshot = () => {
    if (graphRef.current) {
      html2canvas(graphRef.current).then((canvas) => {
        const image = canvas.toDataURL('image/png', 1.8);

        let downloadLink = document.createElement('a');
        downloadLink.href = image;
        downloadLink.download = 'graph-screenshot.png';

        document.body.appendChild(downloadLink);

        downloadLink.click();


        document.body.removeChild(downloadLink);
      });
    }
  };

  return (
    <div className="page-padding ">
      <div className="container">


        <Header />
        <BreadCrumb path={["Tools", calculator?.toolsName]} />
        {/* ===============================  SIP HEADER PART START =================== */}
        <div className="tools-calculator-sub-header">
          <h5 className="lato-regular fs-32-28 black-242">
            {calculator?.toolsName}
          </h5>
          <div className="tools-sip-info">
            <div className="tools-sip-name-frame">
              <p className="lato-regular fx-12 black-242 m-0 op-5">
                Sponsored By
              </p>
              <p className=" lato-medium fs-14-13 m-0 black-242 ">
                Aditya Birla Sun Life Mutual Fund
              </p>
            </div>
            <div className="tools-sip-logo">
              <Image src={abcLogo} alt="sip Logo" />
            </div>
          </div>
        </div>
        <Personalised isTextLogo={(e) => { setIsChecked(e) }} />
      </div>
      <div ref={graphRef} className="container">
        {calculator?.code === "SIPCalculator" && <SIPCalculator url={calculator} textLogo={isChecked} />}
        {calculator?.code === "StepUp" && <StepUp url={calculator} textLogo={isChecked} />}
        {calculator?.code === "DelaySip" && <DelaySip url={calculator} textLogo={isChecked} />}
        {calculator?.code === "TargetAmount" && <TargetAmount url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "ChildEducationPlanner" && <ChildEducationPlanner url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "SWP" && <SWP url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "FixedMonthlyWithdrawal" && <FixedMonthlyWithdrawal url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "RetirementPlanner" && <RetirementPlanner url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "Lumpsum" && <Lumpsum url={calculator} textLogo={isChecked}/>}
        {calculator?.code === "ImpactOfInflation" && <ImpactOfInflation url={calculator}  textLogo={isChecked}/>}
      </div>

      <br /><br /><br />
      <div className="container">
        <p className='lato-regular f-s-12 lh-16 text-center'>Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator. <br />
          Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.</p>
        <br /><br /><br />
      </div>
      <Footer />
    </div>
  );
}


