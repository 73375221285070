import React, { useEffect, useState, useRef } from "react";
import { Image } from "react-bootstrap";
// import PlayVideo from "../../../assets/video/video-detail.mp4";
// import Profile from "../../../assets/images/fiba-logo.png";
// import Presentation from "../../../assets/images/presentation-details.png";
import { MINIMALISTS } from "../../../utils/Constant";
// import infographic from "../../../assets/images/infographic-details.png";
import DocViewer, { DocViewerRenderers } from "@cyntler/react-doc-viewer";


const VideoDetailsContentType = ({ type, extUrlLink, contentFilePath = Presentation, height = "600" }) => {


  // console.log("extUrlLink", extUrlLink)
  // console.log("contentFilePath", contentFilePath)


  // const docs = [
  //   { uri: extUrlLink }, // Remote file
  //   { uri: require("../../../assets/video/video-detail.mp4") }, // Local File
  // ];

  const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.svg']; // Add more extensions if needed

  const isImage = (filePath) => {
    // console.log(filePath);
    const extension = (filePath??false) ? filePath?.toLowerCase().slice(filePath.lastIndexOf('.')) : '';
    return imageExtensions.includes(extension);
  };
  // extUrlLink='https://www.youtube.com/shorts/mfjPYWkSaOE';
  if (extUrlLink.includes("youtube.com")) {
    let YoutubeURL = '';
    if (extUrlLink.includes("/shorts/")) {
      YoutubeURL = `https://www.youtube.com/embed/${extUrlLink?.split("/")[(extUrlLink?.split("/").length - 1)]}?autoplay=1&mute=0&rel=0`;
    } else {
      YoutubeURL = `https://www.youtube.com/embed/${extUrlLink?.split("v=")[1]}?autoplay=1&mute=0&rel=0`;
    }

    return (
      <div className="video-play-area">
        <iframe
          width="100%"
          height={height}
          src={YoutubeURL}
          allowFullScreen
          title="Embedded YouTube"
          allow='autoplay'
        />
      </div>
    )
  } else if (contentFilePath || extUrlLink) {
    let file = contentFilePath ? contentFilePath : extUrlLink
    return (
      <div className="infographic-detail-wrapper">
        {
          isImage(file)
            ?
            <div className="detail-wrapper">
              <Image
                className="presentation"
                src={file}
                alt="presentations"
              />
            </div>
            :
            <iframe
              src={`${file}#toolbar=0&view=fit&navpanes=0`}
              width="100%"
              height={height}
              style={{ width: "100%", height: "100%", border: "none" }}
              title="This is title"
              allowFullScreen
              frameborder="0"
            ></iframe>
        }

      </div>
    )

  } else {
    return null
  }

  // switch (type.toLocaleLowerCase()) {
  //   case "videos":
  //     return (
  //       <div className="video-play-area">
  //         <iframe
  //           width="100%"
  //           height={height}
  //           src={YoutubeURL}
  //           allowFullScreen
  //           title="Embedded YouTube"
  //           allow='autoplay'
  //         />
  //       </div>

  //     )
  //   case "infographics":

  //     return (
  //       <div className="detail-wrapper bg-transparent">
  //         <iframe
  //           // ref={iframeRef}
  //           src={`${extUrlLink || contentFilePath}#toolbar=0&view=fit&navpanes=0`}
  //           style={{
  //             width: "100%",
  //             height: { height },
  //             border: "none",
  //             // padding: "2rem 0",
  //           }}
  //           title="PDF Viewer"
  //           allowFullScreen
  //           autoplay
  //         ></iframe>
  //         {/* <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} /> */}
  //       </div>
  //     )
  //   case MINIMALISTS.toLocaleLowerCase() || "minimalist":
  //     return (
  //       <div className="">

  //         {isImage(contentFilePath) ?
  //           <div className="detail-wrapper">
  //             <Image
  //               className="presentation"
  //               src={contentFilePath}
  //               alt="presentations"
  //             />
  //           </div> :
  //           contentFilePath &&
  //           <iframe
  //             src={`${contentFilePath}#toolbar=0&view=fit&navpanes=0`}
  //             //  src={`https://www.utimf.com/${extUrlLink?.split('utimf.com/')[1]}`}
  //             style={{ width: "100%", height: { height }, border: "none" }}
  //             autoplay
  //           ></iframe>
  //         }
  //       </div>
  //     )
  //   case "podcast":
  //     return (
  //       <div className="infographic-detail-wrapper">
  //         <iframe
  //           src={extUrlLink.includes("youtube") ? YoutubeURL : extUrlLink}
  //           //  src={`https://www.utimf.com/${extUrlLink?.split('utimf.com/')[1]}`}
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //         ></iframe>
  //       </div>
  //     )
  //   case "articles_blogs":
  //     return (
  //       <div className="infographic-detail-wrapper">
  //         <iframe
  //           src={extUrlLink}
  //           //  src={`https://www.utimf.com/${extUrlLink?.split('utimf.com/')[1]}`}
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //         ></iframe>
  //       </div>
  //     )
  //   case "report":
  //     return (
  //       <div className="infographic-detail-wrapper">
  //         <iframe
  //           src={extUrlLink}
  //           //  src={`https://www.utimf.com/${extUrlLink?.split('utimf.com/')[1]}`}
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //         ></iframe>
  //       </div>
  //     )
  //   case "presentation":
  //     // console.log("Presentaing show video")
  //     return (
  //       <div className="infographic-detail-wrapper">
  //         <iframe
  //           src={`${extUrlLink || contentFilePath}#toolbar=0&view=fit&navpanes=0`}
  //           style={{
  //             width: "100%",
  //             height: { height },
  //             border: "none",
  //             // padding: "2rem 0",
  //           }}
  //           title="PDF Viewer"
  //           allowFullScreen
  //           autoplay
  //         ></iframe>
  //       </div>
  //     )
  //   case "e-books":
  //     return (
  //       <div className="infographic-detail-wrapper">
  //         <iframe
  //           src={extUrlLink}
  //           //  src={`https://www.utimf.com/${extUrlLink?.split('utimf.com/')[1]}`}
  //           style={{ width: "100%", height: "100%", border: "none" }}
  //         ></iframe>
  //       </div>
  //     )
  //   case "carousel":

  //     return (
  //       <div className="detail-wrapper bg-transparent">
  //         <iframe
  //           // ref={iframeRef}
  //           src={`${extUrlLink || contentFilePath}#toolbar=0&view=fit&navpanes=0`}
  //           style={{
  //             width: "100%",
  //             height: { height },
  //             border: "none",
  //             // padding: "2rem 0",
  //           }}
  //           title="PDF Viewer"
  //           allowFullScreen
  //           autoplay
  //         ></iframe>
  //         {/* <DocViewer documents={docs} pluginRenderers={DocViewerRenderers} /> */}
  //       </div>
  //     )
  //   default:
  //     return null;
  // }
};
export default VideoDetailsContentType;

