import React, { useState } from 'react'
import { Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import searchIcon from "../../assets/images/Search_1.svg";
import { useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min';
export default function SearchFilter({ data='', filterData, setValueHandler=()=>{}, name, placeholder, clases,changeValue=()=>{}}) {
  const param = useParams();
  const location = useLocation();
  const prams = new URLSearchParams();
  const [keyword, setKeyword] = useState("");
  function searchAmchandler(key = "") {

    if(data){
    // let filteredData = data?.filter((item) => (placeholder==="AMC" ? item.amcName : item?.socialMediaName)?.toLowerCase().includes(key?key.toLowerCase():keyword?.toLowerCase())) || [];
    let filteredData = [...data]?.filter((item) => (item?.[name + 'Name'])?.toLowerCase().includes(key ? key.toLowerCase() : keyword?.toLowerCase())) || [];

    if (!key.trim()) {
      setValueHandler(data);
    } else if (filterData?.length > 0) {
      setValueHandler(filteredData);
    }}else{
      changeValue(key);
    }
  }

  function changeHandler(e) {
  
    setKeyword(e.target.value);
    if(data){
      searchAmchandler(e.target.value);
    }
  }

  function handleKeyPress(e) {
    if (e.key === "Enter") {
      searchAmchandler(keyword);
    }
  };
  return (
    <div className={`keywords-input-fields search-amc-wrapper ${clases}`}>
      <input
        type="text"
        placeholder={`Search ${placeholder}`}
        autoComplete="off"
        className="input-box-keywords"
        name="keyword"
        value={keyword || ""}
        onChange={changeHandler}
        onKeyDown={handleKeyPress}
      />
      {keyword && <Image src={close} onClick={() => { setKeyword(""); setValueHandler(data);searchAmchandler('') }} alt="canle icon" className="cancel-field" />}
      <Image
        onClick={() => searchAmchandler(keyword)}
        src={searchIcon}
        alt="search icon"
        className="search-input-field-icon "
      />
    </div>
  )
}
