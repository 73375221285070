import React from 'react';
import { Button, Image } from "react-bootstrap";
import Google from "../../../assets/images/google.png";
import FaceBook from "../../../assets/images/facebook.png";
import Linkdin from "../../../assets/images/linkdin.png";
import PasswordHide from "../../../assets/images/password-hide.svg";
import PasswordShow from "../../../assets/images/password-show.svg";
import {
  BaseReactComponent,
  CustomButton,
  CustomTextControl,
  Form,
  FormElement,
  FormSubmitButton,
  FormValidator,
} from "../../../utils/form";
import { API_BASE_URL, REGISTER_URL, SITE_KEY } from "../../../utils/Constant";
import ReCAPTCHA from 'react-google-recaptcha'
import { loginApi } from '../Api';
import ForgotPassword from '../ForgotPassword';

class LoginForm extends BaseReactComponent {
  constructor(props) {
    super(props)
    this.state = {
      login: "",
      loginPassword: "",
      showPassowrd: false,
      error: "",
    }
  }
  onValidSubmit = (e) => {

    const data = {
      data: {
        "emailOrMobile": this.state.login,
        "password": this.state.loginPassword
      }
    }
    loginApi(this, data);
  }
  render() {
    // const LoginForm = props => {
    return (
      <div className="login-box">
        {
          this.state.showForgotPasswordModal &&
          <ForgotPassword
            show={this.state.showForgotPasswordModal}
            handleClose={() => this.setState({ showForgotPasswordModal: false })}
          />
        }
        <h5 className="lato-regular f-s-24 lh-32">Log in</h5>
        <Form onValidSubmit={this.onValidSubmit}>
          <FormElement
            valueLink={this.linkState(this, "login")}
            required
            validations={[
              {
                validate: FormValidator.isRequired,
                message: "Please enter register email",
              },
              // {
              //   validate: FormValidator.isEmail,
              //   message: "Please enter valid email",
              // },
            ]}
            control={{
              type: CustomTextControl,
              settings: {
                placeholder: "Email/Phone",
              },
            }}
          />
          <div className='pos-rel'>
            <FormElement
              valueLink={this.linkState(this, "loginPassword")}
              required
              classes={{
                formGroup: "password-input-field",
              }}
              validations={[
                {
                  validate: FormValidator.isRequired,
                  message: "Password cannot be empty",
                },
              ]}
              control={{
                type: CustomTextControl,
                settings: {
                  type: this.state.showPassowrd ? "text" : "password",
                  placeholder: "Password",
                },
              }}
            />
            {
              this.state.error && !this.state.captureText &&
              <p className="lato-regular red-FF3 f-s-14 lh-20 error-msg pointer">{this.state.error}</p>
            }
            <Image
              src={this.state.showPassowrd ? PasswordShow : PasswordHide}
              className='password-hide pos-abs pointer'
              onClick={() => this.setState({ showPassowrd: !this.state.showPassowrd })}
            />
          </div>
          <p
            className="lato-regular white f-s-14 lh-20 forgot-password "
          >
            <span
              className=' pointer'
              onClick={() => { this.setState({ showForgotPasswordModal: true }) }}
            >
              Forgot Password ?
            </span>
          </p>
          <br /><br />
          <CustomButton
            // isDisabled={true}
            type="button "
            onClick={this.onValidSubmit}
            className="secondary-btn mw-352 mw-b-15"
            buttonText="Log in"
          />
          {/* <FormSubmitButton customClass="ghost-btn mw-352">Login</FormSubmitButton> */}
        </Form>
        {/* <div className="login-with">
          <p className="lato-regular white f-s-16 lh-19">Log in with</p>
          <div className="login-with-icons">
            <a target="_blank" href={"https://" + API_BASE_URL + "finpedia/user/auth/google"}><Image
              src={Google}
              alt=""
            /></a>
          </div>
        </div> */}
        <br /><br /><br />
        <div className="hr-lines">
          <span className="line"></span>
          <span className="lato-regular white f-s-16 lh-19">or</span>
          <span className="line"></span>
        </div>
        <div className="new-to-finpedia">
          <h5 className="lato-regular f-s-24 lh-32">New to Thefinpedia? <span onClick={() => this.props.toggleForm()} className=' lato-bold pointer under-line'>Register</span> </h5>
        </div>
        {/* <Button
          onClick={() => this.props.toggleForm()}
          className="ghost-btn mw-352 mw-b-15">
          Register
        </Button> */}
      </div>
    );
  };
}
LoginForm.propTypes = {
  // getPosts: PropTypes.func
};
export default LoginForm;