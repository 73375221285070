import React, { useEffect, useState } from "react";
import { Dropdown, Image } from "react-bootstrap";
import { AllShimmer, CardHeader } from "../../common/components";

import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';



import {
  MAX_LIMIT,
  START_PAGE,
  TARGETEDCONTENT,
} from "../../../utils/Constant";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import DownIcon from '../../../assets/images/dropdown-icon.svg';
import TargetedContentDefault from '../../../assets/images/TargetedContentDefault.jpg';
import { Header, Pagination } from "../../common/pages";
import { BreadCrumb } from "../_utils";
import { getCommonApi } from "../Api";
import { SearchFilter, TargetedContentFilter } from "../../common";
import { calculateTotalPageCount } from "../../../utils/ReusableFunctions";
export default function TargetedContent() {
  const navigate = useHistory();
  const location = useLocation();
  const param = useParams();
  const params = new URLSearchParams(location.search);
  const [data, setData] = useState([]);
  const [dataLength, setDataLength] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [filterData, setFilterData] = useState([]);
  const [sortBy, setSortBy] = useState("Max Content");
  const [currentPage, setCurrentPage] = useState(
    parseInt(params.page, 10) || START_PAGE
  );
  async function featchData() {
    window.scroll(0,0);
    const data = await getCommonApi(`website/targetedContent${location.search === '' ? `?pageNumber=${(params.get("pageNumber") ?? START_PAGE)}&pageSize=${(params.get("pageSize") ?? 18)}` : location.search}`);
    // const data = await getCommonApi(`/targetedContent${location.search === '' ? `?pageNumber=${(params.get("pageNumber") ?? START_PAGE)}&pageSize=${(params.get("pageSize") ?? 18)}` : '/filter' + location.search}`);
    if (data.status === 200) {
      setData(data?.data?.data.list || []);
      setDataLength(data?.data?.data.length || 0);
      setFilterData(data?.data?.data.list || []);
      setIsLoading(false);
    }
  }
  useEffect(() => {
    featchData();

  }, [location.search]);

  function renderHandler(name, item) {
    navigate.push({
      pathname: `/content-type/All`,
      search: `?key=targetedContents&value=${name}&pageSize=${params.get("pageSize") || MAX_LIMIT}&pageNumber=${START_PAGE}`,
      state: item
    })
  }
  function sortHandler(data) {
    setSortBy(data);

    let sortedData;
    switch (data) {
      case 'Ascending (A-Z)':
        sortedData = [...filterData].sort((a, b) => a?.targetedContentName.localeCompare(b?.targetedContentName));
        break;
      case 'Descending (Z-A)':
        sortedData = [...filterData].sort((a, b) => b?.targetedContentName.localeCompare(a?.targetedContentName));
        break;
      case "Min Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0);
          return sumA - sumB;
        });
        break;
      case "Max Content":
        sortedData = [...filterData].sort((a, b) => {
          const sumA = Object.values(a.contentTypeStats).reduce((acc, val) => acc + val, 0);
          const sumB = Object.values(b.contentTypeStats).reduce((acc, val) => acc + val, 0);
          return sumB - sumA;
        });
        break;
      default:
        sortedData = [...data];
    }

    setFilterData(sortedData);
  }
  function serchHandler(val) {
    console.log("CheckSearchvalue:", val)
    if (!val) {
      params.delete("searchValue");
      navigate.push({ search: params.toString() })
    } else {
      params.set("searchValue", val);
      navigate.push({ search: params.toString() })
    }

  }

  return (
    <div className="page-padding ">
      <div className="container">
        <Header />
        {isLoading ?
          <AllShimmer type="amc" noContainer={true} /> :
          <>
            <BreadCrumb
              path={["Targeted Content"]}
            />

            <SearchFilter name="targetedContent" placeholder="Targeted Content" clases="" changeValue={serchHandler} />
            <TargetedContentFilter />
            <div className="d-flex align-items-center justify-content-between">
              <CardHeader
                title={"Targeted Content"}
                count={dataLength}
                hide={false}
                countTitle={"Targeted Content"}
              // viewAll={`/content-type/${countTitle?.toLowerCase()}`}//change
              />
              <div className={`dropdown-main-wrapper w-auto`}>
                <Dropdown>
                  <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                    <h6 className="lato-semibold fx-14 black-242">{sortBy || 'Sort By'}</h6>
                    <Image src={DownIcon} alt="image" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="show-filter-dropdown">
                    {['Ascending (A-Z)', 'Descending (Z-A)', "Max Content", "Min Content"]?.map((item, i) => {
                      return (
                        <Dropdown.Item key={i} onClick={() => sortHandler(item)} className="lato-regular black-242 fx-14" href=''>{item}</Dropdown.Item>
                      )
                    })}

                  </Dropdown.Menu>
                </Dropdown>
              </div>
            </div>

            <div className="targeted-content-card-wrapper ">
              {filterData?.map((item, i) => {
                return (
                  <div
                    onClick={() => renderHandler(item.targetedContentName, item)}
                    className="target-imag-frame pointer p-0 responsive-card-width">
                    {item.thumbnailPath ?
                      // <Image
                      //   src={item.thumbnailPath}
                      //   onClick={() => renderHandler(item.targetedContentName, item)}
                      //   alt="icon"
                      //   className="responsive-card-width targetec-content-image"
                      // />
                      <LazyLoadImage
                      src={item.thumbnailPath}
                      onClick={() => renderHandler(item.targetedContentName, item)}
                      alt="icon"
                      effect="blur"
                      className="responsive-card-width targetec-content-image"
                      // onError={(e) => { e.target.src = DownIcon; }}
                      // placeholderSrc={TargetedContentDefault}
                    />
                      : <p className=" lato-medium fs-14-13">{item.targetedContentName}</p>}
                  </div>
                );
              })
              }
            </div>

            {data?.length > 10 &&
              <Pagination
                history={navigate}
                location={location}
                page={params.get('pageNumber')}
                pageCount={Math.ceil(dataLength / 10)}
              />
            }
          </>
        }
      </div>

    </div>
  );
}
