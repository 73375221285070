import axios from 'axios';

const preLoginInstance = axios.create(
    {
        baseURL: process.env.REACT_APP_API_BASE_URL,  // Url for Dev
        // baseURL: 'http://13.126.239.61:3001/finpedia/v1/', // Url for UAT
        // baseURL: 'http://127.0.0.1:5000/api', // Url for Local
        // baseURL: 'https://cors-anywhere.herokuapp.com/http://samples.openweathermap.org/data/2.5/',  // Url for Production
    }
);

export default preLoginInstance;
