import React from 'react'
import { Image } from 'react-bootstrap'

export default function PlansCard({ image, text, clases }) {
  return (
    <div style={{ position: "relative" }}>
      {clases && <h6 className={`lato-bold ${clases}-text fs-18-16 primary`}>Coming Soon...</h6>}

      <div className={`plans-card-wrapper ${clases}`}>

        <Image src={image} alt='image' />
        {/* <p className="lato-regular fs-22-13 black-242 ellipsis-3" >{text}</p> */}
        <p className="lato-regular fs-20-16 black-242 " dangerouslySetInnerHTML={{ __html: text }} />
      </div>
    </div>
  )
}
