import React, { useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import close from '../../assets/images/greay-cancel-icon.svg';
import searchIcon from "../../assets/images/Search_1.svg";
import { useLocation } from "react-router-dom";
import { getSearchParams } from "../../utils/ReusableFunctions";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { getAllKeywordApi } from "../home/Api";

export default function SelectKeywords({ options = [], getKeys = () => { console.log("default call Kesys") }, navigate = () => { }, placeholder, isEvent = '' }) {
  const location = useLocation();
  const history = useHistory()
  const params = new URLSearchParams(location.search)
  let searchParams = getSearchParams(location);
  const [keyword, setKeyword] = useState((searchParams.searchValue ?? "") || "");
  const [option, setOption] = useState([]);
  const [hide, setHide] = useState(false)
  useEffect(() => {
    setKeyword(searchParams.searchValue);
  }, [searchParams.searchValue])
  useEffect(() => {

    getKeys(keyword);
    // console.log("Ad input serch type-----",location.search.includes("searchValue="),"IsSerchValue=",keyword)
    if (location.search.includes('searchValue=') && keyword === "") {
      // console.log(location.search)
      const newUrl = location.search.replace(`searchValue=${params.get('searchValue')}&`, '')
      history.push({ search: newUrl })
    }
  }, [keyword || ""])

  useEffect(() => {

    if (!isEvent && keyword) {
      getAllKeywordApi(setOption, keyword);
    } else {
      setOption([])
    }

    // let data = null;
    // if (options && keyword) {
    //   data = options?.filter((option) => {
    //     return (
    //       option?.toLowerCase().startsWith(keyword?.toLowerCase())
    //     );
    //   });

    // } else {
    //   data = [];
    // }
    // setOption(data);
    if (keyword !== searchParams.searchValue) {
      setHide(true);
    }

  }, [keyword]);


  function handleKeyPress(e) {
    if (e.key === "Enter") {
      getKeys(keyword);
      navigate();
      setHide(false);
    }
  };

  function addData(data) {
    setKeyword(data);
    navigate(data);
    setHide(false)
  }
  return (

    <div className="img-form-group w-100">
      <div className="keywords-dropdown-frame">
        <div className="keywords-input-fields ">
          <input
            type="text"
            placeholder={placeholder}
            autoComplete="off"
            className="input-box-keywords"
            name="keyword"
            value={keyword || ""}
            onChange={(e) => setKeyword(e.target.value)}
            onKeyDown={handleKeyPress}
          />
          {keyword && <Image src={close} onClick={() => { addData(""); }} alt="canle icon" className="cancel-field" />}
          <Image
            onClick={() => { getKeys(keyword); navigate(); setHide(false) }}
            src={searchIcon}
            alt="search icon"
            className="search-input-field-icon"
          />
        </div>
        {option.length > 0 && keyword && hide &&
          <ul className="autocomplete-keywords-dropdown">
            {
              option?.map((item, i) => {
                return (
                  <li onClick={() => { addData(item); setHide(false) }} className="lato-medium" key={i}>{item}</li>
                )
              })
            }
          </ul>
        }
      </div>
    </div>

  );
}
