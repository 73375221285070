import React from "react";
import { Button, Dropdown, Image } from "react-bootstrap";


import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


import defaultVideoThmbnail from "../../../assets/images/video-img.png";
// import Profile from "../../../assets/images/profile-des.png";
import defaultIcon from "../../../assets/images/default-grey.png";
import Options from "../../../assets/images/optionsIcon.svg";
import BlackHeart from "../../../assets/images/black-heart.svg";
import BlackEye from "../../../assets/images/view-icon.svg";
import ViewedIcon from "../../../assets/images/black-eye.svg";
import BlackDownload from "../../../assets/images/download.svg";
import BlackBookmark from "../../../assets/images/black-save.svg";
import colorIcon from "../../../assets/images/color-icon.svg";
// import close from "../../../assets/images/close.png";
import close from "../../../assets/images/cancel-icon.svg";
import search from "../../../assets/images/search.svg";
import languageIcon from "../../../assets/images/language-icon.svg";
import Premium from "../../../assets/images/tags.svg";
import Ads from "../../../assets/images/ads-icon.svg";
import FeaturedIcon from "../../../assets/images/featuredAd.png";
import BlackShare from "../../../assets/images/black-share.svg";
import save1 from "../../../assets/images/save-filed.svg";
import save2 from "../../../assets/images/save-holo.svg";
import liked from "../../../assets/images/Like.svg";
import likdeFill from "../../../assets/images/Like-fill.svg";
import viewIcon from "../../../assets/images/view-icon.svg";
import training from '../../../assets/images/eventss-training.png';
import { PiEyeFill } from "react-icons/pi";
// import { AiFillHeart } from "react-icons/ai";
import { useHistory } from "react-router-dom";
import { useState } from "react";
import { API_LIMIT, DEFAULT_PUBLISH_DATE, SHARE_BASE_URL, START_PAGE, USER_TRACKING, UserTracking } from "../../../utils/Constant";
import { formatDate, formatNumber, isPremiumAccess, isToday } from "../../../utils/ReusableFunctions";
import { commonAuthPostApi, commonPostApi } from "../../home/Api";
import { getToken } from "../../../utils/ManageToken";
import Modal from 'react-bootstrap/Modal';
import { useEffect } from "react";
import { getDataApi } from "../../profile/Api";
import { conforms } from "lodash";
import { RWebShare } from "react-web-share";
import { AiFillHeart, AiOutlineHeart } from 'react-icons/ai'
import { TbBookmarkFilled } from 'react-icons/tb'
import { BsFillBookmarkFill, BsBookmarkFill, BiSolidBookmark, BsBookmark } from "react-icons/bs";
import { toast } from "react-toastify";
import SaveCollectionModal from "./SaveCollectionModal";
import SignUp from "./SignUp";
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";
import moment from "moment";
// const userTrackingOption = [
//   { name: UserTracking.SAVED, image: "BsBookmarkFill", label: "Save to Collection" },
//   { name: UserTracking.LIKED, image: "AiFillHeart", label: "Like" },
// { name: UserTracking.DOWNLOADED, image: BlackDownload, label: "Download" },
// { name: UserTracking.SHARED, image: BlackShare, label: "Share" }
// ]
export default function Card({
  classes,
  imageClass,
  defaultImage = defaultVideoThmbnail,
  contentType,
  data = "",
}) {
  const history = useHistory();
  const loacation = useLocation();
  const params = new URLSearchParams(loacation.search);
  const [option, setOption] = useState(false);
  const [isValidThumbnail, setIsValidThumbnail] = useState(true)
  const [saveTo, setSaveTo] = useState(false)
  const [filePath, setFilePath] = useState('')
  const [confirm, setConfirm] = useState(false)
  const [confirm2, setConfirm2] = useState(false)
  const [signup, setSignup] = useState(false);
  const [userStats, setUserStats] = useState({ likeCount: data?.likeCount, isLiked: data?.userStats?.isLiked, isSaved: data?.userStats?.isSaved, isDownloaded: data?.userStats?.isDownloaded, isSearched: data?.userStats?.isSearched, isShared: data?.userStats?.isShared, isViewed: data?.userStats?.isViewed });
  const { likeCount, isLiked, isShared, isDownloaded, isSaved, isViewed } = userStats;
  const [collectionName, setCollectionName] = useState('Collection Name')
  // console.log("check data", imageClass);
  const userTrackingOption = [
    { name: UserTracking.SAVED, image: "BsBookmarkFill", label: "Save to Collection" },
    { name: (!isLiked ? UserTracking.LIKED : UserTracking.UNLIKE), image: "AiFillHeart", label: !isLiked ? "Like" : 'Unliked' },

  ]

  useEffect(() => {
    const patObj = data?.contentVariation?.find((item) => item?.isDefault === true);
    // console.log(patObj)
    setFilePath(patObj?.contentFilePath || '')
  }, [])


  function navigate(data) {
    const isPremium = data.isPremium;
    const token = getToken();
    const isPremiumAccessed = isPremiumAccess();

    const navigateToDetails = () => {
      history.push({
        pathname: `/details/${contentType?.replace("/", "_").toLowerCase()}&id=${data?.uuid || START_PAGE}`,
        state: {
          'breadCrumbPath': (params.get('key') ?? false) ? [params.get('key'), params.get('value')] : '',
          'searchValue': params.get('searchValue') || ""
        }
      });
    };

    if (isPremium) {
      if (!token) {
        setSignup(true);
      } else if (!isPremiumAccessed) {
        setConfirm2(!confirm2);
      } else {
        navigateToDetails();
      }
    } else {
      navigateToDetails();
    }
  }

  // ec2c5f68-5de8-11ee-9c26-b48c9dd517c6
  async function userTrackingHandler(content) {
    // console.log(content)
    const info = {
      "data": {
        "contentUuid": data.uuid,
        "type": content,
        "contentVariationUuid": data?.defaultVariation && data?.defaultVariation.uuid ? data?.defaultVariation.uuid : data?.contentVariation.find(x => x.isDefault === true)?.uuid,
      }
    }
    let token = getToken();
    if (!token) {
      if (content !== UserTracking.SHARED) {
        // console.log(content !== UserTracking.SHARED)
        toast.error("You need to login first");
        setSignup(true);
      }
      // alert("You need to login first");
    } else if (content === UserTracking.SAVED) {
      setSaveTo(!saveTo);
    } else {
      const response = await commonAuthPostApi(USER_TRACKING, info);
      // console.log("In card Like, Saved, share", response);
      if (content === UserTracking.LIKED || content === UserTracking.UNLIKE) {
        const newLikeCount = !isLiked ? likeCount + 1 : likeCount - 1;
        setUserStats({ ...userStats, likeCount: newLikeCount, isLiked: content === UserTracking.LIKED });
      }
      if (response && !response?.message) {
        toast.success(`Succesfully ${content}`);
      }
    }


  }
  async function downloadTrackingHandler() {
    const info = {
      "data": {
        "contentUuid": data.uuid,
        "type": UserTracking.DOWNLOADED,
        "contentVariationUuid": data?.defaultVariation ? data?.defaultVariation.uuid : data?.contentVariation.find(x => x.isDefault === true)?.uuid,
      }
    }
    let token = getToken();
    const anchor = document.createElement('a');
    const patObj = data?.contentVariation?.find((item) => item?.isDefault === true);
    // console.log("In card Like, Saved, share", response);
    if (patObj?.contentFilePath && token) {
      const response = await commonAuthPostApi(USER_TRACKING, info);
      if (!response.message) {
        toast.success(`Successfully Downloaded`);
        // console.log("Check Path", patObj);
        patObj?.contentFilePath ? anchor.href = patObj?.contentFilePath : toast.error("No download file available.");
        anchor.target = "_blank";
        anchor.click();
      }
    }

  }
  // conformCollectionPopUp
  function conformCollectionPopUp(data) {
    // console.log("Conform to saved says,", data)
    setSaveTo(data.label);
    setCollectionName(data.selectedItem);
    setConfirm(!confirm);

    if (!data.unSaved) {
      setUserStats({ ...userStats, isSaved: false });
    } else {
      setUserStats({ ...userStats, isSaved: true });
    }
  }
  function navigateAmcDetails() {
    history.push({ pathname: `/content-type/All`, search: `?key=amc&value=${data?.amc}&pageNumber=${START_PAGE}&pageSize=${API_LIMIT}`, state: data })//temp pagesze remve ,becuse its not in backend
  }
  // console.log("Check date=>",data?.publishedAt)

 
  return (
    <div className={`common-card-box ${classes}`}>
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}
      <div className={`main-img position-relative lato-medium fs-14-12 ${(data.thumbnail && isValidThumbnail) ? "" : 'overlay-bg'} ${imageClass}`}
        onClick={() => navigate(data)}
      >{data.thumbnail && isValidThumbnail ?
        // <Image
        //   src={data.thumbnail || defaultImage}
        //   className={`main-img ${imageClass}`}
        //   alt="image"
        //   onError={() => { setIsValidThumbnail(false) }}
        // // onLoad={()=>console.log("loading ...")}//loding function
        // />
        <ImageBlurEffect path={data.thumbnail || defaultImage} setIsValidThumbnail={setIsValidThumbnail} imageClass={imageClass} />
        :
        data.title || "Default title"
        }
        <div className="premium-icon">
          {data?.isPremium && (
            <Image src={Premium} className="premium-img" alt="Premium" />
          )}
          {data?.isAd && (
            <Image src={Ads} className="add-ion" alt="ads" />
          )}
          {/* {data?.isFeaturedAd && (
            <Image src={FeaturedIcon} className="add-ion" alt="ads" />
          )} */}
        </div>

        {/* ===================  AMC LOGO ADDED FOR MOBILE ============== */}
        {
          <Image src={data?.amcCircleImagePath || data?.creatorCircleImagePath || defaultIcon} className="des-profile amc-logo-mobile mb-show" title={data?.amc} alt="amc logo" />
        }
        {/* ===================  AMC LOGO ADDED FOR MOBILE ============== */}
        {/* =========================== COLOR LANGUAGE ICONS ============== */}
        <div className="color-language-icon-wrapper">
          {data?.isMultiColor &&
            <div className="color-frame">
              <Image src={colorIcon} alt="color Icon" />
              <p className="lato-regular fx-12 black-242 ">Color option available</p>
            </div>
          }
          {data?.isMultiLanguage &&
            <div className="language-frame">
              <Image src={languageIcon} alt="language Icon" />
              <p className="lato-regular fx-12 black-242 ">Multiple languages available</p>
            </div>
          }
        </div>
        {/* =========================== COLOR LANGUAGE ICONS ============== */}
      </div>

      <div className="description-box">
        <Image onClick={data?.amcCircleImagePath && navigateAmcDetails} src={data?.amcCircleImagePath || data?.creatorCircleImagePath || defaultIcon} className="des-profile desk-show" title={data?.amc} />
        <div className="des-content-wrappper" onClick={() => navigate(data)}>
          <p onClick={() => navigate(data)} className=" fs-16-14 lato-regular black-242" title={data.title || "NA"}>
            {data.title || "NA"}
          </p>
          <p className=" fs-14-11 lato-medium black-242 mt-1">
            <span>

              {isLiked ? <Image src={likdeFill} className="imageIcon social-icon fs-14-12" /> : <Image src={liked} className="imageIcon social-icon fs-14-12" />}
              {formatNumber(data?.likeCount === likeCount ? data?.likeCount : data?.likeCount < likeCount ? data?.likeCount + 1 : data?.likeCount - 1) || 0}
              {/* {formatNumber(likeCount) || 0} */}
            </span>
            <span className="mb-border-none fs-14-11">
              {/* <PiEyeFill  className="icon fs-14-12" /> */}
              {isViewed ? <Image src={ViewedIcon} className="icon w-20 fs-14-12" /> : <Image src={BlackEye} className="icon w-20 fs-14-12" />}

              {/* <Image src={isViewed?ViewedIcon:BlackEye} className="icon w-17 fs-14-12" /> */}
              {formatNumber(data?.viewCount) || 0}
            </span>
            {
              data?.publishedAt && data?.publishedAt !== DEFAULT_PUBLISH_DATE &&
              <span className="desk-show fs-14-11">{isToday(data?.publishedAt) ? 'today' : moment(data?.publishedAt).fromNow()}</span>
            }

          </p>
        </div>
        {/* ========================== Three drop down ========================== */}
        <Dropdown>
          <Dropdown.Toggle className="dropdown-toggle  option-icon" id="dropdown-basic">
            <Image
              src={Options}
              // className="option-icon"
              alt="icon"
            />
          </Dropdown.Toggle>
          <Dropdown.Menu className="card-options">
            {
              userTrackingOption?.map((item, i) => {
                return (
                  <Dropdown.Item className="lato-semibold d-flex fx-14 black-242" onClick={() => userTrackingHandler(item.name)}>
                    {/* <Image src={item.image} alt="icon" style={{color:isLiked?"red":""}} /> */}
                    <spam className="icon-fixe-width">
                      {item.image === "AiFillHeart" ? (
                        isLiked ? <Image src={likdeFill} className="imageIcon social-icon m-0" /> : <Image src={liked} className="imageIcon social-icon" />
                      ) : (
                        (isSaved) ? <Image src={save1} className="imageIcon m-0" /> : <Image src={save2} className="imageIcon" />
                      )}
                    </spam>
                    {item.label}
                  </Dropdown.Item>

                )
              })
            }
            <Dropdown.Item className={`lato-semibold fx-14 d-flex black-242 ${filePath ? '' : 'pointer-none disable-item'}`} onClick={(data?.isPremium || !filePath) ? null : downloadTrackingHandler} >
              <spam className="icon-fixe-width"> <Image src={BlackDownload} alt="icon" className="imageIcon m-0" />
              </spam>
              Download
            </Dropdown.Item>

            <Dropdown.Item className="lato-semibold fx-14 black-242" >
              <RWebShare
                data={{
                  text: "",
                  url: `${SHARE_BASE_URL + "details/" + contentType.toLowerCase() + "&id=" + data.uuid}`,
                  title: data.title,
                }}
                onClick={() => { userTrackingHandler(UserTracking.SHARED) }}
              >

                <div className="lato-semibold fx-14 black-242 d-flex share-wrapper-btn ">
                  <spam className="icon-fixe-width">
                    <Image src={BlackShare} className="imageIcon w-21" alt="icon" />
                  </spam>
                  Share </div>
              </RWebShare>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
        {/* ========================== Three drop down ========================== */}
      </div>
      {/* ?============================ SAVE TO MODAL START ========================= */}
      {saveTo &&
        <SaveCollectionModal id={data.uuid} variationId={data.contentVariation && data.contentVariation[0]?.uuid || data?.defaultVariation?.uuid} show={saveTo} handleClose={() => setSaveTo(!saveTo)} collectionPopUp={conformCollectionPopUp} isUnSaved={true} />
      }
      {/* ?============================ SAVE TO MODAL END  ========================== */}
      {
        confirm &&
        <Modal className="save-confirm-collection-custom-model" show={confirm} onHide={() => setConfirm(!confirm)}>

          <Modal.Body className="save-confirm-collection-model-body">
            <Image src={close} onClick={() => setConfirm(!confirm)} alt="canle icon" className="close-btn pointer" />
            <h4 className="lato-regular fs-28-18 black-242 w-100 text-center">{collectionName}</h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mycollections') }}>View Collection</div>
            </div>
          </Modal.Body>
        </Modal>

      }
      {/* -------------------------------- UPGRD PRENIUM PLAN MODAL START------------------------ */}
      {
        confirm2 &&
        <Modal className="save-confirm-collection-custom-model" show={confirm2} onHide={() => setConfirm2(!confirm2)}>

          <Modal.Body className="save-confirm-collection-model-body text-center">
            <Image src={close} onClick={() => setConfirm2(!confirm2)} alt="canle icon" className="close-btn pointer" />
            <Image src={training} className="mb-10" />
            <h4 className="lato-regular fs-24-16 black-242 w-100 text-center">This content is available only to the subscribed members. Want to be a part of this <span className="primary"> premium community</span>? </h4>
            <div className="d-flex justify-content-center ">
              <div className={`primary-btn py-3 pointer`} onClick={() => { history.push('/mymembership') }}>View Membership Plans</div>
            </div>
          </Modal.Body>
        </Modal>
      }
      {/* -------------------------------- UPGRD PRENIUM PLAN MODAL START------------------------ */}
    </div>
  );
}





const ImageBlurEffect = ({ path, setIsValidThumbnail, imageClass }) => {
  const [isImageLoaded, setImageLoaded] = useState(false);
  const lowQualityUrl = `${path}?q=low&f=auto&blur=500`;

  return (<>

    <LazyLoadImage
      className={`main-img ${imageClass}`}
      alt="image"
      effect="blur"
      src={path}
      onLoad={(e)=>{}}
      onError={() => setIsValidThumbnail(false)}
    />
  </>
  );
};


