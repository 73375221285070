import React from 'react';
import { Image } from "react-bootstrap";
import { API_LIMIT, SHARE_RAZ_KEY, START_PAGE } from './Constant';
import { commonAuthApis } from '../app/profile/Api';
import logo from '../assets/images/brand-logo.png';
import { toast } from 'react-toastify';
import download from "../assets/images/Download_1.png";
import share from "../assets/images/Share_1.png";
import { RWebShare } from 'react-web-share';
import { getCommonApi } from '../app/events/Api';
import moment from 'moment';

export const getUser = () => {
  return JSON.parse(localStorage.getItem('finUserDetails'));
}

// GET EXTENSION NAME WRAPPER
export const getExtensionNameWrapper = (attachmentName, attachmentUrl, className) => {
  const re = /(?:\.([^.]+))?$/;
  let ext = re.exec(attachmentName)[1];
  let attachmentWrapper;

  if (ext === "jpeg" || ext === "png" || ext === "jpg") {
    attachmentWrapper = (
      <Image
        src={attachmentUrl}
        alt="link-preview"
      />
    )
  } else {
    attachmentWrapper = (
      <span className={className}>{ext}</span>
    )
  }
  return attachmentWrapper
}

export const formatNumber = (num, precision = 0) => {
  const map = [
    { suffix: 'T', threshold: 1e12 },
    { suffix: 'B', threshold: 1e9 },
    { suffix: 'M', threshold: 1e6 },
    { suffix: 'K', threshold: 1e3 },
    { suffix: '', threshold: 1 },
  ];

  const found = map.find((x) => Math.abs(num) >= x.threshold);
  if (found) {
    const formatted = (num / found.threshold).toFixed(precision) + found.suffix;
    return formatted;
  }
  return num;
}

export const calculateTotalPageCount = (totalCount) => {
  return Math.ceil(totalCount / API_LIMIT)
}

export const navigateSetUrl = (history, pathname, search) => {
  history.push({
    ...(pathname && { pathname: pathname }),
    ...(search && { search: search })
  })
}

export const getSearchParams = (location) => {
  const params = new URLSearchParams(location.search);
  // console.log('params', params);
  let searchValue = params.get("searchValue") || "";
  let page = params.get("pageNumber") || START_PAGE;
  return ({ searchValue, page })
}

// export const formatDate = (timestamp) => {
//   let date = new Date(timestamp);
//   let current = new Date();
//   let diffTime = Math.abs(current - date);
//   let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
//   return (diffDays + " days ago");
// }


export const formatDate = (timestamp) => {
  const date = new Date(timestamp);
  const current = new Date();
  const diffTime = Math.abs(current - date);
  const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  if (isNaN(parseFloat(diffDays))) {
    return ""
  } else if (diffDays <= 1) {
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, '0');
    const amPm = hours < 12 ? 'AM' : 'PM';
    const formattedHours = (hours % 12 || 12).toString().padStart(2, '0');
    return `${formattedHours}:${minutes} ${amPm} today`;
  } else if (diffDays <= 6) {

    return `${diffDays} day${diffDays !== 1 ? 's' : ''} ago`;
  } else if (diffDays <= 13) {

    const weeks = Math.floor(diffDays / 7);
    return `${weeks} week${weeks !== 1 ? 's' : ''} ago`;
  } else if (diffDays <= 30) {

    return '2 weeks ago';
  } else if (diffDays <= 365) {

    const months = Math.floor(diffDays / 30);
    return `${months} month${months !== 1 ? 's' : ''} ago`;
  } else {

    const years = Math.floor(diffDays / 365);
    return `${years} year${years !== 1 ? 's' : ''} ago`;
  }
};



function convertToTitleCase(str) {
  return str.toLowerCase().replace(/-\w/g, match => match.charAt(1).toUpperCase());
}

export const formatCurrency = (x) => {
  return x.toLocaleString('en-IN');
}

export const showShare = (calculator) => {
  return (
    <div className="result-download-icon">
      <span>
        <Image src={download} alt="download"  />
      </span>{" "}
      <span>
        <RWebShare
          data={{
            text: calculator?.name,
            url: calculator?.url,
            title: calculator?.name,
          }}
        >
          <Image src={share} alt="download" />
        </RWebShare>
      </span>{" "}
    </div>
  )
}

export const ShowShare = ({calculator, clickHandler}) => {
  return (
    <div className="result-download-icon">
      <span onClick={clickHandler}>
        <Image src={download} alt="download"  />
      </span>{" "}
      <span>
        <RWebShare
          data={{
            text: calculator?.name,
            url: calculator?.url,
            title: calculator?.name,
          }}
        >
          <Image src={share} alt="download" />
        </RWebShare>
      </span>{" "}
    </div>
  )
}

export const isPremiumAccess = () => {
  return JSON.parse(localStorage.getItem("finUserDetails"))?.isPremium || false;

}
export const isEventExpired = (endDate) => {
  return moment(endDate).isBefore(moment());
};
export const reUpdatedUserDetails = async() => {
  const res = await getCommonApi('user/details');
  if (res.status >= 200 && res.status <= 210) {
    localStorage.setItem("finUserDetails",JSON.stringify(res.data?.data));
  }
  
}
export function isToday(date){
  const publishedAtDate = moment(date);
  const currentDate = moment();
  const hoursDifference = currentDate.diff(publishedAtDate, 'hours');
  return hoursDifference < 24;
}

// export const reaxorpayHandler = async (data,setState) => {
//   const userDetails = getUser() || {};
//   let paymentSucessRes=false;
//   let orderData = {
//     "data": {
//       "amount": data.breakup.finalAmount * 100,
//       "currency": "INR",
//       "receipt": "Finpedia"
//     }
//   }
//   const res = await commonAuthApis('payment/order', orderData);
//   // console.log(res)
//   if (res.status >= 200 && res.status <= 210) {
//     toast.success('Order is Recived...')
//     var options = {
//       "key": SHARE_RAZ_KEY, // Enter the Key ID generated from the Dashboard
//       "amount": res?.data?.data.amount, // Amount is in currency subunits. Default currency is INR. Hence, 50000 refers to 50000 paise
//       "currency": res?.data?.data.currency,
//       "name": "Thefinpedia",
//       "description": "Test Transaction",
//       "image": logo,
//       "order_id": res?.data?.data.id, //This is a sample Order ID. Pass the `id` obtained in the response of Step 1
//       // "callback_url": "http://13.200.83.191:3001/finpedia/v1/payment/checkout",
//       "handler": async function (response) {
//         const res = await commonAuthApis('payment/checkout', { "data": response });
//         // console.log(res)
//         if (res.status === 200) {
//           console.log("inRaxer Pay",res)
//         }
//       },
//       "prefill": {
//         "name": userDetails?.firstName + userDetails?.lastName ,
//         "email": userDetails.emailAddress,
//         "contact": userDetails?.mobileNumber
//       },
//       "notes": {
//         "address": "Razorpay Corporate Office"
//       },
//       "theme": {
//         "color": "#3399cc"
//       }
//     };
//     let rzp1 = new window.Razorpay(options);
//     rzp1.open();
//   }

// }





export async function convertImageToBase64(url) {
  try {

    const response = await fetch(url);
    if (!response.ok) throw new Error('Network response was not ok');
    
    const blob = await response.blob();
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });
  } catch (error) {
    console.error('Error fetching image:', error);
    throw error; 
  }
}
