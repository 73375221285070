import React from "react";
import { useLocation, useHistory } from "react-router-dom";
import { formatNumber, getSearchParams } from "../../../utils/ReusableFunctions";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { API_LIMIT, START_PAGE } from "../../../utils/Constant";

export default function CardHeader({ count, hide = true, countTitle, isSubCount = true, viewAll = "/social-media" }) {
  const history = useHistory();
  const location = useLocation();
  const param = useParams();
  const params = new URLSearchParams(location.search)
  // console.log("In Card Header",hide);
  async function navigateData() {
    // console.log(location.search.split('pageSize=')[0] + 'pageSize=18');

    let searchUrl = `${((params.get('searchValue') ?? false) || (params.get('value') ?? false)) ? `searchValue=${params.get('searchValue') ?? params.get('value')}` : ''}&pageNumber=${params.get('pageNumber') || START_PAGE}&pageSize=${params.get('pageSize') || API_LIMIT}`;

    history.push({
      pathname: viewAll,
      search: (params.get('key') ?? false) ? location.search : searchUrl,
    });
  }

  return (
    <div className="curated-sub-header">
      <h5 className="lato-regular fs-28-18 black-242">
        {countTitle}&nbsp;&nbsp;&nbsp;&nbsp;
        {isSubCount && <span className="lato-regular fs-14-12">{formatNumber(count)}</span>}
      </h5>
      {hide && count >= 3 && (
        <p onClick={navigateData} className=" fs-14-12 lato-medium black-242">
          View all
          {/* <span className={isSubCount ? 'desk-show' : ""}>
            {formatNumber(count)} {countTitle}
          </span> */}
        </p>
      )}
    </div>
  );
}
