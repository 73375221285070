import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import abcLogo from "../../assets/images/abc.png";
import { Image } from 'react-bootstrap'
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { useMemo } from 'react';
import { useRef } from 'react';
import { SignUp } from '../common/components';
export default function ChildEducationPlanner({ calculator, textLogo = '' }) {

  let [presentAge, setPresentAge] = useState(0);
  let [presentValue, setPresentValue] = useState(1000000);
  let [corpusAge, setCorpusAge] = useState(18);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [inflation, setInflation] = useState(7);
  let [SIPStepUp, setSIPStepUp] = useState(10);



  let [fixedSIP, setFixedSIP] = useState(4416);
  let [growingSIP, setGrowingSIP] = useState(2345);

  let [corpusFV, setCorpusFV] = useState(3379932);
  let [fixedSIPInvesment, setFixedSIPInvesment] = useState(953856);
  let [cumulativeSIP, setCumulativeSIP] = useState(1439620);
  let [lumpsum, setLumpsum] = useState(439525);




  useEffect(() => {

  }, [presentAge, presentValue, corpusAge, rateOfInterest, inflation, SIPStepUp])

  const handleChange = (value, name) => {
    // console.log('name', name);
    if (name === "presentAge") {
      presentAge = value
    }
    if (name === "presentValue") {
      presentValue = value
    }
    if (name === "corpusAge") {
      corpusAge = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "inflation") {
      inflation = value
    }
    if (name === "SIPStepUp") {
      SIPStepUp = value
    }

    onSubmitAction(presentAge, presentValue, corpusAge, rateOfInterest, inflation, SIPStepUp);
  }
  const onSubmitAction = (presentAge, presentValue, corpusAge, roi, inflation, SIPStepUp) => {
    //   roi = document.getElementById("input1").value;
    // presentValue = document.getElementById("input2").value.replace(/\D+/g, "");
    // inflation = document.getElementById("input3").value;
    // presentAge = document.getElementById("input4").value;
    // corpusAge = document.getElementById("input5").value;
    // SIPStepUp = document.getElementById("input6").value;
    corpusFV = Math.round((inflation / 100 + 1) ** (corpusAge - presentAge) * presentValue,);
    let toDivideROI = roi / 100 / 12;
    lumpsum = Math.round((1 / (roi / 100 + 1) ** (corpusAge - presentAge)) * corpusFV,);

    let tenure = (corpusAge - presentAge) * 12;
    fixedSIP = Math.round(
      corpusFV /
      (((roi / 100 / 12 + 1) ** tenure - 1) / toDivideROI) /
      (roi / 100 / 12 + 1),
    );

    let corpusPV = presentValue;
    let fixedSIPInvesment = fixedSIP * corpusAge * 12;


    setFixedSIP(fixedSIP);
    setGrowingSIP(growingSIP);

    setFixedSIPInvesment(fixedSIPInvesment);
    setCumulativeSIP(0);
    setLumpsum(lumpsum);
    setCorpusFV(Math.round(corpusFV));
    // myChart.config.data.datasets[0].data[0] = fixedSIP;
    // myChart.config.data.datasets[0].data[1] = growingSIP;

    // myChart2.config.data.datasets[0].data[0] = fixedSIPInvesment;
    // myChart2.config.data.datasets[0].data[1] = 0;
    // myChart2.config.data.datasets[0].data[2] = lumpsum;
    // myChart2.config.data.datasets[0].data[3] = Math.round(corpusFV);
    // myChart.update();
    // myChart2.update();

    // document.getElementById("fixedSIP").innerHTML = internationalNumberFormat.format(fixedSIP);
    // document.getElementById("growingSIP").innerHTML = internationalNumberFormat.format(growingSIP);
    // document.getElementById("fixedSIPInvesment").innerHTML = internationalNumberFormat.format(fixedSIPInvesment);
    // document.getElementById("cumulativeSIP").innerHTML = internationalNumberFormat.format(cumulativeSIP);
    // document.getElementById("corpusFV").innerHTML = internationalNumberFormat.format(corpusFV);

    // document.getElementById('corpusPV').innerHTML=corpusPV;
    // document.getElementById('corpusFV').innerHTML=Math.round(corpusFV).toLocaleString('en', {useGrouping:true});;
    // document.getElementById("lumpsum").innerHTML = internationalNumberFormat.format(lumpsum);

    let corpus1 = Math.round(corpusFV);
    let init_pmt = -10000;
    let rate_of_return = roi; // in percentage
    let total_period = (corpusAge - presentAge); //years
    let growth_rate = SIPStepUp; // in percentage
    let goal = corpus1;


    const fnParams = [
      init_pmt,
      rate_of_return / 100,
      total_period * 12,
      growth_rate / 100,
    ];

    try {
      const result = goalSeek({
        fn,
        fnParams,
        percentTolerance: 0.1,
        maxIterations: 100,
        maxStep: 0.1,
        goal: goal,
        independentVariableIdx: 0,
      });

      let result1 = Math.ceil(Math.abs(result));

      setGrowingSIP(result1);
      // myChart.config.data.datasets[0].data[1] = result1;
      // myChart.update();
      // document.getElementById("growingSIP").innerHTML = internationalNumberFormat.format(result1);

      let totalCorpus = totalSIP(result1, total_period, growth_rate);
      setCumulativeSIP(totalCorpus)
      // document.getElementById("cumulativeSIP").innerHTML = internationalNumberFormat.format(totalCorpus);
      // myChart2.config.data.datasets[0].data[1] = totalCorpus;
      // myChart2.update();

    } catch (e) {
      // console.log('error', e);
    }

  };

  const goalSeek = ({ fn, fnParams, percentTolerance, customToleranceFn, maxIterations, maxStep, goal, independentVariableIdx, }) => {
    if (typeof customToleranceFn !== 'function') {
      if (!percentTolerance) {
        throw Error('invalid inputs');
      }
    }
    let g;
    let y;
    let y1;
    let oldGuess;
    let newGuess;
    let res;
    const absoluteTolerance = ((percentTolerance || 0) / 100) * goal;
    // iterate through the guesses
    for (let i = 0; i < maxIterations; i++) {
      // define the root of the function as the error
      res = fn.apply(null, fnParams);
      res = parseFloat(res);

      y = res - goal;
      // console.log(`result: ${res}; y: ${y}`);
      if (isNaN(y))
        throw TypeError('resulted in NaN 1');

      // was our initial guess a good one?
      if (typeof customToleranceFn !== 'function') {
        if (Math.abs(y) <= Math.abs(absoluteTolerance))
          return fnParams[independentVariableIdx];
      }
      else {
        if (customToleranceFn(res))
          return fnParams[independentVariableIdx];
      }
      // set the new guess, correcting for maxStep
      oldGuess = fnParams[independentVariableIdx];
      newGuess = oldGuess + y;
      // if (Math.abs(newGuess - oldGuess) > maxStep) {
      //     if (newGuess > oldGuess) {
      //         newGuess = oldGuess + maxStep;
      //     }
      //     else {
      //         newGuess = oldGuess - maxStep;
      //     }
      // }
      fnParams[independentVariableIdx] = newGuess;
      // re-run the fn with the new guess
      y1 = parseInt(fn.apply(null, fnParams)) - goal;

      // console.log('A', parseInt(fn.apply(null, fnParams)));
      // console.log('B', goal);
      // console.log('D', y1);

      if (isNaN(y1))
        throw TypeError('resulted in NaN 2');
      // calculate the error
      g = (y1 - y) / y;
      if (g === 0)
        g = 0.0001;
      // set the new guess based on the error, correcting for maxStep
      newGuess = oldGuess - y / g;
      // if (maxStep && Math.abs(newGuess - oldGuess) > maxStep) {
      //     if (newGuess > oldGuess) {
      //         newGuess = oldGuess + maxStep;
      //     }
      //     else {
      //         newGuess = oldGuess - maxStep;
      //     }
      // }
      fnParams[independentVariableIdx] = newGuess;
    }
    // done with iterations, and we failed to converge
    throw Error('failed to converge');
  };

  const fn = function calculate(pmt, rate, tper, growth) {
    let years;
    let cum_returns = 0;
    let growth_calc;
    let i;
    let this_pmt;
    let this_nper;
    let fv;

    // console.log(`try with pmt: ${pmt}`);
    growth_calc = 1 + growth;
    years = tper / 12;
    for (i = 1; i <= years; i++) {
      if (i == 1) {
        this_pmt = pmt;
      } else {
        this_pmt = pmt * ((growth_calc ** (i - 1)) - (growth_calc ** (i - 2)));
      }
      this_nper = tper - ((i - 1) * 12);
      // console.log(`rate: ${rate}; nper=${this_nper}; pmt: ${this_pmt}`);
      fv = parseFloat(FV(rate, this_nper, this_pmt, 0, 1));
      // console.log(`year: ${i}; fv=${fv}`);
      cum_returns = cum_returns + fv;
    }
    return cum_returns
  }

  function FV(rate, nper, pmt, pv, type) {
    let pow = Math.pow(1 + (rate / 12), nper),
      fv;
    if (rate) {
      fv = (pmt * (1 + (rate / 12) * type) * (1 - pow) / (rate / 12)) - pv * pow;
    } else {
      fv = -1 * (pv + pmt * nper);
    }
    return fv.toFixed(2);
  }

  function totalSIP(firstValue, tenure, rate) {
    let totalValue = firstValue * 12;
    for (let i = 0; i < tenure; i++) {
      let temp = ((1 + (rate / 100)) * firstValue).toFixed(2);
      totalValue += temp * 12;
      firstValue = temp;
    }
    return Math.round(totalValue);
  }




  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP"],
          data: [fixedSIP, growingSIP],
        }}
      />
    );
  }, [fixedSIP, growingSIP]);
  const barChart2 = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Education Corpus"],
          data: [fixedSIPInvesment, cumulativeSIP, lumpsum, Math.round(corpusFV)],
        }}
      />

    );
  }, [fixedSIPInvesment, cumulativeSIP, Math.round(corpusFV), lumpsum]);


  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          <RangeFields
            label="Current Age of Child"
            name={"presentAge"}
            value={presentAge}
            minVal={0}
            maxVal={30}
            step={1}
            unit="Year"
            getValue={handleChange} />

          <RangeFields
            label="Corpus Required"
            name={"presentValue"}
            value={presentValue}
            minVal={100000}
            maxVal={1000000000}
            step={5000}
            getValue={handleChange}
          />
          <RangeFields
            label="Corpus Required at Age of"
            name={'corpusAge'}
            value={corpusAge}
            minVal={5}
            maxVal={31}
            step={1}
            unit="Year"
            getValue={handleChange}
          />
          <RangeFields
            label="Expected Rate of Return"
            name={'rateOfInterest'}
            value={rateOfInterest}
            minVal={4}
            maxVal={50}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />
          <RangeFields
            label="Rate of Inflation"
            name={'inflation'}
            value={inflation}
            minVal={1}
            maxVal={20}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />
          <RangeFields
            label="SIP Step Up (Annual)"
            name={'SIPStepUp'}
            value={SIPStepUp}
            minVal={1}
            maxVal={25}
            step={0.5}
            unit="%"
            getValue={handleChange}
          />


          {/* <RangeFields label="Delay" minVal={6} maxVal={50} unit="Year" /> */}
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Monthly SIP Amount</p>
              {/* <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP"],
                  data: [fixedSIP, growingSIP],
                }}
              /> */}
              {barChart}
            </div>
            <div className={`graph-1-frame flex-column ${false ? "w-auto" : "w-100"}`}>
              <p className='lato-semibold  f-s-12 lh-14 primary text-center pt-2'>Total Investment</p>
              {/* <BarChart
                info={{
                  labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Education Corpus"],
                  data: [fixedSIPInvesment, cumulativeSIP, lumpsum, Math.round(corpusFV)],
                }}
              /> */}
              {barChart2}
            </div>
          </div>
          <div className='d-flex justify-content-around p-2'>
            <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
              If the corpus required for your child education today is <span className=" lato-semibold primary">₹{formatCurrency(presentValue)}</span>  when your child is <span className=" lato-semibold primary">{presentAge}</span>  years of age, the corpus required will increase to <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span>  when you child turns <span className=" lato-semibold primary">{corpusAge}</span> years of age, considering inflation of <span className=" lato-semibold primary">{inflation}%</span>. And to accumulate this corpus, you can either start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span>, or do a Step Up SIP of <span className=" lato-semibold primary">₹{formatCurrency(growingSIP)}</span> or invest lumpsum amount of ₹"Lumpsum Amount", considering your investment grows at <span className=" lato-semibold primary">{rateOfInterest}%</span> and an Step Up SIP rate of <span className=" lato-semibold primary">{SIPStepUp}%</span> annually. At the end of investment tenure, your total investment to accumulate <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span> corpus, in case of SIP will be <span className=" lato-semibold primary">₹{formatCurrency(fixedSIPInvesment)}</span>, in case of Step UP SIP will be <span className=" lato-semibold primary">₹{formatCurrency(cumulativeSIP)}</span>, and in case of lumpsum will be <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>
            </p>
          </div>
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          corpusAge={corpusAge}
          textLogo={textLogo}
          rateOfInterest={rateOfInterest}
          SIPStepUp={SIPStepUp}
          fixedSIP={fixedSIP}
          growingSIP={growingSIP}
          fixedSIPInvesment={fixedSIPInvesment}
          cumulativeSIP={cumulativeSIP}
          lumpsum={lumpsum}
          corpusFV={corpusFV}
          inflation={inflation}
          presentAge={presentAge}
          presentValue={presentValue}
          onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}

    </div>
  )
}



function CalculatorOutputScreenShot({ corpusAge, textLogo, rateOfInterest, SIPStepUp, fixedSIP, growingSIP, fixedSIPInvesment, sipAmount, cumulativeSIP, lumpsum, corpusFV, presentValue, presentAge, onDownloadScreenshot, inflation }) {
  const { cobrandingLabelling, investor } = textLogo;
  const userProfileDetails = getUser();
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const [signup, setSignup] = useState(false);

  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current && getToken()) {
          html2canvas(screenshotRef.current, { useCORS: true }).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        } else {
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (
    <>
      {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}



      <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
        <div className={`tolls-calulator-output `}>
          {cobrandingLabelling?.startLogo &&
            <Image src={companyLogo.logoUrl || abcLogo} className='startlabeling-size ' alt='logo' />
          }
          {cobrandingLabelling?.startText &&
            <div className='branding-details-box'>
              <div className="">
                <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
                <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
              </div>
              <div className="">
                <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
                <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
              </div>
            </div>}

        </div>

        {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
        <div className="result-graph-wrapper">
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP"],
                data: [fixedSIP, growingSIP],
              }}
            />

          </div>
          <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
            <BarChart
              info={{
                labels: ["Fixed SIP", "Step Up SIP", "Lumpsum", "Education Corpus"],
                data: [fixedSIPInvesment, cumulativeSIP, lumpsum, Math.round(corpusFV)],
              }}
            />

          </div>
        </div>
        <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
        <br />
        <div className='d-flex justify-content-around p-2'>
          <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
            If the corpus required for your child education today is <span className=" lato-semibold primary">₹{formatCurrency(presentValue)}</span>  when your child is <span className=" lato-semibold primary">{presentAge}</span>  years of age, the corpus required will increase to <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span>  when you child turns <span className=" lato-semibold primary">{corpusAge}</span> years of age, considering inflation of <span className=" lato-semibold primary">{inflation}%</span>. And to accumulate this corpus, you can either start a monthly SIP of <span className=" lato-semibold primary">₹{formatCurrency(fixedSIP)}</span>, or do a Step Up SIP of <span className=" lato-semibold primary">₹{formatCurrency(growingSIP)}</span> or invest lumpsum amount of ₹"Lumpsum Amount", considering your investment grows at <span className=" lato-semibold primary">{rateOfInterest}%</span> and an Step Up SIP rate of <span className=" lato-semibold primary">{SIPStepUp}%</span> annually. At the end of investment tenure, your total investment to accumulate <span className=" lato-semibold primary">₹{formatCurrency(corpusFV)}</span> corpus, in case of SIP will be <span className=" lato-semibold primary">₹{formatCurrency(fixedSIPInvesment)}</span>, in case of Step UP SIP will be <span className=" lato-semibold primary">₹{formatCurrency(cumulativeSIP)}</span>, and in case of lumpsum will be <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span>
          </p>
        </div>

        <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
          Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
        </p>
        <div className="bg-primary lh-16 mt-5">&nbspl;</div>

      </div>

    </>
  )
}