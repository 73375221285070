
// import moment from 'moment/moment';
// import React, { useEffect, useState } from 'react';
// import DatePicker from "react-datepicker";
// import "react-datepicker/dist/react-datepicker.css"; 
// import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min';

// export default function DateRangePicker({getDate = () => {}}) {
//     const location = useLocation();
//     const history = useHistory();
//     const params = new URLSearchParams(location?.search);
//     const [date, setDate] = useState({ startDate: params.get('from') || null, endDate: params.get('to') || null });

//     const onChange = (dates, to) => {
//         const newDate = to === 'start' 
//             ? { ...date, startDate: dates } 
//             : { ...date, endDate: dates };
//         setDate(newDate); 
//         // console.log(newDate); 
//     };

//     useEffect(() => {
//           console.log("checking is call or not")
//         const formattedDate = {
//             startDate: date.startDate ? date?.startDate?.toISOString() : '',
//             endDate: date.endDate ? date?.endDate?.toISOString() : '',
//         };
//         // if(date.startDate || date.endDate) getDate(formattedDate);
//         const { startDate, endDate } = formattedDate;
//         if (startDate) params.set('from', decodeURIComponent(startDate));
//         else params.delete('from');

//         if (endDate) params.set('to',decodeURIComponent(endDate));
//         else params.delete('to');

//         history.push({
//           pathname: `/events`,
//           search: params.toString(),
//         });
//         // getDate(formattedDate)
//     }, [date]);

//     return (
//         <div className='d-flex gap-12'>
//             <DatePicker
//                 selected={date.startDate}
//                 onChange={(date) => onChange(date, "start")}
//                 selectsStart
//                 // startDate={date.startDate}
//                 maxDate={date.endDate}
//                 // minDate={moment().toDate()}
//                 isClearable={!!date.startDate}
//                 dateFormat="MMMM d, yyyy"
//                 placeholderText="Start Date"
//                 className="form-control"
//                 showYearDropdown
//                 yearDropdownItemNumber={50}
//                 scrollableYearDropdown
//             />
//             <DatePicker
//                 selected={date.endDate}
//                 onChange={(date) => onChange(date, "end")}
//                 selectsEnd
//                 // startDate={date.startDate}
//                 minDate={date.startDate ||  moment().subtract(1, 'days').toDate()}
//                 isClearable={!!date.endDate}
//                 placeholderText="End Date"
//                 className="form-control"
//                 showYearDropdown
//                 dateFormat="MMMM d, yyyy"
//                 yearDropdownItemNumber={50}
//                 scrollableYearDropdown
//             />
//         </div>
//     );
// }

import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; 
import { useHistory, useLocation } from 'react-router-dom';
import moment from 'moment';

export default function DateRangePicker({ getDate = () => {} }) {
    const location = useLocation();
    const history = useHistory();
    const params = new URLSearchParams(location.search);
    const [date, setDate] = useState({
        startDate: params.get('from') ? new Date(params.get('from')) : null,
        endDate: params.get('to') ? new Date(params.get('to')) : null,
    });

    const onChange = (dates, to) => {
        const newDate = to === 'start' 
            ? { ...date, startDate: dates } 
            : { ...date, endDate: dates };
        setDate(newDate); 
    };

    useEffect(() => {
        const formattedDate = {
            startDate: date.startDate ? date.startDate.toISOString() : '',
            endDate: date.endDate ? date.endDate.toISOString() : '',
        };

        const { startDate, endDate } = formattedDate;
        if (startDate) params.set('from', startDate);
        else params.delete('from');

        if (endDate) params.set('to', endDate);
        else params.delete('to');

        history.push({
            pathname: location.pathname,
            search: params.toString(),
        });
    }, [date.startDate, date.endDate]);

    return (
        <div className='d-flex gap-12'>
            <DatePicker
                selected={date.startDate}
                onChange={(date) => onChange(date, "start")}
                selectsStart
                maxDate={date.endDate}
                isClearable={!!date.startDate}
                dateFormat="MMMM d, yyyy"
                placeholderText="Start Date"
                className="form-control"
                showYearDropdown
                yearDropdownItemNumber={50}
                scrollableYearDropdown
            />
            <DatePicker
                selected={date.endDate}
                onChange={(date) => onChange(date, "end")}
                selectsEnd
                minDate={date.startDate}
                isClearable={!!date.endDate}
                placeholderText="End Date"
                className="form-control"
                showYearDropdown
                dateFormat="MMMM d, yyyy"
                yearDropdownItemNumber={50}
                scrollableYearDropdown
            />
        </div>
    );
}

