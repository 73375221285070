import React, { Component } from "react";
import close from "../../assets/images/profile-created-close.png";
import { Modal, Image, Button, ProgressBar } from "react-bootstrap";

export class ProfileStatus extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div
        className="modal show profile-status-wrapper"
        style={{ display: "block", position: "initial" }}
      >
        <Modal.Dialog>
          <Modal.Header >
            <button
              type="button"
              className="close"
              onClick={this.props.onHide}
              aria-label="Close"
            >
              <Image src={close} alt="close"/>
            </button>
            <Modal.Title></Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="profile-created-wrapper">
              <div className="profile-created-content">
                <div className="profile-status-description">
                  <h2 className="fs-32-28 lato-semibold  primary text-center heading">
                    Welcome to Thefinpedia!
                  </h2>
                  <p className="fs-22-18 lato-regular  text-center ">
                    Discover, personalize and share high-quality content with
                    your clients instantly!
                  </p>
                  <h2 className="fs-32-28 lato-semibold  primary text-center complete">
                    Your profile is 35% complete
                  </h2>
                  <ProgressBar now={35} />;
                  <p className="fs-22-18 lato-regular  text-center ">
                    Enhance your experience. Complete your profile to unlock
                    personalized recommendations tailored to your needs.
                  </p>
                  <button className="primary-btn">Complete My Profile</button>
                </div>
              </div>
            </div>
          </Modal.Body>
        </Modal.Dialog>
      </div>
    );
  }
}

export default ProfileStatus;
