import React, { useEffect, useState } from 'react'
import { Breadcrumb } from 'react-bootstrap'
import { useHistory, useLocation, useParams } from 'react-router-dom/cjs/react-router-dom.min'
import { API_LIMIT, CAMPAIGN, CAMPAIGNS, LANGUAGE, LANGUAGES, MAX_LIMIT, MYCOLLECTION, SOCIALMEDIA, SOCIAL_MEDIA, SOCIAL_MEDIAS, START_PAGE, TARGETEDCONTENT, TARGETED_CONTENT, TARGETED_CONTENTS } from '../../../utils/Constant';
import { TargetedContent } from '../staticComponents';
const listType = ['language', 'languages', 'campaigns', 'campaign', 'category', 'amc']
const titleMap = {
  [SOCIAL_MEDIAS]: SOCIAL_MEDIA,
  [TARGETED_CONTENTS]: TARGETED_CONTENT,
  [CAMPAIGNS]: CAMPAIGN,
  [LANGUAGES]: LANGUAGE,
};
export default function BreadCrumb({ path, clasese, isKeyValue = "" }) {
  const history = useHistory();
  const location = useLocation()
  const contentType = JSON.parse(sessionStorage.getItem("contenType"));
  const contentTypeList = contentType?.map((item) => item.contentTypeName.toLowerCase());
  // console.log("Breadcrumbs", path)
  function navigateHandler(item) {
    // console.log("Breadcrumbs", item)
    // console.log(!isKeyValue,listType?.includes(item.toLowerCase().trim()))
    // console.log(isKeyValue.length,isKeyValue.length!==2)
    const params = new URLSearchParams(location.search);
    if (contentTypeList?.includes(item?.toLowerCase().replace("_", "/"))) {
      // console.log("First Condition", item)
      const newSearchValue = `pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
      if ((isKeyValue?.length !== 2)) {
        console.log("1th my True")
        history.push({
          pathname: `/content-type/${item.toLowerCase().replace("/", "_")}`,
          search: `${newSearchValue}`,
        });
      } else {
        console.log("1th my false")
        history.push({
          pathname: `/content-type/${item.toLowerCase().replace("/", "_")}`,
          search: `?key=${isKeyValue[0]}&value=${isKeyValue[1]}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
        })
      }
    }
    else if (params.get('key') === 'targetedContents' || (isKeyValue[0] === "targetedContents" && isKeyValue[0].toLowerCase() === item)) {
      // console.log("3rd Condition", item)
      history.push({ pathname: `/targetedContent`, search: `?pageSize=${API_LIMIT}&pageNumber=${START_PAGE}` });
    }
    else if (params.get('key') === "socialMedias" || (isKeyValue[0] === "socialMedias" && isKeyValue[0].toLowerCase() === item)) {
      // console.log("4th Condition", item)
      history.push(`/social-media`);
    }
    else if (item.toLowerCase() === "my activity") {
      // console.log("5th Condition", item)
      history.push(`/myactivity/user-tracking?type=downloaded`);
    }
    else if (listType?.includes(item.toLowerCase().trim()) && !isKeyValue) {
      history.push(`/collection-type/${item.toLowerCase().replace(/s$/g, '').trim()}`)
      // console.log("this conditon succes")
      // console.log("6th Condition", item)
    }
    else if (isKeyValue) {
      // console.log("isKeyValue ", isKeyValue);
      // console.log("7th", item);
      if (listType?.includes(item.toLowerCase())) {
        history.push(`/collection-type/${item.toLowerCase().replace(/s$/g, '')}`)
      } else {
        history.push({
          pathname: '/content-type/All',
          search: `?key=${isKeyValue[0]}&value=${item}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`
        })
      }

    }
    else if (MYCOLLECTION?.toLowerCase() === item?.toLowerCase()) {
      // console.log("Yes ",item,"in Url exist");
      // console.log("7th Condition", item)
      history.push(`/mycollections`);
    }
    else if (item?.toLowerCase() === "membership" || item?.toLowerCase() === "my membership") {
      // console.log("8th Condition", item)
      history.push(`/mymembership`);
    }
    else if (item?.toLowerCase() === "event") {
      console.log("8th Condition", item)
      history.push({ pathname: '/events', search: 'pageNumber=1&pageSize=10' });
    }
    else if (item?.toLowerCase() === "tools") {
      console.log("9th Condition", item)
      history.push({ pathname: '/tools' });
    }
    else {
      // console.log("Last else Condition", item)
      history.push({ pathname: `/details/${location?.state?.data?.contentType?.contentTypeName.replace("/", "_").toLowerCase()}&id=${location?.state?.data?.uuid || START_PAGE}` })
    }


  }

  return (
    <div className={`breadcrumb-wrapper ${clasese}`}>
      <Breadcrumb >
        <Breadcrumb.Item className='lato-regular f-s-12' onClick={() => history.push("/")}>Home</Breadcrumb.Item>
        {path && path?.map((item, i) => {
          const isLastItem = i === path.length - 1;
          const titleName=item?.replace(/(?:^|-)\w/g, function (match) {
            return match.toUpperCase();
          }).replace(/-/g, ' ')
          return (
            <Breadcrumb.Item
              key={i}
              className='lato-regular f-s-12'
              onClick={isLastItem ? null : () => navigateHandler(item.toLowerCase())}
            >
              {/* {console.log(titleMap[titleName])} */}
              {titleMap[titleName] || titleName}
            </Breadcrumb.Item>
          )
        })}

      </Breadcrumb>
    </div>
  )
}
