import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from "react-redux";

class Events extends Component {
    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() { }

    render() {
        return (
            <div>Events Component {this.props.eventsState}</div>
        )
    }
}

const mapStateToProps = state => ({
    eventsState: state.EventsState
});
const mapDispatchToProps = {
    // getPosts: fetchPosts
}
Events.propTypes = {
    // getPosts: PropTypes.func
};

export default connect(mapStateToProps, mapDispatchToProps)(Events);