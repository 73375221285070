import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import download from "../../assets/images/Download_1.png";
import share from "../../assets/images/Share_1.png";
import { Image } from 'react-bootstrap'
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import abcLogo from "../../assets/images/abc.png";
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { useMemo } from 'react';
import { SignUp } from '../common/components';
export default function Lumpsum({ calculator, textLogo = '' }) {
  let [lumpsum, setLumpsum] = useState(5000000);
  let [afterYears, setAfterYears] = useState(8);
  let [roi, setRoi] = useState(12);

  let [investmentPV, setInvestmentPV] = useState(5000000);
  let [investmentFV, setInvestmentFV] = useState(12379816);

  useEffect(() => {
    setLumpsum(lumpsum);
    setAfterYears(afterYears);
    setRoi(roi);
    setInvestmentPV(investmentPV);
    setInvestmentFV(investmentFV);
  }, [lumpsum, roi, afterYears, investmentFV, investmentPV])

  const handleChange = (value, name) => {
    if (name === "roi") {
      roi = value
    }
    if (name === "lumpsum") {
      lumpsum = value
    }
    if (name === "afterYears") {
      afterYears = value
    }
    onSubmitAction(roi, lumpsum, afterYears);
  }


  function onSubmitAction(roi, lumpsum, afterYears) {
    roi = roi / 100;
    investmentPV = lumpsum
    investmentFV = Math.round(lumpsum * (1 + roi) ** (afterYears));
    setLumpsum(lumpsum)
    setRoi(roi * 100);
    setAfterYears(afterYears);
    setInvestmentPV(investmentPV);
    setInvestmentFV(investmentFV);
  };
  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Invested Amount", "Total Invested Value"],
          data: [investmentPV, investmentFV],
        }}
      />
    );
  }, [investmentPV, investmentFV]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          {/* <RangeSlider min={10} max={5000} /> */}

          <RangeFields
            label="Lumpsum Investment"
            name={"lumpsum"}
            value={lumpsum}
            minVal={500}
            maxVal={10000000}
            step={500}
            getValue={handleChange} />
          <RangeFields
            label="Expected Rate of Return"
            name={'afterYears'}
            value={afterYears}
            minVal={1}
            maxVal={50}
            unit="%"
            step={1}
            getValue={handleChange}
          />
          <RangeFields
            label="Investment Tenure"
            name={"roi"}
            value={roi}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={0.5}
            getValue={handleChange}
          />
        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
              {/* <BarChart
                info={{
                  labels: ["Total Invested Amount", "Total Invested Value"],
                  data: [investmentPV, investmentFV],
                }}
              /> */}
              {barChart}
            </div>
          </div>
          <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
            With an initial Lumpsum investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> for <span className=" lato-semibold primary">{roi}</span> years your initial investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> will be valued at <span className=" lato-semibold primary">₹{formatCurrency(investmentFV)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{afterYears}</span> for the investment tenure.
          </p>
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot
          textLogo={textLogo}
          investmentPV={investmentPV}
          investmentFV={investmentFV}
          lumpsum={lumpsum}
          afterYears={afterYears}
          roi={roi}
          onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
    </div>
  )
}

function CalculatorOutputScreenShot({ textLogo, investmentPV, investmentFV, roi, lumpsum, afterYears, onDownloadScreenshot }) {
  const { cobrandingLabelling, investor } = textLogo;
  const userProfileDetails = getUser();
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const [signup, setSignup] = useState(false);
 
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current  && getToken()) {
          html2canvas(screenshotRef.current).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (
    <>

    {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>
          </div>}

      </div>

      {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
      <div className="result-graph-wrapper">
        <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
          <BarChart
            info={{
              labels: ["Total Invested Amount", "Total Invested Value"],
              data: [investmentPV, investmentFV],
            }}
          />
        </div>
      </div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        With an initial Lumpsum investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> for <span className=" lato-semibold primary">{roi}</span> years your initial investment of <span className=" lato-semibold primary">₹{formatCurrency(lumpsum)}</span> will be valued at <span className=" lato-semibold primary">₹{formatCurrency(investmentFV)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{afterYears}</span> for the investment tenure.
      </p>
      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

    </>
  )
}