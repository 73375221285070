
import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { Dropdown, Image } from "react-bootstrap";
import searchIcon from "../../assets/images/Search_1.svg";
import Media from "../../assets/images/media.svg";
import { EVENTS, MAX_LIMIT, MOBILE_BREAKPOINT, START_PAGE } from "../../utils/Constant";
import SelectKeywords from "./SelectKeywords";
import { getAllKeywordApi, getContentTypeApi, getTrendingKeysApi } from "../home/Api";
import { ContentTypeOptions } from '../../utils/Constant';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";

export default function SearchDropDown({
  classes,
  contentType = ContentTypeOptions,
  dropdown = true,
  placeholder = "Search",
  getKeys = () => { },
  clearFilter = () => { },
  isEvent = false,
  isFilterYes = false,
}) {
  // console.log(isFilterYes)
  const [keywordOption, setKeywordOption] = useState([]);
  const [key, setKey] = useState("");
  const [contentTypeOption, setContentTypeOption] = useState('All');
  const [contentTypeId, setContentTypeId] = useState('');
  const [isMedia, setIsMedia] = useState();
  const [contentTypes, setContentTypes] = useState([]);
  const [dropDownIcon, setDropDownIcon] = useState(Media)

  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  // console.log(params)

  useEffect(async () => {
    // call keyword list api
    let isMedia = location.pathname.includes('/social-media');
    setIsMedia(isMedia)
    // const keysValue = JSON.parse(sessionStorage.getItem("keysData"));
    const contenType = JSON.parse(sessionStorage.getItem("contenType"));
    const trendingKeywords = JSON.parse(sessionStorage.getItem("trendingKeywords"));
    // console.log(!contenType,!keysValue)
    if ( !contenType) {
      getContentTypeApi(setContentTypes);
    } else {
      // setKeywordOption(keysValue);
      setContentTypes(contenType);
    }
    // if (!trendingKeywords) {
    //   getTrendingKeysApi();
    // }

    const paramsArray = params?.type;
    let paramsType;
    let paramsSerch;

    if (paramsArray) {
      paramsType = paramsArray === "" ? "All" : paramsArray?.charAt(0).toUpperCase();
      paramsSerch = paramsArray?.slice(1);
    }
    let setType = paramsType + paramsSerch;
    setContentTypeOption(setType || 'All');
  }, [params]);

  const handleDropdownItemClick = async (keyword = key) => {
    if (keyword !== "" || contentTypeOption !== 'All') {
      let path, mediaPath, search = "";
      // console.log(contentTypeOption)
      // console.log(location)
      // let isMedia = location.pathname.includes('/social-media');
      // console.log(isMedia)
      mediaPath = `/social-media/${params?.type}`
      path = isEvent ? `/${EVENTS}` : `/content-type/${contentTypeOption?.replace('/', "_")}`;
      // path =isEvent ?  `/${EVENTS}` : `/content-type/${contentTypeOption?.replace('/', "_")}`;
      search = `${(keyword) ? `searchValue=${keyword}` : ""}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
      // search = `${(keyword && !isFilterYes) ? `searchValue=${keyword}` : ""}&pageNumber=${START_PAGE}&pageSize=${MAX_LIMIT}`;
      // console.log(isMedia ? mediaPath : path)
      history.push({
        pathname: isMedia ? mediaPath : path,
        search: search,
      });
      clearFilter();
    }
  };
  function getKeysData(data) {
    // getAllKeywordApi(setKeywordOption,data);
    setKey(data);
    getKeys(data);
  }
  const innerWidth = window.innerWidth;
  // console.log("check contentType",contentTypes);
  return (
    <div className={`search-dropdown ${classes}`}>
      {dropdown && (
        <div className="dropdown-wrapper">
          <Dropdown>
            <Dropdown.Toggle className="dropdown-title lato-semibold" id="dropdown-basic">
              {innerWidth >= MOBILE_BREAKPOINT ? (
                contentTypeOption?.replace('_', "/")
              ) : (
                <Image src={dropDownIcon || Media} alt="meida" />
              )}
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {/* <Dropdown.Item
                onClick={() => { setContentTypeOption("All"); setContentTypeId("all") }}
                title={"all"}
                className="lato-semibold d-flex fs-18-16 black-242"
              ><Image src={Media} className="dropDown-mb-image mb-show" alt="meida" />
                All
              </Dropdown.Item> */}
              {contentTypes?.map((item, i) => (
                <Dropdown.Item
                  key={i}
                  onClick={() => { setContentTypeOption(item.contentTypeName); setContentTypeId(item.uuid); setDropDownIcon(item.contentIcon || Media) }}
                  title={item.option}
                  className="lato-semibold fs-18-16 d-flex  black-242"
                > <Image src={item.contentIcon || Media} className="dropDown-mb-image mb-show" alt="meida" />
                  {item.contentTypeName}
                </Dropdown.Item>
              ))}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      )}
      {/* dropdown for keywords */}
      <div className={`search-wrapper ${dropdown ? "" : "left-b-none"} `}>
        <SelectKeywords
          label="Keywords"
          // options={isEvent ? [] : keywordOption}
          // options={isEvent ? [] : keywordOption}
          getKeys={getKeysData}
          navigate={handleDropdownItemClick}
          placeholder={placeholder}
          isEvent={isEvent}
        />
      </div>
    </div>
  );
}

