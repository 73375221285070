import React from 'react';
import Header from './Header';
import Footer from './Footer';
import { BsFillCircleFill } from "react-icons/bs";
const text = ``
export default function RedirectionPage() {
  return (
    <div className='page-padding'>
      <Header />
      <div className="container text-center">
        <br />

      </div>
      <Footer />
    </div>
  );
}