import React, { Component } from 'react'
import { getUserDetailsApi, verifyEmailApi } from './Api';

export class Verify extends Component {
  constructor(props) {
    super(props)
    const params = new URLSearchParams(props.match.params);
    const token = params.get('token');
    // console.log('props', props);
    this.state = {
      token,
    }
  }
  componentDidMount() {
    if (this.state.token) {
      // console.log('heya');
      verifyEmailApi(this);
    }
  }

  render() {
    // console.log('this.props', this.props);
    // console.log('this.state', this.state);
    return (
      <div>
        <h1>Verify</h1>
      </div>
    )
  }
}

export default Verify
