import React, { useEffect, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import { ShowShare, formatCurrency, getUser, showShare } from '../../utils/ReusableFunctions';
import abcLogo from "../../assets/images/abc.png";
import { Image } from 'react-bootstrap'
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { useMemo } from 'react';
import { useRef } from 'react';
import { SignUp } from '../common/components';
export default function FixedMonthlyWithdrawal({ calculator, textLogo = '' }) {
  let [corpusAmount, setCorpusAmount] = useState(10000000);
  let [swpAmount, setSWPAmount] = useState(75000);
  let [rateOfInterest, setRateOfInterest] = useState(12);
  let [tenure, setTenure] = useState(25);

  let [totalWithdrawal, setTotalWithdrawal] = useState(22500000);
  // let [corpusAmountResult, setCorpusAmountResult] = useState(10000000);
  let [totalAccCorpus, setTotalAccCorpus] = useState(56971166);


  useEffect(() => {

    setCorpusAmount(corpusAmount);
    setSWPAmount(swpAmount);
    setRateOfInterest(rateOfInterest);
    setTenure(tenure);

    setTotalWithdrawal(totalWithdrawal);
    // setCorpusAmountResult(corpusAmountResult);
    setTotalAccCorpus(totalAccCorpus);
  }, [corpusAmount, swpAmount, rateOfInterest, tenure, totalWithdrawal, totalAccCorpus])

  const handleChange = (value, name) => {
    // console.log('name', name);
    if (name === "corpusAmount") {
      corpusAmount = value
    }
    if (name === "swpAmount") {
      swpAmount = value
    }
    if (name === "rateOfInterest") {
      rateOfInterest = value
    }
    if (name === "tenure") {
      tenure = value
    }
    onSubmitAction(corpusAmount, swpAmount, rateOfInterest, tenure);
  }
  const onSubmitAction = (corpusAmount, swpAmount, roi, tenure) => {
    var toDivideROI = (roi / 100) / 12 || 0;
    let partA = ((((((roi / 100) / 12) + 1) ** (tenure * 12)) - 1) / toDivideROI) * (-swpAmount);
    let partB = ((1 + toDivideROI) ** (tenure * 12)) * corpusAmount;
    let calculatedTotalAccCorpus = Math.round(partA + partB);
    totalAccCorpus = calculatedTotalAccCorpus >= 0 ? calculatedTotalAccCorpus : 0;
    setTotalAccCorpus(totalAccCorpus);
    totalWithdrawal = swpAmount * tenure * 12;
    setTotalWithdrawal(totalWithdrawal);


    setTotalWithdrawal(totalWithdrawal);
    // setCorpusAmountResult(corpusAmountResult);
    setTotalAccCorpus(totalAccCorpus);


    setCorpusAmount(corpusAmount);
    setSWPAmount(swpAmount);
    setRateOfInterest(rateOfInterest);
    setTenure(tenure);

  };
  let downloadScreenshotFunc = null;
  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Withdrawal", "Total Invested Corpus", "End Corpus"],
          data: [totalWithdrawal, corpusAmount, totalAccCorpus],
        }}
      />
    );
  }, [totalWithdrawal, corpusAmount, totalAccCorpus]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          {/* <RangeSlider min={10} max={5000} /> */}

          <RangeFields
            label="Corpus or Investment Amount"
            name={"corpusAmount"}
            value={corpusAmount}
            minVal={100000}
            maxVal={1000000000}
            step={100000}
            getValue={handleChange} />
          <RangeFields
            label="Monthly SWP Amount"
            name={'swpAmount'}
            value={swpAmount}
            minVal={5000}
            maxVal={1000000}
            step={5000}
            getValue={handleChange}
          />
          <RangeFields
            label="Expected Rate of Return"
            name={"rateOfInterest"}
            value={rateOfInterest}
            minVal={1}
            maxVal={50}
            unit="%"
            step={0.5}
            getValue={handleChange}
          />
          <RangeFields
            label="Tenure enrolled for SWP"
            name={"tenure"}
            value={tenure}
            minVal={1}
            maxVal={50}
            unit="Year"
            step={1}
            getValue={handleChange}
          />

        </div>
        <div className="result-graph-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Results{" "}
            {/* {showShare(calculator)} */}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}

          </h4>
          <div className="result-graph-wrapper">
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
              {/* <BarChart
                info={{
                  labels: ["Total Withdrawal", "Total Invested Corpus", "End Corpus"],
                  data: [totalWithdrawal, corpusAmount, totalAccCorpus],
                }}
              /> */}
              {barChart}
            </div>
          </div>
          <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
            With an initial investment of <span className=" lato-semibold primary">₹{formatCurrency(corpusAmount)}</span> in an SWP plan at a monthly Withdrawal amount of <span className=" lato-semibold primary">₹{formatCurrency(swpAmount)}</span> for a period of <span className=" lato-semibold primary">{tenure}</span> years, your total withdrawals would amount to <span className=" lato-semibold primary">₹{formatCurrency(totalWithdrawal)}</span> leaving an end corpus of <span className=" lato-semibold primary">₹{formatCurrency(totalAccCorpus)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
          </p>
        </div>
      </div>
      <div className="make-none">
        <CalculatorOutputScreenShot 
        textLogo={textLogo} 
        totalWithdrawal={totalWithdrawal}
        corpusAmount={corpusAmount}
        totalAccCorpus={totalAccCorpus}
        swpAmount={swpAmount}
        tenure={tenure}
        // rateOfInterest={rateOfInterest}
        rateOfInterest={rateOfInterest}
        onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}


function CalculatorOutputScreenShot({ textLogo, totalWithdrawal, corpusAmount, rateOfInterest,totalAccCorpus, swpAmount, tenure, onDownloadScreenshot }) {
  const { cobrandingLabelling, investor } = textLogo;
  const userProfileDetails = getUser();
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const [signup, setSignup] = useState(false);
 
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);

  useEffect(() => {
    if (onDownloadScreenshot ) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current && getToken()) {
          html2canvas(screenshotRef.current).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (
    <>

    {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} className="tools-calulator-output-screenshot-frame ">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>
          </div>}

      </div>

   {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
      <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
        <BarChart
          info={{
            labels: ["Total Withdrawal", "Total Invested Corpus", "End Corpus"],
            data: [totalWithdrawal, corpusAmount, totalAccCorpus],
          }}
        />
      </div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      <br /><br />
      <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
        With an initial investment of <span className=" lato-semibold primary">₹{formatCurrency(corpusAmount)}</span> in an SWP plan at a monthly Withdrawal amount of <span className=" lato-semibold primary">₹{formatCurrency(swpAmount)}</span> for a period of <span className=" lato-semibold primary">{tenure}</span> years, your total withdrawals would amount to <span className=" lato-semibold primary">₹{formatCurrency(totalWithdrawal)}</span> leaving an end corpus of <span className=" lato-semibold primary">₹{formatCurrency(totalAccCorpus)}</span>, if the invested corpus keeps growing at <span className=" lato-semibold primary">{rateOfInterest}%</span> for the investment tenure.
      </p>


      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

    </>
  )
}