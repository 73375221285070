import React, { useEffect, useState } from 'react'
import Header from './Header'
import defaltImg from '../../../assets/images/default-img.png'
import optionsIcon from '../../../assets/images/optionsIcon.svg'
import { CardHeader } from '../components'
import { BreadCrumb } from '../../home/_utils'
import Trending from '../Trending'
import { Dropdown, Image } from 'react-bootstrap'
import { commonAuthApis } from '../../profile/Api'
import { getCommonApi } from '../../home/Api'
import moment from 'moment'
import { useHistory, useLocation } from 'react-router-dom/cjs/react-router-dom.min'
import Pagination from './Pagination'
import { START_PAGE } from '../../../utils/Constant'
import { calculateTotalPageCount } from '../../../utils/ReusableFunctions'
import NotFound from './NotFound'

export default function NotificationPage() {
    const location = useLocation();
    const history = useHistory()
    const params = new URLSearchParams(location.search);
    const [currentPage, setCurrentPage] = useState(parseInt(params.get('pageNumber')) || START_PAGE);
    const [notificationList, setNotificationList] = useState([]);
    const [filterValue, setFilterValue] = useState([]);
    const [isLoading, setILoading] = useState(true)

    useEffect(async () => {
        setCurrentPage(parseInt(params.get('pageNumber')))
        const res = await getCommonApi(`inAppNotification?pageNumber=${params.get('pageNumber')}&pageSize=${params.get('pageSize')}${filterValue.length > 0 ? `&filter=` + filterValue?.join(',') : ''}`);
        setNotificationList(res.data.data);
        setILoading(false)
    }, [filterValue, location.search])
    const addFilterHandler = (a) => {

        if (filterValue?.includes(a)) {
            setFilterValue(filterValue?.filter(item => item !== a))
        } else {
            setFilterValue([...filterValue, a])
        }
    }
    return (
        <div className='page-padding'>
            <Header />
            <section className="notification-main-section">
                {/* ============================================ TOP FILTERS SECTION START ============================ */}
                <div className="container">
                    <BreadCrumb path={["Notifications"]} clasese="mb-3-desk" />

                    <div className="notification-sub-header d-flex align-items-center justify-content-between">
                        <CardHeader
                            countTitle={'Notifications'}
                            count={notificationList?.length}
                            hide={false}
                        />
                        <h4 className=' lato-semibold fx-12 black-242 pointer'>Mark all as Read</h4>
                    </div>

                    <div className="treanding-one-wrapper gap-12 ">
                        {
                            ['Campaigns', 'Premium Content', 'Events', 'Reminders', 'Feature Update', 'System Downtime']?.map((item, i) => {
                                // console.log(item)
                                return (
                                    <div key={i} title={item} onClick={() => addFilterHandler(item)} className={`notification-keys lh-20 fs-14-10 lato-medium black-242  ${filterValue.includes(item) ? 'active-noti-key' : ''}`}>{item}</div>
                                )
                            })
                        }

                    </div>

                </div>
                {/* ============================================ TOP FILTERS SECTION END ============================ */}

                <div className="container-mb">
                    <div className="notificaton-card-wrapper">
                        {isLoading ? <div className='at-hight lato-bold fs-32-28'>Loading...</div> : (
                            notificationList.length > 0 ? notificationList?.list?.map((item, i) => {
                                return (
                                    <div className="notification-main-card  active">
                                        <div className="notificaton-tick-img-frame">
                                            <div className="notifi-disk"> </div>
                                            <div className="img-wrapper">
                                                <Image src={item.thumbnailPath || defaltImg} alt="" />
                                            </div>
                                        </div>

                                        <div className="notification-content ">
                                            <div className="title-header d-flex align-items-center justify-content-between">
                                                <h4 className=' lato-regular fs-22-12 black-242'>{item?.title || 'Na'}</h4>
                                                {/* <Dropdown className="single-select-api  ">
                                                <Dropdown.Toggle className=" border-0 shadow-none  bg-transparent" id="dropdown-basic">
                                                    <Image src={optionsIcon} alt='icon' className=' pointer' />
                                                </Dropdown.Toggle>
                                                <Dropdown.Menu className="">
                                                    {["Mark as read", 'Delete notification', 'Turn off this notification type']?.map((item, i) => {
                                                        return (
                                                            <Dropdown.Item key={i} onClick={() => { }} className="lato-regular fx-14 black-242 " href=''>{item}</Dropdown.Item>
                                                        )
                                                    })}
                                                </Dropdown.Menu>
                                            </Dropdown> */}
                                            </div>
                                            <div className="description-view">
                                                <p className=' lato-regular fs-14-12 black-242'>{item?.discription || ""}  </p>
                                                <a href={item?.link} target='_blank' ><span className=" fs-14-12  lato-semibold text-center pointer black-242 pointer">View</span></a>

                                            </div>
                                            <div className="footer-content d-flex align-items-center justify-content-end">
                                                <span className=' lato-semibold fx-12 black-242'>{moment(item?.createdAt).fromNow()}</span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) :
                                <NotFound />
                        )
                        }

                    </div>
                </div>
                {notificationList?.length > 18 &&
                    <Pagination
                        history={history}
                        location={location}
                        page={currentPage}
                        pageCount={calculateTotalPageCount(notificationList && (((notificationList?.length) ?? 0) !== 0) ? notificationList.length : 10)}
                    />
                }
            </section>
        </div>
    )
}
