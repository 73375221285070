import React from 'react'
import SubscriptionPlan from './SubscriptionPlan'
import { BreadCrumb } from '../home/_utils'
import { useState, useEffect } from 'react';
import { getAuthDataApi } from '../home/Api';
import { Footer, Header } from '../common/pages';
import { getPlansApi } from './Api';
export default function PricingPlan() {
  const [planList, setPlanList] = useState([]);

  useEffect(() => {
    async function fetchData() {
      const res = await getPlansApi(`user/billing-plan/available`);
      // console.log(res)
      setPlanList(res?.data?.data);
    }
    fetchData();
  }, []);
  return (
    <div className="page-padding">
      <Header />
      <div className="container">
        <BreadCrumb path={["Membership Plans"]} clasese="mb-3-desk" />
        <h5 className="lato-rgular fs-32-24 py-2 black-242">Membership Plans</h5>
      </div>
      <div className="up-your-game prising-plan-wrapper">
        <div className="prising-description">
          <div className="container">
            <h5 className=" fs-24-18 lato-regular black-242">
              Discover the membership plans tailored just for you to supercharge your marketing endeavours!
            </h5>
            <p className=" fs-16-13 lato-regular black-05">
              Select and upgrade to a plan of your choice
            </p>
          </div>
        </div>
        <div className="container">
          <div className="your-game-card-wrapper">
            {planList?.map((item, i) => (
              <SubscriptionPlan key={i} index={i} data={item} />
            ))}
          </div>
        </div>
      </div>

      <Footer />
    </div>
  )
}
