import React, { useEffect, useRef } from 'react';
import Chart from 'chart.js';
import { formatCurrency } from '../../../utils/ReusableFunctions';

const BarChart = ({ info,title="" }) => {
  // console.log('info', info);
  const chartRef = useRef(null);
  let myBarChart = null;

  useEffect(() => {
    // Delay the chart initialization
    setTimeout(() => {
      const ctx = chartRef.current.getContext('2d');

      //Datalabels on top//
      Chart.plugins.register({
        afterDatasetsDraw: function (chart) {
          var ctx = chart.ctx;
          chart.data.datasets.forEach(function (dataset, i) {
            var meta = chart.getDatasetMeta(i);
            if (!meta.hidden) {
              meta.data.forEach(function (element, index) {

                ctx.fillStyle = '#270089';

                var fontSize = 12;
                var fontStyle = 'normal';
                var fontFamily = 'lato-light';
                ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily);

                // Just naively convert to string for now
                var dataString = '₹' + formatCurrency(dataset.data[index]).toString();

                // Make sure alignment settings are correct
                ctx.textAlign = 'center';
                ctx.textBaseline = 'bottom';

                var padding = 5;
                var position = element.tooltipPosition();
                ctx.fillText(dataString, position.x, position.y - (fontSize / 2) - padding);
              });
            }
          });
        }
      });


      // Define your chart configuration
      const chartConfig = {
        type: 'bar',
        data: {
          labels: info?.labels || ['Invest', 'Investor'],
          datasets: [{
            data: info?.data || [80, 50],
            backgroundColor: ["#242E3D80", "#270089", '#A5F5DC', '#242E3D'],
          }],
        },
        options: {
          plugins: {
            datalabels: {
              enabled: true,
              format: `{y}`
            }
          },
          // title: {
          //   display: true,
          //   text: title, 
          //   fontColor: '#270089', 
          //   fontSize: 12, 
          //   fontFamily: 'Arial', 
          //   padding: 20, 
          //   position: 'top',
          // },
          responsive: true,
          maintainAspectRatio: false,
          layout: {
            padding: {
              top: 90,
              bottom: 14,
            },
          },
          scales: {
            yAxes: [{
              display: false,
              ticks: {
                beginAtZero: true,
              },
              gridLines: {
                display: false
              },
              // afterFit: function (scaleInstance) {
              //   scaleInstance.width = 100
              // }
            }],
            xAxes: [{
              gridLines: {
                display: true,
                drawBorder: true,
                drawOnChartArea: false,
              },
              categoryPercentage: .3,
              barPercentage: 0.8,
            }]
          },
          legend: {
            display: false
          },
          tooltips: {
            enabled: false
          },
          hover: { mode: null },
          events: [],
        },
      };

      if (myBarChart) {
        myBarChart.destroy();
      }

      myBarChart = new Chart(ctx, chartConfig);
    }, 150);


    const resizeObserver = new ResizeObserver(entries => {
      if (myBarChart) {
        myBarChart.resize();
      }
    });

    resizeObserver.observe(chartRef.current.parentNode);

    return () => {
      if (myBarChart) {
        myBarChart.destroy();
      }
      resizeObserver.disconnect();
    };
  }, [info]);

  return (
    <div className='make-center' style={{ width: '95%', maxWidth: '490px', height: '300px' }}>
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default BarChart;
