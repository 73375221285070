import React from "react";
import { Image } from "react-bootstrap";

export default function ({ image, text, clases }) {
  return (
    <div className={`registerto-card-wrapper ${clases}`}>
      <Image src={image} alt="image" />
      <p className="lato-regular fs-20-16 black-242 ellipsis-3" dangerouslySetInnerHTML={{ __html: text }} />
    </div>
  );
}
