import React from "react";
import { Image } from "react-bootstrap";
import Search from "../../../assets/images/search.svg";

const PageSubHeader = ({ title, count, isSearch, clases,changeHandler={} }) => {
  return (
    <div className={`sub-header-wrapper ${clases}`}>
      <div className="mycollection-header">
        <div className="mycollection-left">
          <h2 className="fs-32-24 lato-semibold black-242">{title}</h2>
          <h4>{count}</h4>
        </div>
        {isSearch && (
          <div className="mycollection-right">
            <input
              type="text"
              placeholder="Search in collections"
              className="fs-22-18 lato-regular"
              onChange={changeHandler}
            />
            <Image src={Search} alt="search" />
          </div>
        )}
      </div>
    </div>
  );
};

export default PageSubHeader;
