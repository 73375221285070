import React, { useEffect, useRef, useState } from 'react'
import RangeFields from './components/RangeFields'
import BarChart from './components/BarChart'
import download from "../../assets/images/Download_1.png";
import share from "../../assets/images/Share_1.png";
import RadioFields from './components/RadioFields'
import { mfObj } from '../../utils/Constant';
import abcLogo from "../../assets/images/abc.png";
import { Image } from 'react-bootstrap'
import { getToken } from '../../utils/ManageToken';
import html2canvas from 'html2canvas';
import { useMemo } from 'react';
import { ShowShare, convertImageToBase64, formatCurrency, getUser, } from '../../utils/ReusableFunctions';
import { SignUp } from '../common/components';


export default function SIPCalculator({ calculator, textLogo = '' }) {
  const graphDiv = useRef(null);
  // let frequency = "monthly";
  let [frequency, setFrequency] = useState("monthly");
  let [roi, setRoi] = useState(8);
  let [sipAmount, setSipAmount] = useState(25000);
  let [investmentPeriod, setInvestmentPeriod] = useState(10);

  let [tai, setTai] = useState(3000000);
  let [tac, setTac] = useState(4604142);

  const selectOption = [
    { value: "monthly", label: "Monthly" },
    // { value: "quarterly", label: "Quarterly" },
  ];


  useEffect(() => {
    // console.log('heyyyy', frequency);
    setFrequency(frequency)
    setRoi(roi);
    setSipAmount(sipAmount);
    setInvestmentPeriod(investmentPeriod);
    setTac(tac);
    setTai(tai);
  }, [tac, tai, roi, sipAmount, frequency, investmentPeriod])

  const handleChange = (value, name) => {
    // console.log('name', name);
    if (name === "frequency") {
      frequency = value
    }
    if (name === "roi") {
      roi = value
    }
    if (name === "sipAmount") {
      sipAmount = value
    }
    if (name === "investmentPeriod") {
      investmentPeriod = value
    }
    onSubmitAction(frequency, roi, sipAmount, investmentPeriod);
  }
  const onSubmitAction = (frequency, roi, sipAmount, investmentPeriod) => {
    let mf = mfObj[frequency];
    // console.log('mf', mf);
    let tenure = investmentPeriod * mf;
    const toDivideROI = (roi / mf) / 100;
    const toMultiplyROI = ((roi / mf) + 100) / 100;
    tai = Math.round(sipAmount * investmentPeriod * mf);
    tac = Math.round(((((((roi / mf) + 100) / 100) ** tenure) - 1) / toDivideROI) * sipAmount * toMultiplyROI);
    // console.log('frequency, roi, sipAmount, investmentPeriod', frequency, roi, sipAmount, investmentPeriod);
    setFrequency(frequency)
    setRoi(roi);
    setSipAmount(sipAmount);
    setInvestmentPeriod(investmentPeriod);
    setTac(tac)
    setTai(tai)
  };

  let downloadScreenshotFunc = null;

  const setDownloadScreenshotFunc = (func) => {
    downloadScreenshotFunc = func;
  };
  const barChart = useMemo(() => {
    return (
      <BarChart
        info={{
          labels: ["Total Invested Amount", "Total Investment Value"],
          data: [tai, tac],
        }}
      />
    );
  }, [tai, tac]);
  return (
    <div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART START ---------------------------------------------- */}
      <div className="calculator-result-main-wrapper">
        <div className="calculator-component-frame">
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7">
            Calculator
          </h4>
          {/* <RangeSlider min={10} max={5000} /> */}

          <RangeFields
            label="SIP Amount"
            name={"sipAmount"}
            value={sipAmount}
            minVal={500}
            maxVal={1000000}
            step={500}
            getValue={handleChange} />
          <RangeFields
            label="Expected Rate of Return"
            name={'roi'}
            value={roi}
            minVal={4}
            maxVal={50}
            // step={1}
            unit="%"
            getValue={handleChange}
          />
          <RadioFields
            options={selectOption}
            initialValue={"monthly"}
            onSelectionChange={(v) => { setFrequency(v) }}
            // initialValue={""}
            lable="SIP Frequency"
          />
          <RangeFields
            label="Investment Tenure"
            name={"investmentPeriod"}
            value={investmentPeriod}
            minVal={1}
            maxVal={50}
            unit="Year"
            // step={1}
            getValue={handleChange}
          />
          {/* <RangeFields label="Delay" minVal={6} maxVal={50} unit="Year" /> */}
        </div>
        <div className="result-graph-frame" ref={graphDiv}>
          <h4 className=" lato-semibold f-s-20 lh-24 black-242 op-7" >
            Results{" "}
            {<ShowShare calculator clickHandler={() => { downloadScreenshotFunc && downloadScreenshotFunc() }} />}
          </h4>
          <div className="result-graph-wrapper" >
            <div className={`graph-1-frame ${false ? "w-auto" : "w-100"}`}>
              {/* <BarChart
                info={{
                  labels: ["Total Invested Amount", "Total Investment Value"],
                  data: [tai, tac],
                }}
              /> */}
              {barChart}
            </div>
          </div>
          <p className=" f-s-16 lh-24 black-242 op-7 pt-2 text-center">
            With a monthly SIP of <span className=" lato-semibold primary">₹ {formatCurrency(sipAmount)}</span>, after <span className=" lato-semibold primary">{investmentPeriod}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹ {formatCurrency(tai)} </span> and its value will be <span className=" lato-semibold primary">₹ {formatCurrency(tac)} </span>, if the invested corpus keeps growing at <span className=" lato-semibold primary"> {roi} </span>% for the investment tenure.
          </p>
        </div>
      </div>
    
        <div className="make-none">
        <CalculatorOutputScreenShot textLogo={textLogo} tai={tai} tac={tac} roi={roi} investmentPeriod={investmentPeriod} sipAmount={sipAmount} onDownloadScreenshot={setDownloadScreenshotFunc} />
      </div>
      {/* ---------------------------------------------------  CALCULATOR AND RESULT SECONG PART END ---------------------------------------------- */}
    </div>
  )
}




function CalculatorOutputScreenShot({ textLogo, tai, tac, roi, sipAmount, investmentPeriod, onDownloadScreenshot }) {
  const {cobrandingLabelling,investor}=textLogo;
  const userProfileDetails = getUser();
  const [signup, setSignup] = useState(false);
 
  const [companyLogo, setCompanyLogo] = useState({
    logoUrl: userProfileDetails?.organizationLogoPath ?? "",
    logoFile: null,
  });
  const { logoUrl, logoFile } = companyLogo;
  const [brandinText, setBrandinText] = useState({
    company: userProfileDetails?.companyName ?? "",
    arnNo: userProfileDetails?.arnNumber ?? "",
    companyEmail: userProfileDetails?.emailAddress ?? "",
    companyNo: userProfileDetails?.mobileNumber ?? "",
  });
  const { company, arnNo, companyEmail, companyNo } = brandinText;

  const screenshotRef = useRef(null);
  const generatePDF = () => {
    const content = document.getElementById('content-to-print');
    const newWindow = window.open('', '_blank');
    newWindow.document.write(content.innerHTML);
    newWindow.document.write(`
      <style>
        body {
          font-family: Arial, sans-serif;
        }
      </style>
    `);
    newWindow.print();
  };
  useEffect(() => {
    if (onDownloadScreenshot) {

      onDownloadScreenshot(() => {
        if (screenshotRef.current  && getToken()) {
          html2canvas(screenshotRef.current,{ useCORS: true,}).then(canvas => {
            const image = canvas.toDataURL('image/png');
            const link = document.createElement('a');
            link.href = image;
            link.download = 'calculator-output.png';
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          });
          // generatePDF()
        }else{
          setSignup(true)
        }
      });
    }
  }, [onDownloadScreenshot]);
  return (<>
   {signup && <SignUp show={signup} handleClose={() => setSignup(!signup)} />}

    <div ref={screenshotRef} id='content-to-print' className="tools-calulator-output-screenshot-frame ">
      <div className={`tolls-calulator-output `}>
        {cobrandingLabelling?.startLogo &&
          <Image src={companyLogo.logoUrl} className='startlabeling-size ' alt='logo' />
        }
        {cobrandingLabelling?.startText &&
          <div className='branding-details-box'>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyEmail}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.company}</p>
            </div>
            <div className="">
              <p className=' lato-regular f-s-12 lh-15 mb-2 black-242'>{brandinText.companyNo}</p>
              <p className=' lato-regular f-s-12 lh-15 black-242'>{brandinText.arnNo}</p>
            </div>
          </div>}

      </div>

   {/* <div className="tools-sip-info d-flex align-items-center gap-12 mb-4">
        <div className="tools-sip-logo ">
          <Image src={abcLogo} alt="sip Logo" />
        </div>
        <div className="tools-sip-name-frame">
          <p className="lato-regular fx-12 black-242 m-0 op-5">
            Sponsored By
          </p>
          <p className=" lato-medium fs-14-13 m-0 black-242 ">
            Aditya Birla Sun Life Mutual Fund
          </p>
        </div>

      </div> */}
      <div className={`graph-1-frame bg-gray ${false ? "w-auto" : "w-100"}`}>
        <BarChart
          info={{
            labels: ["Total Invested Amount", "Total Investment Value"],
            data: [tai, tac],
          }}
        />
      </div>
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">Dear {investor?.investorName ? investor?.investorName : 'Investor'}</p>
      <br /><br />
      <p className=" f-s-16 lh-24 black-242 op-7 pt-3 text-center">
        With a monthly SIP of <span className=" lato-semibold primary">₹ {formatCurrency(sipAmount)}</span>, after <span className=" lato-semibold primary">{investmentPeriod}</span> years of investment, your total investment amount will be <span className=" lato-semibold primary">₹ {formatCurrency(tai)} </span> and its value will be <span className=" lato-semibold primary">₹ {formatCurrency(tac)} </span>, if the invested corpus keeps growing at <span className=" lato-semibold primary"> {roi} </span>% for the investment tenure.
      </p>
      <p className=" f-s-12 lh-14 black-242 op-9 pt-5 mt-5 text-center">Note: Returns are neither assured nor guaranteed. There is no warranty about the accuracy of the calculator.<br />
        Disclaimer: Mutual Fund investments are subject to market risks, read all scheme related documents carefully.
      </p>
      <div className="bg-primary lh-16 mt-5">&nbspl;</div>

    </div>

    </>
  )
}