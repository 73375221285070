import React from 'react'
import { useState } from 'react';
import { formatCurrency } from '../../../utils/ReusableFunctions';

export default function RangeFields({ name = "", value = "", label = "", minVal = 0, maxVal = 100, step = 1, getValue = () => { }, unit = "" }) {
  const [amount, setAmount] = useState(value);

  const handleChange = (e) => {
    console.log('heyyy');
    setAmount(e.target.value);
    getValue(e.target.value, e.target.name)
  };
  const calculatePercentage = (value, min, max) => {
    return ((value - min) / (max - min)) * 100;
  };
  const backgroundStyle = {
    background: `linear-gradient(to right, #270089 0%, #270089 ${calculatePercentage(amount, minVal, maxVal)}%, rgba(36, 46, 61, 0.2) ${calculatePercentage(amount, minVal, maxVal)}%, rgba(36, 46, 61, 0.2) 100%)`
  };

  function handleUint(ui, state) {
    switch (unit) {
      case "%":
        return ui + ' %';
      case 'Year':
        return ui + ' Year';
      default:
        if (state === "min") {
          return minVal
        } else {
          return formatCurrency(maxVal)
        }
    }

  }
  return (
    <div className="range-slider">
      <div className="range-input-frame">
        <div className="range-lable-frame">
          <h3 htmlFor="sip-amount f-s-14 lh-18 black-242 lato-regular ">{label}</h3>
          <input
            // id={id}
            name={name}
            type="range"
            min={minVal}
            max={maxVal}
            value={amount}
            step={step}
            onChange={handleChange}
            className="slider input-custom-range"
            style={backgroundStyle}
          />
          <div className="min-max-frame">
            <h5 className=' lato-regular f-s-12 lh-17 black-242'>Min {handleUint(minVal, "min") || 0}</h5>
            <h5 className=' lato-regular f-s-12 lh-17 black-242'>Max {handleUint(maxVal, "max") || "Na"}</h5>
          </div>
        </div>
        <div className=" input-show-text fx-14 lato-semibold black-242">
          <input
            type="number"
            value={amount}
            name={name}
            onChange={handleChange}
            className="amount-display  fx-14 lato-semibold black-242"
          />&nbsp;
          {unit || '₹'}
        </div>

      </div>
    </div>
  );

}
