import React, { useState, useEffect, useRef, useCallback } from "react";
import { Accordion, Button, Card, Dropdown, Image } from "react-bootstrap";
import { SearchDropDown, Trending } from "../../common";
import DropdownCommon from "./DropdownCommon";
import Trend from "../../../assets/images/black-traind-Icon.svg";
import Filter from "../../../assets/images/leadingicon.svg";
import DownIcon from "../../../assets/images/dropdown-icon.svg";
import closeFilter from "../../../assets/images/cancel-icon.svg";
import languageIcon from "../../../assets/images/language-icon.svg";
import colorIcon from "../../../assets/images/color-icon.svg";
import ContentTypeCards from "../_utils/ContentTypesCards";
import { getAuthDataApi, getCommonApi, getFilterApi } from "../Api";
import {
  useHistory,
  useLocation,
  useParams,
} from "react-router-dom";
import { getSearchParams } from "../../../utils/ReusableFunctions";
import { BreadCrumb, WelcomeFinModal } from "../_utils";
import { AMC, API_LIMIT, CREATOR, EVENTS, MAX_LIMIT, MUTUAL_FUND_COMPANY, SOCIALMEDIA, SOCIALMEDIA_URL, START_PAGE, TARGETEDCONTENT } from "../../../utils/Constant";
import { DateRangePicker } from "../../common/components";

const filterObj = [
  "Day",
  "Organiser",
  "Event Type",
  "Event Format",
  "Category",
  "Speaker",
  "Location",
];
const validValues = [
  "AMC",
  'amc',
  "category",
  "campaigns",
  "languages",
  AMC,
  "targetedContents",
];
export default function SideFilter({
  component,
  dropdown = true,
  placeholder,
  dataLength,
  isFilter = true
}) {
  const location = useLocation();
  // console.log(location)
  const history = useHistory();
  const param = useParams();
  const [showFilter, setShowFilter] = useState(true);
  const [showWelcom, setShowWelcom] = useState(true);
  const [activeOption, setActiveOption] = useState([null]);
  const [filterData, setFilterData] = useState([]);
  const [keyName, setKeyName] = useState("");
  const [amcData, setAmcData] = useState([]);
  const [activeFilterTab, setActiveFilterTab] = useState([]);
  const [amcList, setAmcList] = useState([]);
  const [targetedContentList, setTargetedContentList] = useState([])
  const didMount = useRef(false);
  const getParam = new URLSearchParams(location.search);
  const defaultAmcState = getParam.get('amc') || 'Select AMC';
  const [amcName1, setAmcName1] = useState(defaultAmcState)
  const collectionType = getParam.get('key');
  // const sortByTitle = getParam.get('sortBy');

  // console.log(collectionType)
  async function fetchData() {
    // console.log('collectionType', collectionType);
    // const data = await getFilterApi(collectionType);
    let filterUrl = "content/filter";
    if (location.pathname.includes("/events")) {
      filterUrl = 'website/events/filters'
    }
    const data = await getFilterApi(filterUrl);
    const newData = data?.find((item) => item.code === 'TARGETED_CONTENT');
    // console.log(data,newData);
    setTargetedContentList(newData);
    setFilterData(data);
  }
  async function fetchDropDownData() {
    // console.log('collectionType', collectionType);
    setKeyName(getParam.get('value'));
    const url = collectionType === 'languages' ? 'website/languages' : (collectionType === 'socialMedias') ? SOCIALMEDIA_URL : collectionType === "targetedContents" ? 'website/targetedContent/dashboard/list' : 'website/' + collectionType.replace(/s\b/g, '');
    const res = await getCommonApi(url + `?pageSize=10000&pageNumber=${START_PAGE}`);

    setAmcData(res?.data?.data);
    // console.log(res?.data?.data)
  }
  const setKeyNameHandler = (name) => {
    setKeyName(name);
    // console.log(getParam.toString())
    getParam.set('key', collectionType);
    getParam.set('value', (name));
    // console.log(location.pathname.includes(EVENTS))
    const searchUrl = `key=${collectionType}&value=${encodeURIComponent(name)}&pageNumber=${getParam.get('pageNumber')}&pageSize=${getParam.get('pageSize')}`;
    history.push({
      pathname: (location.pathname.includes(EVENTS)) ? `/${EVENTS}` : `/content-type/${param.type ? param.type : "All"}`,
      search: getParam.toString(),
      // search: `${searchUrl}`,
    });
  }
  useEffect(() => {
    if (collectionType ?? false) {
      fetchDropDownData()
    }
  }, [collectionType])




  const amcListHandler = async () => {
    const res = await getAuthDataApi('website/amc');
    const sortAmc = res.list.sort((a, b) => a.amcName.localeCompare(b.amcName))
    setAmcList(sortAmc);
  }

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const excludedKeys = ['key', 'value', 'pageNumber', 'pageSize', 'searchValue'];
    const filterObjUrl = {};

    params.forEach((value, key) => {
      if (!excludedKeys.includes(key)) {
        filterObjUrl[key] = value.split(',');
      }
    });
    if (params.get('creator') === 'Mutual Fund Company') {
      amcListHandler()
    }
    setActiveFilterTab(filterObjUrl)
    // console.log(filterObjUrl);
    // if(params)
    // console.log("loaded", params.get(TARGETEDCONTENT));
    if (params.get(TARGETEDCONTENT)) {
      // console.log('Heyaaaa');
      fetchData();
    }

  }, [location.search]);

  // todo:----logic changed
  async function handleFilterClick(itemTitle, key) {

    switch (key) {
      case "amc":
        getParam.set("value", itemTitle);
        history.push({
          pathname: `/content-type/${param.type ? param.type : "All"}`,
          search: getParam.toString(),
        });
        break;

      case "rangeDate":
        const { startDate, endDate } = itemTitle;
        if (startDate) getParam.set('from', startDate);
        // else getParam.delete('from');

        if (endDate) getParam.set('to', endDate);
        // else getParam.delete('to');

        history.push({
          pathname: `/events`,
          search: getParam.toString(),
        });
        break;

      default:
        const currentParams = new URLSearchParams(location.search);
        if (itemTitle === MUTUAL_FUND_COMPANY) {
          amcListHandler();
        }

        setActiveFilterTab((prevState) => {
          if (prevState[CREATOR]?.includes(MUTUAL_FUND_COMPANY) && key === CREATOR) {
            // console.log("Why its Deleating =",prevState['creator']);
            delete prevState['amc'];
            currentParams.delete('amc')
            setAmcName1("Select AMC");
            // todo=> RESON FOR THIS CONDITON 1)if hide amc drop then again show previous amc selected 2)
          }
          const existingArray = prevState[key] || [];
          const updatedArray = existingArray.includes(itemTitle.replace('_', "/"))
            ? existingArray.filter((value) => value !== itemTitle.replace('_', "/"))
            : [...existingArray, itemTitle.replace('_', "/")];

          const queryString = Object.entries({ ...prevState, [key]: updatedArray })
            .map(([k, values]) => {
              const formattedValues = values.join(",");
              return (formattedValues !== "") ? `${k}=${encodeURIComponent(formattedValues.replace('_', "/"))}` : "";
            })
            .join("&");

          const searchValue = currentParams.get('searchValue');
          const pageNumber = currentParams.get('pageNumber');
          const pageSize = currentParams.get('pageSize');
          const newSearchValue = `${searchValue ? `searchValue=${searchValue}&` : (currentParams.get('key') ?? false) ? `key=${currentParams.get('key')}&` : ""}${(currentParams.get('value') ?? false) ? `value=${currentParams.get('value')}&` : ""}${pageNumber ? `pageNumber=1&` : ""}${pageSize ? `pageSize=${pageSize}` : ""}`;

          const updatedSearchValue = (newSearchValue && `${newSearchValue}&`) + queryString;
          // console.log('Check updated url',updatedSearchValue);

          history.push({
            pathname: location.pathname.includes("/events") ? `/events` : `/content-type/${param.type ? param.type : "All"}`,
            search: updatedSearchValue.replace(/&+/g, '&'),
          });

          return { ...prevState, [key]: updatedArray };
        });
        break;
    }

  }






  const isMedia = location.pathname.includes(SOCIALMEDIA);
  // console.log("Content Type=>", isMedia);
  // console.log("In Amc",param.type)


  function showFilterCount(obj) {
    const subtract = Object.keys(obj)?.includes('sortBy') ? 1 : 0;
    // const subtract2= obj[CREATOR]?.includes(MUTUAL_FUND_COMPANY) ? 1 : 0;
    const lengthWithSubtraction = Object.keys(obj)?.length - (subtract);
    return lengthWithSubtraction;
  }
  const toCamelCase = (sentence) => sentence.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()).join(' ');

  const sortHandler = (data) => {
    // console.log(data)
    const sorData = data.sort((a, b) => a?.[collectionType.replace(/s\b/g, '') + 'Name'].localeCompare(b?.[collectionType.replace(/s\b/g, '') + 'Name']))
    // console.log(sorData)
    return sorData
  }
  return (
    <div className="container overflow-hidden">

      <BreadCrumb path={(location.pathname === '/events') ? ['Events'] : (collectionType === null) ? [param.type?.replace('_', "/")] : [collectionType.toLowerCase() === AMC.toLowerCase() ? "AMC" : collectionType.replace(/([a-z])([A-Z])|s$/g, '$1 $2'), getParam.get('value')]} clasese="mb-3" />
      <div className="user-search-wrapper">
        <SearchDropDown
          dropdown={isMedia || param.type === AMC || param.type === TARGETEDCONTENT ? false : dropdown}
          placeholder={placeholder}
          classes="search-border"
          clearFilter={() => { setActiveFilterTab([]); setAmcName1(defaultAmcState) }}
          isEvent={(location.pathname.includes(EVENTS))}
          isFilterYes={(Object.keys(activeFilterTab).includes('searchValue'))}
        />
      </div>
      {isFilter &&
        <div className="filter-sort-frame social-media-filter position-relative">

          {/* <div className="d-flex align-items-center w-100 gap-12"> */}
          <div className={`filter-btn-main-wrapper ${!showFilter ? 'w-37' : "w-auto"}`}>
            <div
              className="filter-button"
              onClick={() => {
                if (!filterData.length > 0) fetchData();
                setShowFilter(!showFilter)
              }}
            >{showFilterCount(activeFilterTab) > 0 &&
              <span className="filter-count">{showFilterCount(activeFilterTab)}</span>
              }
              <Image src={Filter} alt="icon" />
              <p className="fx-14 lato-medium black-495 desk-show">Filters</p>

              <Image src={DownIcon} alt="icon" className="desk-show" />
            </div>
            <div className={`clear-filter-wrapper desk-show ${showFilter && 'd-none'}`}>
              <div onClick={() => {
                const searchValue = getParam.get('searchValue');
                // console.log("parasm Type", param.type, 'is key exist=', validValues.includes(collectionType), collectionType)
                if (getParam.get('value') && validValues.includes(collectionType)) {
                  history.push({ paathname: `/content-type/${param.type ? param.type : "All"}`, search: `key=${collectionType}&value=${getParam.get('value')}&pageNumber=${getParam.get('pageNumber')}&pageSize=${getParam.get('pageSize')}` });
                } else {
                  history.push({ paathname: `/content-type/${param.type ? param.type : "All"}`, search: `${searchValue ? `searchValue=${searchValue}&` : ''}pageNumber=${getParam.get('pageNumber')}&pageSize=${getParam.get('pageSize')}` });

                }
                setActiveFilterTab([]); setAmcName1(defaultAmcState)

              }} className={`clear-filter-btn lato-medium fs-16-14 ${(showFilterCount(activeFilterTab) < 1) ? 'd-none' : ""}`}>
                Clear All
              </div>
              <Image onClick={() => setShowFilter(!showFilter)} src={closeFilter} className="pointer " alt="cancle" />
            </div>
          </div>
          <div className={`d-flex gap-12 align-items-center justify-content-between main-filter-frame ${!showFilter ? "width-half" : "width-full"}`}>
            {activeFilterTab['day']?.includes('Date Range') &&
              <DateRangePicker
              // getDate={(date) => {
              // handleFilterClick(
              //   date,
              //   'rangeDate'
              // )
              // }}
              />
            }
            {((getParam.get('creator') === 'Mutual Fund Company')) &&
              <Dropdown className="single-select-api 1111">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                  <h6 className="lato-semibold fx-14 black-242">{amcName1 || 'Na'}</h6>
                  <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {amcList?.map((item, i) => {

                    return (
                      <Dropdown.Item key={i} onClick={() => {
                        setAmcName1(item?.amcName);
                        if (getParam.get('amc')) {
                          getParam.set('amc', item?.amcName);
                        } else {
                          getParam.append('amc', item?.amcName);
                        }
                        history.push({
                          pathname: location.pathname.includes("/events") ? `/events` : `/content-type/${param.type ? param.type : "All"}`,
                          search: getParam.toString(),
                        });

                      }} className="lato-regular fx-14 black-242 " href=''>{item?.amcName}</Dropdown.Item>
                    )
                  })}
                  {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            }
            {!(collectionType ?? false) && !((getParam.get('creator') === 'Mutual Fund Company')) && !(activeFilterTab['day']?.includes('Date Range')) &&
              <div className=""></div>
            }
            {(collectionType ?? false) &&
              <Dropdown className="single-select-api 22222">
                <Dropdown.Toggle className="dropdown-btn" id="dropdown-basic">
                  <h6 className="lato-semibold fx-14 black-242">{keyName || 'Na'}</h6>
                  <Image src={DownIcon} alt="image" />
                </Dropdown.Toggle>
                <Dropdown.Menu className="">
                  {((collectionType === 'socialMedias' ? amcData : amcData?.list)?.length > 0) ? (
                    (collectionType === 'socialMedias' ? amcData : sortHandler(amcData?.list)).map((item, i) => {
                      return (
                        <Dropdown.Item
                          key={i}
                          onClick={() => setKeyNameHandler(item?.[collectionType.replace(/s\b/g, '') + 'Name'])}
                          className="lato-regular fx-14 black-242 "
                          href=""
                        >
                          {item?.[collectionType.replace(/s\b/g, '') + 'Name']}
                        </Dropdown.Item>
                      );
                    })
                  ) : (
                    <Dropdown.Item className="lato-regular fx-14 black-242 ">No Data Found!</Dropdown.Item>

                  )}

                  {/* <Dropdown.Item className="lato-regular black-242 " href=''>Most Viewed</Dropdown.Item> */}
                </Dropdown.Menu>
              </Dropdown>
            }
            {getParam.get('targetedContent') &&
              <div className="targeted-filter-wrapper">
                {
                  activeFilterTab?.['targetedContent']?.map((ele, i) => {
                    const fineTarget = targetedContentList?.['items']?.find((item) => item.title === ele) || [];
                    //  console.log("This is find tha conten",fineTarget.types);
                    return (
                      <CustomDropDown data={fineTarget} targetfilterChangeHandler={(e, item) =>
                        handleFilterClick(
                          item,
                          (ele.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase())
                        )} filterObj={activeFilterTab} />
                    )
                  }
                  )

                }
              </div>
            }
            {/* </div> */}
            {/* <div className="filter-wrapper">
          {[1, 2, 3, 4].map((_, i) => (
            <Trending
              key={i}
              classes="filter-trend lato-medium "
              image={Trend}
            />
          ))}
        </div> */}

            {dropdown && param.type !== "social-media" && (
              <div className="right-0 ">
                <DropdownCommon title={"Sort by"} />
              </div>
            )}
          </div>
        </div>
      }
      <div className="filter-open-warpper">
        <div className={`filter-side-bar ${showFilter && "show-filter"}`}>
          <div className="filter-title">
            <h6 className="lato-semibold f-s-18 black-242">Filter</h6>

            <div className={`clear-filter-wrapper pe-0`}>
              {/* <div onClick={() => setActiveFilterTab([])} className={`clear-filter-btn lato-medium fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
                Clear
              </div> */}
              <Image onClick={() => setShowFilter(!showFilter)} src={closeFilter} className="pointer " alt="cancle" />
            </div>
          </div>
          {filterData?.map((tab, i) => (
            <Accordion key={i} defaultActiveKey={"PREMIUM"}>
              <Card className={tab.title || "filter-premium-card"}>
                {tab.code !== "PREMIUM" && (
                  <Accordion.Toggle
                    as={Card.Header}
                    eventKey={(collectionType ?? "").replace(/s\b/g, '') === (tab.title.toLowerCase() === "creator" ? 'amc' : tab.title.toLowerCase()) ? '' : tab.title || "PREMIUM"}
                    className={(collectionType ?? "").replace(/s\b/g, '') === (tab.title.toLowerCase() === "creator" ? 'amc' : tab.title.toLowerCase()) ? "pointer-none" : ''}
                  >
                    <>
                      <h6 className="lato-semibold fx-14 black-242 ">
                        {tab.title}
                        {(getParam.get(tab.key) ?? false) &&
                          <span className="filter-indicator"></span>
                        }
                      </h6>
                      <Image src={DownIcon} alt="image" />
                    </>
                  </Accordion.Toggle>
                )}
                <Accordion.Collapse eventKey={tab.title || "PREMIUM"}>
                  <Card.Body className={tab.code === "AMC" ? "overflow-auto" : ""}>
                    {tab.code === "CUSTOMIZATION" ? (

                      <div className="customization-color-box">
                        {/* <div
                          className={`clear-filter-btn w-50 mb-3 text-center py-2 ellipsis pointer lato-medium black-242 fx-12 ${(activeFilterTab["is Multi Color".replace(/\s/g, "")] || []).includes("true") || (getParam.get("is Multi Color".replace(/\s/g, "")) === "true") ? 'active-bg' : ''}`}
                          onClick={() => handleFilterClick("true", "is Multi Color".replace(/\s/g, ""))}
                        > <Image src={colorIcon}    onClick={() => handleFilterClick("true", "is Multi Color".replace(/\s/g, ""))} alt="Image" className="multi-icon" />&nbsp;&nbsp;
                          is Multi Color
                        </div> */}
                        <div className="filter-color-frame flex-column  align-items-start gap-6">
                          <p className="lato-medium text-start f-s-12 grey-919">
                            Select Color
                          </p>
                          <div className="filter-multi-colors flex-wrap  align-items-center">
                            {tab.items?.map((color, i) => {
                              let isActive = false;
                              const activeFiltersForCategory = activeFilterTab[tab.key] || [];
                              isActive = activeFiltersForCategory.includes(color.title);

                              return (
                                <>
                                  <span
                                    key={color.uuid}
                                    title={color.title}
                                    onClick={() =>
                                      handleFilterClick(
                                        color.title.replace("/", "_"),
                                        tab.key
                                      )
                                    }
                                    className={`lato-semibold d-flex align-items-center gap-6 fx-12  ${isActive && getParam.get(tab.key)?.includes(color.title) ? "active-bg" : ""
                                      }`}
                                  >
                                    <div
                                      key={i}
                                      // className={`filter-colors ${isActive?'color-outline':''}`}
                                      className={`filter-colors border-0`}
                                      // onClick={() =>
                                      //   handleFilterClick(
                                      //     color.title.replace("/", "_"),
                                      //     tab.key
                                      //   )
                                      // }
                                      style={{ background: color.colorCode }}
                                      // style={{ background: color.colorCode,outlineColor: color.colorCode} }
                                      title={color.title}
                                    ></div>
                                    {color.title}
                                  </span>

                                </>
                              );
                            })}
                            <span
                              title={"Multi Color"}
                              onClick={() => handleFilterClick("true", "is Multi Color".replace(/\s/g, ""))}
                              className={`lato-semibold d-flex align-items-center gap-6 fx-12 ${(getParam.get('isMultiColor') ?? false) ? "active-bg" : ""} `}
                            >
                              <Image src={colorIcon} alt="Image" className={`multi-icon pointer`} /> Multi Color
                            </span>
                          </div>
                        </div>
                      </div>

                    ) : (
                      <>
                        {
                          tab.items.map((item) => {
                            let isActive = false;
                            // if (tab.key === "contentType") {
                            //   isActive = item.title.replace("/", "_") === contentType;
                            // } else {
                            const activeFiltersForCategory = activeFilterTab[tab.key] || [];
                            // console.log('Check type:=',param.type,'===',item.title,(param?.type?.toLowerCase()===item?.title?.toLowerCase()))
                            isActive = activeFiltersForCategory.includes(item.title);
                            const isDisabled = (param?.type?.toLowerCase() === item?.title?.toLowerCase());
                            // }
                            // console.log(getParam.get(tab.key),'itemTitle',item.title.replace("/", "_"),getParam.get(tab.key)?.includes(item.title))
                            return (
                              <span
                                key={item.uuid}
                                title={item.title}
                                onClick={
                                  () =>
                                    handleFilterClick(
                                      item.title.replace("/", "_"),
                                      tab.key
                                    )
                                }
                                className={`lato-semibold fx-12 ${isDisabled ? "pointer-none" : ''}   ${(isActive && getParam.get(tab.key)?.includes(item.title)) ? "active-bg" : ""
                                  }`}
                              >
                                {(item.title)}
                              </span>
                            );
                          })
                        }
                        {tab.code === "LANGUAGE" &&
                          <div
                            className={`clear-filter-btn w-50 mb-3 text-center py-2 ellipsis pointer lato-medium black-242 fx-12 ${(activeFilterTab["is Multi Language".replace(/\s/g, "")] || []).includes("true") || (getParam.get("is Multi Language".replace(/\s/g, "")) === "true") ? 'active-bg' : ''}`}
                            onClick={() => handleFilterClick("true", "is Multi Language".replace(/\s/g, ""))}
                          > <Image src={"is Multi Language" === 'is Multi Color' ? colorIcon : languageIcon} alt="Image" className="multi-icon" />&nbsp;&nbsp;
                            Multi Language
                          </div>

                        }

                      </>
                    )}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
            </Accordion>
          ))}
          {/* <div className="add-isMultiple-option-frame ">
            {["is Multi Color", "is Multi Language"].map((option, index) => (
              <div
                key={index}
                className={`clear-filter-btn w-100 text-center py-2 ellipsis pointer lato-medium black-242 fx-12 ${(activeFilterTab[option.replace(/\s/g, "")] || []).includes("true") || getParam.get(option.replace(/\s/g, "") === "true") ? 'active-bg' : ''}`}
                onClick={() => handleFilterClick("true", option.replace(/\s/g, ""))}
              > <Image src={option === 'is Multi Color' ? colorIcon : languageIcon} alt="Image" className="multi-icon" />&nbsp;&nbsp;
                {option}
              </div>
            ))}
          </div> */}
          <div className={`clear-filter-wrapper sticky-bottom justify-content-between  pe-0 mb-flex`}>
            <div onClick={() => {
              if (getParam.get('value') && validValues.includes(collectionType)) {
                history.push({ paathname: `/content-type/${param.type ? param.type : "All"}`, search: `key=${collectionType}&value=${getParam.get('value')}&pageNumber=${getParam.get('pageNumber') || START_PAGE}&pageSize=${getParam.get('pageSize') || API_LIMIT}` });
              } else {
                history.push({ paathname: `/content-type/${param.type ? param.type : "All"}`, search: `&pageNumber=${getParam.get('pageNumber') || START_PAGE}&pageSize=${getParam.get('pageSize') || API_LIMIT}` });

              } setActiveFilterTab([]); setAmcName1(defaultAmcState)
            }} className={`clear-filter-btn w-100 text-center py-2 pointer lato-medium black-242 fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
              Clear All
            </div>
            &nbsp;&nbsp;&nbsp;&nbsp;
            <div onClick={() => setShowFilter(true)} className={`clear-filter-btn w-100 text-center py-2 pointer lato-medium black-242 bg-primary text-white fs-16-14 ${(Object.keys(activeFilterTab).length < 1) ? 'd-none' : ""}`}>
              Apply
            </div>
          </div>
        </div>

        <div className={`right-data-frame fs-16-14 ${!showFilter ? "width-half" : "width-full"}`} >
          {component}
        </div>
      </div>
      {/* {console.log("This is section===",sessionStorage.getItem("fromLogin"),typeof(JSON.parse(sessionStorage.getItem("fromLogin")) ))} */}
      {/* {param.type === "All" && JSON.parse(sessionStorage.getItem("fromLogin")) &&
        <WelcomeFinModal show={showWelcom} handleClose={() => { setShowWelcom(false); sessionStorage.setItem('fromLogin', false); }} />
      } */}
    </div>
  );
}






function CustomDropDown({ data, targetfilterChangeHandler, filterObj }) {
  const [show, setShow] = useState(false);
  const location = useLocation();
  const params = new URLSearchParams(location.search);


  const isChecked = (item, title) => {
    const formattedTitle = (title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase());
    // console.log(filterObj[formattedTitle] ,"second=>", filterObj[formattedTitle]?.includes(item))
    return (filterObj[formattedTitle] && filterObj[formattedTitle].includes(item)) ?? false;
  };

  return (
    <>
      <div className={`target-item-dropdown  ${false ? 'zindex-6' : ''}`}>
        <Button onClick={() => setShow(!show)} className={`dropdown-toggle lato-medium f-s-14 lh-20 ${(params.get((data.title?.replace(/\s/g, ''))?.replace(/\w/, (a) => a.toLowerCase())) ?? false) ? 'active-bg' : ''}`} id={data.title}>
          {data.title}
        </Button>
        <ul className={`dropdown-menu ${show ? 'show-menu' : ''} `} >
          {
            data.types?.map((item, i) => {
              return (
                <li key={i} className='lato-regular'>
                  <input
                    type="checkbox"
                    onChange={(e) => targetfilterChangeHandler(e, item, (data.title.replace(/\s/g, '')).replace(/\w/, (a) => a.toLowerCase()))}
                    checked={isChecked(item, data.title)}
                    className='targeted-chekbox'
                    id={item + i}
                  />
                  <label className='w-100 pointer m-0' htmlFor={item + i}>{item}</label>
                </li>
              )
            })
          }
        </ul>
      </div>
      <div onClick={() => setShow(false)} className={show ? "dropdown-baground-wrapper" : "d-none"}> </div>
    </>
  )
}